import type { Dispatch } from 'redux';
import { SetupIntent } from '@stripe/stripe-js';
import type { InvoiceInfo } from 'src/@types/profile-subscription';
import { type PaginatedResponse, PaginationParams } from 'src/@types/pagination';
import type {
    BillingRecordsResponse,
    DomainResponse,
    ManagersResponse,
    SubscriptionActionsResponse,
    SubscriptionModel,
    SubscriptionUserModel,
} from 'src/@types/subscription-service-api';
import type { APIClientRequestConfig } from 'src/@types/api-client';
import {
    AvailablePaymentMethods,
    ChangeEmailCheckData,
    ChangeEmailRequestData,
    ChangeEmailRequestSendData,
    ChangePasswordRequestData,
    ChangePhoneNumberRequestSendData,
    ChangePhoneNumberRequestVerifyData,
    CheckoutConfig,
    CheckSubscriptionRequestData,
    CreateAccountData,
    CreateAccountResponse,
    CreateCustomerParams,
    CreateSubscriptionBillingInvoiceParams,
    CustomerInfo,
    GenerateLinkRequest,
    GenerateLinkResponse,
    GetPaidSubscriptionUsageRequestData,
    InvoicePreviewInfo,
    InvoicePreviewRequest,
    LogInRequestData,
    LogInResponseData,
    LogInViaCodeRequestData,
    NewsletterList,
    NewsletterModel,
    PaidSubscriptionUsageData,
    ProfileAccessibleSubscription,
    ProfileEmailDeliveryPreferences,
    ResetPasswordRequestData,
    SaveCustomerRequest,
    SearchRequestData,
    SearchResponse,
    SendLogInCodeRequestData,
    SendResetPasswordRequestData,
    SettingsData,
    SignUpData,
    SignUpResponse,
    StripeCustomerInfo,
    StripeInvoice,
    SubscriberSummary,
    SubscriptionPaymentMethodDetails,
    SubscriptionToCheckoutRequest,
    SubscriptionToCheckoutResponse,
    SubscriptionUsage,
    TfaLogInRequestData,
    TokenCollection,
    TokensResponseData,
    UpdateEmailDeliveryPreferences,
    UpdateNewsletterDeliveryPreferences,
    UserActiveSubscriptions,
    ValidateAccessCodeRequestData,
    ValidateAccessCodeResponseData,
    ValidateCouponResponse,
    VerifyCheckoutResultRequest,
    VerifyCheckoutResultResponse,
} from 'src/@types/sso-api';
import type { SsoAPIEventRevokeToken, SsoAPIEventUpdateTokens } from 'src/@types/sso-api-client';
import SsoApiClient from 'src/classes/sso-api-client';
import TokenProvider from 'src/classes/token-provider';
import APIClientError from 'src/classes/api-client-error';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, SsoApiEventTypes, TOKEN_EXPIRED_CODE } from 'src/constants';
import { clearCurrentUser, setCurrentUser } from 'src/redux/slices';
import { Application, ApplicationsSearchParams } from 'src/@types/application';
import { Credentials, CredentialsSearchParams, CredentialsUpdateProps } from 'src/@types/credentials';
import { ProfileInfo } from 'src/@types/profile-info';
import { IpGeolocationData } from 'src/@types/ip-geolocation';
import { RequestAQuoteInfo } from 'src/@types/reques-a-quote';

export let ssoApiInitialized = false;

let currentTokenExpiration: number | undefined;
let tokenRefreshingPromise: Promise<void> = Promise.resolve();

export const ssoApiClient = new SsoApiClient(new TokenProvider([
    { key: ACCESS_TOKEN_KEY, storage: sessionStorage },
    { key: REFRESH_TOKEN_KEY, storage: localStorage },
]));

export const refreshToken = async (clear = true, onError = () => {}) => {
    await tokenRefreshingPromise;
    tokenRefreshingPromise = ssoApiClient.refreshToken(clear).catch(onError);
    await tokenRefreshingPromise;
};

export const initSsoApi = async (baseURL: string, dispatch: Dispatch) => {
    ssoApiClient.init({ baseURL });
    ssoApiClient.on<SsoAPIEventUpdateTokens>(SsoApiEventTypes.updateTokens, (payload) => {
        dispatch(setCurrentUser(payload));
        currentTokenExpiration = payload.exp ? (payload.exp - 3) * 1000 : undefined;
    });
    ssoApiClient.on<SsoAPIEventRevokeToken>(SsoApiEventTypes.revokeToken, () => {
        dispatch(clearCurrentUser());
        currentTokenExpiration = undefined;
    });

    await refreshToken();
    ssoApiInitialized = true;
};

const refreshTokenIfNeeded = async () => {
    await tokenRefreshingPromise;
    if (currentTokenExpiration && currentTokenExpiration < Date.now()) {
        currentTokenExpiration = undefined;
        await refreshToken(false);
    }
};

export const ssoApiRequest = async <RequestDataType extends object | void, ResponseType extends object | string | void>(
    url: string,
    config?: APIClientRequestConfig<RequestDataType>,
): Promise<ResponseType> => {
    await refreshTokenIfNeeded();

    return ssoApiClient.request<RequestDataType, ResponseType>(url, config)
        .catch(async (error: APIClientError | Error) => {
            if (
                !ssoApiClient.tokenProvider.getToken(REFRESH_TOKEN_KEY)
                || !(error instanceof APIClientError)
                || !error.responseError
                || !('status' in error.responseError)
                || error.responseError.status !== TOKEN_EXPIRED_CODE
            ) {
                throw error;
            }

            await refreshToken(true, () => ssoApiClient.revokeToken());

            return ssoApiClient.request(url, config);
        });
};

export const revokeToken = async (refreshTokenToRevoke: string): Promise<void> => (
    ssoApiRequest<{ refreshToken: string }, void>('/auth/token/revoke', {
        method: 'POST',
        data: { refreshToken: refreshTokenToRevoke },
    })
);

export const revokeTokens = async (refreshTokens: string[]): Promise<void> => (
    ssoApiRequest<{ refreshTokens: string[] }, void>('/auth/token/revoke-many', {
        method: 'POST',
        data: { refreshTokens },
    })
);

export const disableTwoFactorAuth = async (): Promise<void> => {
    return ssoApiRequest<void, void>('/auth/2fa/disable', { method: 'POST' });
};

export const enabledTwoFactorAuth = async (key: string, code: string): Promise<void> => {
    return ssoApiRequest<{ key: string, code: string }, void>('/auth/2fa/enable', {
        method: 'POST',
        data: { key, code },
    });
};

export const logIn = async (logInData: Omit<LogInRequestData, 'returnPayload'>): Promise<LogInResponseData<true>> => {
    // not logged-in user, use ssoApiClient.request directly
    return ssoApiClient.request<LogInRequestData<true>, LogInResponseData<true>>('/auth/log-in', {
        method: 'POST',
        data: { ...logInData, returnPayload: true },
    }).then((data) => {
        if (!('tfaRequired' in data)) {
            ssoApiClient.updateTokens(data);
        }
        return data;
    });
};

export const tfaLogIn = async (logInData: Omit<TfaLogInRequestData, 'returnPayload'>): Promise<TokensResponseData<true>> => {
    // not logged-in user, use ssoApiClient.request directly
    return ssoApiClient.request<TfaLogInRequestData<true>, TokensResponseData<true>>('/auth/2fa/log-in', {
        method: 'POST',
        data: { ...logInData, returnPayload: true },
    }).then((data) => {
        ssoApiClient.updateTokens(data);
        return data;
    });
};

export const logInViaCode = async (accessCode: string): Promise<TokensResponseData<true>> => {
    // not logged-in user, use ssoApiClient.request directly
    return ssoApiClient.request<LogInViaCodeRequestData<true>, TokensResponseData<true>>('/auth/log-in-code', {
        method: 'POST',
        data: { accessCode, returnPayload: true },
    })
        .then((data) => {
            ssoApiClient.updateTokens(data);
            return data;
        });
};

export const validateAccessCode = async (accessCode: string): Promise<ValidateAccessCodeResponseData> => {
    // not logged-in user, use ssoApiClient.request directly
    return ssoApiClient.request<ValidateAccessCodeRequestData, ValidateAccessCodeResponseData>(
        '/auth/access-code/validate',
        {
            method: 'POST',
            data: { accessCode },
        },
    );
};

export const sendLogInCode = async (email: string) => {
    // not logged-in user, use ssoApiClient.request directly
    return ssoApiClient.request<SendLogInCodeRequestData>('/auth/log-in-code/send', {
        method: 'POST',
        data: { email },
    });
};

export const sendResetPassword = async (email: string) => {
    // not logged-in user, use ssoApiClient.request directly
    return ssoApiClient.request<SendResetPasswordRequestData>('/auth/reset-password/send', {
        method: 'POST',
        data: { email },
    });
};

export const resetPassword = async (data: ResetPasswordRequestData) => {
    // not logged-in user, use ssoApiClient.request directly
    return ssoApiClient.request<ResetPasswordRequestData>('/auth/reset-password', { method: 'POST', data });
};

export const changePassword = async (data: ChangePasswordRequestData): Promise<void> => {
    return ssoApiRequest<ChangePasswordRequestData, void>('/auth/change-password', { method: 'POST', data });
};

export const changeEmailSend = async (data: ChangeEmailRequestSendData): Promise<void> => {
    return ssoApiRequest<ChangeEmailRequestSendData, void>('/profile/change-email/send', { method: 'POST', data });
};

export const changeEmail = async (data: ChangeEmailRequestData): Promise<void> => {
    return ssoApiRequest<ChangeEmailRequestData, void>('/profile/change-email', { method: 'POST', data });
};

export const changeEmailCheck = async (): Promise<ChangeEmailCheckData> => {
    return ssoApiRequest<void, ChangeEmailCheckData>('/profile/change-email/check', { method: 'GET' });
};

export const changePhoneNumberSend = async (data: ChangePhoneNumberRequestSendData): Promise<void> => {
    return ssoApiRequest<ChangePhoneNumberRequestSendData, void>('/profile/change-phone-number/send', { method: 'POST', data });
};

export const changePhoneNumber = async (data: ChangePhoneNumberRequestVerifyData): Promise<void> => {
    return ssoApiRequest<ChangePhoneNumberRequestVerifyData, void>('/profile/change-phone-number', { method: 'POST', data });
};

export const clearPhoneNumber = async (): Promise<void> => {
    return ssoApiRequest<void, void>('/profile/change-phone-number/clear', { method: 'POST' });
};

export const getProfileSubscription = async (subscriptionUUID: string): Promise<SubscriptionModel> => {
    return ssoApiRequest<void, SubscriptionModel>(`/profile/subscriptions/${subscriptionUUID}`);
};

export const getProfileSubscriptionManagers = async (
    subscriptionUUID: string,
    params?: PaginationParams,
): Promise<ManagersResponse> => {
    return ssoApiRequest<PaginationParams, ManagersResponse>(`/profile/subscriptions/${subscriptionUUID}/managers`, {
        params,
    });
};

export const getProfileSubscriptionBillingRecord = async (
    subscriptionUUID: string,
    params?: PaginationParams,
): Promise<BillingRecordsResponse> => {
    return ssoApiRequest<PaginationParams, BillingRecordsResponse>(`/profile/subscriptions/${subscriptionUUID}/billing`, {
        params,
    });
};

export const getProfileSubscriptionDomains = async (
    subscriptionUUID: string,
    params?: PaginationParams,
): Promise<DomainResponse> => {
    return ssoApiRequest<PaginationParams, DomainResponse>(`/profile/subscriptions/${subscriptionUUID}/domains`, {
        params,
    });
};

export const getProfileSubscriptionActions = async (
    uuid: string,
): Promise<SubscriptionActionsResponse> => {
    return ssoApiRequest<void, SubscriptionActionsResponse>(`/profile/subscriptions/${uuid}/actions`, {
        method: 'GET',
    });
};

export const getProfileSubscriptionUsers = async (
    subscriptionUUID: string,
    params?: PaginationParams,
): Promise<PaginatedResponse<SubscriptionUserModel>> => {
    return ssoApiRequest<PaginationParams, PaginatedResponse<SubscriptionUserModel>>(`/profile/subscriptions/${subscriptionUUID}/users`, {
        params,
    });
};

export const getProfileSubscriptionUsage = async (subscriptionUUID: string) => {
    return ssoApiRequest<void, SubscriptionUsage>(`/profile/subscriptions/${subscriptionUUID}/usage`);
};

export const getProfileActiveSubscriptions = () => {
    return ssoApiRequest<void, UserActiveSubscriptions[]>('/profile/subscriptions/list-active');
};

export const getProfileAccessibleSubscriptions = async (
    includeInactive = true,
): Promise<ProfileAccessibleSubscription[]> => {
    return ssoApiRequest<{ includeInactive?: boolean }, ProfileAccessibleSubscription[]>('/profile/subscriptions/accessible-subscriptions', {
        params: {
            includeInactive: includeInactive,
        },
    });
};

export const getProfileSubscriptionPaymentMethod = async (subscriptionUUID: string) => {
    return ssoApiRequest<void, SubscriptionPaymentMethodDetails>(`/profile/subscriptions/${subscriptionUUID}/payment-method`);
};

export const getSubscriptionPaymentMethod = async (subscriptionUUID: string) => {
    return ssoApiRequest<void, SubscriptionPaymentMethodDetails>(`/subscriptions/${subscriptionUUID}/payment-method`);
};

export const getAvailableSubscriptionPaymentMethods = async (subscriptionUUID: string) => {
    return ssoApiRequest<void, AvailablePaymentMethods>(`/profile/subscriptions/${subscriptionUUID}/payment-methods/available`);
};

export const updateProfileSubscriptionPaymentMethod = async (
    subscriptionUUID: string,
    id: string,
) => {
    return ssoApiRequest<{ id: string }, void>(`/profile/subscriptions/${subscriptionUUID}/payment-method`, {
        method: 'POST',
        data: { id },
    });
};

export const removePaymentMethod = async (subscriptionUUID: string, paymentMethodId: string) => {
    return ssoApiRequest<void, void>(`/profile/subscriptions/${subscriptionUUID}/payment-methods/${paymentMethodId}`, {
        method: 'DELETE',
    });
};

export const restoreProfileSubscription = async (subscriptionUUID: string): Promise<void> => {
    return ssoApiRequest<void, void>(`/profile/subscriptions/${subscriptionUUID}/restore`, {
        method: 'POST',
    });
};

export const cancelProfileSubscription = async (subscriptionUUID: string): Promise<void> => {
    return ssoApiRequest<void, void>(`/profile/subscriptions/${subscriptionUUID}/cancel`, {
        method: 'POST',
    });
};

export const searchApplications = async (
    data?: SearchRequestData<ApplicationsSearchParams>,
): Promise<SearchResponse<Application>> => {
    return ssoApiRequest<SearchRequestData<ApplicationsSearchParams>, SearchResponse<Application>>('/applications/search', {
        method: 'POST',
        data,
    });
};

export const searchCredentials = async (
    data?: SearchRequestData<CredentialsSearchParams>,
): Promise<SearchResponse<Credentials>> => {
    return ssoApiRequest<SearchRequestData<CredentialsSearchParams>, SearchResponse<Credentials>>('/credentials/search', {
        method: 'POST',
        data,
    });
};

export const updateCredentials = async (
    credentialsID: number,
    data: CredentialsUpdateProps,
): Promise<Credentials> => {
    return ssoApiRequest<CredentialsUpdateProps, Credentials>(`/credentials/${credentialsID}`, {
        method: 'PATCH',
        data,
    });
};

export const getProfileInfo = async (): Promise<ProfileInfo> => {
    return ssoApiRequest<Partial<ProfileInfo>, ProfileInfo>('/profile/info');
};

export const updateProfileInfo = async (data: Partial<ProfileInfo>): Promise<ProfileInfo> => {
    return ssoApiRequest<Partial<ProfileInfo>, ProfileInfo>('/profile/info', {
        method: 'PATCH',
        data,
    });
};

export const updateRequestAQuoteInfo = async (data: Partial<RequestAQuoteInfo>): Promise<RequestAQuoteInfo> => {
    return ssoApiRequest<Partial<RequestAQuoteInfo>, RequestAQuoteInfo>('/subscriptions/request-a-quote', {
        method: 'POST',
        data,
    });
};

export const fetchProfileNewsletterDelivery = async (): Promise<ProfileEmailDeliveryPreferences> => {
    return ssoApiRequest<void, ProfileEmailDeliveryPreferences>('/profile/newsletters-delivery');
};

export const updateProfileNewsletterDelivery = async (
    id: string,
    data: UpdateNewsletterDeliveryPreferences,
): Promise<void> => {
    return ssoApiRequest<UpdateNewsletterDeliveryPreferences, void>(`/profile/newsletters-delivery/${id}`, {
        method: 'PATCH',
        data,
    });
};

export const fetchEmailDelivery = async (uuid: string): Promise<ProfileEmailDeliveryPreferences> => {
    return ssoApiRequest<void, ProfileEmailDeliveryPreferences>(`/email-delivery/${uuid}`);
};

export const updateEmailDelivery = async (
    uuid: string,
    data: UpdateEmailDeliveryPreferences,
): Promise<void> => {
    return ssoApiRequest<UpdateEmailDeliveryPreferences, void>(`/email-delivery/${uuid}`, {
        method: 'POST',
        data,
    });
};

export const fetchPaidSubscriptionUsage = async (
    uuid: string,
    data: GetPaidSubscriptionUsageRequestData,
): Promise<PaidSubscriptionUsageData> => {
    return ssoApiRequest<GetPaidSubscriptionUsageRequestData, PaidSubscriptionUsageData>(
        `/stats/paid-subscriptions/${uuid}/usage`,
        {
            method: 'POST',
            data,
        },
    );
};

export const checkEmailAvailability = async (email: string): Promise<{ available: boolean }> => {
    return ssoApiRequest<{ email: string }, { available: boolean }>('/email/check-availability', {
        method: 'POST',
        data: { email },
    });
};

export const getNewsletterList = async (): Promise<NewsletterList> => {
    return ssoApiRequest<void, NewsletterList>('/newsletters/list-by-verticals');
};

export const signUp = async (data: SignUpData): Promise<SignUpResponse> => {
    return ssoApiRequest<SignUpData, SignUpResponse>('/auth/sign-up', {
        method: 'POST',
        data,
    })
        .then((responseData) => {
            ssoApiClient.updateTokens(responseData);
            return responseData;
        });
};

export const getNewsletterByKey = async (key: string): Promise<NewsletterModel> => {
    return ssoApiRequest<void, NewsletterModel>(`/newsletters/get-by-key/${key}`);
};

export const getTokenCollection = async (): Promise<TokenCollection> => {
    return ssoApiRequest<object, { tokenCollection: TokenCollection }>('/auth/token/list')
        .then(({ tokenCollection }) => tokenCollection);
};

export const getGeolocationByIP = (ip: string): Promise<IpGeolocationData> => {
    return ssoApiRequest<{ ip: string }, IpGeolocationData>('/geolocation-by-ip', { params: { ip } });
};

export const updateSettings = async (data: Partial<SettingsData>): Promise<SettingsData> => {
    return ssoApiRequest<Partial<SettingsData>, SettingsData>('/profile/settings', { method: 'POST', data });
};

export const checkSubscriberByEmail = async (email: string): Promise<SubscriberSummary> => {
    return ssoApiRequest<CheckSubscriptionRequestData, SubscriberSummary>('/email/check-subscriber', {
        method: 'POST',
        data: { email },
    });
};

export const createAccount = async (data: CreateAccountData): Promise<CreateAccountResponse> => {
    return ssoApiRequest<CreateAccountData, CreateAccountResponse>('/auth/create-account', {
        method: 'POST',
        data,
    });
};

export const generateLink = (data: GenerateLinkRequest): Promise<GenerateLinkResponse> => {
    return ssoApiRequest<GenerateLinkRequest, GenerateLinkResponse>('/auth/access-code/generate', {
        method: 'POST',
        data,
    });
};

export const requestChangeEmailForUser = async (newEmail: string, uuid: string): Promise<void> => {
    return ssoApiRequest<{ newEmail: string, uuid: string }, void>('/email/request-change/send', {
        method: 'POST',
        data: { newEmail, uuid },
    });
};

export const checkChangeEmailStatusForUser = async (uuid: string): Promise<ChangeEmailCheckData> => {
    return ssoApiRequest<{ uuid: string }, ChangeEmailCheckData>('/email/request-change/check', {
        method: 'POST',
        data: { uuid },
    });
};

let checkoutConfig: CheckoutConfig | undefined;

export const getCheckoutConfig = async (): Promise<CheckoutConfig> => {
    if (!checkoutConfig) {
        checkoutConfig = await ssoApiRequest<void, CheckoutConfig>('/checkout/config');
    }

    return checkoutConfig;
};

export const getCustomerByUserUUID = async (userUUID: string): Promise<{ customer: StripeCustomerInfo | null }> => {
    return ssoApiRequest<void, { customer: StripeCustomerInfo | null }>(`/checkout/customer/${userUUID}`);
};

export const saveCustomer = async (
    data: SaveCustomerRequest,
): Promise<{ customer: StripeCustomerInfo }> => {
    return ssoApiRequest<SaveCustomerRequest, { customer: StripeCustomerInfo }>('/checkout/customer', {
        method: 'POST',
        data,
    });
};

export const createInvoicePreview = async (
    data: InvoicePreviewRequest,
): Promise<InvoicePreviewInfo> => {
    return ssoApiRequest<InvoicePreviewRequest, InvoicePreviewInfo>('/checkout/invoice-preview', {
        method: 'POST',
        data,
    });
};

export const createSubscriptionToCheckout = async (
    data: SubscriptionToCheckoutRequest,
): Promise<SubscriptionToCheckoutResponse> => {
    return ssoApiRequest<SubscriptionToCheckoutRequest, SubscriptionToCheckoutResponse>('/checkout/subscription', {
        method: 'POST',
        data,
    });
};

export const verifyCheckoutResult = async (
    accessCode: string,
    paymentIntentId: string,
): Promise<VerifyCheckoutResultResponse> => {
    return ssoApiRequest<VerifyCheckoutResultRequest, VerifyCheckoutResultResponse>('/checkout/verify-result', {
        method: 'POST',
        data: { accessCode, paymentIntentId },
    });
};

export const validateCoupon = async (
    code: string,
): Promise<ValidateCouponResponse> => {
    return ssoApiRequest<{ code: string }, ValidateCouponResponse>('/checkout/validate-coupon', {
        method: 'POST',
        data: { code },
    });
};

export const getProfileBillingInvoice = async (
    subscriptionUUID: string,
    billingRecordId: number,
): Promise<InvoiceInfo> => {
    return ssoApiRequest<void, InvoiceInfo>(`/profile/subscriptions/${subscriptionUUID}/billing/${billingRecordId}/invoice`);
};

export const getStripeCustomerInfo = async (
    stripeCustomerId: string,
): Promise<StripeCustomerInfo> => {
    return ssoApiRequest<void, StripeCustomerInfo>(`/stripe/customers/${stripeCustomerId}`);
};

export const getStripeInvoice = async (invoiceId: string) => {
    return ssoApiRequest<void, StripeInvoice>(`/stripe/invoice/${invoiceId}`);
};

export const assignStripeCustomer = async (
    subscriptionUUID: string,
    stripeCustomerId: string,
): Promise<void> => {
    return ssoApiRequest<{ stripeCustomerId: string }, void>(`/subscriptions/${subscriptionUUID}/customer`, {
        method: 'POST',
        data: { stripeCustomerId },
    });
};

export const createStripeCustomer = async (data: CreateCustomerParams): Promise<StripeCustomerInfo> => {
    return ssoApiRequest<CreateCustomerParams, StripeCustomerInfo>('/stripe/customers', {
        method: 'POST',
        data,
    });
};

export const updateStripeCustomer = async (
    stripeCustomerId: string,
    data: Partial<CreateCustomerParams>,
): Promise<StripeCustomerInfo> => {
    return ssoApiRequest<Partial<CreateCustomerParams>, StripeCustomerInfo>(`/stripe/customers/${stripeCustomerId}`, {
        method: 'PATCH',
        data,
    });
};

export const updateProfileStripeCustomer = async (
    subscriptionUUID: string,
    data: Partial<CreateCustomerParams>,
): Promise<StripeCustomerInfo> => {
    return ssoApiRequest<Partial<CreateCustomerParams>, StripeCustomerInfo>(`/profile/subscriptions/${subscriptionUUID}/customer`, {
        method: 'PATCH',
        data,
    });
};

export const getCustomerInfo = async (
    subscriptionUUID: string,
): Promise<CustomerInfo> => {
    return ssoApiRequest<void, CustomerInfo>(`/subscriptions/${subscriptionUUID}/customer`);
};

export const getProfileCustomerInfo = async (
    subscriptionUUID: string,
): Promise<CustomerInfo> => {
    return ssoApiRequest<void, CustomerInfo>(`profile/subscriptions/${subscriptionUUID}/customer`);
};

export const createSubscriptionBillingInvoice = async (
    subscriptionUUID: string,
    billingRecordId: number,
    data?: CreateSubscriptionBillingInvoiceParams,
): Promise<StripeInvoice> => {
    return ssoApiRequest<CreateSubscriptionBillingInvoiceParams, StripeInvoice>(`/subscriptions/${subscriptionUUID}/billing/${billingRecordId}/invoice`, {
        method: 'POST',
        data,
    });
};

export const attachInvoiceToBillingRecord = async (
    subscriptionUUID: string,
    billingRecordId: number,
    stripeInvoiceID: string,
): Promise<StripeInvoice> => {
    return ssoApiRequest<{ stripeInvoiceID: string }, StripeInvoice>(`/subscriptions/${subscriptionUUID}/billing/${billingRecordId}/invoice/attach`, {
        method: 'POST',
        data: { stripeInvoiceID },
    });
};

export const finalizeBillingRecordInvoice = async (
    subscriptionUUID: string,
    billingRecordId: number,
): Promise<StripeInvoice> => {
    return ssoApiRequest<void, StripeInvoice>(`/subscriptions/${subscriptionUUID}/billing/${billingRecordId}/invoice/finalize`, {
        method: 'POST',
    });
};

export const fetchCredentialsSettings = async (credentialsId: number): Promise<SettingsData> => {
    return ssoApiRequest<void, SettingsData>(`/credentials/${credentialsId}/settings`);
};

export const updateCredentialsSettings = async (
    credentialsId: number,
    data: Partial<SettingsData>,
): Promise<SettingsData> => {
    return ssoApiRequest<Partial<SettingsData>, SettingsData>(`/credentials/${credentialsId}/settings`, {
        method: 'POST',
        data,
    });
};

export const createSetupIntent = async (subscriptionUUID: string): Promise<SetupIntent> => {
    return ssoApiRequest<void, SetupIntent>(`/profile/subscriptions/${subscriptionUUID}/payment-methods/setup-intent`, {
        method: 'POST',
    });
};
