import {
    ChangePaymentMethodModalProps,
} from 'src/components/Modals/ChangePaymentMethodModal/ChangePaymentMethodModal';
import { AddPaymentMethodModalProps } from 'src/components/Modals/AddPaymentMethodModal/AddPaymentMethodModal';
import type { ManageActionType } from 'src/components/ManageActions/ManageActions';
import { ManageProfileSubscriptionActions, ProfilePaymentMethodSectionModal, SubscriptionAction } from 'src/constants';
import { PaymentMethodDetails, type StripeCustomerInfo, SubscriptionPaymentMethodDetails } from 'src/@types/sso-api';

export const getManageSubscriptionActionsList = (
    isActionAllowed: (actionKey: SubscriptionAction) => boolean,
): Partial<Record<ManageProfileSubscriptionActions, ManageActionType>> => {

    return {
        ...(isActionAllowed(SubscriptionAction.CANCEL) && { [ManageProfileSubscriptionActions.cancelSubscription]: { label: 'Cancel subscription' } }),
        ...(isActionAllowed(SubscriptionAction.RESTORE) && { [ManageProfileSubscriptionActions.restoreSubscription]: { label: 'Restore subscription' } }),
    };
};

export const getPaymentMethodSectionActionsList = ({
    paymentMethod,
    availablePaymentMethods,
    customer,
}: {
    paymentMethod?: SubscriptionPaymentMethodDetails;
    availablePaymentMethods?: Array<PaymentMethodDetails>;
    customer?: StripeCustomerInfo | null;
}): Partial<Record<ProfilePaymentMethodSectionModal, ManageActionType>> => {
    const card = paymentMethod?.card;
    const hasPaymentMethods = !!availablePaymentMethods?.length;

    const addPaymentMethodModalProps: AddPaymentMethodModalProps = {
        customer,
    };

    const changePaymentMethodModalProps: ChangePaymentMethodModalProps = {
        id: paymentMethod?.id,
        paymentMethods: availablePaymentMethods,
        customer,
    };

    return {
        ...((!card && !hasPaymentMethods) && { [ProfilePaymentMethodSectionModal.addPaymentMethodModal]: {
            label: 'Add payment method',
            actionExtraProps: addPaymentMethodModalProps,
        } }),
        ...((card || hasPaymentMethods) && { [ProfilePaymentMethodSectionModal.changePaymentMethodModal]: {
            label: `${hasPaymentMethods && !card ? 'Assign' : 'Change'} payment method`,
            actionExtraProps: changePaymentMethodModalProps,
        } }),
    };
};
