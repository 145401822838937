import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { useOverflowTooltip } from 'src/hooks/useOverflowTooltip';
import { Scope, useUserCan } from 'src/hooks/useUserCan';
import { AssociatedLinkSection } from 'src/components/AssociatedLinkSection';
import { userRoleRenderMapping } from 'src/constants';
import { BadgeVariant } from 'src/components/Badge/Badge/Badge.styles';
import type { ManageActionType } from 'src/components/ManageActions/ManageActions';
import { ManageActions } from 'src/components/ManageActions';
import { Badge } from 'src/components/Badge';
import {
    SecondaryTitle,
    StyledGrid,
    StyledStack,
    SubscriptionInfo,
} from 'src/components/SubscriptionManageHeader/ManageHeader.styles';
import type { Credentials } from 'src/@types/credentials';
import StyledTooltip from 'src/components/Tooltip';

type ManageUserHeaderProps = {
    credentials: Credentials;
    userName?: string;
    actionsList: Record<string, ManageActionType>;
    isLoaded: boolean;
};

const ManageCredentialsHeader: FC<ManageUserHeaderProps> = ({
    actionsList,
    userName,
    credentials,
    isLoaded,
}) => {
    const { email, role, uuid } = credentials;
    const canReadUDB = useUserCan(Scope.UDB_READ);
    const { ref, tooltipTitle } = useOverflowTooltip(userName);

    return (
        <StyledGrid data-testid="credentials-header" container alignItems="flex-start" marginY={{ xs: 2, sm: 2.5 }}>
            <Grid item xs={12} sm={9}>
                <StyledStack direction="row" gap={1}>
                    <StyledTooltip arrow title={tooltipTitle}>
                        <SecondaryTitle ref={ref}>{userName}</SecondaryTitle>
                    </StyledTooltip>
                    <Badge label={userRoleRenderMapping[role]} variant={BadgeVariant.BLUE} />
                </StyledStack>
                <StyledStack direction="row" marginTop={0.5}>
                    <SubscriptionInfo>
                        {email}
                    </SubscriptionInfo>
                </StyledStack>
            </Grid>
            <Grid
                item
                container
                xs={3}
                justifyContent="flex-end"
                position={{ xs: 'absolute', sm: 'relative' }}
                top={0}
                right={0}
            >
                <ManageActions
                    credentials={credentials}
                    uuid={credentials.uuid}
                    actionsList={actionsList}
                    isLoaded={isLoaded}
                />
            </Grid>
            {canReadUDB && (
                <AssociatedLinkSection
                    href={`/udb/users/${uuid}`}
                    linkText="Open associated user page"
                />
            )}
        </StyledGrid >
    );
};

export default ManageCredentialsHeader;
