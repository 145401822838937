import { useEffect } from 'react';
import dayjs from 'dayjs';

export const useExecuteAt = (
    callable: (...args: any[]) => any | void, // eslint-disable-line @typescript-eslint/no-explicit-any
    executeAt: string | Date | false | null | undefined,
    maxDelayInMins: number = 60,
) => {
    useEffect(() => {
        if (!executeAt) {
            return;
        }

        const delay = Math.max(0, dayjs.utc(executeAt).diff(dayjs.utc()));

        if (delay > maxDelayInMins * 60000) {
            return;
        }

        const timeoutId = setTimeout(() => {
            callable();
        }, delay);

        return () => clearTimeout(timeoutId);
    }, [executeAt, maxDelayInMins]);
};
