import { SubscriptionStatus } from 'src/constants';
import { BillingRecordWithRenewal, SubscriptionModel } from 'src/@types/subscription-service-api';

export const getContractConfirmationMessage = ({
    billingRecord,
    subscription,
    isActivationAllowed,
    isContractJustSigned,
}: {
    billingRecord: BillingRecordWithRenewal;
    subscription: SubscriptionModel;
    isActivationAllowed: boolean;
    isContractJustSigned: boolean;
}): string | undefined => {
    const { isInitial, isUpcoming, cycleStart, isCurrent } = billingRecord;
    const { status, activationDate, expirationDate } = subscription;

    if (!isContractJustSigned) {
        return;
    }

    if (isInitial && status === SubscriptionStatus.DRAFT && activationDate === null && isActivationAllowed) {
        return 'Subscription will be activated shortly after saving changes.';
    }

    if (isCurrent && status === SubscriptionStatus.PAUSED) {
        return 'Subscription will be resumed shortly after saving changes.';
    }

    if (isUpcoming && cycleStart === expirationDate) {
        const messageMapping: Partial<Record<SubscriptionStatus, string>> = {
            [SubscriptionStatus.ACTIVE]: 'Subscription will be extended shortly after saving changes.',
            [SubscriptionStatus.PAUSED]: 'Subscription will be resumed and extended shortly after saving changes.',
            [SubscriptionStatus.SERVICE_ENDED]: 'Subscription will be re-activated and extended shortly after saving changes.',
        };

        return messageMapping[subscription.status];
    }
};
