import React, { FC, useEffect } from 'react';
import { useReloadPage, useSnackbarMessage } from 'src/hooks';
import { updateBillingRecordNotification } from 'src/services/subscription-service-api';
import { useConfirmationModal } from 'src/hooks/useConfirmationModal';
import { BillingRecordWithRenewal } from 'src/@types/subscription-service-api';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';

export type EditPaymentRenewalNotificationProps = {
    billingRecord: Required<BillingRecordWithRenewal>;
    uuid: string;
};

const EditPaymentRenewalNotification: FC<EditPaymentRenewalNotificationProps> = ({ billingRecord, uuid }) => {
    const { notification, id } = billingRecord;

    const { reloadPage } = useReloadPage();
    const { addErrorMessage } = useSnackbarMessage();
    const { modalId, updateModal } = useConfirmationModal({
        onConfirm: async () => {
            await updateBillingRecordNotification(uuid, id, {
                disableRenewalNotifications: !notification?.disableRenewalNotifications,
            })
                .then(reloadPage)
                .catch((error) => addErrorMessage('Failed to update renewal', error));
        },
    });

    useEffect(() => {
        updateModal({
            title: `${notification?.disableRenewalNotifications ? 'Enable' : 'Disable'} notifications`,
        });
    }, []);

    return (
        <ConfirmationModal
            subtitle={
                `Are you sure you want to ${notification?.disableRenewalNotifications ? 'enable' : 'disable'} renewal notifications?`
            }
            confirmButtonText="Confirm"
            modalId={modalId}
        />
    );
};

export default EditPaymentRenewalNotification;
