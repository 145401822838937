import React, { FC } from 'react';
import { Box, Grid, GridProps, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useModalWithAnchor } from 'src/hooks';
import InvoiceLink from 'src/components/InvoiceLink';
import { Scope, useUserCan } from 'src/hooks/useUserCan';
import { ActionButton, ActionButtonVariant } from 'src/components/Buttons/ActionButton';
import {
    StyledPaymentAmountInfo,
    StyledPeriodInfo,
    StyledPlainText,
    StyledStripeInvoiceID,
} from 'src/components/BillingSection/components/BillingItem/BillingItem.styles';
import { BillingRecordWithRenewal, SubscriptionModel } from 'src/@types/subscription-service-api';
import { MenuVariant } from 'src/components/Menu/Menu';
import { getPaymentStatusBadgeProps, getUdbBillingItemAction } from 'src/components/BillingSection/services/getters';
import { DateFormats, GeneralModalKey, TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { Menu } from 'src/components/Menu';
import { RoundedBadge } from 'src/components/Badge';
import { ReactComponent as PaymentDetailsIcon } from 'src/assets/icons/payment-details-icon.svg';
import { formatDate, formatPrice } from 'src/services/formatters';
import type { RootState } from 'src/redux/root-reducer';
import type { ConfigState } from 'src/@types/redux';
import { configSelector } from 'src/redux/slices';

type PaymentItemProps = {
    billingRecord: BillingRecordWithRenewal;
    rowProps: Record<string, GridProps>;
    subscription: SubscriptionModel;
    variant: 'udb' | 'profile';
    isLoaded: boolean;
    isActivationAllowed: boolean;
    pollingId?: string;
};

const BillingItem: FC<PaymentItemProps> = ({
    variant,
    billingRecord,
    rowProps,
    subscription,
    isLoaded,
    isActivationAllowed,
    pollingId,
}) => {
    const canManage = useUserCan(Scope.SUBSCRIPTIONS_SERVICE_WRITE);
    const { priceInCents, stripeInvoiceID, cycleStart, cycleEnd } = billingRecord;
    const paymentStatusBadgeProps = getPaymentStatusBadgeProps(billingRecord, subscription);
    const paymentStatus = paymentStatusBadgeProps && paymentStatusBadgeProps.label;

    const { testMode } = useSelector<RootState, ConfigState>(configSelector);

    const label = !paymentStatusBadgeProps ? (
        <StyledPlainText>Not available</StyledPlainText>
    ) : (
        <RoundedBadge {...paymentStatusBadgeProps} />
    );

    const isUdb = variant === 'udb';
    const actions = isUdb ? getUdbBillingItemAction({
        billingRecord,
        subscription,
        paymentStatus,
        canManage,
        isActivationAllowed,
        pollingId,
    }) : [];
    const allowedActions = isUdb ? actions.map(({ value }) => value) : [GeneralModalKey.billingDetailsModal];

    const modalPropsGetter = (key: string) => {
        const { actionProps } = actions.find(({ value }) => value === key) ?? {};

        return ({
            extraProps: {
                billingRecord,
                variant,
                subscription,
                uuid: subscription.uuid,
                billingType: subscription.billingType,
                subscriptionType: subscription.type,
                stripeCustomerId: subscription.customAttributes?.stripeCustomerId,
                ...actionProps,
            },
        });
    };

    const { openModal } = useModalWithAnchor(allowedActions, isLoaded, modalPropsGetter, billingRecord.id.toString());

    const actionHandler = (action: string) => {
        openModal(action as GeneralModalKey);
    };

    return (
        <Stack direction="row" alignItems="center">
            <Grid container gap={{ xs: 0.5, md: 0 }} columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                <Grid item {...rowProps.amount}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <StyledPaymentAmountInfo paddingTop={{ xs: 0.25, md: 0 }}>
                            {priceInCents !== null ? formatPrice(priceInCents) : 'Not available'}
                        </StyledPaymentAmountInfo>
                        <Box display={{ xs: 'block', md: 'none' }}>{label}</Box>
                    </Stack>
                </Grid>
                <Grid item {...rowProps.stripeInvoiceID}>
                    <StyledStripeInvoiceID>
                        {stripeInvoiceID ? (
                            <InvoiceLink
                                billingRecord={billingRecord}
                                variant={variant}
                                testMode={testMode}
                            />
                        ) : 'Not available'}
                    </StyledStripeInvoiceID>
                </Grid>
                <Grid item {...rowProps.periodStart}>
                    <StyledPeriodInfo>
                        {formatDate(cycleStart)}
                    </StyledPeriodInfo>
                </Grid>
                <Grid item {...rowProps.periodEnd}>
                    <StyledPeriodInfo>
                        {formatDate(cycleEnd)}
                    </StyledPeriodInfo>
                </Grid>
                <Grid item {...rowProps.fullPeriod}>
                    <StyledPeriodInfo>
                        {`${formatDate(cycleStart, DateFormats.DATE)} - ${formatDate(cycleEnd, DateFormats.DATE)}`}
                    </StyledPeriodInfo>
                </Grid>
                <Grid item {...rowProps.status}>
                    <Box>{label}</Box>
                </Grid>
            </Grid>
            <Box position="absolute" right={{ xs: 16, md: 36 }}>
                {isUdb ? (
                    <Menu
                        menuItems={actions}
                        buttonVariant={MenuVariant.THREE_DOTS_ACTION_BUTTON}
                        onClick={actionHandler}
                    />
                ) : (
                    <ActionButton
                        data-testid="details-button"
                        data-attribute-to-change="stroke"
                        data-variant={ActionButtonVariant.BLUE}
                        onClick={() => actionHandler(GeneralModalKey.billingDetailsModal) }
                    >
                        <PaymentDetailsIcon />
                    </ActionButton>
                )}
            </Box>
        </Stack>
    );
};

export default BillingItem;
