import { Box, Stack, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import { CouponRowButton } from './CouponRow.styles';
import { Button, TextButton } from 'src/components/Buttons';
import { FormWrapper } from 'src/components/Forms/components';
import { LabeledInput } from 'src/components/Inputs';
import { useIsSubmitDisabled } from 'src/hooks';
import { stripeCouponCodeValidator, validateSchemaObject } from 'src/services/validators';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit-icon.svg';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { StripeDiscountInfo } from 'src/@types/sso-api';
import { composeDiscountText } from 'src/services/coupon-discount-composer';
import { formatPrice } from 'src/services/formatters';

export type CouponFormValues = {
    couponCode: string;
};

type CouponRowProps = {
    onFormSubmit: (values: CouponFormValues) => Promise<boolean>;
    discount: StripeDiscountInfo | null;
    subtotalInCents: number;
    initialCode: string;
};

enum CouponRowSteps {
    BUTTON,
    FORM,
    COMPLETE,
}

const CouponRow: FC<CouponRowProps> = ({ onFormSubmit, discount, subtotalInCents, initialCode }) => {
    const [step, setStep] = useState<CouponRowSteps>(discount ? CouponRowSteps.COMPLETE : CouponRowSteps.BUTTON);
    const [code, setCode] = useState<string>(initialCode);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const theme = useTheme();

    const { amount, duration } = composeDiscountText(discount);
    const isPercent = discount?.percentOff;

    const {
        onFormChangeHandler,
        isSubmitDisabled,
        setIsSubmitDisabled,
    } = useIsSubmitDisabled<CouponFormValues>({ couponCode: code });

    const handleFormSubmit = async (values: CouponFormValues) => {
        setIsSubmitDisabled(true);
        setIsSubmitting(true);

        await onFormSubmit(values)
            .then((valid) => {
                if (valid) {
                    setCode(values.couponCode);
                    setStep(CouponRowSteps.COMPLETE);
                }
            })
            .finally(() => {
                setIsSubmitDisabled(false);
                setIsSubmitting(false);
            });
    };

    const makeDiscountAmountText = () => {
        if (isPercent) {
            const calculatedAmount = subtotalInCents * (discount?.percentOff || 0) / 100;
            return formatPrice(calculatedAmount, { showCurrency: true });
        }
        return amount;
    };

    const returnToPreviousStep = () => {
        if (code) {
            setStep(CouponRowSteps.COMPLETE);
            return;
        }

        setStep(CouponRowSteps.BUTTON);
    };

    if (step === CouponRowSteps.BUTTON) {
        return (
            <Box data-testid="coupon-row-button-step">
                <CouponRowButton onClick={() => setStep(CouponRowSteps.FORM)}>+ Add coupon code</CouponRowButton>
            </Box>
        );
    }

    if (step === CouponRowSteps.COMPLETE) {
        return (
            <Stack
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
                alignItems="center"
                data-testid="coupon-row-complete-step"
            >
                <Stack
                    display="flex"
                    flexDirection="column"
                >
                    <Typography fontFamily={theme.fonts.medium} color="secondary" fontSize={14}>
                        Discount code:
                    </Typography>
                    <Stack
                        display="flex"
                        gap={1}
                        flexDirection="row"
                        alignItems="center"
                    >
                        <Typography fontFamily={theme.fonts.bold} fontSize={14} lineHeight="20px">
                            {duration} {amount} discount
                        </Typography>
                        <TextButton onClick={() => setStep(CouponRowSteps.FORM)} data-testid="coupon-row-edit">
                            <EditIcon height={14} width={14} />
                        </TextButton>
                    </Stack>
                </Stack>
                <Typography fontFamily={theme.fonts.medium} color="secondary" fontSize={14}>
                    -{makeDiscountAmountText()}
                </Typography>
            </Stack>
        );
    }

    return (
        <FormWrapper
            id="coupon-form"
            testId="coupon-row-form-step"
            onFormChange={onFormChangeHandler}
            initialValues={{ couponCode: code }}
            onSubmit={handleFormSubmit}
            validationSchema={validateSchemaObject({ couponCode: stripeCouponCodeValidator.required() })}
        >
            {isSubmitting && <LoaderOverlay />}
            <Stack
                display="flex"
                flexDirection={{ sm: 'column', md: 'row' }}
                gap={{ xs: 2, md: 1 }}
                justifyContent="space-between"
            >
                <Box flexGrow={1}>
                    <LabeledInput
                        testId="coupon-row-input"
                        name="couponCode"
                        placeholder=""
                        label="Discount Code"
                    />
                </Box>
                <Box
                    display="flex"
                    alignItems="flex-end"
                    gap={1}
                >
                    <Button
                        testId="coupon-row-submit"
                        variant="primary"
                        minSize="small"
                        type="submit"
                        disabled={isSubmitDisabled || isSubmitting}
                        fullWidth
                    >
                        Apply
                    </Button>
                    <Button
                        testId="coupon-row-previous-step"
                        variant="secondary"
                        minSize="small"
                        onClick={returnToPreviousStep}
                        type="button"
                        disabled={isSubmitting}
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Box>
            </Stack>
        </FormWrapper>
    );
};

export default CouponRow;
