import React from 'react';
import { Box, capitalize, Stack } from '@mui/material';
import dayjs from 'dayjs';
import InvoiceLink from 'src/components/InvoiceLink';
import Tooltip from 'src/components/Tooltip';
import {
    NoContractText,
    RenewalIndexContainer,
    StyledLinkContainer,
} from 'src/components/BillingSection/components/BillingItem/BillingItem.styles';
import { SubscriptionBillingType, SubscriptionType } from 'src/constants';
import { formatDate, formatPrice } from 'src/services/formatters';
import type { BillingRecordModel, BillingRecordWithRenewal, RenewalItem } from 'src/@types/subscription-service-api';
import { ReactComponent as DoneIcon } from 'src/assets/icons/done-with-bacground-icon.svg';
import { ReactComponent as WarnIcon } from 'src/assets/icons/yellow-warn-icon.svg';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';

export const getContractLinkElement = (billingRecord: BillingRecordModel) => {
    if (!billingRecord.contractLink) {
        return (
            <NoContractText variant="inherit">
                {billingRecord.contractSigned &&
                <Tooltip title="The contract has been signed">
                    <DoneIcon />
                </Tooltip>}
                No contract added
            </NoContractText>
        );
    }

    return (
        <StyledLinkContainer data-modal href={billingRecord.contractLink} target="_blank">
            <Tooltip
                title={`The contract ${billingRecord.contractSigned ? 'has' : 'has not'} been signed`}
            >
                {billingRecord.contractSigned ? <DoneIcon /> : <WarnIcon />}
            </Tooltip>
            Open contract
        </StyledLinkContainer>
    );
};

export const getRenewalIndexElement = (
    { renewalIndex, calculatedOn, activeUsers, contentViews }: RenewalItem,
    priceInCents: number | null,
) => {
    if (!renewalIndex) {
        return 'N/A';
    }

    return (
        <RenewalIndexContainer>
            {renewalIndex}
            <Tooltip
                data-max-width="unset"
                title={
                    <>
                        <Stack direction={{ xs: 'column', sm: 'row' }}>
                            Calculated on {formatDate(calculatedOn)}
                            <Box marginLeft={{ xs: 0, sm: 0.5 }}>based on:</Box>
                        </Stack>
                        Active users: {activeUsers}<br />
                        Content views: {contentViews}<br />
                        Price: {formatPrice(priceInCents)}<br />
                    </>
                }
                placement="right"
                arrow
            >
                <InfoIcon />
            </Tooltip>
        </RenewalIndexContainer>
    );
};

export const paymentStatusGetter = (
    { invoiceStatus, stripeInvoiceID, cycleStart }: BillingRecordModel,
    billingType: SubscriptionBillingType,
) => {
    if (invoiceStatus === null) {
        return 'Not available';
    }

    if (billingType === SubscriptionBillingType.FREE) {
        return 'Free';
    }

    if (stripeInvoiceID && invoiceStatus === 'open' && dayjs().valueOf() >= dayjs(cycleStart).valueOf()) {
        return 'Past due';

    }

    return capitalize(invoiceStatus);
};

type PaymentDetailsData = {
    title: string;
    value: string | JSX.Element | number;
    tooltip?: string;
};

export const getPaymentDetailsData = (
    {
        billingType,
        billingRecord,
        variant,
        subscriptionType,
        testMode,
    }: {
        billingType: SubscriptionBillingType;
        billingRecord: BillingRecordModel;
        variant: 'profile' | 'udb';
        subscriptionType?: SubscriptionType;
        testMode?: boolean;
    },
): PaymentDetailsData[] => {
    const {
        priceInCents,
        cycleStart,
        cycleEnd,
        domains,
        requestedAmountOfUsers,
        actualAmountOfUsers,
    } = billingRecord;

    const isUdb = variant === 'udb';

    const generalList: PaymentDetailsData[] = [
        { title: 'Amount:', value: formatPrice(priceInCents) },
        { title: 'Invoice:', value: billingRecord.stripeInvoiceID
            ? (
                <InvoiceLink
                    billingRecord={billingRecord}
                    variant={variant}
                    testMode={testMode}
                    isModal
                />
            )
            : 'Not available' },
        { title: 'Cycle start:', value: formatDate(cycleStart) },
        { title: 'Cycle end:', value: formatDate(cycleEnd) },
        { title: 'Payment status:', value: paymentStatusGetter(billingRecord, billingType) },
    ];

    const coveredDomainsItem: PaymentDetailsData = {
        title: 'Covered domains:',
        value: domains?.length ?? 0,
        tooltip: domains?.length ? domains.join('\n') : undefined,
    };

    if (subscriptionType === SubscriptionType.ENTERPRISE) {
        return [
            ...generalList,
            { title: 'Requested amount of users:', value: requestedAmountOfUsers ?? 'N/A' },
            { title: 'Actual amount of users:', value: actualAmountOfUsers ?? 'N/A' },
            ...(isUdb ? [
                {
                    title: 'Price per user:',
                    value: formatPrice(((priceInCents ?? 0) / (actualAmountOfUsers || 1))),
                },
                coveredDomainsItem,
                { title: 'Contract:', value: getContractLinkElement(billingRecord) },
            ] : [
                coveredDomainsItem,
            ]),
        ];
    }

    return generalList;
};

export const getRenewalData = ({ renewal, priceInCents }: BillingRecordWithRenewal) => {
    if (!renewal) {
        return;
    }

    return [
        { title: 'Renewal index:', value: getRenewalIndexElement(renewal, priceInCents) },
        { title: 'Calculated price:', value: formatPrice(renewal.calculatedPriceInCents) },
    ];
};
