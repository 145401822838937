import { Stack, Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const StyledStack = styled(Stack)`
    padding: 16px 24px 16px 16px;
    border: 1px solid ${({ theme }) => theme.palette.borderGrey};
    background-color: ${({ theme }) => theme.palette.white};
    border-radius: 4px;
`;

export const UserInfoName = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.demiBold};
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const UserInfoText = styled(Typography)<{ 'data-text-wrap'?: 'auto' }>`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        ${({ 'data-text-wrap': textWrap }) => textWrap && css`
            text-wrap: ${textWrap};
        `}
    }
`;
