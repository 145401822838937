import { ConditionModelType, GetToolBarModelValue, ToolBarModelType } from 'src/@types/tool-bar';
import { emptyValueConditions, FILTERING_ERROR_MESSAGE, SORTING_ERROR_MESSAGE, ToolBarModelTypes } from 'src/constants';

export const getConditionsModel = (model: ToolBarModelType): ConditionModelType[] => {
    return Object.entries(model).reduce<ConditionModelType[]>((conditionsModel, [key, condition]) => {
        Object.entries(condition).forEach(([conditionValue, value]) => {
            conditionsModel.push([key, { [conditionValue]: value }] as ConditionModelType);
        });

        return conditionsModel;
    }, []);
};

export const getToolBarModelValue: GetToolBarModelValue = (
    {
        key,
        conditionObject,
        index,
        preparedModel,
    },
    deleteValue,
) => {
    const cleanedConditionObject = Object.fromEntries(
        Object.entries(conditionObject).map(([conditionKey, value]) => [
            conditionKey,
            typeof value === 'string' ? value.trim() : value,
        ]),
    );

    if (Object.entries(cleanedConditionObject).every(([condition, value]) => (
        (Boolean(value) && !!value?.length) || emptyValueConditions.includes(condition)
    ))) {
        return { [key]: { ...preparedModel[key], ...cleanedConditionObject } };
    }

    deleteValue(index);
};

export const getInitialValuesFromConditionModel = ([key, conditionObject]: ConditionModelType): object => {
    const [[condition, inputValue]] = Object.entries(conditionObject);
    return {
        initialParamValue: key,
        initialConditionValue: condition,
        initialInputValue: inputValue,
    };
};

export const isConditionsModelValid = (conditions: ConditionModelType[]): boolean => {
    const uniqueConditions: string[] = [...new Set(conditions.map((condition) => JSON.stringify(condition)))];

    return uniqueConditions.length === conditions.length;
};

export const getErrorText = (type: keyof typeof ToolBarModelTypes): string => (
    type === ToolBarModelTypes.FILTERING ? FILTERING_ERROR_MESSAGE : SORTING_ERROR_MESSAGE
);
