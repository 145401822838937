import React, { FC, Fragment } from 'react';
import { Grid } from '@mui/material';
import { getPricingTermsFields } from './services/getters';
import { SectionItem } from 'src/components/SectionItem';
import { useModalWithAnchor } from 'src/hooks';
import { Menu } from 'src/components/Menu';
import { useInsiderRenewalPrice } from 'src/hooks/useInsiderRenewalPrice';
import { CustomAttributes, CustomPricingTermsModel } from 'src/@types/subscription-service-api';
import Line from 'src/components/Line';
import { actionModalKeyMap, GeneralModalKey, SubscriptionStatus, TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { Section } from 'src/components/Section';
import { MenuVariant } from 'src/components/Menu/Menu';

type PricingTermsSectionProps = {
    isLoading: boolean;
    pricingTerms: CustomPricingTermsModel;
    subscriptionUUID: string;
    customAttributes?: CustomAttributes;
    status: SubscriptionStatus;
};

const actionsList = {
    [GeneralModalKey.adjustPricingTerms]: { label: 'Adjust pricing terms' },
};

const PricingTermsSection: FC<PricingTermsSectionProps> = ({
    pricingTerms,
    isLoading,
    customAttributes = {},
    status,
    subscriptionUUID,
}) => {
    const productPriceInCents = useInsiderRenewalPrice();

    const modalPropsGetters = (selectedAction: string) => {
        const modalKey = actionModalKeyMap[selectedAction as keyof typeof actionModalKeyMap];
        return {
            key: modalKey,
            extraProps: {
                status,
                customAttributes,
                subscriptionUUID,
                initialPricingTerms: pricingTerms,
            },
        };
    };

    const { openModal } = useModalWithAnchor(Object.keys(actionsList), !isLoading, modalPropsGetters);

    const handleSelectChange = (selectedAction: string) => {
        openModal(selectedAction as GeneralModalKey);
    };

    const { cycleLengthInMonths = 12 } = customAttributes;
    const pricingTermsFields = getPricingTermsFields(pricingTerms, productPriceInCents, Number(cycleLengthInMonths));

    return (
        <Section
            data-testid="pricing-terms-section"
            sectionPaddingBottom={3}
            header="Pricing terms"
            headerChildren={(
                <Menu
                    buttonVariant={MenuVariant.THREE_DOTS_TEXT_BUTTON}
                    tooltipMaxWidth="unset"
                    menuItems={Object.entries(actionsList)
                        .map(([value, { label }]) => (
                            { value, label }
                        ))}
                    onClick={handleSelectChange}
                />
            )}
        >
            {isLoading && <LoaderOverlay />}
            <Grid container columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                {pricingTermsFields.map((field, index) => (
                    <Fragment key={`${field.title}-${index}`}>
                        {index !== 0 && (
                            <Grid item display={{ xs: 'block', md: 'none' }} paddingY={2} xs={12}>
                                <Line />
                            </Grid>
                        )}
                        <SectionItem
                            title={field.title}
                            value={field.value}
                            gridProps={field.gridProps}
                        />
                    </Fragment>
                ))}
            </Grid>
        </Section>
    );
};

export default PricingTermsSection;
