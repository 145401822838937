import styled from 'styled-components';
import { Grid, Paper, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReactComponent as EndpointsEnterPrice } from 'src/assets/icons/endpoints-enterprise-logo.svg';

export const StyledPaper = styled(Paper)`
  && {
    display: flex;  
    flex-direction: column;
    max-width: 750px;
    border-radius: unset;
    box-shadow: 0 15px 40px 0 ${({ theme }) => theme.palette.shadow};
  }
`;

export const StyledLogo = styled(Grid)`
    & .MuiGrid-root{
        display: flex;
        align-items: center;
        min-height: 34px;
        
        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            min-height: 19px;
        }
    }
    
`;

export const StyledEndpointsEnterPrice = styled(EndpointsEnterPrice)`
        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            width: 240px;
            height: 14px;
        }
    }
`;

export const StyledArrowBackIcon = styled(ArrowBackIcon)`
    && {
        height: 20px;
        width: 20px;
        color: ${({ theme }) => theme.palette.lightBlack};
        cursor: pointer;

        &:hover {
            color: ${({ theme }) => theme.palette.black};
        }
    }
`;

export const PageDescription = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.lightBlack};
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    max-width: 890px;
      
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
       margin-top: -2px;
       font-family: ${({ theme }) => theme.fonts.normal};
       font-size: 14px;
       font-weight: 450;
    }
  }
`;
