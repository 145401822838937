import React, { FC } from 'react';
import { GridProps } from '@mui/material';
import { StyledContent } from 'src/components/ManagerSection/ManagerItem.styles';
import StyledTooltip from 'src/components/Tooltip';
import { StyledGridItem } from 'src/components/CopyIcon/CopyIcon.styles';
import { CopyIcon } from 'src/components/CopyIcon';
import { GRID_DEFAULT_TOOLTIP_PROPS } from 'src/constants';
import { useOverflowTooltip } from 'src/hooks/useOverflowTooltip';

type TableSectionItemProps = {
    value?: string | null;
    canCopy?: boolean;
    gridProps: GridProps;
    iconElement?: React.ReactNode;
    badgeElement?: React.ReactNode;
    variant?: 'grey' | 'lightGrey';
};

const TableSectionItem: FC<TableSectionItemProps> = ({
    value,
    canCopy,
    gridProps,
    iconElement,
    badgeElement,
    variant,
}) => {
    const { ref, tooltipTitle } = useOverflowTooltip(value);

    return (
        <StyledGridItem minHeight={{ xs: 'unset', sm: '40px' }} item {...gridProps}>
            {iconElement}
            <StyledTooltip {...GRID_DEFAULT_TOOLTIP_PROPS} title={tooltipTitle}>
                <StyledContent data-variant={variant} ref={ref}>
                    {value}
                </StyledContent>
            </StyledTooltip>
            {badgeElement}
            {canCopy && value && (<CopyIcon content={value} hiddenIcon />)}
        </StyledGridItem>
    );
};

export default TableSectionItem;
