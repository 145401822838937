import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { CreateSubscriptionInvoiceData } from 'src/pages/udb/CreateSubscription/CreateSubscription';
import { CreateInvoiceSetup } from 'src/components/CreateInvoiceSetup';
import { CustomPricingTermsProps } from 'src/@types/subscription-service-api';
import { PricingTerms } from 'src/components/PricingTerm';
import { BillingCycleLengthInMonth, SnackbarMessageVariants } from 'src/constants';
import { getCheckoutConfig } from 'src/services/sso-api';
import { useSnackbarMessage } from 'src/hooks';

type InsiderPricingTermsProps = {
    billingCycleLength: BillingCycleLengthInMonth;
    setInsiderData: Dispatch<SetStateAction<CustomPricingTermsProps | undefined>>;
    initialData?: CustomPricingTermsProps;
    numberOfCycles: number;
    invoiceData: CreateSubscriptionInvoiceData;
    setInvoiceData: Dispatch<SetStateAction<CreateSubscriptionInvoiceData>>;
    onInvoiceSetupSubmit: (data: CreateSubscriptionInvoiceData) => void;
};

const InsiderPricingTerms: FC<InsiderPricingTermsProps> = ({
    billingCycleLength,
    numberOfCycles,
    setInsiderData,
    initialData = {},
    invoiceData,
    setInvoiceData,
    onInvoiceSetupSubmit,
}) => {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [productPriceInCents, setProductPriceInCents] = useState<number>(0);

    const { addMessage } = useSnackbarMessage();

    useEffect(() => {
        getCheckoutConfig()
            .then(({ product }) => {
                if (product?.priceInCents) {
                    setProductPriceInCents(product.priceInCents);
                }
            })
            .catch(() => {
                addMessage('Failed to get checkout config', SnackbarMessageVariants.ERROR);
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <PricingTerms
            isLoading={isLoading}
            setPricingTermsData={setInsiderData}
            customPricingTerms={initialData}
            numberOfCycles={numberOfCycles}
            billingCycleLength={billingCycleLength}
            productPriceInCents={productPriceInCents}
            finalPriceRowTitle="Resulting price to be paid"
            additionalFinalPriceRows={(
                <CreateInvoiceSetup
                    invoiceData={invoiceData}
                    setInvoiceData={setInvoiceData}
                    onSubmit={onInvoiceSetupSubmit}
                />
            )}
        />
    );
};

export default InsiderPricingTerms;
