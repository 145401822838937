import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import { dueDateValidator, validateSchemaObject } from 'src/services/validators';
import { convertDateTimeZone } from 'src/services/date-time-zone-converter';
import { prepareInvoiceData } from 'src/services/request-data-formatter';
import { CreateSubscriptionBillingInvoiceParams } from 'src/@types/sso-api';
import { FormWrapper } from 'src/components/Forms/components';
import { LabeledDateInput } from 'src/components/Inputs/LabeledDateInput';
import StyledTooltip from 'src/components/Tooltip';
import { PONumberRow } from 'src/components/PONumberRow';
import type { BillingRecordModel } from 'src/@types/subscription-service-api';
import { PriceRow } from 'src/components/PriceRow';
import { Button } from 'src/components/Buttons';
import { CheckboxWithLabel } from 'src/components/CheckboxWithLabel';
import { InfoBanner } from 'src/components/InfoBanner';
import { SnackbarMessageVariants, DATE_TIME_FORMAT } from 'src/constants';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';

type CreateNewInvoiceProps = {
    priceInCents: number | null;
    oldPrice: number | null;
    isFinalizeInvoice: boolean;
    handleFinalizeInvoiceChange: () => void;
    onCreateInvoice: (values: Partial<CreateSubscriptionBillingInvoiceParams>) => void;
    handleSave: (renewal: Partial<BillingRecordModel>) => void;
    submitButtonText: string;
    isButtonDisabled?: boolean;
    errorBannerText?: string;
    showErrorBanner?: boolean;
    cycleEnd?: string;
};

const CreateNewInvoice: FC<CreateNewInvoiceProps> = ({
    priceInCents,
    oldPrice,
    onCreateInvoice,
    handleSave,
    isFinalizeInvoice,
    handleFinalizeInvoiceChange,
    submitButtonText,
    isButtonDisabled,
    errorBannerText,
    showErrorBanner,
    cycleEnd,
}) => {
    const [overrideDueDate, setOverrideDueDate] = useState<boolean>(false);
    const [dueDate, setDueDate] = useState<string>();
    const [savedPONumber, setSavedPONumber] = useState<string>('');

    const now = dayjs();
    const cycleEndDate = cycleEnd ? convertDateTimeZone(cycleEnd) : null;
    const computedDueDate = cycleEndDate && cycleEndDate.isAfter(now) ? cycleEndDate : now.add(30, 'days');

    return (
        <FormWrapper
            testId="create-invoice"
            initialValues={{ dueDate: '' }}
            validationSchema={validateSchemaObject({
                dueDate: overrideDueDate
                    ? dueDateValidator.required('Enter due date or uncheck the override due date checkbox')
                    : dueDateValidator,
            })}
            onSubmit={() => {
                onCreateInvoice(prepareInvoiceData(overrideDueDate ? dueDate : computedDueDate, savedPONumber));
            }}
        >
            <PriceRow
                paddingTop={{ xs: 0, sm: 1.25 }}
                title="Price:"
                height={{ xs: 'unset', sm: '20px' }}
                paddingBottom={{ xs: 1.5, sm: 2.5 }}
                handleSave={handleSave}
                finalPrice={priceInCents}
                oldPrice={oldPrice}
            />
            <PONumberRow savedPONumber={savedPONumber} setSavedPONumber={setSavedPONumber} />
            <CheckboxWithLabel
                testId="finalize-invoice-checkbox"
                checked={isFinalizeInvoice}
                onClick={handleFinalizeInvoiceChange}
                label="Finalize invoice"
                paddingTop={3}
            />
            <Stack
                paddingTop={2}
                paddingBottom={{ xs: overrideDueDate ? 2 : 0, sm: 3 }}
                gap={1}
                flexDirection="row"
                alignItems="center"
            >
                <CheckboxWithLabel
                    testId="override-due-date-checkbox"
                    checked={overrideDueDate}
                    onClick={() => setOverrideDueDate(!overrideDueDate)}
                    label="Override due date"
                />
                {!overrideDueDate && (
                    <StyledTooltip
                        arrow
                        title={`Due date will be set to ${convertDateTimeZone(computedDueDate).format(DATE_TIME_FORMAT)}`}
                    >
                        <InfoIcon />
                    </StyledTooltip>)}
            </Stack>
            {overrideDueDate && (
                <LabeledDateInput
                    dateTimeOnly
                    withSeconds
                    centered="center"
                    position="fixed"
                    testId="due-date-picker"
                    name="dueDate"
                    label="Due date"
                    onChange={(newDate) => setDueDate(newDate)}
                    minDate={convertDateTimeZone(dayjs().add(30, 'minutes'))}
                    maxDate={computedDueDate}
                />
            )}
            {showErrorBanner && errorBannerText && (
                <InfoBanner
                    marginBottom={overrideDueDate ? 0 : 3 }
                    marginTop={overrideDueDate ? 3 : 0 }
                    type={SnackbarMessageVariants.ERROR}
                >
                    Update unsuccessful. Please try again.
                </InfoBanner>
            )}
            <Stack paddingTop={{ xs: 3, sm: overrideDueDate ? 6 : 0 }}>
                <Button
                    data-testid="create-invoice-btn"
                    type="submit"
                    fullWidth
                    disabled={!overrideDueDate && isFinalizeInvoice && !savedPONumber && isButtonDisabled}
                >
                    {submitButtonText}
                </Button>
            </Stack>
        </FormWrapper>
    );
};

export default CreateNewInvoice;
