import React, { FC } from 'react';
import { AddressElement } from '@stripe/react-stripe-js';
import { StripeAddressElement, StripeAddressElementChangeEvent } from '@stripe/stripe-js';
import { StripeAddress as StripeAddressType } from 'src/@types/sso-api';

type StripeAddressElementProps = {
    name?: string;
    address?: StripeAddressType;
    onChange: (event: StripeAddressElementChangeEvent) => void;
    onReady: (addressElement: StripeAddressElement) => void;
};

const StripeAddress: FC<StripeAddressElementProps> = ({
    name,
    address,
    onChange,
    onReady,
}) => {
    return (
        <AddressElement
            options={{
                mode: 'billing',
                defaultValues: {
                    name,
                    address,
                },
            }}
            onChange={onChange}
            onReady={onReady}
        />
    );
};

export default StripeAddress;
