import React, { FC, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { StyledErrorMessage } from 'src/components/Inputs/StyledInput.styles';
import { INVALID_PO_NUMBER } from 'src/constants';
import { poNumberValidator } from 'src/services/validators';
import { RenewalDataRow } from 'src/components/Modals/UpcomingRenewalModal/components';
import { TextInput } from 'src/components/Inputs/TextInput';
import { StyledTextButton } from 'src/components/Modals/UpcomingRenewalModal/UpcomingRenewalModal.styles';
import { Button } from 'src/components/Buttons';

type PONumberRowProps = {
    savedPONumber: string;
    setSavedPONumber: (value: string) => void;
};

const PONumberRow: FC<PONumberRowProps> = ({ savedPONumber, setSavedPONumber }) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [showEditInput, setShowEditInput] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [poNumber, setPONumber] = useState<string>(savedPONumber);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsValid(true);
        setPONumber(event.target.value);
    };

    const handleBlur = () => {
        setIsFocused(false);
        poNumberValidator.validate(poNumber)
            .then(() => setIsValid(true))
            .catch(() => setIsValid(false));
    };

    const handleSaveClick = () => {
        setSavedPONumber(poNumber.trim());
        setPONumber('');
        setShowEditInput(false);
    };

    return (
        <RenewalDataRow
            title="PO number:"
            skipLine
            height={{ xs: 'unset', sm: '20px' }}
            paddingTop={{ xs: 1.5, sm: 2.5 }}
            paddingBottom={{ xs: 0, sm: showEditInput ? 0 : 2.5 }}
            currentRenewalData={savedPONumber || 'N/A'}
            overrideButton={(
                <Box>
                    {!showEditInput && (
                        <StyledTextButton onClick={() => setShowEditInput(true)} data-testid="add-number">
                            Add PO number
                        </StyledTextButton>
                    )}
                </Box>
            )}
        >
            {showEditInput && (
                <Grid
                    container
                    spacing={1}
                    marginTop={{ xs: 0, sm: 0.5 }}
                >
                    <Grid item xs={9} sm={8}>
                        <TextInput
                            placeholder="Add PO number"
                            value={poNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            hasError={!isValid && !isFocused}
                            onFocus={() => {
                                setIsFocused(true);
                            }}
                        />
                        {!isValid && (<StyledErrorMessage>{INVALID_PO_NUMBER}</StyledErrorMessage>)}
                    </Grid>
                    <Grid item xs={3} sm={4}>
                        <Button
                            minSize="unset"
                            fullWidth
                            disabled={!poNumber.trim() || !isValid}
                            testId="save-po-number"
                            onClick={handleSaveClick}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )}
        </RenewalDataRow>
    );
};

export default PONumberRow;
