import React, { FC, Fragment, ReactNode, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { TabBar } from 'src/components/TabBar';
import { TabBarVariants } from 'src/components/TabBar/TabBar';
import { TabsContainer } from 'src/components/TabBar/TabBar.styles';

type CreateAssignCustomerTabBarProps = {
    testId?: string;
    initialTabIndex?: number;
    items: Array<{
        tabLabel: string;
        content: ReactNode;
    }>;
    onTabChange?: (selectedTab: string) => void;
};

const TabbedContainer: FC<CreateAssignCustomerTabBarProps> = ({
    onTabChange,
    items,
    testId = 'tabbed-container',
    initialTabIndex = 0,
}) => {
    const [selectedTab, setSelectedTab] = useState<string>(items[0].tabLabel);

    const handleTabChange = (newValue: string | number) => {
        setSelectedTab(newValue.toString());
        onTabChange?.(newValue.toString());
    };

    useEffect(() => {
        setSelectedTab(items[initialTabIndex].tabLabel);
    }, [initialTabIndex]);

    return (
        <Box data-testid={testId}>
            <TabsContainer padding={0.5} marginBottom={{ xs: 2.5, sm: 3 }} data-testid="tabs-container">
                <TabBar
                    onChange={handleTabChange}
                    initialValue={selectedTab}
                    items={items.map(({ tabLabel }) => ({ label: tabLabel, value: tabLabel }))}
                    data-testid="tab-bar"
                    variant={TabBarVariants.SECONDARY_TABS}
                />
            </TabsContainer>
            {items.map(({ tabLabel, content }) => {
                if (tabLabel === selectedTab) {
                    return <Fragment key={tabLabel}>{content}</Fragment>;
                }
            })}
        </Box>
    );
};

export default TabbedContainer;
