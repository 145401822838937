import styled from 'styled-components';
import { Typography } from '@mui/material';

export const AddedDomainsLabel = styled(Typography)`
    && {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        color: ${({ theme }) => theme.palette.grey};
        font-family: ${({ theme }) => theme.fonts.medium};
    }
`;

export const ConfirmMessage = styled(Typography)`
    && {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        line-height: 140%;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.medium};

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 16px;
            font-weight: 450;
        }

        & b {
            font-weight: 700;
        }
    }
`;
