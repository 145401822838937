import React, { FC, Fragment, useState } from 'react';
import { Stack } from '@mui/material';
import PaymentMethod from './PaymentMethod';
import { StyledText } from 'src/components/Modals/ChangePaymentMethodModal/ChangePaymentMethodModal.styles';
import {
    getPaymentMethodMessage,
    getSelectedMethod,
} from 'src/components/Modals/ChangePaymentMethodModal/services/getters';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { SnackbarMessageVariants } from 'src/constants';
import { removePaymentMethod } from 'src/services/sso-api';
import Line from 'src/components/Line';
import { BaseModal } from 'src/components/Modals/BaseModal';
import { Button } from 'src/components/Buttons';
import { PaymentMethodDetails } from 'src/@types/sso-api';
import { useGeneralModal, useSnackbarMessage } from 'src/hooks';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import { ConfirmMessage } from 'src/components/Modals/AddDomainModal/AddDomainModal.styles';

type ManagePaymentMethodModalProps = {
    uuid: string;
    defaultMethodId: string | null;
    paymentMethods: Array<PaymentMethodDetails>;
    handleGoBack: (value: boolean) => void;
    refreshMethods: () => void;
};

const ManagePaymentMethodModal: FC<ManagePaymentMethodModalProps> = ({
    uuid,
    defaultMethodId,
    paymentMethods,
    handleGoBack,
    refreshMethods,
}) => {
    const [shouldConfirm, setShouldConfirm] = useState<boolean>(false);
    const [paymentMethodId, setPaymentMethodId] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { closeModal } = useGeneralModal();
    const { addMessage } = useSnackbarMessage();

    const selectedMethod = getSelectedMethod(paymentMethods, paymentMethodId);

    const confirmChanges = async () => {
        setIsLoading(true);
        return removePaymentMethod(uuid, paymentMethodId)
            .then(() => {
                setShouldConfirm(false);
                refreshMethods();
                addMessage(`${getPaymentMethodMessage(selectedMethod)} has been successfully removed from payment method`, SnackbarMessageVariants.SUCCESS);
            })
            .catch(() => {
                setShouldConfirm(false);
                addMessage('Failed to remove payment method', SnackbarMessageVariants.ERROR);
            })
            .finally(() => setIsLoading(false));
    };

    const handleOpenConfirmation = (id: string) => {
        setPaymentMethodId(id);
        setShouldConfirm(true);
    };

    if (shouldConfirm) {
        return (
            <BaseModal
                open
                step={1}
                overlayScope="relative"
                handleClose={closeModal}
                handleGoBack={() => setShouldConfirm(false)}
                title="Remove payment method"
            >
                <ConfirmationModal
                    modalId="remove-modal"
                    onConfirm={confirmChanges}
                    onCancel={() => setShouldConfirm(false)}
                    subtitle={(
                        <ConfirmMessage>
                            Are you sure you want to remove&nbsp;
                            <b>{getPaymentMethodMessage(selectedMethod)}</b>
                            &nbsp;from available payment methods?
                        </ConfirmMessage>
                    )}
                />
            </BaseModal>
        );
    }

    return (
        <Stack gap={{ xs: 2, md: 3 }}>
            {isLoading && <LoaderOverlay />}
            <StyledText>Payment methods</StyledText>
            {paymentMethods.map((method, index) => (
                <Fragment key={method.id}>
                    {index !== 0 && <Line />}
                    <PaymentMethod
                        method={method}
                        isDefault={method.id === defaultMethodId}
                        onRemove={handleOpenConfirmation}
                    />
                </Fragment>
            ))}
            <Stack marginTop={{ xs: 1, md: 0 }}>
                <Button
                    fullWidth
                    data-testid="done-button"
                    onClick={() => handleGoBack(false)}
                >
                 Done
                </Button>
            </Stack>
        </Stack>
    );
};

export default ManagePaymentMethodModal;
