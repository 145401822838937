import React, { Fragment, useContext, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import { ToolBarItemsTitle } from 'src/components/ToolBar/components/ToolBarItem';
import { ToolBarCondition } from 'src/components/ToolBar/components/ToolBarCondition';
import { getInitialValuesFromConditionModel } from 'src/components/ToolBar/services/tool-bar-item-helpers';
import { HandleToolBarConditionChangeParams, ToolbarItemContext } from 'src/@types/tool-bar';
import { ToolBarItemContext } from 'src/components/ToolBar/components/ToolBarContext';
import { ToolBarItemTitle } from 'src/components/ToolBar/components/ToolBarItem/ToolBarItem.styles';
import Line from 'src/components/Line';

type AddConditionProps = {
    shouldShowNewCondition: boolean;
    setShouldShowNewCondition: (newValue: boolean) => void;
    toolBarTitle: string;
};

export const AddCondition = ({
    shouldShowNewCondition,
    setShouldShowNewCondition,
    toolBarTitle,
}: AddConditionProps) => {
    const {
        conditionsModel,
        setConditionsModel,
    } = useContext<ToolbarItemContext>(ToolBarItemContext);
    const addConditionsModel = conditionsModel.filter(([param]) => param !== 'quickAction');

    const handleDeleteCondition = (index?: number) => {
        setConditionsModel((prevConditions) => prevConditions.filter((_, i) => i !== index));
    };

    useEffect(() => {
        if (!addConditionsModel.length) {
            setShouldShowNewCondition(true);
        }
    }, [addConditionsModel.length]);

    const handleAdd = ({
        paramValue,
        conditionValue,
    }: Pick<HandleToolBarConditionChangeParams, 'paramValue' | 'conditionValue'>) => {
        setConditionsModel((prevToolBarModel) => {
            const copy = [...prevToolBarModel.filter(([param]) => param !== 'quickAction')];
            copy.push([paramValue, { [conditionValue]: undefined }]);
            return copy;
        });
        setShouldShowNewCondition(false);
    };

    const handleModelChange = ({
        paramValue,
        conditionValue,
        index,
        inputValue,
    }: HandleToolBarConditionChangeParams) => {
        setConditionsModel((prevToolBarModel) => {
            const copy = [...prevToolBarModel];
            copy[index] = [paramValue, { [conditionValue]: inputValue }];
            return copy;
        });
    };

    return (
        <Box>
            <Stack
                paddingLeft={3}
                paddingRight={3}
                direction="row"
                display={{ xs: 'flex', sm: 'none' }}
                justifyContent="space-between"
            >
                {!addConditionsModel.length && (
                    <ToolBarItemTitle>
                        {toolBarTitle}
                    </ToolBarItemTitle>
                )}
            </Stack>
            {addConditionsModel.map((currentCondition, index) => (
                <Fragment key={`${currentCondition[0]}-${Object.keys(currentCondition[1])[0]}`}>
                    {index !== 0 && (
                        <Box paddingBottom={1.63} component="span" display={{ xs: 'inherit', sm: 'none' }}>
                            <Line />
                        </Box>
                    )}
                    <ToolBarItemsTitle
                        handleDelete={handleDeleteCondition}
                        index={index}
                    />
                    <ToolBarCondition
                        onModelChange={handleModelChange}
                        index={index}
                        {...getInitialValuesFromConditionModel(currentCondition)}
                    />
                </Fragment>
            ))}
            <Box>
                {(shouldShowNewCondition && addConditionsModel.length > 0) && (
                    <>
                        <Box paddingBottom={1.63} component="span" display={{ xs: 'inherit', sm: 'none' }}>
                            <Line />
                        </Box>
                        <ToolBarItemsTitle
                            handleDelete={() => setShouldShowNewCondition(false)}
                            index={addConditionsModel.length}
                        />
                    </>
                )}
                {(shouldShowNewCondition || !addConditionsModel.length) && (
                    <ToolBarCondition
                        onModelChange={handleAdd}
                    />
                )}
            </Box>
        </Box>
    );
};
