import { ManageEntityLinkProps } from 'src/pages/udb/Subscriptions/components/ManageEntityLink/ManageEntityLink';

export const makeURL = (path: string, { hash }: { hash?: string } = {}): URL => {
    const url = new URL(path, window.location.origin);

    if (hash) {
        url.hash = hash;
    }

    return url;
};

export const makeManageEntityURL = (type: ManageEntityLinkProps['type'], id: string): string => {
    switch (type) {
        case 'subscription':
            return `/udb/subscriptions/${id}`;
        case 'credentials':
            return `/credentials/${id}`;
        case 'user':
            return `/udb/users/${id}`;
        default:
            return '';
    }
};
