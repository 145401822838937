import React, { FC, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { FormikHelpers } from 'formik';
import { Button } from 'src/components/Buttons';
import { useReloadPage } from 'src/hooks/useReloadPage';
import { LabeledInput } from 'src/components/Inputs';
import { emailValidator, validateSchemaObject } from 'src/services/validators';
import { FormWrapper } from 'src/components/Forms/components';
import { useIsSubmitDisabled } from 'src/hooks';
import { changeEmailSend } from 'src/services/sso-api';
import { getResponseError } from 'src/services/error-formatters';

type ChangeEmailFormValues = {
    email: string;
};

const ChangeEmailForm: FC<{ email: string }> = ({ email }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showButton, setShowButton] = useState<boolean>(false);

    const { reloadPage } = useReloadPage();
    const {
        isSubmitDisabled,
        onFormChangeHandler,
        setIsSubmitDisabled,
    } = useIsSubmitDisabled({ email });

    const handleSubmit = (values: ChangeEmailFormValues, helpers: FormikHelpers<ChangeEmailFormValues>) => {
        setIsSubmitDisabled(true);
        setIsSubmitting(true);
        setShowButton(false);

        changeEmailSend({
            newEmail: values.email,
        })
            .then(() => {
                reloadPage();
            })
            .catch((error) => {
                helpers.setErrors({
                    email: getResponseError(error, 'Failed to change email'),
                });
                setIsSubmitDisabled(false);
                setShowButton(true);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <Grid item md={6} xs={12}>
            <FormWrapper<ChangeEmailFormValues>
                onFormChange={onFormChangeHandler}
                isSubmitting={isSubmitting}
                validationSchema={validateSchemaObject({ email: emailValidator })}
                initialValues={{ email }}
                onSubmit={handleSubmit}
            >
                <Stack
                    spacing={{ xs: 2, sm: 1 }}
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent="flex-start"
                >
                    <LabeledInput
                        testId="email-input"
                        disallowSpace
                        name="email"
                        label="Email address"
                        placeholder="Enter your email"
                        inputProps={{ required: true }}
                        flex={1}
                        onFocus={() => setShowButton(true)}
                        onBlur={() => setShowButton(!isSubmitDisabled)}
                    />
                    {showButton && (
                        <Box paddingTop={{ xs: 0, sm: 2.75 }}>
                            <Button
                                data-testid="change-email-form-submit-button"
                                disabled={isSubmitDisabled}
                                fullWidth
                                type="submit"
                                variant="primary"
                                minSize="small"
                            >
                                Update
                            </Button>
                        </Box>
                    )}
                </Stack>
            </FormWrapper>
        </Grid>
    );
};

export default ChangeEmailForm;
