import dayjs from 'dayjs';
import React from 'react';
import { Stack } from '@mui/material';
import { CreateSubscriptionInvoiceData } from 'src/pages/udb/CreateSubscription/CreateSubscription';
import { ActivateLaterLabel } from 'src/components/Label';
import { InfoBoxItemProps } from 'src/components/InfoBox/InfoBoxItem';
import { buildStripeCustomerHref } from 'src/services/stripe';
import { getAddressString } from 'src/services/address';
import { StripeCustomerInfo } from 'src/@types/sso-api';
import { getDiscountDurationRenderLabels } from 'src/components/PricingTerm/services/getters';
import { makePlural } from 'src/services/text-modifier';
import { FinalPriceRowTitle, FinalPriceRowValue } from 'src/components/FinalPriceRow';
import type {
    TypeAndBillingData,
} from 'src/pages/udb/CreateSubscription/components/TypeAndBillingStep/TypeAndBillingStep';
import {
    ActivationDate,
    billingCycleLengthRenderMapping,
    billingTypeRenderMapping,
    DiscountDuration,
    PricingModel,
    pricingModelRenderLabels,
    SubscriptionBillingType,
    SubscriptionType,
} from 'src/constants';
import { PricingTermsData } from 'src/pages/udb/CreateSubscription/components/PricingTermsStep/PricingTermsStep';
import { formatDate, formatPrice } from 'src/services/formatters';
import { ReactComponent as EndpointsInsider } from 'src/assets/icons/endpoints-insider-logo.svg';
import { ReactComponent as EndpointsEnterprise } from 'src/assets/icons/endpoints-enterprise-logo.svg';
import { Link } from 'src/components/Link';
import { ReactComponent as StripeLinkIcon } from 'src/assets/icons/stripe-link-icon.svg';

export const subscriptionTypeIcons = {
    [SubscriptionType.INSIDER]: <EndpointsInsider />,
    [SubscriptionType.ENTERPRISE]: <EndpointsEnterprise />,
};

const getExpirationDateText = ({ selectedBillingCycleLength, numberOfCycles }: Required<TypeAndBillingData>) => {
    const billingCycleLength = billingCycleLengthRenderMapping[selectedBillingCycleLength];

    return `${numberOfCycles} ${makePlural(billingCycleLength, numberOfCycles)} from the moment of activation`;
};

export const getTypeAndBillingData = (typeAndBillingData: Required<TypeAndBillingData>, expirationDate?: string) => {
    return [
        { key: 'subscriptionType', label: 'Subscription type:', value: subscriptionTypeIcons[typeAndBillingData.selectedSubscriptionType] },
        { key: 'billing', label: 'Billing:', value: billingTypeRenderMapping[typeAndBillingData.selectedBillingType] },
        {
            key: 'billingCycleLength',
            label: 'Billing cycle length:',
            value: `1 ${billingCycleLengthRenderMapping[typeAndBillingData.selectedBillingCycleLength]}`,
        },
        {
            key: 'activationDate',
            label: 'Activation date:',
            value: typeAndBillingData.activationDate
                ? formatDate(typeAndBillingData.activationDate)
                : (
                    <ActivateLaterLabel
                        label="Moment of activation"
                        hideInfoIcon={typeAndBillingData.selectedActivationDateType === ActivationDate.NOW }
                        billingType={typeAndBillingData.selectedBillingType}
                        subscriptionType={typeAndBillingData.selectedSubscriptionType}
                    />
                ),
        },
        {
            key: 'expirationDate',
            label: 'Expiration date:',
            value: expirationDate
                ? formatDate(expirationDate)
                : getExpirationDateText(typeAndBillingData),
        },
    ];
};

export const getBillingDetailsData = (
    { name, id, email, address }: StripeCustomerInfo,
    country: string,
    testMode?: boolean,
): Array<InfoBoxItemProps & { key: string }> => {
    const addressData = getAddressString(address, country);

    return [
        { key: name, label: 'Name:', value: name, tooltipTitle: name },
        { key: id, label: 'Customer ID:', value: (
            <Stack display="inline-flex" alignItems="center" direction="row">
                {id}
                <Link
                    gap={0.5}
                    paddingLeft={1}
                    fontSize="inherit"
                    target="_blank"
                    display="inline-flex"
                    alignItems="center"
                    data-testid="stripe-link"
                    href={buildStripeCustomerHref(id, testMode)}
                >
                    <StripeLinkIcon width="16px" height="16px" />
                </Link>
            </Stack>
        ) },
        { key: email, label: 'Email address:', value: email, tooltipTitle: email },
        { key: addressData, label: 'Address:', value: addressData === 'N/A' ? 'No address added' : addressData },
    ];
};

export const getInvoiceData = (invoiceData: CreateSubscriptionInvoiceData) => {
    const rows = [
        { key: 'createInvoice', label: 'Create invoice:', value: invoiceData.isCreate ? 'Yes' : 'No' },
    ];

    if (invoiceData.isCreate) {
        rows.push(
            { key: 'finalize', label: 'Finalize invoice:', value: invoiceData.isFinalize ? 'Yes' : 'No' },
            { key: 'poNumber', label: 'PO number:', value: invoiceData.poNumber || 'N/A' },
            { key: 'dueDate', label: 'Invoice due date:', value: formatDate(invoiceData.dueDate || dayjs().add(30, 'days').toString()) },
        );
    }

    return rows;
};

export const getPricingTermsData = (
    pricingTermsData: PricingTermsData,
    {
        selectedBillingCycleLength,
        selectedSubscriptionType,
        selectedBillingType,
    }: Required<TypeAndBillingData>,
) => {
    if (selectedSubscriptionType === SubscriptionType.ENTERPRISE) {
        const isFree = selectedBillingType === SubscriptionBillingType.FREE;

        return [
            {
                key: 'amountOfUsers',
                label: 'Amount of users:',
                value: (
                    isFree
                        ? pricingTermsData[selectedSubscriptionType]?.domains
                            ?.reduce((acc, domain) => acc + domain.userCount, 0)
                        : pricingTermsData[selectedSubscriptionType]?.priceData?.userCount
                ) ?? 0,
            },
            {
                key: 'firstYearPrice',
                label: isFree ? 'Price:' : 'First year price:',
                value: isFree ? 'Free' : formatPrice(pricingTermsData[selectedSubscriptionType]?.finalPriceInCents ?? 0),
            },
        ];
    }

    const {
        discountDurationInCycles,
        discountType,
        discountValue,
        fixedPriceInCents,
        finalPriceInCents,
    } = pricingTermsData[selectedSubscriptionType]!;

    const pricingModel = fixedPriceInCents ? PricingModel.FIXED_PRICE : PricingModel.RENEWABLE_PRICE;
    const discountDurationCyclesAmount = discountDurationInCycles === 1
        ? DiscountDuration.FIRST_CYCLE
        : DiscountDuration.MULTIPLE_CYCLE;
    const discountDuration = discountDurationInCycles ? discountDurationCyclesAmount : DiscountDuration.FOREVER;

    const renderFields = [
        { key: 'pricingModel', label: 'Pricing model:', value: pricingModelRenderLabels[pricingModel] },
        { key: 'finalPrice',
            label: (
                <FinalPriceRowTitle
                    discountType={discountType}
                    discountValue={discountValue}
                />
            ),
            value: (
                <FinalPriceRowValue
                    priceInCents={fixedPriceInCents || finalPriceInCents}
                    discountValue={discountValue}
                    discountType={discountType}
                />
            ) },
    ];

    if (discountValue) {
        renderFields.push({
            key: 'discountDuration',
            label: 'Discount duration:',
            value: getDiscountDurationRenderLabels(
                selectedBillingCycleLength,
                discountDurationInCycles,
            )[discountDuration],
        });
    }

    return renderFields;
};
