import { CaseReducer, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { PageState } from 'src/@types/redux';
import type { ScreenSize } from 'src/@types/screen';
import type { RootState } from 'src/redux/root-reducer';

const initialState: PageState = {
    pageReloadCount: 0,
    initiallyRequestedPage: null,
    activePollingIds: [],
    size: 'xl',
};

export { initialState as pageInitialState };

export const pageSlice = createSlice<PageState, {
    incrementPageReloadCount: CaseReducer<PageState>;
    setInitiallyRequestedPage: CaseReducer<PageState, PayloadAction<PageState['initiallyRequestedPage']>>;
    clearInitiallyRequestedPage: CaseReducer<PageState>;
    startPolling: CaseReducer<PageState, PayloadAction<string>>;
    stopPolling: CaseReducer<PageState, PayloadAction<string>>;
    setPageSize: CaseReducer<PageState, PayloadAction<ScreenSize>>;
}>({
    name: 'page',
    initialState,
    reducers: {
        incrementPageReloadCount: (state) => ({
            ...state,
            pageReloadCount: state.pageReloadCount + 1,
        }),
        setInitiallyRequestedPage: (state, { payload }) => ({
            ...state,
            initiallyRequestedPage: payload,
        }),
        clearInitiallyRequestedPage: (state) => ({
            ...state,
            initiallyRequestedPage: initialState.initiallyRequestedPage,
        }),
        startPolling: (state, { payload }) => {
            state.activePollingIds = [...state.activePollingIds, payload];
        },
        stopPolling: (state, { payload }) => {
            state.activePollingIds = state.activePollingIds.filter((id) => id !== payload);
        },
        setPageSize: (state, { payload }) => ({
            ...state,
            size: payload,
        }),
    },
});

export const {
    incrementPageReloadCount,
    setInitiallyRequestedPage,
    clearInitiallyRequestedPage,
    startPolling,
    stopPolling,
    setPageSize,
} = pageSlice.actions;

export const pageSelector = (state: RootState): PageState => state[pageSlice.name];

export const pageSizeSelector = createSelector(
    pageSelector,
    (pageState: PageState) => pageState.size,
);

export const isMobileSelector = createSelector(
    pageSizeSelector,
    (size: ScreenSize) => size === 'xs',
);

export const isPollingActiveSelector = createSelector(
    pageSelector,
    (pageState: PageState) => (id: string) => pageState.activePollingIds.includes(id),
);
