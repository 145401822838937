import React, { FC, FormEvent, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import type { StripeError } from '@stripe/stripe-js';
import { useParams } from 'react-router-dom';
import { isSameAddresses } from 'src/services/address';
import { createSetupIntent, updateProfileStripeCustomer } from 'src/services/sso-api';
import { makeURL } from 'src/services/url-maker';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { CurrentUserState, type GeneralModalState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';
import { Button } from 'src/components/Buttons';
import type { StripeCustomerInfo } from 'src/@types/sso-api';
import { FlowSteps } from 'src/components/FlowSteps';
import { StripeAddress, StripePaymentElement } from 'src/components/Stripe';
import { useStripeAppearance } from 'src/hooks/useStripeAppearance';
import {
    generalModalSelector,
    modalStepSelector,
    openNextStep,
    openPreviousStep,
    userSelector,
} from 'src/redux/slices';
import { SnackbarMessageVariants } from 'src/constants';
import { useSnackbarMessage } from 'src/hooks';

enum Step {
    ADDRESS = 'address',
    CARD = 'card',
}

const addPaymentMethodSteps = [
    { key: Step.CARD, label: 'Payment method' },
    { key: Step.ADDRESS, label: 'Billing address' },
];

export type AddPaymentMethodContentProps = {
    customer: StripeCustomerInfo | null | undefined;
    initialStep?: number;
};

const AddPaymentMethodContent: FC<AddPaymentMethodContentProps> = ({
    customer,
    initialStep = 0,
}) => {
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(true);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [activeStep, setActiveStep] = useState<Step>(Step.CARD);

    const { currentUser } = useSelector<RootState, CurrentUserState>(userSelector);
    const { key } = useSelector<RootState, GeneralModalState>(generalModalSelector);
    const step = useSelector<RootState, number>(modalStepSelector);
    const { addMessage } = useSnackbarMessage();

    const { uuid = '' } = useParams<{ uuid: string }>();

    const dispatch = useDispatch();
    const theme = useTheme();
    const elements = useElements();
    const stripe = useStripe();

    useStripeAppearance();

    useEffect(() => {
        setActiveStep(initialStep === step ? Step.CARD : Step.ADDRESS);
    }, [step]);

    const handleError = (error?: StripeError | Error | string) => {
        setIsLoading(false);

        addMessage(
            typeof error === 'string' ? error : error?.message || 'Failed to add payment method, please try again or contact us',
            SnackbarMessageVariants.ERROR,
        );
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const { error: submitError } = await elements.submit().catch((error) => ({ error }));

        if (submitError) {
            handleError(submitError);
            return;
        }

        const { value } = await elements.getElement('address')?.getValue() || {};

        if (value?.address && !isSameAddresses(customer?.address, value.address)) {
            await updateProfileStripeCustomer(uuid, { address: value.address })
                .then(() => {
                    addMessage('Billing address updated successfully', SnackbarMessageVariants.SUCCESS);
                })
                .catch(() => {
                    addMessage('Failed to update billing address', SnackbarMessageVariants.WARNING);
                });
        }

        const clientSecret = await createSetupIntent(uuid)
            .then(({ client_secret }) => client_secret)
            .catch(() => handleError());

        if (!clientSecret) {
            return handleError('Failed to create setup intent');
        }

        await stripe.confirmSetup({
            elements,
            clientSecret,
            confirmParams: {
                return_url: makeURL(`/subscriptions/${uuid}/add-payment-method-result`, { hash: key ?? '' }).toString(),
            },
        })
            .then(({ error }) => {
                if (!error) {
                    return;
                }

                handleError(error.message);
                dispatch(openPreviousStep());
            })
            .catch(() => handleError());
    };

    return (
        <form data-testid="add-payment-method-form" onSubmit={handleSubmit}>
            {isLoading && <LoaderOverlay />}
            <Box maxWidth={478} paddingTop={{ xs: 0, sm: 1 }}>
                <Stack spacing={3}>
                    <Box paddingBottom={{ xs: 0, sm: 1 }}>
                        <FlowSteps
                            activeStepKey={activeStep}
                            steps={addPaymentMethodSteps}
                        />
                    </Box>
                    <Box
                        display={activeStep === Step.ADDRESS ? 'block' : 'none' }
                        data-testid="add-payment-method-billing-address-container"
                    >
                        <StripeAddress
                            name={customer?.name || currentUser?.fullName}
                            address={customer?.address || undefined}
                            onChange={({ complete }) => {
                                setIsSubmitDisabled(!complete);
                            }}
                            onReady={(addressElement) => {
                                if (!customer?.address?.line1) {
                                    return;
                                }

                                setTimeout(() => {
                                    addressElement.getValue()
                                        .then(({ complete }) => {
                                            if (complete) {
                                                setIsSubmitDisabled(false);
                                            }
                                        });
                                }, 20);
                            }}
                        />
                    </Box>
                    <Box
                        display={activeStep === Step.CARD ? 'block' : 'none' }
                        data-testid="add-payment-method-card-container"
                    >
                        <StripePaymentElement
                            onChange={({ complete }) => {
                                setIsNextButtonDisabled(!complete);
                            }}
                            onReady={() => {
                                setIsLoading(false);
                            }}
                        />
                    </Box>
                </Stack>
                <Box paddingTop={{ xs: 3, sm: 6 }}>
                    {activeStep === Step.CARD ? (
                        <Button fullWidth onClick={() => dispatch(openNextStep())} disabled={isNextButtonDisabled}>
                        Next
                        </Button>
                    ) : (
                        <Button fullWidth type="submit" disabled={isSubmitDisabled}>
                        Save
                        </Button>
                    )}
                    <Typography fontSize={12} color={theme.palette.lightGrey} marginTop={{ xs: 1.5, sm: 2 }} textAlign="center" fontFamily={theme.fonts.medium}>
                    We do not store your credit card and billing information.
                    It is stored in and processed by Stripe, our payments platform partner.
                    </Typography>
                </Box>
            </Box>
        </form>
    );
};

export default AddPaymentMethodContent;
