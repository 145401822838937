import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { StackProps } from '@mui/material';
import CreateInvoiceSetupContent from './CreateInvoiceSetupContent';
import { FormWrapper } from 'src/components/Forms/components';
import { dueDateValidator, poNumberValidator, validateSchemaObject } from 'src/services/validators';
import { CreateSubscriptionInvoiceData } from 'src/pages/udb/CreateSubscription/CreateSubscription';

type InvoiceSetupProps = {
    paddingTop?: StackProps['paddingTop'];
    onSubmit: (data: CreateSubscriptionInvoiceData) => void;
    invoiceData: CreateSubscriptionInvoiceData;
    setInvoiceData: Dispatch<SetStateAction<CreateSubscriptionInvoiceData>>;
};

const CreateInvoiceSetup: FC<InvoiceSetupProps> = ({
    paddingTop,
    invoiceData,
    setInvoiceData,
    onSubmit,
}) => {
    const [overrideDueDate, setOverrideDueDate] = useState<boolean>(!!invoiceData.dueDate);

    return (
        <FormWrapper<Required<CreateSubscriptionInvoiceData>>
            id="create-subscription-form"
            enableReinitialize
            initialValues={{
                poNumber: invoiceData.poNumber || '',
                dueDate: invoiceData.dueDate || '',
                isFinalize: invoiceData.isFinalize,
                isCreate: invoiceData.isCreate,
            }}
            validationSchema={validateSchemaObject({
                poNumber: poNumberValidator,
                dueDate: overrideDueDate
                    ? dueDateValidator.required('Enter due date or uncheck the override due date checkbox')
                    : dueDateValidator,
            })}
            onSubmit={onSubmit}
        >
            <CreateInvoiceSetupContent
                paddingTop={paddingTop}
                setInvoiceData={setInvoiceData}
                overrideDueDate={overrideDueDate}
                setOverrideDueDate={setOverrideDueDate}
            />
        </FormWrapper>
    );
};

export default CreateInvoiceSetup;
