import dayjs from 'dayjs';
import { ContractDetailsModalProps } from 'src/components/Modals/ContractDetailsModal/ContractDetailsModal';
import { MenuOption } from 'src/components/Menu/Menu';
import { BillingRecordModel, BillingRecordWithRenewal, SubscriptionModel } from 'src/@types/subscription-service-api';
import { RoundedBadgeProps } from 'src/components/Badge/RoundedBadge/RoundedBadge';
import { RoundedBadgeVariant } from 'src/components/Badge/RoundedBadge/RoundedBadge.styles';
import {
    BillingStatus,
    GeneralModalKey,
    InvoiceStatus,
    SubscriptionBillingType,
    SubscriptionStatus,
} from 'src/constants';

type BillingItemAction = MenuOption & { actionProps?: object };

export const getPaymentStatusBadgeProps = (
    { invoiceStatus, cycleStart, stripeInvoiceID }: BillingRecordModel,
    { billingType }: SubscriptionModel,
): RoundedBadgeProps | null => {
    const currentTime = dayjs();

    if (billingType === SubscriptionBillingType.FREE) {
        return {
            label: BillingStatus.FREE,
            variant: RoundedBadgeVariant.OUTLINED,
        };
    }

    if (!stripeInvoiceID && currentTime.isBefore(dayjs(cycleStart))) {
        return {
            label: BillingStatus.UPCOMING,
            variant: RoundedBadgeVariant.BLUE,
        };
    }

    if (
        stripeInvoiceID &&
        invoiceStatus === InvoiceStatus.OPEN &&
        (currentTime.isAfter(dayjs(cycleStart)) || currentTime.isSame(dayjs(cycleStart)))
    ) {
        return {
            label: InvoiceStatus.PAST_DUE,
            variant: RoundedBadgeVariant.RED,
        };
    }

    switch (invoiceStatus) {
        case InvoiceStatus.PAID:
            return {
                label: InvoiceStatus.PAID,
                variant: RoundedBadgeVariant.GREEN,
            };
        case InvoiceStatus.OPEN:
            return {
                label: InvoiceStatus.OPEN,
                variant: RoundedBadgeVariant.BLUE,
            };
        case InvoiceStatus.UNCOLLECTIBLE:
            return {
                label: InvoiceStatus.UNCOLLECTIBLE,
                variant: RoundedBadgeVariant.GREY,
            };
        case InvoiceStatus.VOID:
            return {
                label: InvoiceStatus.VOID,
                variant: RoundedBadgeVariant.GREY,
            };
        case InvoiceStatus.DRAFT:
            return {
                label: InvoiceStatus.DRAFT,
            };
        default:
            return invoiceStatus && {
                label: invoiceStatus,
            };
    }
};

export const getUdbBillingItemAction = ({
    isActivationAllowed,
    billingRecord,
    subscription,
    canManage,
    paymentStatus,
    pollingId,
}: {
    billingRecord: BillingRecordWithRenewal;
    subscription: SubscriptionModel;
    paymentStatus: string | null;
    canManage: boolean;
    isActivationAllowed: boolean;
    pollingId?: string;
}): Array<BillingItemAction> => {
    const { notification, stripeInvoiceID, invoiceStatus, isCurrent, isUpcoming } = billingRecord;
    const { billingType, status } = subscription;
    const isInvoiced = billingType === SubscriptionBillingType.INVOICED;

    const actions: Array<BillingItemAction> = [
        {
            label: 'View details',
            value: GeneralModalKey.billingDetailsModal,
        },
    ];

    if (status === SubscriptionStatus.TERMINATED) {
        return actions;
    }

    if (!stripeInvoiceID && isInvoiced) {
        actions.push({
            label: 'Create/attach an invoice',
            value: GeneralModalKey.attachAnInvoice,
        });
    }

    if (stripeInvoiceID && isInvoiced && invoiceStatus === InvoiceStatus.DRAFT) {
        actions.push({
            label: 'Finalize invoice',
            value: GeneralModalKey.finalizeInvoiceModal,
        });
    }

    if (stripeInvoiceID && invoiceStatus !== InvoiceStatus.PAID && isInvoiced) {
        actions.push({
            label: 'Update invoice',
            value: GeneralModalKey.updateInvoice,
        });
    }

    if (canManage && isInvoiced && (isCurrent || isUpcoming || status === SubscriptionStatus.DRAFT)) {
        const actionProps: ContractDetailsModalProps = { billingRecord, subscription, isActivationAllowed, pollingId };

        actions.push({
            label: 'Contract details',
            value: GeneralModalKey.contractDetailsModal,
            actionProps,
        });
    }

    if (notification && paymentStatus === BillingStatus.UPCOMING) {
        actions.push({
            label: `${notification?.disableRenewalNotifications ? 'Enable' : 'Disable'} renewal notifications`,
            value: GeneralModalKey.editBillingRenewalNotification,
        });
    }

    return actions;
};
