import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useGeneralModal } from './useGeneralModal';
import { GeneralModalKey } from 'src/constants';
import { OpenModalActionPayload } from 'src/@types/redux';

export const useModalWithAnchor = (
    allowedModalKeys: string[],
    isLoaded: boolean,
    extraPropsGetter?: (selectedAction: string) => Partial<OpenModalActionPayload>,
    id?: string,
) => {
    const [opened, setOpened] = useState<boolean>(false);

    const location = useLocation();
    const navigate = useNavigate();
    const { openModal } = useGeneralModal();

    useEffect(() => {
        const anchorParts = location.hash.slice(1).split('-');
        const anchorId = anchorParts.length > 1 ? anchorParts.pop() : undefined;
        const anchorKey = anchorParts.join('-') as GeneralModalKey;

        if (isLoaded && allowedModalKeys.includes(anchorKey) && !opened && anchorId === id) {
            openModal({ key: anchorKey, ...extraPropsGetter?.(anchorKey) });
            setOpened(true);
        }

        if (!anchorKey || !allowedModalKeys.includes(anchorKey)) {
            setOpened(false);
        }

    }, [isLoaded, allowedModalKeys, location.hash]);

    return {
        openModal: (key: GeneralModalKey, hash: string = key) => {
            navigate({ ...location, hash: id ? [hash, id].join('-') : hash });
            openModal({ ...extraPropsGetter?.(hash), key });
        },
    };
};
