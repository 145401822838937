import styled from 'styled-components';
import { Typography } from '@mui/material';
import { TextButton } from 'src/components/Buttons';

export const StyledTextButton = styled(TextButton)`
    &&&.MuiButtonBase-root {
        font-size: 14px;
        font-family: ${({ theme }) => theme.fonts.medium};

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 16px;
        }
    }
`;

export const StyledUserName = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.demiBold};
        font-size: 16px;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const StyledUserEmail = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 14px;
        font-weight: 450;
    }
`;

export const StyledMessage = styled(Typography)`
    &&{
        background-color: ${({ theme }) => theme.palette.snackbar.infoBackground};
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.medium};
        line-height: normal;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
    }
`;

export const StyledCaption = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-style: normal;
        line-height: 140%;
        font-weight: 500;
        font-size: 14px;
        
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 16px;
        }
    }
`;
