import React, { FC } from 'react';
import { Box, Grid, GridProps } from '@mui/material';
import ContactInfoItem from './ContactInfoItem';
import { useModalWithAnchor } from 'src/hooks/useModalWithAnchor';
import { TextButton } from 'src/components/Buttons';
import { RoundedBadgeVariant } from 'src/components/Badge/RoundedBadge/RoundedBadge.styles';
import { EmailStatus, GeneralModalKey, TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { useLookupNamesHook } from 'src/hooks';
import { getCountriesList } from 'src/services/user-profile-api';
import { Email, PhoneNumber } from 'src/@types/unified-db-api';
import StyledTooltip from 'src/components/Tooltip';
import { RoundedBadge } from 'src/components/Badge';
import { Section } from 'src/components/Section';
import Line from 'src/components/Line';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit-icon.svg';
import { ChangeEmailCheckData } from 'src/@types/sso-api';

export type FormattedContactInfoType = {
    email: string;
    status: EmailStatus;
    phoneNumber?: PhoneNumber | null;
    country?: string;
    secondaryEmails?: Email[];
};

type ContactInformationProps = {
    contactsInformation?: FormattedContactInfoType;
    changeEmailStatus?: ChangeEmailCheckData;
    isEditable: boolean;
    uuid: string;
};

export type GridColumnProps = Record<'email' | 'phoneNumber' | 'country', GridProps>;

const gridColumnParams: GridColumnProps = {
    email: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2,
        display: 'flex',
        flexDirection: 'column',
        paddingRight: { xs: 0, sm: 2 },
        gap: { xs: 0, md: 1 },
    },
    phoneNumber: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0, md: 1 },
    },
    country: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0, md: 1 },
    },
};

const ContactInformationSection: FC<ContactInformationProps> = ({ contactsInformation,
    isEditable,
    uuid,
    changeEmailStatus = {
        newEmail: null,
        expiration: null,
    } }) => {
    if (!contactsInformation) {
        return null;
    }

    const { email, phoneNumber, secondaryEmails, country, status } = contactsInformation;
    const hasSecondaryEmails = secondaryEmails && secondaryEmails.length > 0;
    const otherEmails = secondaryEmails?.length;
    const phoneNum = phoneNumber?.phoneNumber;

    const countryName = useLookupNamesHook(getCountriesList, country);
    const modalPropsGetter = (key: string) => {
        if (key === GeneralModalKey.additionalEmailModal) {
            return {
                extraProps: {
                    secondaryEmails: secondaryEmails?.map((e) => e.email),
                },
            };
        }

        return { extraProps: {
            contactsInformation,
            changeEmailStatus,
            uuid,
        } };
    };

    const { openModal } = useModalWithAnchor(
        [GeneralModalKey.additionalEmailModal, GeneralModalKey.editContactInformation],
        !!contactsInformation,
        modalPropsGetter,
    );

    const handleAdditionalEmailModal = () => {
        openModal(GeneralModalKey.additionalEmailModal);
    };

    const handleEditContactInformationModal = () => {
        openModal(GeneralModalKey.editContactInformation);
    };

    const badgeVariantMapping = {
        [EmailStatus.BOUNCED]: RoundedBadgeVariant.RED,
        [EmailStatus.UNSUBSCRIBED]: RoundedBadgeVariant.RED,
        [EmailStatus.SUBSCRIBED]: RoundedBadgeVariant.BLUE,
        [EmailStatus.DELETED]: RoundedBadgeVariant.GREY,
        [EmailStatus.UNDEFINED]: RoundedBadgeVariant.DEFAULT,
    };

    return (
        <Section
            data-testid="contact-information-section"
            sectionPaddingBottom={3}
            header="Contact information"
            headerChildren={
                isEditable ? (
                    <TextButton data-testid="edit-btn" onClick={handleEditContactInformationModal}>
                        <EditIcon />
                    </TextButton>
                ) : null
            }
        >
            <Grid container columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                <ContactInfoItem
                    label="Email address"
                    value={email}
                    gridProps={gridColumnParams.email}
                    canCopy
                >
                    <StyledTooltip title="Email status in Campaign Monitor" arrow>
                        <Box>
                            <RoundedBadge
                                label={status}
                                variant={badgeVariantMapping[status]}
                            />
                        </Box>
                    </StyledTooltip>
                    {hasSecondaryEmails && (
                        <StyledTooltip
                            title={`Click to see ${otherEmails} additional ${otherEmails === 1 ? 'email' : 'emails'}`}
                            arrow
                        >
                            <TextButton data-testid="email-chip" onClick={handleAdditionalEmailModal}>
                                <RoundedBadge label={`+${otherEmails}`} variant={RoundedBadgeVariant.DEFAULT} />
                            </TextButton>
                        </StyledTooltip>
                    )}
                </ContactInfoItem>
                <Grid item display={{ xs: 'block', md: 'none' }} paddingY={2} xs={12}>
                    <Line />
                </Grid>
                <ContactInfoItem
                    label="Phone number"
                    value={phoneNum}
                    gridProps={gridColumnParams.phoneNumber}
                    canCopy={!!phoneNum}
                />
                <Grid item display={{ xs: 'block', md: 'none' }} paddingY={2} xs={12}>
                    <Line />
                </Grid>
                <ContactInfoItem
                    label="Country"
                    value={countryName}
                    gridProps={gridColumnParams.country}
                    canCopy
                />
            </Grid>
        </Section>
    );
};

export default ContactInformationSection;
