import { useState } from 'react';
import { PaginationData } from 'src/@types/pagination';

export const usePaginationParams = (initialData: PaginationData) => {
    const [pagination, setPagination] = useState<PaginationData>(initialData);

    const updatePagination = (newPagination: Partial<PaginationData>) => {
        setPagination((prev) => ({
            ...prev,
            ...newPagination,
            currentPage: Math.max(newPagination.currentPage ?? prev.currentPage, 1),
        }));
    };

    return [pagination, updatePagination] as const;
};
