import React from 'react';
import { StripeCustomerInfo } from 'src/@types/sso-api';
import { StripeCustomerStep } from 'src/pages/udb/CreateSubscription/components/StripeCustomerStep';
import { ActiveStep, CreateSubscriptionInvoiceData } from 'src/pages/udb/CreateSubscription/CreateSubscription';
import { SubscriptionBillingType, SubscriptionType } from 'src/constants';
import { ConfirmationStep } from 'src/pages/udb/CreateSubscription/components/ConfirmationStep';
import { SubscriptionOwnerStep } from 'src/pages/udb/CreateSubscription/components/SubscriptionOwnerStep';
import { User } from 'src/@types/unified-db-api';
import { PricingTermsStep } from 'src/pages/udb/CreateSubscription/components/PricingTermsStep';
import { PricingTermsData } from 'src/pages/udb/CreateSubscription/components/PricingTermsStep/PricingTermsStep';
import { TypeAndBillingStep } from 'src/pages/udb/CreateSubscription/components/TypeAndBillingStep';
import type {
    TypeAndBillingData,
} from 'src/pages/udb/CreateSubscription/components/TypeAndBillingStep/TypeAndBillingStep';

export const initialInvoiceData = { isCreate: false, isFinalize: true };

export const isFreeInsider = (data: TypeAndBillingData) => data?.selectedBillingType === SubscriptionBillingType.FREE
    && data?.selectedSubscriptionType === SubscriptionType.INSIDER;

export const typeAndBillingNextClickHandler = (
    setActiveStep: React.Dispatch<React.SetStateAction<ActiveStep>>,
    setTypeAndBillingData: React.Dispatch<React.SetStateAction<Required<TypeAndBillingData> | undefined>>,
    setStripeCustomer: React.Dispatch<React.SetStateAction<StripeCustomerInfo | null>>,
) => (
    data: Required<TypeAndBillingData>,
) => {
    setTypeAndBillingData(data);

    if (data.selectedBillingType !== SubscriptionBillingType.INVOICED) {
        setStripeCustomer(null);
    }

    if (isFreeInsider(data)) {
        setActiveStep(ActiveStep.SUBSCRIPTION_OWNER);
        return;
    }

    setActiveStep(ActiveStep.PRICING_TERMS);
};

export const pricingTermsChangeStepHandler = ({
    nextStep,
    setActiveStep,
    setPricingTermsData,
    setInvoiceData,
}: {
    nextStep: ActiveStep;
    setActiveStep: React.Dispatch<React.SetStateAction<ActiveStep>>;
    setPricingTermsData: React.Dispatch<React.SetStateAction<PricingTermsData>>;
    setInvoiceData: React.Dispatch<React.SetStateAction<CreateSubscriptionInvoiceData>>;
}) => (
    { invoiceData, ...data }: PricingTermsData & {
        invoiceData: CreateSubscriptionInvoiceData;
    },
) => {
    setPricingTermsData(data);
    setInvoiceData(invoiceData);
    setActiveStep(nextStep);
};

export const accountOwnerNextClickHandler = ({
    setActiveStep,
    setOwnerData,
    ownerData,
    setStripeCustomer,
    typeAndBillingData: {
        selectedBillingType,
        selectedSubscriptionType,
    },
}: {
    setActiveStep: React.Dispatch<React.SetStateAction<ActiveStep>>;
    setOwnerData: React.Dispatch<React.SetStateAction<User | undefined>>;
    typeAndBillingData: TypeAndBillingData;
    setStripeCustomer: React.Dispatch<React.SetStateAction<StripeCustomerInfo | null>>;
    ownerData: User | undefined;
}) => (
    data: User,
) => {
    if (ownerData?.uuid !== data.uuid && selectedSubscriptionType === SubscriptionType.INSIDER) {
        setStripeCustomer(null);
    }

    setOwnerData(data);
    setActiveStep(
        selectedBillingType === SubscriptionBillingType.INVOICED
            ? ActiveStep.STRIPE_CUSTOMER
            : ActiveStep.CONFIRMATION,
    );
};

export const accountOwnerGoBackHandler = ({
    setOwnerData,
    setActiveStep,
    typeAndBillingData,
    ownerData,
    setStripeCustomer,
}: {
    setActiveStep: React.Dispatch<React.SetStateAction<ActiveStep>>;
    setOwnerData: React.Dispatch<React.SetStateAction<User | undefined>>;
    typeAndBillingData: Required<TypeAndBillingData>;
    setStripeCustomer: React.Dispatch<React.SetStateAction<StripeCustomerInfo | null>>;
    ownerData: User | undefined;
}) => (
    userData: User | null,
) => {
    if (
        ownerData?.uuid !== userData?.uuid && typeAndBillingData.selectedSubscriptionType === SubscriptionType.INSIDER
    ) {
        setStripeCustomer(null);
    }

    if (userData) {
        setOwnerData(userData);
    }

    if (isFreeInsider(typeAndBillingData)) {
        setActiveStep(ActiveStep.TYPE_AND_BILLING);
        return;
    }

    setActiveStep(ActiveStep.PRICING_TERMS);
};

export const stripeCustomerChangeHandler = (
    setActiveStep: React.Dispatch<React.SetStateAction<ActiveStep>>,
    setCustomerData: React.Dispatch<React.SetStateAction<StripeCustomerInfo | null>>,
    nextStep: ActiveStep.SUBSCRIPTION_OWNER | ActiveStep.CONFIRMATION,
) => (
    customer: StripeCustomerInfo | null,
) => {
    setActiveStep(nextStep);
    setCustomerData(customer);
};

export const confirmStepGoBackHandler = (
    setActiveStep: React.Dispatch<React.SetStateAction<ActiveStep>>,
    billingType: SubscriptionBillingType,
) => () => {
    setActiveStep(
        billingType === SubscriptionBillingType.INVOICED
            ? ActiveStep.STRIPE_CUSTOMER
            : ActiveStep.SUBSCRIPTION_OWNER,
    );
};

export const reloadPageHandler = (
    {
        setActiveStep,
        setOwnerData,
        setPricingTermsData,
        setTypeAndBillingData,
        setStripeCustomer,
        setInvoiceData,
    }: {
        setActiveStep: React.Dispatch<React.SetStateAction<ActiveStep>>;
        setOwnerData: React.Dispatch<React.SetStateAction<User | undefined>>;
        setPricingTermsData: React.Dispatch<React.SetStateAction<PricingTermsData>>;
        setTypeAndBillingData: React.Dispatch<React.SetStateAction<Required<TypeAndBillingData> | undefined>>;
        setStripeCustomer: React.Dispatch<React.SetStateAction<StripeCustomerInfo | null>>;
        setInvoiceData: React.Dispatch<React.SetStateAction<CreateSubscriptionInvoiceData>>;
    },
) => () => {
    setOwnerData(undefined);
    setPricingTermsData({});
    setTypeAndBillingData(undefined);
    setStripeCustomer(null);
    setActiveStep(ActiveStep.TYPE_AND_BILLING);
    setInvoiceData(initialInvoiceData);
};

export const creationStepController = ({
    ownerState,
    activeStepState,
    pricingTermsState,
    typeAndBillingState,
    invoiceState,
    stripeCustomerState,
}: {
    ownerState: [User | undefined, React.Dispatch<React.SetStateAction<User | undefined>>];
    pricingTermsState: [PricingTermsData, React.Dispatch<React.SetStateAction<PricingTermsData>>];
    typeAndBillingState: [
        Required<TypeAndBillingData> | undefined,
        React.Dispatch<React.SetStateAction<Required<TypeAndBillingData> | undefined>>,
    ];
    activeStepState: [ActiveStep, React.Dispatch<React.SetStateAction<ActiveStep>>];
    stripeCustomerState: [StripeCustomerInfo | null, React.Dispatch<React.SetStateAction<StripeCustomerInfo | null>>];
    invoiceState: [
        CreateSubscriptionInvoiceData,
        React.Dispatch<React.SetStateAction<CreateSubscriptionInvoiceData>>,
    ];
}) => {
    const [activeStep, setActiveStep] = activeStepState;

    const [ownerData, setOwnerData] = ownerState;
    const [invoiceData, setInvoiceData] = invoiceState;
    const [pricingTermsData, setPricingTermsData] = pricingTermsState;
    const [typeAndBillingData, setTypeAndBillingData] = typeAndBillingState;
    const [stripeCustomer, setStripeCustomer] = stripeCustomerState;

    switch (activeStep) {
        case ActiveStep.CONFIRMATION:
            if (!typeAndBillingData) {
                setActiveStep(ActiveStep.TYPE_AND_BILLING);
                return null;
            }
            if (!pricingTermsData[typeAndBillingData.selectedSubscriptionType] && !isFreeInsider(typeAndBillingData)) {
                setActiveStep(ActiveStep.PRICING_TERMS);
                return null;
            }
            if (!ownerData) {
                setActiveStep(ActiveStep.SUBSCRIPTION_OWNER);
                return null;
            }

            return (
                <ConfirmationStep
                    handleGoBack={(confirmStepGoBackHandler(setActiveStep, typeAndBillingData.selectedBillingType))}
                    reloadPage={
                        reloadPageHandler({
                            setActiveStep,
                            setOwnerData,
                            setPricingTermsData,
                            setTypeAndBillingData,
                            setStripeCustomer,
                            setInvoiceData,
                        })
                    }
                    typeAndBillingData={typeAndBillingData}
                    pricingTermsData={pricingTermsData}
                    stripeCustomer={stripeCustomer}
                    ownerData={ownerData}
                    invoiceData={invoiceData}
                />
            );
        case ActiveStep.STRIPE_CUSTOMER:
            if (!typeAndBillingData) {
                setActiveStep(ActiveStep.TYPE_AND_BILLING);
                return null;
            }

            if (typeAndBillingData.selectedBillingType !== SubscriptionBillingType.INVOICED) {
                setActiveStep(ActiveStep.CONFIRMATION);
                return null;
            }

            if (!ownerData) {
                setActiveStep(ActiveStep.SUBSCRIPTION_OWNER);
                return null;
            }

            return (
                <StripeCustomerStep
                    owner={ownerData}
                    initialCustomer={stripeCustomer}
                    handleGoBack={
                        stripeCustomerChangeHandler(setActiveStep, setStripeCustomer, ActiveStep.SUBSCRIPTION_OWNER)
                    }
                    handleNextButtonClick={
                        stripeCustomerChangeHandler(setActiveStep, setStripeCustomer, ActiveStep.CONFIRMATION)
                    }
                />
            );
        case ActiveStep.SUBSCRIPTION_OWNER:
            if (!typeAndBillingData) {
                setActiveStep(ActiveStep.TYPE_AND_BILLING);
                return null;
            }
            if (!pricingTermsData[typeAndBillingData.selectedSubscriptionType] && !isFreeInsider(typeAndBillingData)) {
                setActiveStep(ActiveStep.PRICING_TERMS);
                return null;
            }

            return (
                <SubscriptionOwnerStep
                    ownerData={ownerData}
                    handleGoBack={accountOwnerGoBackHandler({
                        setActiveStep,
                        setOwnerData,
                        typeAndBillingData,
                        setStripeCustomer,
                        ownerData,
                    })}
                    handleNextButtonClick={accountOwnerNextClickHandler({
                        setActiveStep,
                        setOwnerData,
                        setStripeCustomer,
                        typeAndBillingData,
                        ownerData,
                    })}
                />
            );
        case ActiveStep.PRICING_TERMS:
            if (!typeAndBillingData) {
                setActiveStep(ActiveStep.TYPE_AND_BILLING);
                return null;
            }

            return (
                <PricingTermsStep
                    initialData={pricingTermsData}
                    numberOfCycles={typeAndBillingData.numberOfCycles}
                    billingCycleLength={typeAndBillingData.selectedBillingCycleLength}
                    selectedSubscriptionType={typeAndBillingData.selectedSubscriptionType}
                    selectedBillingType={typeAndBillingData.selectedBillingType}
                    handleNextButtonClick={
                        pricingTermsChangeStepHandler({
                            nextStep: ActiveStep.SUBSCRIPTION_OWNER,
                            setActiveStep,
                            setPricingTermsData,
                            setInvoiceData,
                        }) }
                    handleGoBackClick={
                        pricingTermsChangeStepHandler({
                            nextStep: ActiveStep.TYPE_AND_BILLING,
                            setActiveStep,
                            setPricingTermsData,
                            setInvoiceData,
                        })
                    }
                    initialInvoiceData={invoiceData}
                />
            );
        case ActiveStep.TYPE_AND_BILLING:
        default:
            return (
                <TypeAndBillingStep
                    initialData={typeAndBillingData}
                    handleNextButtonClick={
                        typeAndBillingNextClickHandler(setActiveStep, setTypeAndBillingData, setStripeCustomer)
                    }
                />
            );
    }
};
