import React, { FC } from 'react';
import SettingsModal from './SettingsModal';
import { updateCredentialsSettings } from 'src/services/sso-api';
import type { Credentials } from 'src/@types/credentials';
import { SettingsData } from 'src/@types/sso-api';

export type CredentialsSettingsModalProps = {
    settings: SettingsData;
    credentialsId: number;
};

export const getCredentialsSettingsModalProps = (
    { settings, credentials }: { credentials: Credentials, settings: SettingsData },
): CredentialsSettingsModalProps => ({ settings, credentialsId: credentials.id });

export const CredentialsSettings: FC<CredentialsSettingsModalProps> = ({ settings, credentialsId }) => {
    const handleSave = async (newSettings: Partial<SettingsData>) => {
        return updateCredentialsSettings(credentialsId, newSettings);
    };

    return (
        <SettingsModal
            onSave={handleSave}
            settings={settings}
        />
    );
};

export default CredentialsSettings;
