import React, { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import {
    buildActivationParams,
    buildCreateBillingRecordParams,
    buildCreateSubscriptionParams,
    buildUpdateSubscriptionParams,
    shouldUpdateActivationDate,
} from './services/param-builders';
import { getCountriesList } from 'src/services/user-profile-api';
import type { RootState } from 'src/redux/root-reducer';
import { StripeCustomerInfo } from 'src/@types/sso-api';
import { CreateSubscriptionInvoiceData } from 'src/pages/udb/CreateSubscription/CreateSubscription';
import { convertDateTimeZone, convertToUTCDate } from 'src/services/date-time-zone-converter';
import { makePlural } from 'src/services/text-modifier';
import { useLookupNamesHook, useSnackbarMessage } from 'src/hooks';
import { SuccessMessage } from 'src/pages/udb/CreateSubscription/components/SuccessMessage';
import {
    activateSubscription,
    addManager,
    attachDomains,
    calculateDates,
    createCustomPricingTerms,
    createInitialBillingRecord,
    createSubscription,
    setOwner,
    updateSubscription,
} from 'src/services/subscription-service-api';
import {
    getBillingDetailsData,
    getInvoiceData,
    getPricingTermsData,
    getTypeAndBillingData,
} from 'src/pages/udb/CreateSubscription/services/confirmation-step-getters';
import { DomainInfoBox } from 'src/components/DomainInfoBox';
import { UserInfoBox } from 'src/components/UserInfoBox';
import {
    ActivationDate,
    DATE_TIME_ISO_8601,
    SnackbarMessageVariants,
    SubscriptionBillingType,
    SubscriptionType,
} from 'src/constants';
import { InfoBox } from 'src/components/InfoBox';
import { PricingTermsData } from 'src/pages/udb/CreateSubscription/components/PricingTermsStep/PricingTermsStep';
import type {
    TypeAndBillingData,
} from 'src/pages/udb/CreateSubscription/components/TypeAndBillingStep/TypeAndBillingStep';
import { Section } from 'src/components/Section';
import { StepContainer } from 'src/pages/udb/CreateSubscription/components/StepContainer';
import { User } from 'src/@types/unified-db-api';
import {
    assignStripeCustomer,
    createSubscriptionBillingInvoice,
    finalizeBillingRecordInvoice,
} from 'src/services/sso-api';
import { BillingRecordWithRenewal } from 'src/@types/subscription-service-api';
import { getResponseError } from 'src/services/error-formatters';
import type { ConfigState } from 'src/@types/redux';
import { configSelector } from 'src/redux/slices';

type ConfirmationStepProps = {
    handleGoBack: () => void;
    typeAndBillingData: Required<TypeAndBillingData>;
    pricingTermsData: PricingTermsData;
    stripeCustomer: StripeCustomerInfo | null;
    ownerData: User;
    reloadPage: () => void;
    invoiceData: CreateSubscriptionInvoiceData;
};

const ConfirmationStep: FC<ConfirmationStepProps> = ({
    handleGoBack,
    typeAndBillingData,
    pricingTermsData,
    stripeCustomer,
    ownerData,
    reloadPage,
    invoiceData,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [createdSubscriptionUUID, setCreatedSubscriptionUUID] = useState<string>();
    const [expirationDate, setExpirationDate] = useState<string>();

    const { testMode } = useSelector<RootState, ConfigState>(configSelector);
    const country = useLookupNamesHook(getCountriesList, stripeCustomer?.address?.country);

    const { addMessage } = useSnackbarMessage();
    const { isCreate, isFinalize } = invoiceData;

    const isInvoiced = typeAndBillingData.selectedBillingType === SubscriptionBillingType.INVOICED;
    const isFreeInsider = typeAndBillingData.selectedBillingType === SubscriptionBillingType.FREE
        && typeAndBillingData.selectedSubscriptionType === SubscriptionType.INSIDER;

    useEffect(() => {
        if (
            typeAndBillingData.selectedActivationDateType === ActivationDate.SPECIFIED
            && typeAndBillingData.activationDate
        ) {
            setIsLoading(true);
            calculateDates({
                cycleCount: typeAndBillingData.numberOfCycles,
                cycleLengthInMonths: typeAndBillingData.selectedBillingCycleLength,
                activationDate: convertToUTCDate(typeAndBillingData.activationDate).format(DATE_TIME_ISO_8601),
            })
                .then(({ expirationDate: calculatedExpirationDate }) => {
                    setExpirationDate(calculatedExpirationDate);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, []);

    if (createdSubscriptionUUID) {
        return (
            <SuccessMessage
                createdSubscriptionUUID={createdSubscriptionUUID}
                subscriptionType={typeAndBillingData.selectedSubscriptionType}
                pricingTermsData={pricingTermsData}
                reloadPage={reloadPage}
                ownerData={ownerData}
            />
        );
    }

    const activateSubscriptionRequest = async (subscriptionUUID: string) => {
        const isSpecifiedDate = typeAndBillingData.selectedActivationDateType === ActivationDate.SPECIFIED;
        const isNowDate = typeAndBillingData.selectedActivationDateType === ActivationDate.NOW;
        const isActivationDateInPast = dayjs(typeAndBillingData.activationDate).isBefore(convertDateTimeZone(dayjs()));

        if (!isNowDate && (!isSpecifiedDate || !isActivationDateInPast || !typeAndBillingData.activationDate)) {
            return;
        }

        return activateSubscription(subscriptionUUID, buildActivationParams(typeAndBillingData))
            .then(() => {
                addMessage('Subscription has been activated', SnackbarMessageVariants.SUCCESS);
            })
            .catch(() => {
                addMessage('Failed to activate subscription', SnackbarMessageVariants.ERROR);
            });
    };

    const attachDomainsRequest = async (subscriptionUUID: string) => {
        if (typeAndBillingData.selectedSubscriptionType === SubscriptionType.INSIDER) {
            return true;
        }

        const { domains } = pricingTermsData.enterprise!;

        return attachDomains(subscriptionUUID, { domains: domains.map(({ domain }) => domain) })
            .then(() => {
                addMessage(`${domains.length} ${makePlural('domain', domains.length)} added successfully`, SnackbarMessageVariants.SUCCESS);
                return true;
            })
            .catch(() => {
                addMessage(`Failed to add ${domains.length} ${makePlural('domain', domains.length)}`, SnackbarMessageVariants.ERROR);
                return false;
            });
    };

    const setOwnerRequest = async (subscriptionUUID: string) => {
        const { fullName, uuid: ownerUUID } = ownerData;

        return setOwner(subscriptionUUID, { userUUID: ownerUUID })
            .then(() => {
                addMessage(`${fullName} has been successfully set as an owner`, SnackbarMessageVariants.SUCCESS);
                return true;
            })
            .catch(() => {
                addMessage(`Failed to set ${fullName} as an owner`, SnackbarMessageVariants.ERROR);
                return false;
            });
    };

    const addManagerRequest = async (subscriptionUUID: string) => {
        const { fullName, uuid: ownerUUID } = ownerData;

        return addManager(subscriptionUUID, { managers: [ownerUUID] })
            .then(() => {
                addMessage(`${fullName} has been successfully added as a manager`, SnackbarMessageVariants.SUCCESS);
                return true;
            })
            .catch(() => {
                addMessage(`Failed to set ${fullName} as an manager`, SnackbarMessageVariants.ERROR);
                return false;
            });
    };

    const createInitialBillingRecordRequest = async (
        subscriptionUUID: string,
    ): Promise<BillingRecordWithRenewal | void> => {
        return createInitialBillingRecord(
            subscriptionUUID,
            buildCreateBillingRecordParams(typeAndBillingData, pricingTermsData),
        )
            .then((result) => {
                addMessage('Billing record has been created', SnackbarMessageVariants.SUCCESS);
                return result;
            }).catch(() => {
                addMessage('Failed to create billing record', SnackbarMessageVariants.ERROR);
            });
    };

    const createCustomPricingTermsRequest = async (subscriptionUUID: string) => {
        if (!pricingTermsData.insider || pricingTermsData.insider.discountValue === null || isFreeInsider) {
            return true;
        }

        const { finalPriceInCents, ...customPricingTerms } = pricingTermsData.insider;

        return createCustomPricingTerms(subscriptionUUID, customPricingTerms)
            .then(() => {
                addMessage('Custom pricing terms have been created', SnackbarMessageVariants.SUCCESS);
                return true;
            }).catch(() => {
                addMessage('Failed to create custom pricing terms', SnackbarMessageVariants.ERROR);
                return false;
            });
    };

    const assignStripeCustomerRequest = async (subscriptionUUID: string): Promise<boolean> => {
        if (!stripeCustomer || typeAndBillingData.selectedBillingType !== SubscriptionBillingType.INVOICED) {
            return true;
        }

        return assignStripeCustomer(subscriptionUUID, stripeCustomer.id)
            .then(() => {
                addMessage('Stripe customer has been assigned', SnackbarMessageVariants.SUCCESS);
                return true;
            })
            .catch(() => {
                addMessage('Failed to assign stripe customer', SnackbarMessageVariants.ERROR);
                return false;
            });
    };

    const updateSubscriptionRequest = async (subscriptionUUID: string) => {
        const shouldUpdateSubscription = shouldUpdateActivationDate(typeAndBillingData);

        if (!shouldUpdateSubscription) {
            return;
        }

        return updateSubscription(subscriptionUUID, buildUpdateSubscriptionParams(typeAndBillingData))
            .catch(() => {
                addMessage('Failed to set activation date', SnackbarMessageVariants.ERROR);
            });
    };

    const handleFinalizeInvoice = (uuid: string, billingRecordId: number): Promise<void> => {
        return finalizeBillingRecordInvoice(uuid, billingRecordId)
            .then(() => {
                addMessage('Invoice successfully finalized', SnackbarMessageVariants.SUCCESS);
            })
            .catch((error) => {
                addMessage(getResponseError(error, 'Failed to finalize invoice'), SnackbarMessageVariants.ERROR);
            });
    };

    const handleCreateInvoice = async (uuid: string, billingRecord: BillingRecordWithRenewal): Promise<void> => {
        const { id, priceInCents } = billingRecord;

        return createSubscriptionBillingInvoice(uuid, id, {
            ...(priceInCents && { priceInCents: priceInCents }),
            ...(invoiceData.poNumber && { customFields: { 'PO Number': invoiceData.poNumber } }),
            ...(invoiceData.dueDate && { dueDate: convertToUTCDate(invoiceData.dueDate).toISOString() }),
        })
            .then(async () => {
                addMessage('Invoice successfully created', SnackbarMessageVariants.SUCCESS);

                if (isFinalize) {
                    await handleFinalizeInvoice(uuid, id);
                }

            })
            .catch((error) => {
                addMessage(getResponseError(error, 'Failed to create invoice'), SnackbarMessageVariants.ERROR);
            });
    };

    const handleCreateSubscription = () => {
        setIsLoading(true);
        createSubscription(buildCreateSubscriptionParams(typeAndBillingData))
            .then(async ({ uuid }) => {
                addMessage('Draft subscription has been successfully created', SnackbarMessageVariants.SUCCESS);

                return Promise.all<boolean>([
                    attachDomainsRequest(uuid),
                    createCustomPricingTermsRequest(uuid),
                    addManagerRequest(uuid).then((added) => added && setOwnerRequest(uuid)),
                    assignStripeCustomerRequest(uuid),
                ]).then(async (results) => {
                    await updateSubscriptionRequest(uuid);

                    const billingRecordRequestStatus = await createInitialBillingRecordRequest(uuid)
                        .then(async (data) => {
                            if (data && isCreate && isInvoiced) {
                                await handleCreateInvoice(uuid, data);
                            }

                            return !!data;
                        });

                    const allRequestsSucceeded = results.every((value) => value) && billingRecordRequestStatus;
                    if (allRequestsSucceeded) {
                        await activateSubscriptionRequest(uuid);
                    }
                }).then(() => {
                    setCreatedSubscriptionUUID(uuid);
                });
            })
            .catch((error) => {
                addMessage(error.message, SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <StepContainer
            isLoading={isLoading}
            handleGoBack={handleGoBack}
            handleNextButtonClick={handleCreateSubscription}
            showNextButton
            showGoBackButton
            title="Confirmation"
            nextButtonTitle="Create"
            testId="confirmation-step"
        >
            <Section variant="headless" header="Subscription type and billing terms">
                <InfoBox items={getTypeAndBillingData(typeAndBillingData, expirationDate)} />
            </Section>
            {!isFreeInsider && (
                <Section variant="headless" header="Pricing terms">
                    <InfoBox
                        items={[
                            ...getPricingTermsData(pricingTermsData, typeAndBillingData),
                            ...typeAndBillingData.selectedBillingType === SubscriptionBillingType.INVOICED
                                ? getInvoiceData(invoiceData)
                                : [],
                        ]}
                    />
                </Section>
            )}
            {typeAndBillingData.selectedSubscriptionType === SubscriptionType.ENTERPRISE && (
                <Section variant="headless" header="Domains">
                    <Stack spacing={1.5}>
                        {pricingTermsData.enterprise!.domains.map((domain) => (
                            <DomainInfoBox key={domain.domain} domain={domain} />
                        ))}
                    </Stack>
                </Section>
            )}
            <Section variant="headless" header="Subscription owner">
                <UserInfoBox
                    name={ownerData.fullName}
                    email={ownerData.email.email}
                    jobTitle={ownerData.jobInfo?.jobTitle}
                    companyName={ownerData.jobInfo?.companyName}
                />
            </Section>
            {stripeCustomer && (
                <Section variant="headless" header="Billing details">
                    <InfoBox
                        items={getBillingDetailsData(stripeCustomer, country, testMode)}
                    />
                </Section>
            )}
        </StepContainer>
    );
};

export default ConfirmationStep;
