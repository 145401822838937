import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledContent = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.normal};
        line-height: 140%;
        font-size: 16px;

        @media(max-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 14px;
        }
    }
`;

export const StyledBoldText = styled.span`
    && {
        font-family: ${({ theme }) => theme.fonts.bold};
    }
`;
