import styled from 'styled-components';
import { FormControlLabel, Stack, Typography } from '@mui/material';
import { TextButton } from 'src/components/Buttons';

export const StyledText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.black};
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 14px;
    }
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin: 0;
  }
`;

export const StyledDescription = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.medium};
    color: ${({ theme }) => theme.palette.lightGrey};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
      
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 12px;
    }
  }
`;

export const StyledQR = styled(Stack)`
  && {
    padding: 20px 18px 16px;
    border: 2px solid ${({ theme }) => theme.palette.borderGrey};
    border-radius: 4px;
  }
`;

export const StyledQRDescription = styled(TextButton)`
  &&& {
    padding: 0;
    margin-top: 12px;
    font-family: ${({ theme }) => theme.fonts.normal};
    font-size: 14px;
    font-weight: 450;
    line-height: normal;  
      
    &:focus {
      margin-top: 12px;
    }
  }
`;
