import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import SettingsModal from './SettingsModal';
import { updateSettings } from 'src/services/sso-api';
import type { RootState } from 'src/redux/root-reducer';
import type { CurrentUserState } from 'src/@types/redux';
import { userSelector } from 'src/redux/slices';

const CurrentUserSettings: FC = () => {
    const { currentUser } = useSelector<RootState, CurrentUserState>(userSelector);

    if (!currentUser) {
        return null;
    }

    return (
        <SettingsModal
            onSave={updateSettings}
            settings={currentUser.settings}
            excludedSettings={['disableTFAForcing']}
        />
    );
};

export default CurrentUserSettings;
