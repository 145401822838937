import type { ScreenSize } from 'src/@types/screen';
import { screenSizeBreakpoints } from 'src/constants';

export const getThemeBreakpoints = (): Record<ScreenSize, string> => {
    return Object.fromEntries(
        Object.entries(screenSizeBreakpoints)
            .map(([key, value]) => [key, `${value}px`]),
    ) as Record<ScreenSize, string>;
};

const screenSizeMapXlToXs = Object.entries(screenSizeBreakpoints)
    .sort((a, b) => b[1] - a[1]) as Array<[ScreenSize, number]>;

export const getScreenSizeByWidth = (width: number | undefined): ScreenSize => {
    if (typeof width !== 'number') {
        return 'xl';
    }

    return screenSizeMapXlToXs.find((sizeProps) => width >= sizeProps[1])?.[0] ?? 'xl';
};
