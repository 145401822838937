import React, { FC, FormEvent, useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import type { StripeError } from '@stripe/stripe-js';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useTheme } from 'styled-components';
import { CouponRow } from './components';
import { useSaveBillingAddress } from './hooks/useSaveBillingAddress';
import { CouponFormValues } from './components/CouponRow';
import { useStripeAppearance } from 'src/hooks/useStripeAppearance';
import { StripeAddress, StripePaymentElement } from 'src/components/Stripe';
import { RequestError } from 'src/components/Forms/components';
import { FlowSteps } from 'src/components/FlowSteps';
import type { StripeCustomerInfo, StripeDiscountInfo } from 'src/@types/sso-api';
import { Link } from 'src/components/Link';
import Line from 'src/components/Line';
import { useSnackbarMessage } from 'src/hooks';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { BillingAddress } from 'src/components/BillingAddress';
import { Button } from 'src/components/Buttons';
import PageTitle from 'src/components/PageTitle';
import { formatPrice } from 'src/services/formatters';
import {
    createInvoicePreview,
    createSubscriptionToCheckout,
    getCustomerByUserUUID,
    validateCoupon,
} from 'src/services/sso-api';
import { makeURL } from 'src/services/url-maker';
import { SnackbarMessageVariants } from 'src/constants';
import APIClientError from 'src/classes/api-client-error';

type CheckoutFormProps = {
    productName: string;
    priceInCents: number;
    priceId: string;
    isNewUser: boolean;
    userUUID: string;
    userFullName?: string;
};

enum Step {
    ADDRESS = 'address',
    CARD = 'card',
}

const checkoutSteps = [
    { key: Step.ADDRESS, label: 'Billing address' },
    { key: Step.CARD, label: 'Payment method' },
];

type CouponData = { couponId?: string, promotionCodeId?: string };

const CheckoutForm: FC<CheckoutFormProps> = ({
    isNewUser,
    userUUID,
    userFullName,
    priceInCents,
    productName,
    priceId,
}) => {
    const [isNextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true);
    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [taxesInCents, setTaxesInCents] = useState<number>(0);
    const [subtotalInCents, setSubtotalInCents] = useState<number>(priceInCents);
    const [totalInCents, setTotalInCents] = useState<number>(priceInCents);
    const [customerInfo, setCustomerInfo] = useState<StripeCustomerInfo | null>();
    const [requestError, setRequestError] = useState<string | undefined>();
    const [discountData, setDiscountData] = useState<StripeDiscountInfo | null>(null);
    const [couponData, setCouponData] = useState<CouponData>({});

    const { addMessage } = useSnackbarMessage();
    useStripeAppearance();

    const theme = useTheme();
    const stripe = useStripe();
    const elements = useElements();

    const [step, setStep] = useState<Step>(Step.ADDRESS);

    const handleError = (error?: StripeError | Error | string) => {
        setLoading(false);

        if (typeof error === 'object') {
            setRequestError(error.message);
            return;
        }

        addMessage(
            typeof error === 'string' ? error : 'Failed to checkout, please try again or contact us',
            SnackbarMessageVariants.ERROR,
        );
    };

    const calculateTotal = async (coupon: CouponData = {}) => {
        await createInvoicePreview({ priceId, userUUID, ...couponData, ...coupon })
            .then(({ tax, total, subtotal, discount }) => {
                setTaxesInCents(tax ?? 0);
                setTotalInCents(total);
                setSubtotalInCents(subtotal);
                setDiscountData(discount);
                setCouponData((prevValue) => ({ ...prevValue, ...coupon }));
            })
            .catch(() => {
                if (coupon) {
                    addMessage('Failed to apply provided coupon', SnackbarMessageVariants.ERROR);
                }
                handleError('Failed to calculate total');
            });
    };

    useEffect(() => {
        setLoading(true);
        getCustomerByUserUUID(userUUID)
            .then(async ({ customer }) => {
                if (customer) {
                    await calculateTotal();
                }
                setCustomerInfo(customer);
            })
            .catch(() => setCustomerInfo(null));
    }, []);

    useEffect(() => {
        if (!!stripe && !!elements && customerInfo !== undefined) {
            setLoading(false);
        }
    }, [!!stripe, !!elements, customerInfo !== undefined]);

    const saveBillingAddress = useSaveBillingAddress({
        userUUID,
        customerInfo,
        onDataNotCompleteYet: () => {
            setNextButtonDisabled(true);
        },
        onDataNotChanged: () => {
            setStep(Step.CARD);
        },
        onDataSaved: async (customer) => {
            setCustomerInfo(customer);
            setStep(Step.CARD);
            await calculateTotal();
        },
        onDataSaveError: (error) => {
            handleError(
                error instanceof APIClientError
                && !!error.responseError
                && 'status' in error.responseError
                && [400, 409].includes(error.responseError.status)
                    ? new Error(error.responseError.data?.error)
                    : 'Failed to save billing address',
            );
        },
        setLoading,
    });

    const handleCouponFormSubmit = async (values: CouponFormValues): Promise<boolean> => {
        return validateCoupon(values.couponCode)
            .then(async (response) => {
                const { valid, couponId, promotionCodeId } = response;

                if (!valid) {
                    addMessage('Provided coupon is not valid', SnackbarMessageVariants.ERROR);
                    return valid;
                }

                await calculateTotal({ couponId, promotionCodeId });
                return valid;
            })
            .catch(() => {
                addMessage('Failed to validate provided coupon', SnackbarMessageVariants.WARNING);
                return false;
            });
    };

    const handleSubmit = async (event: FormEvent) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setLoading(true);

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit().catch((error) => ({ error }));
        if (submitError) {
            handleError(submitError);
            return;
        }

        const checkoutSubscriptionDetails = await createSubscriptionToCheckout({
            priceId,
            userUUID,
            isNewUser,
            ...couponData,
        }).catch(() => handleError());

        if (!checkoutSubscriptionDetails) {
            return;
        }

        await stripe.confirmPayment({
            elements,
            clientSecret: checkoutSubscriptionDetails.clientSecret,
            confirmParams: {
                return_url: makeURL(`/upgrade/insider/${checkoutSubscriptionDetails.accessCode}`).toString(),
            },
        })
            .then(({ error }) => error && handleError(error.message))
            .catch(() => handleError());
    };

    const formattedPrice = formatPrice(priceInCents);
    const formattedTaxes = formatPrice(taxesInCents);
    const formattedSubtotal = formatPrice(subtotalInCents);
    const formattedTotal = formatPrice(totalInCents);

    const renderSubmitButtons = () => (
        step === Step.ADDRESS ? (
            <Button onClick={saveBillingAddress} disabled={isNextButtonDisabled} fullWidth data-testid="next-button">
                Next
            </Button>
        ) : (
            <Button type="submit" form="checkout-form" disabled={isSubmitDisabled} fullWidth data-testid="submit-button">
                Pay {formattedTotal}
            </Button>
        )
    );

    const notice = (
        <Typography fontSize={12} color={theme.palette.lightGrey} marginTop={2} textAlign="center" fontFamily={theme.fonts.medium}>
            We do not store your credit card and billing information.
            It is stored in and processed by Stripe, our payments platform partner.
        </Typography>
    );

    return (
        <>
            {isLoading && <LoaderOverlay />}
            <Box paddingBottom={2} display={{ xs: 'block', md: 'none' }}>
                <PageTitle variant="form" testId="checkout-form-title" title="Checkout" />
            </Box>
            <Grid
                container
                data-testid="checkout-page"
                display="flex"
                direction={{ xs: 'column-reverse', md: 'row' }}
                justifyContent={{ xs: 'flex-end' }}
                flexWrap="nowrap"
                paddingX={{ xs: 0, md: 2.5 }}
            >
                <Grid
                    item
                    xs={12}
                    md={5.375}
                    container
                    display="flex"
                    direction="column"
                    justifyContent="space-between"
                >
                    <Stack spacing={1.5}>
                        <PageTitle
                            variant="form"
                            testId="checkout-form-title"
                            title="Checkout"
                            display={{ xs: 'none', md: 'block' }}
                        />
                        {customerInfo && step !== Step.ADDRESS && (
                            <Box paddingTop={1.5}>
                                <BillingAddress
                                    name={customerInfo.name}
                                    address={customerInfo.address}
                                    onEditButtonClick={() => setStep(Step.ADDRESS)}
                                />
                            </Box>
                        )}
                        <Stack direction="row" justifyContent="space-between" paddingTop={1.5}>
                            <Typography fontFamily={theme.fonts.medium} fontSize={14}>
                                {productName}
                            </Typography>
                            <Typography fontFamily={theme.fonts.medium} fontSize={14}>
                                {formattedPrice}
                            </Typography>
                        </Stack>
                        <Line />
                        <Stack direction="row" justifyContent="space-between">
                            <Typography fontFamily={theme.fonts.medium} color="secondary" fontSize={14}>
                                    Subtotal
                            </Typography>
                            <Typography fontFamily={theme.fonts.medium} color="secondary" fontSize={14}>
                                {formattedSubtotal}
                            </Typography>
                        </Stack>
                        {!!taxesInCents && (
                            <>
                                <Line />
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography fontFamily={theme.fonts.medium} color="secondary" fontSize={14}>
                                            Taxes
                                    </Typography>
                                    <Typography fontFamily={theme.fonts.medium} color="secondary" fontSize={14}>
                                        {formattedTaxes}
                                    </Typography>
                                </Stack>
                            </>
                        )}
                        <Line />
                        <CouponRow
                            onFormSubmit={handleCouponFormSubmit}
                            discount={discountData}
                            subtotalInCents={subtotalInCents}
                            initialCode={couponData.promotionCodeId || couponData.couponId || ''}
                        />
                        <Line />
                        <Stack direction="row" justifyContent="space-between">
                            <Typography fontFamily={theme.fonts.bold} fontSize={{ xs: 16, md: 18 }}>
                                    Total due
                            </Typography>
                            <Typography fontFamily={theme.fonts.bold} fontSize={{ xs: 16, md: 18 }}>
                                {formattedTotal}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Box
                        width="fit-content"
                        paddingTop={2}
                        margin={{ xs: 'auto', md: 0 }}
                        position={{ xs: 'absolute', md: 'static' }}
                        bottom={16}
                        left={0}
                        right={0}
                    >
                        <Link target="_blank" href="/paid-subscription-terms-and-conditions">
                            <Typography
                                fontFamily={theme.fonts.medium}
                                color={theme.palette.grey}
                                fontSize={{ xs: 12, md: 14 }}
                            >
                                    Terms and Conditions
                            </Typography>
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={12} md={1.25}>
                    <Box
                        display={{ xs: 'none', md: 'block' }}
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        margin="auto"
                        height="100%"
                        width="1px"
                    >
                        <Line variant="vertical" />
                    </Box>
                </Grid>

                <Grid item xs={12} md={5.375}>
                    <form onSubmit={handleSubmit} data-testid="checkout-form" id="checkout-form">
                        {requestError && <RequestError testId="checkout-request-error" display={{ md: 'none' }} marginBottom={2}>{requestError}</RequestError>}
                        <Stack spacing={3}>
                            <Box paddingBottom={{ xs: 0, md: 1 }}>
                                <FlowSteps
                                    activeStepKey={step}
                                    steps={checkoutSteps}
                                />
                            </Box>
                            <Box
                                display={step === Step.ADDRESS ? 'block' : 'none' }
                                data-testid="checkout-form-billing-address-container"
                            >
                                {customerInfo === undefined ? null : (
                                    <StripeAddress
                                        name={customerInfo?.name || userFullName}
                                        address={customerInfo?.address || undefined}
                                        onChange={({ complete }) => {
                                            setRequestError(undefined);
                                            setNextButtonDisabled(!complete);
                                        }}
                                        onReady={(addressElement) => {
                                            if (!customerInfo?.address?.line1) {
                                                return;
                                            }

                                            setTimeout(() => {
                                                addressElement.getValue()
                                                    .then(({ complete }) => {
                                                        if (complete) {
                                                            setNextButtonDisabled(false);
                                                            setStep(Step.CARD);
                                                        }
                                                    });
                                            }, 20);
                                        }}
                                    />
                                )}
                            </Box>
                            <Box
                                display={step !== Step.ADDRESS ? 'block' : 'none' }
                                data-testid="checkout-form-card-container"
                            >
                                <StripePaymentElement
                                    onChange={({ complete }) => {
                                        setRequestError(undefined);
                                        setSubmitDisabled(!complete);
                                    }}
                                />
                            </Box>
                        </Stack>
                        {requestError && (
                            <Box display={{ xs: 'none', md: 'block' }} paddingTop={3}>
                                <RequestError>{requestError}</RequestError>
                            </Box>
                        )}
                        <Box display={{ xs: 'none', md: 'block' }} width="100%" paddingTop={requestError ? 3 : 6}>
                            {renderSubmitButtons()}
                            {notice}
                        </Box>
                    </form>
                </Grid>
            </Grid>
            <Box
                display={{ xs: 'block', md: 'none' }}
                textAlign="center"
                width="100%"
                paddingBottom={3.75}
                paddingTop={3}
            >
                {renderSubmitButtons()}
                {notice}
            </Box>
        </>
    );
};

export default CheckoutForm;
