import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { Box, BoxProps, GridProps, Stack, StackProps } from '@mui/material';
import { getKeyProp } from './services/getters';
import { NoContentMessage } from './TableSection.styles';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { Pagination } from 'src/components/Pagination';
import { PROFILE_SECTION_PAGE_SIZE, PROFILE_SECTION_PAGE_SIZE_OPTIONS } from 'src/constants';
import { PaginationData } from 'src/@types/pagination';
import { HeaderSection } from 'src/components/TableSection/HeaderSection';
import { Section } from 'src/components/Section';
import Line from 'src/components/Line';

type ExtendedGridProps = GridProps & {
    display_title?: string; // snake_case is used here to avoid React compile warning
    column_title?: string; // snake_case is used here to avoid React compile warning
};

export type GridColumnProps<T extends string = string> = Record<T, ExtendedGridProps>;

type BaseItem = Record<string, unknown>;
type ExtraProps = Record<string, unknown>;

type SectionProps<T extends BaseItem, GC extends GridColumnProps> = {
    items: T[];
    gridColumnParams: GC;
    sectionHeader: string;
    testId?: string;
    renderItem: (item: T, extraProps: { gridColumnParams: GC } & ExtraProps) => ReactNode;
    extraProps?: ExtraProps;
    headerChildren?: ReactNode;
    headerHint?: ReactNode;
    children?: ReactNode;
    headerPaddingBottom?: StackProps['paddingBottom'];
    paginationModel?: PaginationData;
    noContentMessage?: string;
    setPagination?: (newPagination: Partial<PaginationData>) => void;
    isLoading: boolean;
    columns?: GridProps['columns'];
    maxContentHeight?: BoxProps['maxHeight'];
};
const TableSection = <T extends BaseItem, GC extends GridColumnProps> ({
    items,
    gridColumnParams,
    sectionHeader,
    renderItem,
    extraProps = {},
    headerHint,
    headerChildren,
    children,
    headerPaddingBottom,
    maxContentHeight,
    noContentMessage,
    paginationModel,
    setPagination,
    isLoading,
    columns,
    testId,
}: SectionProps<T, GC>) => {
    const [showLoader, setShowLoader] = useState<boolean>(isLoading);

    const shouldShowPagination = paginationModel
        && (paginationModel.totalCount > PROFILE_SECTION_PAGE_SIZE || paginationModel.pages > 1);

    useEffect(() => {
        if (!isLoading) {
            setShowLoader(false);
        }
    }, [isLoading, items]);

    useEffect(() => {
        if (isLoading) {
            setShowLoader(true);
        }
    }, [isLoading]);

    const setPageSizeState = (pageSize: number) => {
        if (paginationModel?.pageSize === pageSize) {
            return;
        }

        setShowLoader(true);
        setPagination?.({ pageSize });
    };

    const setPageState = (nextPage: number) => {
        if (paginationModel?.currentPage === nextPage) {
            return;
        }
        setShowLoader(true);
        setPagination?.({ currentPage: nextPage });
    };

    return (
        <Section
            data-testid={testId}
            sectionPaddingBottom={3}
            header={sectionHeader}
            headerHint={headerHint}
            headerChildren={headerChildren}
        >
            {showLoader && <LoaderOverlay />}
            {items.length ? (
                <Fragment>
                    <HeaderSection
                        paddingBottom={headerPaddingBottom}
                        gridColumnParams={gridColumnParams}
                        columns={columns}
                    />
                    <Box maxHeight={maxContentHeight} overflow="hidden auto">
                        {items.map((item, index) => (
                            <Fragment key={getKeyProp(item) ?? index}>
                                {index !== 0 && <Stack paddingY={2}><Line /></Stack>}
                                {renderItem(item, {
                                    gridColumnParams,
                                    ...extraProps,
                                })}
                            </Fragment>
                        ))}
                    </Box>
                    {children}
                    {shouldShowPagination && (
                        <Pagination
                            totalCount={paginationModel.totalCount}
                            pageSize={paginationModel.pageSize}
                            pageState={paginationModel.currentPage}
                            pageSizeOptions={PROFILE_SECTION_PAGE_SIZE_OPTIONS}
                            setPageState={setPageState}
                            setPageSizeState={setPageSizeState}
                            variant="profile-section"
                        />
                    )}
                </Fragment>
            ) : (
                <NoContentMessage marginY={1.5}>
                    {noContentMessage}
                </NoContentMessage>
            )}
        </Section>
    );
};

export default TableSection;
