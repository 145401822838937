import { useElements } from '@stripe/react-stripe-js';
import { useEffect } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import type { Appearance } from '@stripe/stripe-js/dist/stripe-js/elements-group';
import { useSelector } from 'react-redux';
import type { RootState } from 'src/redux/root-reducer';
import { isMobileSelector } from 'src/redux/slices';

const tabBoxShadow = '0px 3px 6px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)';
const selectedTabBoxShadow = '0px 1px 1px 0px rgba(0, 0, 0, 0.08)';

export const getAppearance = (theme: DefaultTheme, isMobile: boolean): Appearance => ({
    theme: 'stripe',
    variables: {
        colorText: theme.palette.grey,
        colorDanger: theme.palette.red,
        fontFamily: theme.fonts.medium,
        borderRadius: '4px',
        gridColumnSpacing: '16px',
        gridRowSpacing: isMobile ? '20px' : '32px',
    },
    rules: {
        '.Input': {
            fontSize: '16px',
            padding: '14px 16px',
            color: theme.palette.black,
            fontFamily: theme.fonts.normal,
            boxShadow: 'none',
            lineHeight: '20px',
        },
        '.Input:hover': {
            backgroundColor: theme.palette.hoveredInputBackground,
        },
        '.Input:focus': {
            border: `solid 1px ${theme.palette.blue}`,
            boxShadow: 'none',
        },
        '.Input--invalid': {
            border: `solid 1px ${theme.palette.red}`,
            boxShadow: 'none',
        },
        '.Input::placeholder': {
            fontFamily: theme.fonts.normal,
            color: theme.palette.lightGrey,
            fontSize: '16px',
        },
        '.Error': {
            color: theme.palette.red,
            fontFamily: theme.fonts.normal,
            fontSize: '12px',
        },
        '.Label': {
            fontSize: '14px',
        },
        '.TermsText': {
            color: theme.palette.grey,
            fontSize: '14px',
        },
        '.Tab': {
            boxShadow: tabBoxShadow,
        },
        '.Tab:hover': {
            boxShadow: tabBoxShadow,
        },
        '.Tab:focus': {
            boxShadow: tabBoxShadow,
        },
        '.Tab--selected': {
            borderColor: theme.palette.blue,
            boxShadow: selectedTabBoxShadow,
        },
        '.Tab--selected:focus': {
            borderColor: theme.palette.blue,
            boxShadow: selectedTabBoxShadow,
        },
        '.Tab--selected:hover': {
            color: theme.palette.blue,
            borderColor: theme.palette.blue,
            boxShadow: selectedTabBoxShadow,
        },
        '.TabLabel': {
            fontFamily: theme.fonts.demiBold,
            color: theme.palette.grey,
            fontSize: '14px',
        },
        '.TabLabel--selected': {
            color: theme.palette.black,
        },
        '.TabIcon--selected': {
            fill: theme.palette.black,
        },
    },
});

export const useStripeAppearance = () => {
    const elements = useElements();
    const theme = useTheme();

    const isMobile = useSelector<RootState, boolean>(isMobileSelector);

    useEffect(() => {
        elements?.update({
            appearance: getAppearance(theme, isMobile),
        });
    }, [elements, isMobile]);
};
