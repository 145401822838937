import React from 'react';
import type { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { ManageEntityLink } from 'src/pages/udb/Subscriptions/components';
import type { GridColDef } from 'src/@types/data-grid';
import { defaultHeaderRenderer } from 'src/components/DataGrid/components/defaultHeaderRenderer';
import { defaultCellRenderer } from 'src/components/DataGrid/components/defaultCellRenderer';
import { SubscriberType, subscriberTypeRenderMapping } from 'src/constants';
import { dateValueFormatter, defaultValueFormatter } from 'src/services/grid-formatters';
import { User } from 'src/@types/unified-db-api';
import { SubscriptionLinkedCell } from 'src/components/DataGrid/components/SubscriptionLinkedCell';
import CopyCellRenderer from 'src/components/DataGrid/components/CopyCell/Renderer';

const COLUMN_HEADERS = {
    fullName: 'Full name',
    email: 'Email address',
    phone: 'Phone number',
    userType: 'User type',
    companyName: 'Company name',
    jobTitle: 'Job title',
    createdAt: 'Account created on',
    manage: 'Manage',
};

export const emailGetter = ({ row }: { row: User }): string => row?.email?.email ?? '';

export const phoneGetter = ({ row }: { row: User }): string => row?.phoneNumber?.phoneNumber ?? '';

export const companyNameGetter = ({ row }: { row: User }): string => row?.jobInfo?.companyName ?? '';

export const jobTitleGetter = ({ row }: { row: User }): string => row?.jobInfo?.jobTitle ?? '';

export const subscriberTypeGetter = ({ row: { type } }: { row: { type?: SubscriberType } }): string =>
    type ? subscriberTypeRenderMapping[type] : '';

export const userTypeLinkRender = (props: GridRenderCellParams) => {
    const { formattedValue, row } = props;

    if (row.subscriptionUUID) {
        return <SubscriptionLinkedCell type={formattedValue} id={row.subscriptionUUID} />;
    }

    return defaultCellRenderer(props);
};

export const ManageLinkRenderer = ({ row }: GridRenderCellParams) => (
    <ManageEntityLink id={row.uuid} type="user" />
);

export const usersDataGridColumns: readonly GridColDef[] = [
    {
        field: 'fullName',
        headerName: COLUMN_HEADERS.fullName,
        minWidth: 265,
        flex: 1,
        sortable: true,
        valueFormatter: defaultValueFormatter,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.fullName),
    },
    {
        field: 'email.email',
        headerName: COLUMN_HEADERS.email,
        minWidth: 265,
        flex: 1,
        sortable: true,
        valueGetter: emailGetter,
        valueFormatter: defaultValueFormatter,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.email),
    },
    {
        field: 'phoneNumber.phoneNumber',
        headerName: COLUMN_HEADERS.phone,
        minWidth: 265,
        sortable: false,
        valueGetter: phoneGetter,
        valueFormatter: defaultValueFormatter,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.phone),
    },
    {
        field: 'type',
        headerName: COLUMN_HEADERS.userType,
        minWidth: 265,
        sortable: false,
        valueFormatter: defaultValueFormatter,
        valueGetter: subscriberTypeGetter,
        renderCell: userTypeLinkRender,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.userType),
    },
    {
        field: 'jobInfo.companyName',
        headerName: COLUMN_HEADERS.companyName,
        minWidth: 265,
        flex: 1,
        sortable: true,
        valueGetter: companyNameGetter,
        valueFormatter: defaultValueFormatter,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.companyName),
    },
    {
        field: 'jobInfo.jobTitle',
        headerName: COLUMN_HEADERS.jobTitle,
        minWidth: 265,
        flex: 1,
        sortable: true,
        valueGetter: jobTitleGetter,
        valueFormatter: defaultValueFormatter,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.jobTitle),
    },
    {
        field: 'createdAt',
        headerName: COLUMN_HEADERS.createdAt,
        minWidth: 265,
        sortable: true,
        valueFormatter: dateValueFormatter,
        type: 'date',
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.createdAt),
    },
    {
        field: 'manage',
        headerName: COLUMN_HEADERS.manage,
        width: 106,
        sortable: false,
        valueFormatter: defaultValueFormatter,
        renderCell: ManageLinkRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.manage),
        sticky: true,
    },
];
