import React, { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from 'react';
import { Box, Stack, StackProps } from '@mui/material';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { CreateSubscriptionInvoiceData } from 'src/pages/udb/CreateSubscription/CreateSubscription';
import { convertDateTimeZone } from 'src/services/date-time-zone-converter';
import { LabeledDateInput } from 'src/components/Inputs/LabeledDateInput';
import { LabeledInput } from 'src/components/Inputs';
import { formatDate } from 'src/services/formatters';
import Tooltip from 'src/components/Tooltip';
import { Checkbox } from 'src/components/Checkbox';
import { StyledCheckboxLabel } from 'src/components/Label/Label.styles';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';

type InvoiceSetupProps = {
    paddingTop?: StackProps['paddingTop'];
    overrideDueDate: boolean;
    setOverrideDueDate: Dispatch<SetStateAction<boolean>>;
    setInvoiceData: Dispatch<SetStateAction<CreateSubscriptionInvoiceData>>;
};

const CreateInvoiceSetupContent: FC<InvoiceSetupProps> = ({
    paddingTop,
    overrideDueDate,
    setOverrideDueDate,
    setInvoiceData,
}) => {
    const { values, setFieldValue } = useFormikContext<Required<CreateSubscriptionInvoiceData>>();
    const [cachedDueDate, setCachedDueDate] = useState<string>('');

    useEffect(() => {
        setInvoiceData(values);
    }, [values]);

    const handleFinalizeInvoiceChange = (checked: boolean) => {
        setFieldValue('isFinalize', checked);
    };

    const handleCreateInvoiceChange = (checked: boolean) => {
        setFieldValue('isCreate', checked);
    };

    const handleOverrideDueDateChange = () => {
        setOverrideDueDate(!overrideDueDate);

        if (overrideDueDate) {
            setCachedDueDate(values.dueDate);
            setFieldValue('dueDate', cachedDueDate);
        } else {
            setCachedDueDate('');
            setFieldValue('dueDate', cachedDueDate);
        }
    };

    return (
        <Stack spacing={2} paddingTop={paddingTop}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={{ xs: 1.5, sm: 2 } }
            >
                <Checkbox
                    checked={!!values?.isCreate}
                    onChange={(event) => handleCreateInvoiceChange(event.target.checked)}
                />
                <StyledCheckboxLabel>
                    Create invoice
                </StyledCheckboxLabel>
            </Stack>
            {values?.isCreate && (
                <Fragment>
                    <Box maxWidth={{ xs: 'unset', sm: '240px' }}>
                        <LabeledInput
                            label="PO number (optional)"
                            name="poNumber"
                            placeholder=""
                            errorPosition="relative"
                            testId="po-number"
                        />
                    </Box>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={{ xs: 1.5, sm: 2 } }
                    >
                        <Checkbox
                            checked={!!values?.isFinalize}
                            onChange={(event) => handleFinalizeInvoiceChange(event.target.checked)}
                        />
                        <StyledCheckboxLabel>
                            Finalize invoice
                        </StyledCheckboxLabel>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={{ xs: 1.5, sm: 2 } }
                    >
                        <Checkbox
                            data-testid="override-due-date"
                            checked={overrideDueDate}
                            onClick={handleOverrideDueDateChange}
                        />
                        <Stack spacing={1} direction="row" alignItems="center">
                            <StyledCheckboxLabel>
                                Override due date
                            </StyledCheckboxLabel>
                            {!overrideDueDate && (
                                <Tooltip
                                    arrow
                                    title={`Due date will be set to ${formatDate(dayjs().add(30, 'days').toString())}`}
                                >
                                    <InfoIcon width={16} height={16} />
                                </Tooltip>
                            )}
                        </Stack>
                    </Stack>
                    {overrideDueDate && (
                        <Box>
                            <LabeledDateInput
                                testId="due-date"
                                label="Due date"
                                name="dueDate"
                                dateTimeOnly
                                withSeconds
                                minDate={convertDateTimeZone(dayjs().add(30, 'minutes'))}
                            />
                        </Box>
                    )}
                </Fragment>
            )}
        </Stack>
    );
};

export default CreateInvoiceSetupContent;
