import styled from 'styled-components';
import { Grid, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from 'src/assets/icons/black-cross-icon.svg';

export const DomainItem = styled(Grid)`
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.palette.borderGrey};
    font-family: ${({ theme }) => theme.fonts.normal};

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-family: ${({ theme }) => theme.fonts.demiBold};
    }
`;

export const DomainName = styled(Typography)`
    &&{
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.demiBold};
`;

export const UserCount = styled(Typography)`
    &&{
        font-size: 14px;
        font-weight: 450;
        font-style: normal;
        line-height: normal;
        font-family: ${({ theme }) => theme.fonts.normal};
        color: ${({ theme }) => theme.palette.lightBlack};
    }
`;

export const RemoveIcon = styled(CloseIcon)`
    && {
        cursor: pointer;

        &:hover path{
            stroke: ${({ theme }) => theme.palette.black};
        }
    }
`;
