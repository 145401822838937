import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import { generalTableCellStyles } from 'src/components/TableSection/TableSection.styles';
import { ReactComponent as UserIcon } from 'src/assets/icons/user-icon.svg';

export const colorVariantSelector = ({ 'data-variant': variant }: { 'data-variant'?: string }) => {
    switch (variant) {
        case 'lightGrey':
            return css`
                color: ${({ theme }) => theme.palette.lightGrey};
            `;
        case 'grey':
            return css`
                color: ${({ theme }) => theme.palette.grey};
            `;
        default:
            return css`
                color: ${({ theme }) => theme.palette.black};
            `;
    }
};

export const StyledContent = styled(Typography)<{ 'data-variant'?: string }>`
    && {
        ${generalTableCellStyles};
        ${colorVariantSelector};
        
        max-width: fit-content;
        display: block;
        white-space: nowrap;
    } 
`;

export const StyledUserIcon = styled(UserIcon)`
    && {
        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            width: 16px;
        }
    }
`;
