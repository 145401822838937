import React, { FC, useEffect, useState } from 'react';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import { CurrencyAdornment } from './styles';
import { formatPrice, preparePriceInCents } from 'src/services/formatters';
import { TextInput } from 'src/components/Inputs/TextInput';
import { numberDividerRegExp } from 'src/services/validators';

type PriceInputProps = {
    name?: string;
    initialPrice?: number | null;
    placeholder?: string;
    currency?: string;
    maxPrice?: number;
    handleChange: (price: number) => void;
    inputProps?: InputBaseComponentProps;
    onBlur?: () => void;
    onFocus?: () => void;
    disabled?: boolean;
    className?: string;
};

const PriceInput: FC<PriceInputProps> = ({
    name,
    maxPrice,
    initialPrice,
    placeholder,
    handleChange,
    currency,
    inputProps,
    onFocus,
    onBlur,
    disabled,
    className,
}) => {
    const initialNumberPrice = typeof initialPrice === 'number'
      && !Number.isNaN(initialPrice) ? initialPrice : 0;

    const [price, setPrice] = useState<string>(
        formatPrice(initialNumberPrice, { showCurrency: false, useGrouping: false }),
    );

    const handlePriceInputBlur = () => {
        onBlur?.();

        if (price === '') {
            return;
        }

        let newPrice: number = preparePriceInCents(price || '0');

        if (maxPrice && newPrice > maxPrice) {
            newPrice = maxPrice;
        }

        setPrice(formatPrice(newPrice, { showCurrency: false, useGrouping: false }));

        handleChange(newPrice);
    };

    useEffect(() => {
        if (initialNumberPrice !== preparePriceInCents(price)) {
            setPrice(formatPrice(initialNumberPrice ?? 0, { showCurrency: false, useGrouping: false }));
        }
    }, [initialNumberPrice]);

    useEffect(() => {
        if (initialNumberPrice && maxPrice && initialNumberPrice >= maxPrice) {
            handleChange(maxPrice);
        }
    }, []);

    const handlePriceInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { data: key } = event.nativeEvent as Event & { data: string };
        const value = event.target.value;

        const hasDot = price.includes('.');
        const hasComma = price.includes(',');
        const hasDotOrComma = hasDot || hasComma;
        const isDotOrComma = ['.', ','].includes(key);
        const isNumber = !Number.isNaN(Number(key));
        const replacedValue = value.replace(/[^0-9.]/g, '');

        if ((hasDotOrComma && isDotOrComma) || (!isNumber && !isDotOrComma && key)) {
            event.target.value = replacedValue;
        } else {
            const newValue = replacedValue
                .replace(numberDividerRegExp, '')
                .replace(',', '.');

            const decimalLength = newValue.split('.')[1]?.length || 0;

            if (decimalLength > 2) {
                return;
            }

            setPrice(newValue);
        }
    };

    return (
        <TextInput
            autoSelect
            name={name}
            value={price}
            disabled={disabled}
            className={className}
            placeholder={placeholder}
            onFocus={onFocus}
            onBlur={handlePriceInputBlur}
            onChange={handlePriceInputChange}
            InputProps={{
                startAdornment: currency && <CurrencyAdornment position="start">{currency}</CurrencyAdornment>,
                inputProps: {
                    'data-testid': 'price-input',
                    autoComplete: 'off',
                    ...inputProps,
                },
            }}
        />
    );
};

export default PriceInput;
