import styled from 'styled-components';
import { Typography } from '@mui/material';

export const ChangeAllCheckboxLabel = styled(Typography)`
  && {
    font-size: 14px;
    line-height: normal;
    font-family: ${({ theme }) => theme.fonts.normal};
    color: ${({ theme }) => theme.palette.grey};
    margin-left: 7px;
    cursor: pointer;

    @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
      font-size: 16px;
    }
  }
`;
