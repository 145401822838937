import styled from 'styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, Typography } from '@mui/material';

export const StyledArrowBackIcon = styled(ArrowBackIcon)`
    && {
        color: ${({ theme }) => theme.palette.lightBlack};
        height: 18px;
        max-height: 18px;
        width: 18px;
        max-width: 18px;
        cursor: pointer;
        position: relative;
        z-index: 100;
        
        &:hover {
            color: ${({ theme }) => theme.palette.black};
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            height: 20px;
            max-height: 20px;
            width: 20px;
            max-width: 20px;
        }
    }
`;

export const StyledHeaderContainer = styled(Grid)`
    z-index: 100;
    height: 20px;
    
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        height: 34px;
    }
    
    & svg {
        height: 14px;

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            height: 17px;
        }
    }
`;

export const StyledHeaderTitle = styled(Typography)`
    && {
        font-family: ${({ theme }) => theme.fonts.bold};
        color: ${({ theme }) => theme.palette.black};
        font-size: 16px;
        
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 18px;
        }
    }
`;
