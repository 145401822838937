import styled from 'styled-components';
import { Stack, Typography } from '@mui/material';

export const StyledChangedNewsletter = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 16px;
        }
    }
`;

export const StyledNewslettersDetails = styled(Typography)`
    && {
        font-family: ${({ theme }) => theme.fonts.bold};
        color: inherit;
        font-size: inherit;
        font-style: inherit;
        font-weight: inherit;
        line-height: inherit;
    }
`;

export const ButtonContainer = styled(Stack)`
    background-color: ${({ theme }) => theme.palette.white};
`;
