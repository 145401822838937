import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { TextButton } from 'src/components/Buttons';

export const FooterWrapper = styled(Stack)`
    height: ${({ theme }) => theme.sizes.footerHeight.md};
    border-top: 1px solid ${({ theme }) => theme.palette.mainBorderColor};
    background-color: ${({ theme }) => theme.palette.mainBackgroundColor};
    box-sizing: border-box;

    @media(max-width: ${({ theme }) => theme.breakpoints.md}) {
        height: ${({ theme }) => theme.sizes.footerHeight.xs};
    }
`;

export const StyledAccessTimeIcon = styled(AccessTimeIcon)`
    && {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        color: ${({ theme }) => theme.palette.lightBlack};
    }
`;

export const TimeText = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 14px;
    }
`;

export const FooterButton = styled(TextButton)`
    &&&.MuiButton-root {
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 14px;
    }
`;
