import React, { FC } from 'react';
import { Stack, StackProps } from '@mui/material';
import { Label } from './CheckboxWithLabel.styles';
import { Checkbox } from 'src/components/Checkbox';

type CheckboxWithLabelProps = {
    checked: boolean;
    onClick: () => void;
    label: string;
    testId?: string;
    disabled?: boolean;
} & StackProps;

const CheckboxWithLabel: FC<CheckboxWithLabelProps> = ({
    checked,
    onClick,
    label,
    testId,
    disabled = false,
    ...stackProps
}) => (
    <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={{ xs: 1, md: 1.5 }}
        {...stackProps}
    >
        <Checkbox
            data-testid={testId}
            checked={checked}
            onClick={onClick}
            disabled={disabled}
        />
        <Label>{label}</Label>
    </Stack>
);

export default CheckboxWithLabel;
