import APIClientError from 'src/classes/api-client-error';

const isNotReadableErrorByCode = (status: number) => status && ![400, 403, 409, 421, 422].includes(status);

export const getResponseError = (error: APIClientError | Error, defaultMessage: string): string => {
    if (!(
        error
        && typeof error === 'object'
        && 'responseError' in error
        && error?.responseError
        && 'data' in error.responseError
    )) {
        return defaultMessage;
    }

    const { data, status } = error.responseError;

    if (isNotReadableErrorByCode(data.status) || isNotReadableErrorByCode(status)) {
        return defaultMessage;
    }

    return ('data' in data && data.data.error)
        || data.error
        || defaultMessage;
};

export const appendResponseError = (baseMessage: string, error: APIClientError | Error): string => {
    const responseError = getResponseError(error, '');

    if (!baseMessage) {
        return responseError;
    }

    return !responseError
        ? baseMessage
        : `${baseMessage.replace(/\s*\.\s*$/, '')}. ${responseError}`;
};
