import React, { ForwardedRef, forwardRef } from 'react';
import type { ButtonProps } from '@mui/material';
import { StyledButton } from './Button.styles';

export type MinSizeVariants = 'small' | 'medium' | 'unset' | 'default';

export type ResponsiveMinSize = {
    xs?: MinSizeVariants;
    sm?: MinSizeVariants;
    md?: MinSizeVariants;
};

type ButtonPropsType = {
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    testId?: string;
    type?: 'button' | 'submit';
    variant?: 'primary' | 'secondary' | 'grey';
    minSize?: MinSizeVariants | ResponsiveMinSize;
    isBold?: boolean;
    target?: '_blank';
} & Pick<ButtonProps, ('fullWidth' | 'startIcon' | 'children' | 'disabled' | 'href' | 'form')>;

const Button = forwardRef(function Button(props: ButtonPropsType, ref: ForwardedRef<HTMLButtonElement>) {
    const {
        testId,
        variant,
        isBold = true,
        minSize = 'default',
        ...restProps
    } = props;

    return (
        <StyledButton
            ref={ref}
            data-isbold={isBold}
            data-min-size={minSize}
            data-testid={testId}
            data-variant={variant}
            disableRipple
            {...restProps}
        />
    );
});

export default Button;
