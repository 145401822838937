import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const generalTableCellStyles = css`
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow: hidden;

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 16px;
        font-weight: 450;
        font-style: normal;
        line-height: normal;
    }
`;

export const NoContentMessage = styled(Typography)<{ textColor?: 'grey' | 'lightBlack' }>`
    && {
        color: ${({ theme, textColor }) =>
        textColor === 'lightBlack' ? theme.palette.lightBlack : theme.palette.grey};
        text-align: center;
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 14px;
        }
    }
`;
