import styled from 'styled-components';
import { Typography } from '@mui/material';

export const RedirectionCaption = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.blue};
        font-family: ${({ theme }) => theme.fonts.medium};
        text-align: center;
        font-style: normal;
        line-height: 140%;
        font-weight: 500;
        font-size: 14px;
        
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 16px;
        }
    }
`;
