import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { CreateNewInvoice, InvoiceModalContent } from './components';
import { StyledStack } from 'src/components/Modals/InvoiceModals/InvoiceModals.styles';
import { createSubscriptionBillingInvoice, finalizeBillingRecordInvoice } from 'src/services/sso-api';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { SnackbarMessageVariants, SubscriptionType } from 'src/constants';
import { BillingRecordModel, BillingRecordWithRenewal } from 'src/@types/subscription-service-api';

export type UpdateInvoiceModalProps = {
    billingRecord: BillingRecordWithRenewal;
    subscriptionType: SubscriptionType;
};

const UpdateInvoiceModal: FC<UpdateInvoiceModalProps> = ({ billingRecord: initialBillingRecord, subscriptionType }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [isPriceChanged, setIsPriceChanged] = useState<boolean>(false);
    const [isFinalizeInvoice, setIsFinalizeInvoice] = useState<boolean>(true);
    const [billingRecord, setBillingRecord] = useState<BillingRecordWithRenewal>(initialBillingRecord);

    const { addMessage, addErrorMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();
    const { closeModal } = useGeneralModal();
    const {
        priceInCents,
        subscriptionUUID,
        id,
    } = billingRecord;

    const updateBillingRecordData = (updateData: Partial<BillingRecordModel>) => {
        setBillingRecord({ ...billingRecord, ...updateData });
        setIsPriceChanged(updateData.priceInCents !== initialBillingRecord.priceInCents);
        setShowError(false);
    };

    const handleFinalizeInvoiceChange = () => {
        setIsFinalizeInvoice((value) => !value);
    };

    const handleFinalizeInvoice = (): Promise<void> => {
        return finalizeBillingRecordInvoice(subscriptionUUID, id)
            .then(() => {
                addMessage('Invoice successfully finalized', SnackbarMessageVariants.SUCCESS);
            })
            .catch((error) => {
                addErrorMessage('Failed to finalize invoice', error);
            });
    };

    const handleSubmitInvoice = () => {
        setShowError(false);
        setIsLoading(true);

        return createSubscriptionBillingInvoice(subscriptionUUID, id, { ...(typeof priceInCents === 'number' && { priceInCents }) })
            .then(async () => {
                addMessage('Invoice successfully updated', SnackbarMessageVariants.SUCCESS);

                if (isFinalizeInvoice) {
                    await handleFinalizeInvoice();
                }

                reloadPage();
                closeModal();
            })
            .catch((error) => {
                addErrorMessage('Failed to update invoice', error);
                setShowError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <StyledStack data-testid="update-invoice-modal">
            {isLoading && <LoaderOverlay />}
            <Stack spacing={{ xs: 1.5, sm: 1.25 }}>
                <InvoiceModalContent subscriptionType={subscriptionType} billingRecord={billingRecord} />
                <Stack>
                    <CreateNewInvoice
                        priceInCents={priceInCents}
                        oldPrice={initialBillingRecord.priceInCents}
                        onCreateInvoice={handleSubmitInvoice}
                        handleSave={updateBillingRecordData}
                        handleFinalizeInvoiceChange={handleFinalizeInvoiceChange}
                        isFinalizeInvoice={isFinalizeInvoice}
                        submitButtonText="Update invoice"
                        isButtonDisabled={!isPriceChanged}
                        showErrorBanner={showError}
                        errorBannerText="Update unsuccessful. Please try again."
                    />
                </Stack>
            </Stack>
        </StyledStack>
    );
};

export default UpdateInvoiceModal;
