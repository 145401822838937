import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import { Stack } from '@mui/material';
import { StyledModalMessage } from 'src/components/Modals/BaseModal/BaseModal.styles';
import StyledTitle from 'src/components/Title';
import { SubscriptionModel } from 'src/@types/subscription-service-api';
import { formatDate } from 'src/services/formatters';
import { useReloadPage } from 'src/hooks/useReloadPage';
import { BaseModal, DEFAULT_MODAL_MAX_WIDTH } from 'src/components/Modals/BaseModal';
import { FormWrapper } from 'src/components/Forms/components';
import { reactivateSubscription } from 'src/services/subscription-service-api';
import { Button } from 'src/components/Buttons';
import { useGeneralModal, useIsSubmitDisabled, useSnackbarMessage } from 'src/hooks';
import { LabeledDateInput } from 'src/components/Inputs/LabeledDateInput/LabeledDateInput';
import { DATE_ISO_8601, DateFormats, SnackbarMessageVariants } from 'src/constants';

export type ReactivateSubscriptionModalProps = {
    subscriptionUUID: string;
    gracePeriodEndDate: string;
};

const ReactivateSubscriptionModal: FC<ReactivateSubscriptionModalProps> = ({
    subscriptionUUID,
    gracePeriodEndDate,
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [endDate, setEndDate] = useState<string>('');
    const [isConfirming, setIsConfirming] = useState<boolean>(false);

    const { addMessage, addErrorMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();
    const { closeModal } = useGeneralModal();

    const minEndDate = dayjs().startOf('day').add(1, 'day');

    const initialValues = {
        gracePeriodEndDate: '',
        toDate: formatDate(endDate, DateFormats.DATE),
    };

    const handleEndDateChange = (newDate?: string) => {
        const currentEndDate = dayjs(gracePeriodEndDate);
        const newEndDate = dayjs(newDate);

        const updatedEndDate = newEndDate
            .set('hour', currentEndDate.hour())
            .set('minute', currentEndDate.minute())
            .set('second', currentEndDate.second());

        const formattedEndDate = updatedEndDate.format(DATE_ISO_8601);

        setEndDate(formattedEndDate);
    };

    const handleSubmit = async () => {
        setIsConfirming(false);
        setIsSubmitting(true);
        return reactivateSubscription(subscriptionUUID, { gracePeriodEndDate: endDate })
            .then(() => {
                addMessage('Subscription successfully reactivated', SnackbarMessageVariants.SUCCESS);
                addMessage('Grace period updated successfully', SnackbarMessageVariants.SUCCESS);
                reloadPage();
                closeModal();
            })
            .catch((error) => {
                addErrorMessage('Failed to reactivate subscription', error);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const { onFormChangeHandler } = useIsSubmitDisabled<Partial<SubscriptionModel>>(initialValues);

    if (isConfirming) {
        return (
            <BaseModal
                open={true}
                handleClose={() => setIsConfirming(false)}
                title="Reactivate subscription"
            >
                <StyledModalMessage>
                    Are you sure you want to reactivate this subscription and extend the grace period until&nbsp;
                    <span>{formatDate(endDate, DateFormats.DATE)}</span>
                </StyledModalMessage>
                <Stack direction="row" spacing={{ xs: 2, sm: 2.5 }} marginTop={3}>
                    <Button
                        minSize="small"
                        data-testid="reactivate-subscription-modal-cancel-button"
                        onClick={() => setIsConfirming(false)}
                        fullWidth
                        variant="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        minSize="small"
                        fullWidth
                        data-testid="reactivate-subscription-modal-submit-button"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    >
                        Confirm
                    </Button>
                </Stack>
            </BaseModal>
        );
    }

    return (
        <FormWrapper
            testId="reactivate-subscription-modal"
            onFormChange={onFormChangeHandler}
            initialValues={initialValues}
            onSubmit={() => setIsConfirming(true)}
            isSubmitting={isSubmitting}
        >
            <Stack maxWidth={DEFAULT_MODAL_MAX_WIDTH}>
                <StyledTitle marginBottom={{ xs: 2.5, md: 3 }} data-font="medium-modal">
                    To reactivate this subscription, a new grace period end date is required.
                </StyledTitle>
                <LabeledDateInput
                    testId="end-date-picker"
                    onChange={handleEndDateChange}
                    name="toDate"
                    label="New end of grace period"
                    placeholder="Select end date"
                    minDate={minEndDate}
                    dateOnly
                    position="fixed"
                />
                <Stack direction="row" spacing={{ xs: 2, sm: 2.5 }} marginTop={3}>
                    <Button minSize="small" onClick={closeModal} fullWidth variant="secondary">Cancel</Button>
                    <Button
                        minSize="small"
                        fullWidth
                        data-testid="reactivate-subscription-modal-button"
                        type="submit"
                        disabled={isSubmitting || !endDate}
                    >
                        Reactivate
                    </Button>
                </Stack>
            </Stack>
        </FormWrapper>
    );
};

export default ReactivateSubscriptionModal;
