import { SettingsData } from 'src/@types/sso-api';
import { ManageActionType } from 'src/components/ManageActions/ManageActions';
import { ManageCredentialsActions, Status } from 'src/constants';
import type { Credentials } from 'src/@types/credentials';
import { getCredentialsSettingsModalProps } from 'src/components/Modals/SettingsModal/CredentialsSettings';

export const getManageCredentialsActionsList = ({
    canEditUser,
    canEditAll,
    settings,
    credentials,
}: {
    canEditUser: boolean;
    credentials: Credentials;
    canEditAll: boolean;
    settings?: SettingsData;
}): Partial<Record<ManageCredentialsActions, ManageActionType>> => {
    if (!canEditUser) {
        return {};
    }

    const is2TfaEnabled = credentials.tfaEnabled;
    const isActive = credentials.status === Status.ACTIVE;

    return {
        ...(canEditAll && { [ManageCredentialsActions.changeRole]: { label: 'Change role' } }),
        [ManageCredentialsActions.disable2FA]: { label: 'Disable 2FA', disabled: !is2TfaEnabled },
        [ManageCredentialsActions.changeAccountStatus]: { label: `${isActive ? 'Disable' : 'Enable'} account` },
        [ManageCredentialsActions.logInLink]: { label: 'Generate login link' },
        [ManageCredentialsActions.passwordLink]: { label: 'Generate password change link' },
        ...(settings && canEditAll && { [ManageCredentialsActions.settings]: {
            label: 'Edit user settings',
            actionExtraProps: getCredentialsSettingsModalProps({ settings, credentials }),
        } }),
    };
};
