import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { StyledSwitchStack } from './Switch.styles';
import { StyledSwitch } from 'src/components/Switch';
import { FormikValuesType } from 'src/@types/forms';

type SwitchAdornmentProps = {
    name: string;
    ariaLabel: string;
    onClick?: (value: boolean) => void;
};

const SwitchAdornment: FC<SwitchAdornmentProps> = ({ name, ariaLabel, onClick }) => {
    const { values, setFieldValue } = useFormikContext<FormikValuesType>();

    const handleSwitchChange = () => {
        setFieldValue(name, !values[name]);
        onClick?.(!values[name]);
    };

    return (
        <StyledSwitchStack direction="row" paddingX={2}>
            <StyledSwitch
                data-testid={`switch-${name}`}
                size="small"
                name={name}
                checked={!!values[name]}
                onClick={handleSwitchChange}
                inputProps={{ 'aria-label': ariaLabel, 'role': 'switch' }}
            />
        </StyledSwitchStack>
    );
};

export default SwitchAdornment;
