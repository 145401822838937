import styled from 'styled-components';
import { Box, Grid } from '@mui/material';
import { ReactComponent as LogoIcon } from 'src/assets/icons/endpoints-news-logo.svg';
import { maxWidthByPageVariant } from 'src/layouts/BasicLayout/BasicLayout.styles';

export const StyledHeaderContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.white};
`;

export const StyledHeader = styled(Grid)`
  height: ${({ theme }) => theme.sizes.headerHeight.md};
  background-color: ${({ theme }) => theme.palette.white};
  
  @media(max-width: ${({ theme }) => theme.breakpoints.md}) {
    height: ${({ theme }) => theme.sizes.headerHeight.xs};
  }

  ${maxWidthByPageVariant(true)}
`;

export const StyledLogo = styled(LogoIcon)`
  width: 179px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  @media(min-width: ${({ theme }) => theme.breakpoints.md}){
    width: 258px;
    position: static;
    transform: none;
  }
`;
