import React, { useState } from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { Label } from 'src/components/Label';
import { isInputValid } from 'src/services/validators';
import { StyledErrorMessage } from 'src/components/Inputs/StyledInput.styles';
import { DatePicker } from 'src/components/DatePicker/DatePicker';

type LabeledDateInputProps = {
    testId?: string;
    name: string;
    label: string;
    placeholder?: string;
    minDate?: dayjs.Dayjs;
    maxDate?: dayjs.Dayjs;
    onChange?: (date?: string) => void;
    dateOnly?: boolean;
    dateTimeOnly?: boolean;
    position?: 'fixed' | 'absolute';
    centered?: 'center' | 'default';
    withSeconds?: boolean;
    shouldDisableDate?: (date: dayjs.Dayjs) => boolean;
};

export const LabeledDateInput: React.FC<LabeledDateInputProps> = ({
    name,
    label,
    minDate,
    maxDate,
    placeholder,
    testId,
    onChange,
    dateOnly,
    position,
    centered,
    shouldDisableDate,
    dateTimeOnly,
    withSeconds,
}) => {
    const [isActive, setIsActive] = useState<boolean>(false);

    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => (
                <Box>
                    <Label htmlFor={name} isFocused={isActive}>{label}</Label>
                    <DatePicker
                        position={position}
                        centered={centered}
                        withSeconds={withSeconds}
                        dateTimeOnly={dateTimeOnly}
                        label={label}
                        validate={() => isInputValid(name)({ field, form })}
                        maxDate={maxDate}
                        minDate={minDate}
                        handleDateChange={(newDate) => {
                            form.setFieldError(name, '');
                            form.setFieldValue(field.name, newDate);
                            onChange?.(newDate);
                            setIsActive(false);
                        }}
                        testId={testId}
                        onOpen={() => setIsActive(true)}
                        onClose={() => setIsActive(false)}
                        dateOnly={dateOnly}
                        placeholder={placeholder}
                        initialValue={field.value}
                        shouldDisableDate={shouldDisableDate}
                    />
                    <ErrorMessage
                        name={name}
                        render={(message) => (
                            message.trim() && (
                                <StyledErrorMessage
                                    dangerouslySetInnerHTML={{ __html: message }}
                                />
                            )
                        )}
                    />
                </Box>
            )}
        </Field>
    );
};
