import React, { FC, Fragment, ReactNode, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTheme } from 'styled-components';
import { StyledSwitch } from 'src/components/Switch';
import Line from 'src/components/Line';
import { SettingsData } from 'src/@types/sso-api';
import { useReloadPage } from 'src/hooks/useReloadPage';
import { Button } from 'src/components/Buttons';
import { TabBarVariants } from 'src/components/TabBar/TabBar';
import { TabBar } from 'src/components/TabBar';
import { SnackbarMessageVariants } from 'src/constants';
import { useGeneralModal, useSnackbarMessage } from 'src/hooks';
import { getTimeZoneType, TimeZoneType } from 'src/services/profile-settings-helpers';
import { LoaderOverlay } from 'src/components/LoaderOverlay';

type SettingsModalProps = {
    settings: SettingsData;
    excludedSettings?: Array<keyof SettingsData>;
    onSave: (settings: Partial<SettingsData>) => Promise<SettingsData>;
};

type SettingsSet = {
    key: keyof SettingsData;
    label: string;
    getComponent: (
        value: SettingsData[keyof SettingsData],
        onChange: (value: SettingsData[keyof SettingsData]) => void
    ) => ReactNode;
};

const settingsSet: Array<SettingsSet> = [{
    key: 'timeZone',
    label: 'Timezone:',
    getComponent: (value, onChange) => (
        <Box paddingTop={{ xs: 0.5, sm: 0 }} width={{ xs: '-webkit-fill-available', sm: 'auto' }}>
            <TabBar
                variant={TabBarVariants.SWITCHER}
                initialValue={getTimeZoneType(value as SettingsData['timeZone'])}
                onChange={(newValue: string | number) => {
                    onChange(newValue === TimeZoneType.UTC ? TimeZoneType.UTC : dayjs.tz.guess());
                }}
                items={[
                    { label: 'UTC', value: TimeZoneType.UTC },
                    { label: 'Local time', value: TimeZoneType.LOCAL_TIME },
                ]}
            />
        </Box>
    ),
}, {
    key: 'disableTFAForcing',
    label: 'Enforce 2FA',
    getComponent: (value, onChange) => (
        <StyledSwitch
            checked={!value}
            data-testid="disable-tfa-forcing-switch"
            onClick={() => onChange(!value || null)}
        />
    ),
}];

const SettingsModal: FC<SettingsModalProps> = ({ settings, onSave, excludedSettings }) => {
    const [selectedSettings, setSelectedSettings] = useState<SettingsData>(settings);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { fonts, palette } = useTheme();
    const { reloadPage } = useReloadPage();
    const { addMessage } = useSnackbarMessage();
    const { closeModal } = useGeneralModal();

    const handleClose = () => {
        closeModal();
    };

    const handleSettingsChange = (key: string) => (value: SettingsData[keyof SettingsData]) => {
        setSelectedSettings((previousState) => ({
            ...previousState,
            [key]: value,
        }));
    };

    const handleSaveSettings = () => {
        setIsSubmitting(true);

        onSave(selectedSettings)
            .then(() => {
                reloadPage();
                closeModal();
                addMessage('Settings updated', SnackbarMessageVariants.SUCCESS);
            })
            .catch(() => {
                addMessage('Failed to update settings', SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <Box data-testid="settings-modal">
            {isSubmitting && <LoaderOverlay />}
            <Stack
                direction="column"
                spacing={{ xs: 2, sm: 3 }}
            >
                {settingsSet.map(({ key, label, getComponent }, index) => {
                    if (excludedSettings?.includes(key)) {
                        return;
                    }

                    return (
                        <Fragment key={key}>
                            {index !== 0 && <Line />}
                            <Stack
                                width="100%"
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
                            >
                                <Typography
                                    fontSize={{ xs: 14, sm: 16 }}
                                    fontFamily={fonts.medium}
                                    color={palette.black}
                                    lineHeight="normal"
                                >
                                    {label}
                                </Typography>
                                {getComponent(selectedSettings[key], handleSettingsChange(key))}
                            </Stack>
                        </Fragment>
                    );
                })}
            </Stack>
            <Stack
                direction="row"
                spacing={{ xs: 2, sm: 2.5 }}
                paddingTop={{ xs: 3, sm: 4.5 }}
            >
                <Button
                    data-testid="settings-modal-cancel"
                    variant="secondary"
                    fullWidth
                    minSize="small"
                    onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                    data-testid="settings-modal-save"
                    variant="primary"
                    fullWidth
                    minSize="small"
                    disabled={!Object.keys(selectedSettings).some((key) => {
                        const settingsKey = key as keyof SettingsData;
                        return settings[settingsKey] !== selectedSettings[settingsKey];
                    })}
                    onClick={handleSaveSettings}
                >
                    Save
                </Button>
            </Stack>
        </Box>
    );
};

export default SettingsModal;
