import styled from 'styled-components';
import { Box, InputAdornment, Typography } from '@mui/material';

export const IconWrapper = styled(Box)`
    border-right: 1px solid ${({ theme }) => theme.palette.dirtyWhite};
    height: ${({ theme }) => theme.sizes.inputHeight.xs};
    line-height: 56px;
    padding: 0 12px;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        line-height: 62px;
        padding: 0 14px;
        height: ${({ theme }) => theme.sizes.inputHeight.default};
    }
`;

export const StyledStartInputAdornment = styled(InputAdornment)`
    &.MuiInputAdornment-root {
        margin-right: 0;
    }

    && + input {
        padding: 0 12px;

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            padding: 0 14px;
        }    
    }
`;

export const TextAdornment = styled(Typography)`
    &&&&.MuiTypography-root{
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.lightGrey};
        line-height: normal;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
    }
`;
