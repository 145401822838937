import styled from 'styled-components';
import { Stack } from '@mui/material';

export const StyledSwitchStack = styled(Stack)`
    height: ${({ theme }) => theme.sizes.inputHeight.default};
    align-items: center;
    border-left: 1px solid ${({ theme }) => theme.palette.dirtyWhite};
    
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        height: ${({ theme }) => theme.sizes.inputHeight.xs};
    }
`;
