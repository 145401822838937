import styled from 'styled-components';
import { Typography } from '@mui/material';
import { Link } from 'src/components/Link';

export const Title = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.grey};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 14px;
    }
`;

export const StyledLink = styled(Link)`
    && {
        @media(max-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 14px;
        }
    }
`;
