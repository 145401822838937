import { FC, useEffect, useState } from 'react';
import type { TypeAndBillingData } from './components/TypeAndBillingStep/TypeAndBillingStep';
import { PricingTermsData } from './components/PricingTermsStep/PricingTermsStep';
import { creationStepController, initialInvoiceData } from './services/creation-step-controller';
import { StripeCustomerInfo } from 'src/@types/sso-api';
import { User } from 'src/@types/unified-db-api';

export enum ActiveStep {
    TYPE_AND_BILLING = 'typeAndBilling',
    PRICING_TERMS = 'pricingTerms',
    SUBSCRIPTION_OWNER = 'subscriptionOwner',
    STRIPE_CUSTOMER = 'stripeCustomer',
    CONFIRMATION = 'confirmation',
}

export type CreateSubscriptionInvoiceData = {
    isCreate: boolean;
    isFinalize: boolean;
    poNumber?: string;
    dueDate?: string;
};

const CreateSubscriptionPage: FC = () => {
    const activeStepState = useState<ActiveStep>(ActiveStep.TYPE_AND_BILLING);

    const typeAndBillingState = useState<Required<TypeAndBillingData>>();
    const pricingTermsState = useState<PricingTermsData>({});
    const ownerState = useState<User>();
    const stripeCustomerState = useState<StripeCustomerInfo | null>(null);
    const invoiceState = useState<CreateSubscriptionInvoiceData>(initialInvoiceData);

    const [typeAndBilling] = typeAndBillingState;
    const [, setPricingTermsState] = pricingTermsState;

    useEffect(() => {
        setPricingTermsState({});
    }, [typeAndBilling?.selectedSubscriptionType]);

    return creationStepController({
        ownerState,
        activeStepState,
        pricingTermsState,
        typeAndBillingState,
        invoiceState,
        stripeCustomerState,
    });
};

export default CreateSubscriptionPage;
