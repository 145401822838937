import React, { FC, ReactNode } from 'react';
import { Stack } from '@mui/material';
import { InfoBoxTitle, InfoBoxValue } from './InfoBox.styles';
import StyledTooltip from 'src/components/Tooltip';
import { useOverflowTooltip } from 'src/hooks/useOverflowTooltip';

export type InfoBoxItemProps = {
    label: ReactNode;
    value: ReactNode;
    tooltipTitle?: string;
};

const InfoBoxItem: FC<InfoBoxItemProps> = ({ label, value, tooltipTitle = '' }) => {
    const { ref, tooltipTitle: title } = useOverflowTooltip(tooltipTitle);

    return (
        <Stack direction="row" justifyContent="space-between" position="relative" spacing={1}>
            <InfoBoxTitle variant="caption">{label}</InfoBoxTitle>
            <StyledTooltip title={title} arrow>
                <InfoBoxValue ref={ref} variant="caption" data-has-tooltip={!!tooltipTitle} textAlign="right">{value}</InfoBoxValue>
            </StyledTooltip>
        </Stack>
    );
};

export default InfoBoxItem;
