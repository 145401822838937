import React, { FormEvent } from 'react';
import { Formik, FormikConfig, FormikHelpers, type FormikValues } from 'formik';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { StyledFormWrapper } from 'src/components/Forms/components/FormWrapper/FormWrapper.styles';

type FormProps<Values extends FormikValues> = {
    testId?: string;
    onFormChange?: (event: FormEvent<HTMLFormElement>) => void;
    isSubmitting?: boolean;
    id?: string;
} & FormikConfig<Values>;

export const FormWrapper = <Values extends FormikValues = FormikValues>({
    isSubmitting,
    initialValues,
    onSubmit,
    validationSchema,
    children,
    onFormChange,
    id,
    testId = id,
    ...props
}: FormProps<Values>) => {
    const trimValues = (formValues: Values) => (
        Object.entries(formValues).reduce<Values>((trimmedValues, [key, value]) => ({
            ...trimmedValues,
            [key]: typeof value === 'string' ? value.trim() : value,
        }), {} as Values)
    );

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async (data: Values, formikHelpers: FormikHelpers<Values>) => {
                const trimmedValues = trimValues(data);
                await formikHelpers.setValues(trimmedValues);
                onSubmit(trimmedValues, formikHelpers);
            }}
            validationSchema={validationSchema}
            validateOnChange={false}
            {...props}
        >
            <StyledFormWrapper
                onChange={onFormChange}
                data-testid={testId}
                id={id}
            >
                <>
                    {children}
                    {isSubmitting && <LoaderOverlay />}
                </>
            </StyledFormWrapper>
        </Formik>
    );
};
