import styled from 'styled-components';
import { Typography } from '@mui/material';

export const SignUpSuccessSubTitle = styled(Typography)`
    &&& {
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.lightBlack};
        font-size: 14px;
        line-height: 140%;
        
        a {
            line-height: 0.9;
        }

        @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
            font-size: 16px;
        }
    }
`;
