import styled from 'styled-components';
import { Box, Button, Paper, PaperProps } from '@mui/material';
import Grid from '@mui/material/Grid';
import { TextButton } from 'src/components/Buttons';

export const tollBarWidthSelector = (props: PaperProps & { 'data-has-condition'?: boolean }) => {
    if (props['data-has-condition']) {
        return 'width: 656px;';
    }
    return 'width: 498px;';
};

export const StyledConditionsIndicator = styled.p`
  margin: 0;
  position: absolute;
  display: block;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  font-size: 15px;
  line-height: 20px;
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.bold};
  background-color: ${({ theme }) => theme.palette.red};
    
  @media(max-width: ${({ theme }) => theme.breakpoints.sm}){
      width: 18px;
      height: 18px;   
  }
`;

export const ToolBarButton = styled.button`
  position: relative;
  border: none;
  width: 130px;
  height: 47px;
  font-size: 18px;
  line-height: 46px;
  background-color: ${({ theme }) => theme.palette.mainBackgroundColor};
  font-family: ${({ theme }) => theme.fonts.demiBold};
  color: ${({ theme }) => theme.palette.lightBlack};
  margin-left: 12px;
  cursor: pointer;

  &.active{
    background-color: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.black};
  }
  
  &:hover{
    background-color: ${({ theme }) => theme.palette.white};
  }

  svg {
    color: ${({ theme }) => theme.palette.lightBlack};
    margin: 0 9px -6px 0;
  }
    
  @media(max-width: ${({ theme }) => theme.breakpoints.sm}){
    margin-left: 8px;
    padding: 9px;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    line-height: unset;
    border: 1px solid ${({ theme }) => theme.palette.mainBorderColor};
    svg {
      margin: 0;
      width: 20px;
      height: 20px;
      }
      
    &.active{
          background-color: unset;
      }
    }
`;

export const StyledToolBarItem = styled(Paper)<{ 'data-has-condition': boolean }>`
    && {
        box-shadow: 0 15px 40px 0 rgba(136, 149, 172, 0.15);
        position: absolute;
        right: 0;
        top: 60px;
        padding-bottom: 24px;
        z-index: 1000;
        background-color: ${({ theme }) => theme.palette.white};
        ${tollBarWidthSelector};

        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            && {
                border-radius: 8px 8px 0 0;
                position: fixed;
                bottom: 0;
                right: 0;
                left: 0;
                top: unset;
                width: 100%;
                max-height: 100%;
                overflow-y: auto;
                padding-bottom: 7px;

                && .MuiPopover-paper {
                    box-shadow: unset;
                    max-width: none;
                    position: fixed;
                    top: auto !important;
                    bottom: 0;
                    left: 0 !important;
                    right: 0;
                    width: 100%;
                    border-radius: 8px 8px 0 0;
                    background-color: ${({ theme }) => theme.palette.white};
                }

                && .MuiMenu-list {
                    padding-bottom: 14px;
                }
            }
        }
    }
`;

export const ToolBarItemTitle = styled.p`
  line-height: 18px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.palette.lightBlack};
  padding: 0 24px;
  margin: 24px 0;
    
  @media(max-width: ${({ theme }) => theme.breakpoints.sm}){
      padding: 0;
      margin: 7px 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
    }
`;

export const TabsContainer = styled(Box)`
    max-width: 450px;
    background-color: ${({ theme }) => theme.palette.mainBackgroundColor};

    & .MuiTabs-root {
        min-height: 42px;
            
        & .MuiTabs-scroller {
            & .MuiTabs-flexContainer {
                justify-content: space-between;

                & .MuiTab-root {
                    min-height: 42px;
                    min-width: 220px;
                    font-size: 16px;
                }
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        max-width: unset;
        border-radius: 4px;
        & .MuiTabs-root {
            height: 32px;
            min-height: unset;

            & .MuiTabs-scroller {
                & .MuiTabs-flexContainer {
                    & .MuiTab-root {
                        padding: 7px 16px;
                        min-height: unset;
                        font-size: 14px;
                        width: 50%;
                        min-width: unset;
                    }
                }
            }
        }
    }
`;

export const AddButton = styled(TextButton)`
    &&&& {
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 18px;
        cursor: pointer;
        padding-right: 5px;

        svg {
            margin-top: -2px;
            margin-right: 8px;
        }

        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 14px;
        }
    }
`;

export const StyledToolBarError = styled(Box)`
  padding: 16px;
  color: ${({ theme }) => theme.palette.red};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 15px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.lightRed};
  svg {
    margin-right: 10px;
  }
`;

export const StyledToolbarMobileHeader = styled(Grid)`
    color: ${({ theme }) => theme.palette.black};
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const StyledSelectRemove = styled(Button)`
 && {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.snackbar.errorFont};
    font-family: ${({ theme }) => theme.fonts.medium};
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-transform: unset;
     
     &:hover{
         background-color: unset;
     }
 }
`;
