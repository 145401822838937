import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledCardInfo = styled(Typography)`
    &&&.MuiTypography-root {
        line-height: normal;
        font-size: 16px;

        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 14px;
        }
    }
`;

export const StyledSecondaryInfo = styled(Typography)`
    &&&.MuiTypography-root {
        font-size: 14px;
        color: ${({ theme }) => theme.palette.grey};

        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 12px;
        }
    }
`;
