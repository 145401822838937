import type { Dispatch } from 'redux';
import { getScreenSizeByWidth } from './screen-breakpoints';
import { setPageSize } from 'src/redux/slices';

let lastDispatchedScreenSize: string | null = null;

export const initResizeListener = (dispatch: Dispatch) => {
    window.addEventListener('resize', (event: Event) => {
        const target = event.target as Window;
        const currentScreenSize = getScreenSizeByWidth(target.innerWidth);

        if (lastDispatchedScreenSize === currentScreenSize) {
            return;
        }

        lastDispatchedScreenSize = currentScreenSize;
        dispatch(setPageSize(currentScreenSize));
    });

    window.dispatchEvent(new Event('resize'));
};
