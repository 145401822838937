import React, { FC, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { StripeAddress } from 'src/components/Stripe';
import { useStripeAppearance } from 'src/hooks/useStripeAppearance';
import { StripeAddress as StripeAddressType } from 'src/@types/sso-api';
import { LabeledInput } from 'src/components/Inputs';
import { emailValidator, validateSchemaObject } from 'src/services/validators';
import { Button } from 'src/components/Buttons';
import { useIsSubmitDisabled } from 'src/hooks';
import { FormWrapper } from 'src/components/Forms/components';

export type CreateStripeCustomerFormProps = {
    name?: string;
    email?: string;
    onSubmit: (data: CreateStripeCustomerFormData) => Promise<void>;
};

type CreateStripeCustomerFormData = {
    email: string;
    name: string;
    address: StripeAddressType | null;
};

const CreateStripeCustomerFormContent: FC<CreateStripeCustomerFormProps> = ({ name = '', email = '', onSubmit }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(true);

    useStripeAppearance();

    const {
        onFormChangeHandler,
        isSubmitDisabled,
    } = useIsSubmitDisabled<CreateStripeCustomerFormData>({ email, name, address: null }, ['email'], true);

    const handleSubmit = async (values: CreateStripeCustomerFormData) => {
        setIsSubmitting(true);
        onSubmit(values).then(() => {
            setIsSubmitting(false);
        });
    };

    return (
        <FormWrapper
            onFormChange={onFormChangeHandler}
            onSubmit={handleSubmit}
            initialValues={{ email, name, address: null }}
            validationSchema={validateSchemaObject({
                email: emailValidator.required(),
            })}
        >
            {(isSubmitting || isLoading) && <LoaderOverlay fullOverlay />}
            <Stack data-testid="create-stripe-customer-form">
                <Grid container marginBottom={{ xs: 3, sm: 6 }}>
                    <Grid item xs={12}>
                        <LabeledInput
                            inputProps={{ inputProps: { style: { height: '50px' } } }}
                            disallowSpace
                            disabled={isSubmitting}
                            name="email"
                            label="Email"
                            placeholder="Enter Stripe customer email"
                        />
                    </Grid>
                    <Grid item xs={12} paddingTop={{ xs: 2.5, sm: 4 }}>
                        <Field>
                            {({ form }: FieldProps) => (
                                <StripeAddress
                                    name={name}
                                    onChange={({ complete, value }) => {
                                        form.setValues({
                                            ...form.values,
                                            name: value.name,
                                            address: value.address,
                                        }).then(() => {
                                            setIsComplete(complete);
                                        });
                                    }}
                                    onReady={() => {
                                        setLoading(false);
                                    }}
                                />
                            )}
                        </Field>
                    </Grid>
                </Grid>
                <Button
                    fullWidth
                    data-testid="customer-submit-button"
                    disabled={isSubmitDisabled || isSubmitting || !isComplete}
                    type="submit"
                >
                    Create customer
                </Button>
            </Stack>
        </FormWrapper>
    );
};

export default CreateStripeCustomerFormContent;
