import React, { FC } from 'react';
import { Box } from '@mui/material';
import { ConfirmMessage } from 'src/components/Modals/AddDomainModal/AddDomainModal.styles';
import { formatDate } from 'src/services/formatters';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import { updateSubscription } from 'src/services/subscription-service-api';
import { BaseModal } from 'src/components/Modals/BaseModal';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { SnackbarMessageVariants } from 'src/constants';
import { InfoBanner } from 'src/components/InfoBanner';

export type CancelScheduledActivationProps = {
    subscriptionUUID: string;
    activationDate: string;
};

const CancelScheduledActivationModal: FC<CancelScheduledActivationProps> = ({
    subscriptionUUID,
    activationDate,
}) => {
    const { closeModal } = useGeneralModal();
    const { addMessage, addErrorMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();

    const clearActivationDate = () => {
        updateSubscription(subscriptionUUID, {
            activationDate: null,
        })
            .then(() => {
                addMessage('Scheduled activation has been successfully canceled', SnackbarMessageVariants.SUCCESS);
                closeModal();
                reloadPage();
            })
            .catch((error) => {
                addErrorMessage('Failed to cancel scheduled activation', error);
            });
    };

    return (
        <BaseModal
            open
            dataTestId="cancel-scheduled-activation-modal"
            handleClose={closeModal}
            title="Cancel scheduled activation"
        >
            <ConfirmationModal
                modalId="Cancel-scheduled-activation-modal"
                onConfirm={clearActivationDate}
                onCancel={closeModal}
                subtitle={(
                    <Box>
                        <ConfirmMessage>
                            This subscription is scheduled to be activated on <b>{formatDate(activationDate)}</b>.
                            Are you sure you’d like to cancel its activation?
                        </ConfirmMessage>
                        <InfoBanner hideIcon marginTop={3}>
                            It may be activated manually later or it will be activated
                            automatically upon payment or signing of contract.
                        </InfoBanner>
                    </Box>
                )}
            />
        </BaseModal>
    );
};

export default CancelScheduledActivationModal;
