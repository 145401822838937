import React, { FC, ReactNode } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import type { Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import { theme } from 'src/theme';
import { makeURL } from 'src/services/url-maker';
import TTNormsProMediumWoff2 from 'src/assets/fonts/TT_Norms_Pro_Medium.woff2';
import TTNormsProMediumWoff from 'src/assets/fonts/TT_Norms_Pro_Medium.woff';
import TTNormsProMediumOtf from 'src/assets/fonts/TT_Norms_Pro_Medium.otf';
import TTNormsProMediumTtf from 'src/assets/fonts/TT_Norms_Pro_Medium.ttf';
import TTNormsProNormalWoff2 from 'src/assets/fonts/TT_Norms_Pro_Normal.woff2';
import TTNormsProNormalWoff from 'src/assets/fonts/TT_Norms_Pro_Normal.woff';
import TTNormsProNormalOtf from 'src/assets/fonts/TT_Norms_Pro_Normal.otf';
import TTNormsProNormalTtf from 'src/assets/fonts/TT_Norms_Pro_Normal.ttf';
import TTNormsProDemiBoldWoff2 from 'src/assets/fonts/TT_Norms_Pro_DemiBold.woff2';
import TTNormsProDemiBoldWoff from 'src/assets/fonts/TT_Norms_Pro_DemiBold.woff';
import TTNormsProDemiBoldOtf from 'src/assets/fonts/TT_Norms_Pro_DemiBold.otf';
import TTNormsProDemiBoldTtf from 'src/assets/fonts/TT_Norms_Pro_DemiBold.ttf';

type StripeElementsProps = {
    children: ReactNode;
    stripePromise: Promise<Stripe | null>;
    options?: StripeElementsOptions;
};

const StripeElements: FC<StripeElementsProps> = ({ children, stripePromise, options }) => {

    return (
        <Elements
            stripe={stripePromise}
            options={{
                fonts: [{
                    family: theme.fonts.medium,
                    src: `url('${makeURL(TTNormsProMediumWoff2).toString()}') format('woff2'), url('${makeURL(TTNormsProMediumWoff).toString()}') format('woff'), url('${makeURL(TTNormsProMediumOtf).toString()}') format("opentype"), url('${makeURL(TTNormsProMediumTtf).toString()}') format("truetype")`,
                },
                {
                    family: theme.fonts.normal,
                    src: `url('${makeURL(TTNormsProNormalWoff2).toString()}') format('woff2'), url('${makeURL(TTNormsProNormalWoff).toString()}') format('woff'), url('${makeURL(TTNormsProNormalOtf).toString()}') format("opentype"), url('${makeURL(TTNormsProNormalTtf).toString()}') format("truetype")`,
                },
                {
                    family: theme.fonts.demiBold,
                    src: `url('${makeURL(TTNormsProDemiBoldWoff2).toString()}') format('woff2'), url('${makeURL(TTNormsProDemiBoldWoff).toString()}') format('woff'), url('${makeURL(TTNormsProDemiBoldOtf).toString()}') format("opentype"), url('${makeURL(TTNormsProDemiBoldTtf).toString()}') format("truetype")`,
                }],
                ...options,
            }}
        >
            {children}
        </Elements>
    );
};

export default StripeElements;
