import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledDomain = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.medium};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
      
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 14px;
    }
  }
`;

export const StyledSocialContainer = styled.a`
  color: ${({ theme }) => theme.palette.snackbar.infoFont};
  font-family: ${({ theme }) => theme.fonts.medium};
  line-height: normal;
  display: flex;
  text-decoration: none;
  align-items: center;
  height: 40px;
  gap: 8px;
  max-width: 100%;
    
  svg{
      width: 16px;
  }  
    
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 24px;
    font-size: 14px  
  }
`;
