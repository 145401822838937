import React, { FC, useState } from 'react';
import { Box, Stack } from '@mui/material';
import TipsList from './TipsList';
import { TabsContainer } from 'src/components/TabBar/TabBar.styles';
import { CopyInput } from 'src/components/Inputs';
import { TabBar } from 'src/components/TabBar';

export type QRCodeModalProps = {
    tfaSecret: string;
};

export enum QRType {
    SCANNING_TIPS = 'scanningTips',
    CAMERA_ISSUES = 'cameraIssues',
}

const tipContents = {
    [QRType.SCANNING_TIPS]: [
        'Use brighter lighting',
        'Avoid shaking the phone while scanning',
        'Don’t tilt your phone while scanning',
        'Don’t hold the phone too close or too far away',
        'Make sure your shadow is not falling on the QR code',
        'Make sure your camera lens is clean',
    ],
    [QRType.CAMERA_ISSUES]: [
        '1. Open the authenticator app on your phone',
        '2. Add a new account',
        '3. Choose the option to manually enter a setup key',
        '4. Copy the setup key below and paste it there',
    ],
};
const QRCodeModal: FC<QRCodeModalProps> = ({ tfaSecret }) => {
    const [selectedTab, setSelectedTab] = useState<QRType>(QRType.SCANNING_TIPS);

    const handleTabChange = (newValue: string | number) => {
        setSelectedTab(newValue as QRType);
    };

    return (
        <Stack>
            <TabsContainer padding={0.5} marginBottom={{ xs: 2.5, sm: 3 }} data-testid="tabs-container">
                <TabBar
                    onChange={handleTabChange}
                    initialValue={selectedTab}
                    items={[
                        { label: 'Scanning tips', value: QRType.SCANNING_TIPS },
                        { label: 'Camera issues', value: QRType.CAMERA_ISSUES },
                    ]}
                    data-testid="tab-bar"
                />
            </TabsContainer>
            <Stack data-testid={`${selectedTab}-list`}>
                <TipsList
                    tips={tipContents[selectedTab]}
                    titleTestId={`${selectedTab}-title`}
                    listTestId={`${selectedTab}-tip`}
                />
                {selectedTab === QRType.CAMERA_ISSUES && (
                    <Box marginTop={2.5}>
                        <CopyInput
                            testId="setup-key-input"
                            name="copy"
                            label="Setup key"
                            value={tfaSecret}
                        />
                    </Box>
                )}
            </Stack>
        </Stack>
    );
};

export default QRCodeModal;
