import styled from 'styled-components';
import { Typography } from '@mui/material';
import { TextButton } from 'src/components/Buttons';

export const StyledEnableTFAModalStep = styled(Typography)`
    && {
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.lightBlack};
        font-size: 14px;

        & ${TextButton} {
            vertical-align: inherit;
        }
        
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 16px;
        }
    }
`;
