import React, { FC, FunctionComponent, ReactNode } from 'react';
import { BoxProps, Stack } from '@mui/material';
import { InfoBannerContainer } from './InfoBanner.styles';
import { ReactComponent as WarningIcon } from 'src/assets/icons/blue-warn-icon.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/icons/icon-attention.svg';
import { SnackbarMessageVariants } from 'src/constants';

type InfoBannerProps = {
    children: ReactNode;
    type?: SnackbarMessageVariants;
    hideIcon?: boolean;
} & BoxProps;

const iconMap: Partial<Record<SnackbarMessageVariants, FunctionComponent<React.SVGProps<SVGSVGElement>>>> = {
    [SnackbarMessageVariants.INFO]: WarningIcon,
    [SnackbarMessageVariants.ERROR]: ErrorIcon,
};

const InfoBanner: FC<InfoBannerProps> = ({
    children,
    type = SnackbarMessageVariants.INFO,
    hideIcon = false,
    ...boxProps
}) => {
    const Icon = iconMap[type];

    return (
        <InfoBannerContainer
            padding={{ xs: 1.5, sm: 2 }}
            gap={{ xs: 1, sm: 1.25 }}
            data-type={type}
            {...boxProps}
        >
            {!hideIcon && Icon && (
                <Stack>
                    <Icon />
                </Stack>
            )}
            {children}
        </InfoBannerContainer>
    );
};

export default InfoBanner;
