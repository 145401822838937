import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledLabel = styled(Typography)`
    && {
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.black};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 12px;

        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 14px;
        }
    }
`;
