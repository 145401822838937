import React, { FC, Fragment } from 'react';
import { Grid, GridProps } from '@mui/material';
import { SectionItem } from 'src/components/SectionItem';
import { TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { SubscriptionUsage } from 'src/@types/sso-api';
import { CalculatedRenewalItem } from 'src/@types/subscription-service-api';
import { formatPrice } from 'src/services/formatters';
import Line from 'src/components/Line';
import { Section } from 'src/components/Section';

export type GridColumnProps = Record<keyof CalculatedRenewalItem, GridProps>;

const gridColumnParams: GridColumnProps = {
    totalUsers: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap-reverse',
        gap: { xs: 0, md: 1 },
    },
    activeUsers: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0, md: 1 },
    },
    contentViews: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0, md: 1 },
    },
    renewalIndex: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0, md: 1 },
    },
    calculatedPriceInCents: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0, md: 1 },
    },
    currentPriceInCents: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0, md: 1 },
    },
};

enum HeaderTitles {
    totalUsers = 'Total users',
    activeUsers = 'Active users (90 days)',
    contentViews = 'Content views (90 days)',
    renewalIndex = 'Renewal index',
    calculatedPriceInCents = 'Renewal index price',
    currentPriceInCents = 'Current price',
}

type UsageSectionProps = {
    usage: SubscriptionUsage & Partial<CalculatedRenewalItem>;
    variant: 'profile' | 'udb';
};

const gridColumnKeys = Object.keys(gridColumnParams) as Array<keyof GridColumnProps>;

const UsageSection: FC<UsageSectionProps> = ({ usage, variant }) => {
    const formattedUsage = {
        ...usage,
        currentPriceInCents: formatPrice(usage.currentPriceInCents),
        calculatedPriceInCents: formatPrice(usage.calculatedPriceInCents),
        renewalIndex: usage.renewalIndex ?? 'N/A',
    };

    const profileKeys: Array<keyof GridColumnProps> = ['totalUsers', 'activeUsers', 'contentViews'];
    const keysToDisplay = variant === 'profile' ? profileKeys : gridColumnKeys;

    return (
        <Section data-testid="usage-section" sectionPaddingBottom={3} header="Usage">
            <Grid container columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                {keysToDisplay.map((key, index) => (
                    <Fragment key={key}>
                        {index !== 0 && (
                            <Grid
                                item
                                display={{ xs: 'block', md: index === 3 ? 'block' : 'none' }}
                                paddingY={2}
                                xs={12}
                            >
                                <Line />
                            </Grid>
                        )}
                        <SectionItem
                            gridProps={gridColumnParams[key]}
                            value={formattedUsage[key]}
                            title={HeaderTitles[key]}
                        />
                    </Fragment>
                ))}
            </Grid>
        </Section>
    );
};

export default UsageSection;
