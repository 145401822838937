import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StripeCustomerTitle = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.grey};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
