import styled, { css } from 'styled-components';
import { Stack, Typography } from '@mui/material';
import { TextButton } from 'src/components/Buttons';

const colorSelector = (props: { 'data-color-index'?: number | null }) => {
    if (typeof props['data-color-index'] !== 'number') {
        return css`
            color: ${({ theme }) => theme.palette.black};
        `;
    }

    if (props['data-color-index'] < 0) {
        return css`
            color: ${({ theme }) => theme.palette.green};
        `;
    }

    if (props['data-color-index'] > 0) {
        return css`
            color: ${({ theme }) => theme.palette.red};
        `;
    }

    return css`
        color: ${({ theme }) => theme.palette.grey};
    `;
};

export const StyledMessage = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 14px;
        font-style: inherit;
        font-weight: inherit;
        line-height: 140%;

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 16px;
        }
    }
`;

export const StyledRenewalDetail = styled(Typography)`
    && {
        font-family: inherit;
        font-size: inherit;
        font-style: inherit;
        font-weight: inherit;
        line-height: inherit;
        
        ${colorSelector}

        svg {
            margin-left: 4px;
        }
    }
`;

export const StyledOldPrice = styled(Typography)`
    && {
        font-size: 12px;
        text-decoration: line-through;
        color: ${({ theme }) => theme.palette.grey};
        font-family: ${({ theme }) => theme.fonts.medium};

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 14px;
        }
    }
`;

export const StyledTextButton = styled(TextButton)`
    &&& {
        font-size: 12px;
        font-family: ${({ theme }) => theme.fonts.medium};

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 14px;
        }
    }
`;

export const StyledButtonTooltipText = styled.p`
    text-align: center;
    margin: 0;
`;

export const StyledStack = styled(Stack)`
    && {
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 16px;
        }
    }
`;
