import React from 'react';
import { gridColumnParams } from './schemas';
import { getAddressString } from 'src/services/address';
import { GeneralModalKey, SubscriptionStatus, UdbManageSubscriptionActions } from 'src/constants';
import { CustomerInfo } from 'src/@types/sso-api';
import { useLookupNamesHook } from 'src/hooks';
import { getCountriesList } from 'src/services/user-profile-api';
import { Link } from 'src/components/Link';

export const getBillingAddressFields = (response: CustomerInfo) => {
    const { customer } = response;
    const country = useLookupNamesHook(getCountriesList, response.customer?.address?.country);
    const address = getAddressString(customer?.address);

    return [
        {
            title: gridColumnParams.fullName.column_title,
            value: customer?.name,
            gridProps: gridColumnParams.fullName,
        },
        {
            title: gridColumnParams.country.column_title,
            value: country,
            gridProps: gridColumnParams.country,
        },
        {
            title: gridColumnParams.address.column_title,
            value: address,
            gridProps: gridColumnParams.address,
        },
    ];
};

export const getBillingAddressUrl = (uuid: string, isUdb?: boolean) =>
    `${isUdb ? '/udb/subscriptions' : '/subscriptions'}/${uuid}#${GeneralModalKey.billingAddressModal}`;

export const getBillingAddressMessage = (isUdb?: boolean) =>
    isUdb
        ? 'now so you can start creating invoices.'
        : 'to ensure smooth renewal of your subscription.';

export const getNoContentMessage = (
    status: SubscriptionStatus,
    uuid: string,
    isUdb?: boolean,
    customerInfo?: CustomerInfo,
) => {
    const isEditable = [SubscriptionStatus.ACTIVE, SubscriptionStatus.DRAFT, SubscriptionStatus.PAUSED]
        .includes(status);

    if (isEditable && isUdb && !customerInfo?.customer) {
        return (
            <>
                No customer assigned to this subscription. Please&nbsp;
                <Link href={`/udb/subscriptions/${uuid}#${UdbManageSubscriptionActions.assignStripeCustomer}`}>create or assign</Link>
                &nbsp;a customer with a billing address.
            </>
        );
    }

    if (isEditable) {
        return (
            <>
                No billing address added.&nbsp;
                <Link href={getBillingAddressUrl(uuid, isUdb)}>Add billing address</Link>
                &nbsp;{getBillingAddressMessage(isUdb)}
            </>
        );
    }

    return <>No billing address added.</>;
};
