import styled from 'styled-components';
import { Stack, Typography } from '@mui/material';

export const StyledIndex = styled(Typography)`
    && {
        width: 20px;
        height: 20px;
        min-width: 20px;
        line-height: 20px;
        border-radius: 10px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: inherit;

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            width: 24px;
            height: 24px;
            min-width: 24px;
            line-height: 24px;
            border-radius: 12px;
            font-size: 13px;
        }
    }
`;

export const StyledLabel = styled(Typography)`
    && {
        font-size: 14px;
        min-width: max-content;
        font-family: inherit;
        
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 16px;
        }
    }
`;

export const StyledStepContainer = styled(Stack)<{ 'data-is-active': boolean }>`
    font-family: ${({ theme }) => theme.fonts.demiBold};
    
    & ${StyledIndex}{
        background-color: ${({ theme, 'data-is-active': isActive }) => isActive ? theme.palette.blue : theme.palette.dirtyWhite};
        color: ${({ theme, 'data-is-active': isActive }) => isActive ? theme.palette.white : theme.palette.lightGrey};
    }
    
    & ${StyledLabel}{
        color: ${({ theme, 'data-is-active': isActive }) => isActive ? theme.palette.lightBlack : theme.palette.lightGrey};
    }
`;
