import React, { FC, ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { Button } from 'src/components/Buttons';
import { BasicLayout, PageVariant } from 'src/layouts';
import PageTitle from 'src/components/PageTitle';

export type StepContainerProps = {
    title: string;
    handleGoBack?: () => void;
    handleNextButtonClick?: () => void;
    showNextButton?: boolean;
    showGoBackButton?: boolean;
    nextButtonTitle?: string;
    children: ReactNode[] | ReactNode;
    testId?: string;
    isLoading?: boolean;
};

const StepContainer: FC<StepContainerProps> = ({
    title,
    handleGoBack,
    handleNextButtonClick,
    children,
    showNextButton,
    showGoBackButton,
    nextButtonTitle = 'Next',
    testId,
    isLoading,
}) => {
    const hasGoBackButton = showGoBackButton && handleGoBack;

    return (
        <BasicLayout pageVariant={PageVariant.FORM} testId={testId}>
            {isLoading && (<LoaderOverlay fullOverlay />)}
            <PageTitle
                handleGoBack={handleGoBack}
                title={title}
            />
            <Stack spacing={2} paddingTop={{ xs: 2, sm: 3 }}>
                {children}
            </Stack>
            {(hasGoBackButton || showNextButton) && (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={hasGoBackButton ? 'space-between' : 'flex-end'}
                    paddingTop={{ xs: hasGoBackButton ? 3 : 6, sm: 4 }}
                    spacing={4}
                >
                    {hasGoBackButton && (
                        <Box width={{ xs: '150px', sm: '300px' }}>
                            <Button
                                fullWidth
                                minSize="unset"
                                variant="grey"
                                onClick={handleGoBack}
                            >
                                Go back
                            </Button>
                        </Box>
                    )}
                    {showNextButton && (
                        <Box width={{ xs: '150px', sm: '300px' }}>
                            <Button
                                fullWidth
                                form="create-subscription-form"
                                minSize="unset"
                                type="submit"
                                onClick={handleNextButtonClick}
                            >
                                {nextButtonTitle}
                            </Button>
                        </Box>
                    )}
                </Stack>
            )}
        </BasicLayout>
    );
};

export default StepContainer;
