import { Box } from '@mui/material';
import styled from 'styled-components';

export const ButtonWrapper = styled(Box)`
    && {
        max-width: 96px;
        margin-top: 0;

        @media(max-width: ${({ theme }) => theme.breakpoints.sm}) {
            max-width: 100%;
        }
    }
`;
