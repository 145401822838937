import { Box, Stack } from '@mui/material';
import styled, { css } from 'styled-components';

const inputIconPaddingStyles = (props: { 'data-is-button-visible'?: boolean }) => {
    return props['data-is-button-visible'] ? css`
      .MuiSvgIcon-root {
        padding-right: 0;
      }
    ` : '';
};

export const RevertButtonWrapper = styled(Box)`
    &&& {
        border-left: 1px solid ${({ theme }) => theme.palette.dirtyWhite};
        height: 50px;
        box-sizing: border-box;
        padding: 0 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            height: 44px;
        }
    }
`;

export const InputWithRevertButtonWrapper = styled(Stack)`
    &&& {
        .MuiInputBase-root {
            ${inputIconPaddingStyles}
        }
    }
`;
