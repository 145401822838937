import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import SearchUserResults from './SearchUserResults';
import { StyledLink } from './UserQuickSearch.styles';
import { SearchUserForm } from 'src/components/Forms/SearchUserForm';
import { BasicLayout, PageVariant } from 'src/layouts';
import PageTitle from 'src/components/PageTitle';
import { User } from 'src/@types/unified-db-api';
import { searchUsers } from 'src/services/unified-db-api';
import { useSnackbarMessage } from 'src/hooks';
import { SnackbarMessageVariants } from 'src/constants';
import { Section } from 'src/components/Section';

const UserQuickSearch: FC = () => {
    const [user, setUser] = useState<User | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const { addMessage } = useSnackbarMessage();

    const formInitialValues = { email: user?.email.email || '' };

    const handleSearch = (values: { email: string }) => {
        setLoading(true);
        searchUsers(
            {
                pageSize: 1,
                page: 1,
                filterBy: {
                    operator: 'or',
                    filters: [
                        { name: 'secondaryEmails.email', value: values.email, comparison: '=' },
                        { name: 'email.email', value: values.email, comparison: '=' },
                    ],
                },
            },
            ['jobInfo', 'email', 'secondaryEmails'],
        )
            .then(({ data }) => setUser(data[0]))
            .catch(() => addMessage('Failed to search email', SnackbarMessageVariants.ERROR))
            .finally(() => setLoading(false));
    };

    return (
        <BasicLayout pageVariant={PageVariant.FORM} testId="quick-search-page">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <PageTitle title="Quick search" />
                <StyledLink href={'/udb/users'}>Advanced search</StyledLink>
            </Stack>
            <Section
                header="Search user"
                isMultiSection
                isLoading={isLoading}
                testId="users-section"
                variant="headless"
                paddingTop={0.5}
            >
                <SearchUserForm
                    onSubmit={handleSearch}
                    initialValues={formInitialValues}
                    isDisable={user !== undefined}
                />
                {user !== null && (<SearchUserResults user={user} />)}
            </Section>
        </BasicLayout>
    );
};

export default UserQuickSearch;
