import React, { FC, Fragment, useState } from 'react';
import { Box, Grid } from '@mui/material';
import CustomerInfo from 'src/components/CustomerInfo/CustomerInfo';
import RetrieveStripeCustomerForm from 'src/components/Forms/RetrieveStripeCustomerForm/RetrieveStripeCustomerForm';
import { StripeCustomerTitle } from 'src/components/Modals/AssignStripeCustomerModal/AssignStripeCustomerModal.styles';
import { Button, TextButton } from 'src/components/Buttons';
import { StripeCustomerInfo } from 'src/@types/sso-api';

type AssignStripeCustomerFormProps = {
    onSubmit: (stripeCustomerID: string) => Promise<void>;
};

const AssignStripeCustomerForm: FC<AssignStripeCustomerFormProps> = ({ onSubmit }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [customerInfo, setCustomerInfo] = useState<StripeCustomerInfo>();

    const handleAssignCustomer = async () => {
        if (!customerInfo?.id) {
            return;
        }

        setIsSubmitting(true);
        onSubmit(customerInfo.id)
            .finally(() => setIsSubmitting(false));
    };

    return (
        <Grid container spacing={{ xs: 2.5, sm: 4 }} data-testid="assign-stripe-customer-form">
            <Grid item xs={12}>
                {customerInfo ? (
                    <Fragment>
                        <Box marginBottom={3}>
                            <StripeCustomerTitle marginBottom={0.5}>
                                Customer
                                <TextButton
                                    data-testid="search-again-button"
                                    onClick={() => setCustomerInfo(undefined)}
                                >
                                    Search again
                                </TextButton>
                            </StripeCustomerTitle>
                            <CustomerInfo customer={customerInfo} />
                        </Box>
                        <Button
                            data-testid="customer-assign-button"
                            disabled={!customerInfo.id || isSubmitting}
                            onClick={handleAssignCustomer}
                            fullWidth
                        >
                            Assign customer
                        </Button>
                    </Fragment>
                ) : (
                    <RetrieveStripeCustomerForm
                        isSubmitting={isSubmitting}
                        setIsSubmitting={setIsSubmitting}
                        onCustomerRetrieved={setCustomerInfo}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default AssignStripeCustomerForm;
