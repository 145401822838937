import React, { FC, Fragment } from 'react';
import { Grid } from '@mui/material';
import { getCredentialsDetailsFields } from './services/getters';
import { SettingsData } from 'src/@types/sso-api';
import { SectionItem } from 'src/components/SectionItem';
import type { Credentials } from 'src/@types/credentials';
import Line from 'src/components/Line';
import { TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { Section } from 'src/components/Section';

type CredentialsDetailsSectionProps = {
    isLoading: boolean;
    credentials: Credentials;
    settings?: SettingsData;
};

const CredentialsDetailsSection: FC<CredentialsDetailsSectionProps> = ({
    credentials,
    settings,
    isLoading,
}) => {
    const credentialsFields = getCredentialsDetailsFields(credentials, settings);

    return (
        <Section
            sectionPaddingBottom={3}
            header="Details"
            data-testid="credentials-details-section"
        >
            {isLoading && <LoaderOverlay />}
            <Grid container columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                {credentialsFields.map((field, index) => (
                    <Fragment key={field.title}>
                        {index !== 0 && (
                            <Grid item display={{ xs: 'block', md: 'none' }} paddingY={2} xs={12}>
                                <Line />
                            </Grid>
                        )}
                        <SectionItem
                            title={field.title}
                            value={field.value}
                            gridProps={field.gridProps}
                        />
                    </Fragment>
                ))}
            </Grid>
        </Section>
    );
};

export default CredentialsDetailsSection;
