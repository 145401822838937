import { TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { GridColumnProps } from 'src/components/TableSection/TableSection';

export const gridColumnParams: GridColumnProps = {
    fullName: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Full name',
        gap: { xs: 0, md: 1 },
    },
    country: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Country',
        gap: { xs: 0, md: 1 },
    },
    address: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 5,
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Address',
        display: 'flex',
        gap: { xs: 0, md: 1 },
    },
};
