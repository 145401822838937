import React, { FC, Fragment, ReactNode } from 'react';
import { InfoContainer } from './InfoBox.styles';
import InfoBoxItem, { InfoBoxItemProps } from './InfoBoxItem';
import Line from 'src/components/Line';

type InfoBoxProps = {
    prependNode?: ReactNode;
    items: Array<InfoBoxItemProps & {
        key: string;
    }>;
};

const InfoBox: FC<InfoBoxProps> = ({ items, prependNode }) => {
    return (
        <InfoContainer paddingX={{ xs: 2, sm: 3 }} paddingY={{ xs: 2.5, sm: 3 }} spacing={{ xs: 2, sm: 2.5 }}>
            {prependNode}
            {items.map(({ label, value, key, tooltipTitle }, index) => (
                <Fragment key={key}>
                    {(index !== 0 || !!prependNode) && <Line />}
                    <InfoBoxItem value={value} label={label} tooltipTitle={tooltipTitle} />
                </Fragment>
            ))}
        </InfoContainer>
    );
};

export default InfoBox;
