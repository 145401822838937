import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { startPolling } from 'src/redux/slices';
import StyledTooltip from 'src/components/Tooltip';
import { BaseModal } from 'src/components/Modals/BaseModal';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import { CheckboxWithLabel } from 'src/components/CheckboxWithLabel';
import { PriceFormInput } from 'src/components/Inputs/PriceFormInput';
import { GeneralModalKey, SnackbarMessageVariants, SubscriptionBillingType, SubscriptionStatus } from 'src/constants';
import { prepareBillingRequestData } from 'src/services/request-data-formatter';
import { createInitialBillingRecord } from 'src/services/subscription-service-api';
import {
    InitialBillingRecordRequest,
    SubscriptionModel,
} from 'src/@types/subscription-service-api';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';
import {
    contractLinkValidator,
    numberOfPriceValidator,
    numberOfRequestedUserValidator,
    validateSchemaObject,
} from 'src/services/validators';
import { SignedText } from 'src/components/Modals/ContractDetailsModal/ContractDetailsModal.styles';
import { SwitchAdornment } from 'src/components/Inputs/Adornments/Switch';
import { LabeledInput } from 'src/components/Inputs';
import { useGeneralModal, useIsSubmitDisabled, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { Button } from 'src/components/Buttons';
import { FormWrapper } from 'src/components/Forms/components';

export type CreateInitialBillingRecordModalProps = {
    subscription: SubscriptionModel;
    isActivationAllowed: boolean;
    pollingId: string;
};

type BillingRecordFormValues = Partial<InitialBillingRecordRequest> & {
    priceInCents: string;
    requestedAmountOfUsers: string;
};

const CreateInitialBillingRecordModal: FC<CreateInitialBillingRecordModalProps> = ({
    subscription,
    isActivationAllowed,
    pollingId,
}) => {
    const initialValues = {
        priceInCents: '',
        requestedAmountOfUsers: '',
        contractLink: '',
        contractSigned: false,
    } as BillingRecordFormValues;

    const { uuid, billingType, status, activationDate, type } = subscription;

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const [updateData, setUpdateData] = useState<BillingRecordFormValues>(initialValues);
    const [attachExistingInvoiceChecked, setAttachExistingInvoiceChecked] = useState<boolean>(false);

    const dispatch = useDispatch();

    const isFree = billingType === SubscriptionBillingType.FREE;
    const isSubscriptionActivating = status === SubscriptionStatus.DRAFT
        && activationDate === null
        && isActivationAllowed;

    const {
        onFormChangeHandler,
        isSubmitDisabled,
        setIsSubmitDisabled,
    } = useIsSubmitDisabled(initialValues, !isFree ? ['priceInCents'] : undefined);
    const { reloadPage } = useReloadPage();
    const { closeModal, openModal } = useGeneralModal();
    const { addMessage, addErrorMessage } = useSnackbarMessage();

    const handleSaveInitialBillingRecord = async (
        values: BillingRecordFormValues,
    ) => {
        setIsSubmitting(true);
        setIsSubmitDisabled(true);

        const requestData = prepareBillingRequestData(values, initialValues);

        return createInitialBillingRecord(uuid, requestData)
            .then(async (data) => {
                addMessage('Initial billing record successfully created', SnackbarMessageVariants.SUCCESS);
                closeModal();
                reloadPage();

                if (isSubscriptionActivating && !!values.contractSigned) {
                    dispatch(startPolling(pollingId));
                }

                if (attachExistingInvoiceChecked) {
                    openModal({
                        key: GeneralModalKey.attachAnInvoice,
                        extraProps: {
                            billingRecord: data,
                            subscriptionType: type,
                            stripeCustomerId: subscription.customAttributes?.stripeCustomerId,
                        },
                    });
                }
            })
            .catch((error) => {
                addErrorMessage('Failed to create initial billing record', error);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const handleAttachExistingInvoiceChange = () => {
        setAttachExistingInvoiceChecked((value) => !value);
    };

    const handleSubmit = (values: BillingRecordFormValues) => {
        setUpdateData(values);

        if (isSubscriptionActivating && values.contractSigned === true) {
            setIsConfirming(true);
        } else {
            handleSaveInitialBillingRecord(values);
        }
    };

    const handleCloseConfirmModal = () => {
        setIsConfirming(false);
    };

    const handleConfirmSave = async () => {
        return handleSaveInitialBillingRecord(updateData)
            .finally(() => setIsConfirming(false));
    };

    if (isConfirming) {
        return (
            <BaseModal
                step={1}
                open={true}
                title="Confirm changes"
                handleClose={handleCloseConfirmModal}
                handleGoBack={handleCloseConfirmModal}
            >
                <ConfirmationModal
                    modalId="confirm-initial-billing-record-modal"
                    subtitle="Subscription will be activated shortly after saving changes."
                    onConfirm={handleConfirmSave}
                    onCancel={handleCloseConfirmModal}
                />
            </BaseModal>
        );
    }

    return (
        <FormWrapper
            onFormChange={onFormChangeHandler}
            testId="initial-billing-record-form"
            isSubmitting={isSubmitting}
            initialValues={{
                ...updateData,
                priceInCents: isFree ? 0 : updateData.priceInCents,
            } as BillingRecordFormValues}
            onSubmit={handleSubmit}
            validationSchema={validateSchemaObject({
                priceInCents: numberOfPriceValidator,
                requestedAmountOfUsers: numberOfRequestedUserValidator,
                contractLink: contractLinkValidator,
            })}
        >
            <Stack>
                <PriceFormInput
                    testId="price-in-cents"
                    name="priceInCents"
                    label="Price"
                    placeholder=""
                    disabled={isFree}
                    required={!isFree}
                />
                <LabeledInput
                    marginTop={{ xs: 2.5, md: 4 }}
                    type="number"
                    name="requestedAmountOfUsers"
                    testId="requested-amount-of-users"
                    label="Requested amount of users"
                    placeholder=""
                />
                <Stack marginY={{ xs: 2.5, md: 4 }} position="relative">
                    <SignedText>Signed</SignedText>
                    <LabeledInput
                        disallowSpace
                        flexGrow={1}
                        placeholder="Add contract link"
                        name="contractLink"
                        label="Contract link"
                        testId="contract-link-input"
                        endAdornment={(
                            <SwitchAdornment
                                name="contractSigned"
                                ariaLabel="contract signed switch"
                            />
                        )}
                    />
                </Stack>
                {!isFree && (
                    <Stack flexDirection="row" alignItems="center" gap={{ xs: 1, sm: 1.5 }}>
                        <CheckboxWithLabel
                            testId="attach-an-invoice-checkbox"
                            checked={attachExistingInvoiceChecked}
                            onClick={handleAttachExistingInvoiceChange}
                            label="Create/attach an invoice"
                        />
                        <StyledTooltip arrow title="You will be able to create or attach an invoice in the next step.">
                            <InfoIcon data-testid="info-icon" />
                        </StyledTooltip>
                    </Stack>
                )}
                <Stack marginTop={!isFree ? 3 : 2} direction="row" spacing={{ xs: 2, sm: 2.5 }}>
                    <Button onClick={closeModal} minSize="small" variant="secondary" fullWidth>
                        Cancel
                    </Button>
                    <Button
                        data-testid="create-button"
                        disabled={isSubmitting || (!isFree && isSubmitDisabled)}
                        minSize="small"
                        fullWidth
                        type="submit"
                    >
                        Create
                    </Button>
                </Stack>
            </Stack>
        </FormWrapper>
    );
};

export default CreateInitialBillingRecordModal;
