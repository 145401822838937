import React, { FC, useEffect, useState } from 'react';
import { StepContainer } from 'src/pages/udb/CreateSubscription/components/StepContainer';
import { CreateSubscriptionInvoiceData } from 'src/pages/udb/CreateSubscription/CreateSubscription';
import { CustomPricingTermsProps } from 'src/@types/subscription-service-api';
import type {
    EnterprisePricingTermsData,
} from 'src/pages/udb/CreateSubscription/components/EnterprisePricingTerms/EnterprisePricingTerms';
import { BillingCycleLengthInMonth, SubscriptionBillingType, SubscriptionType } from 'src/constants';
import { InsiderPricingTerms } from 'src/pages/udb/CreateSubscription/components/InsiderPricingTerms';
import { EnterprisePricingTerms } from 'src/pages/udb/CreateSubscription/components/EnterprisePricingTerms';

export type PricingTermsData = {
    [SubscriptionType.INSIDER]?: CustomPricingTermsProps & { finalPriceInCents?: number };
    [SubscriptionType.ENTERPRISE]?: EnterprisePricingTermsData;
};

export type PricingTermsStepProps = {
    handleGoBackClick: (data: PricingTermsData & { invoiceData: CreateSubscriptionInvoiceData }) => void;
    handleNextButtonClick: (data: PricingTermsData & { invoiceData: CreateSubscriptionInvoiceData }) => void;
    billingCycleLength: BillingCycleLengthInMonth;
    initialData: PricingTermsData | undefined;
    numberOfCycles: number;
    selectedSubscriptionType: SubscriptionType;
    selectedBillingType: SubscriptionBillingType;
    initialInvoiceData: CreateSubscriptionInvoiceData;
};

export const shouldShowNextButton = ({ insider, enterprise }: PricingTermsData) => {
    const hasInsiderRenewalAllData = !!insider?.discountType
        && insider?.discountDurationInCycles !== undefined
        && !!insider?.discountValue;

    return !!enterprise?.domains.length
        || !!insider?.fixedPriceInCents
        || hasInsiderRenewalAllData
        || insider?.discountValue === null;
};

const PricingTermsStep: FC<PricingTermsStepProps> = ({
    handleGoBackClick,
    billingCycleLength,
    initialData,
    numberOfCycles,
    selectedSubscriptionType,
    selectedBillingType,
    initialInvoiceData,
    handleNextButtonClick,
}) => {
    const [insiderData, setInsiderData] =
        useState<CustomPricingTermsProps | undefined>(initialData?.[SubscriptionType.INSIDER]);
    const [enterpriseData, setEnterpriseData] =
        useState<EnterprisePricingTermsData | undefined>(initialData?.[SubscriptionType.ENTERPRISE]);
    const [invoiceData, setInvoiceData] = useState<CreateSubscriptionInvoiceData>(initialInvoiceData);

    useEffect(() => {
        setInsiderData(initialData?.[SubscriptionType.INSIDER]);
        setEnterpriseData(initialData?.[SubscriptionType.ENTERPRISE]);
    }, [initialData]);

    const shouldSubmitOnCreateInvoiceSetup = selectedBillingType === SubscriptionBillingType.INVOICED;

    const onInvoiceSetupSubmit = (data: CreateSubscriptionInvoiceData) => {
        handleNextButtonClick({
            [SubscriptionType.INSIDER]: insiderData,
            [SubscriptionType.ENTERPRISE]: enterpriseData,
            invoiceData: data,
        });
    };

    return (
        <StepContainer
            handleGoBack={() => {
                handleGoBackClick({
                    [SubscriptionType.INSIDER]: insiderData,
                    [SubscriptionType.ENTERPRISE]: enterpriseData,
                    invoiceData,
                });
            }}
            handleNextButtonClick={() => {
                if (shouldSubmitOnCreateInvoiceSetup) {
                    return;
                }

                handleNextButtonClick({
                    [SubscriptionType.INSIDER]: insiderData,
                    [SubscriptionType.ENTERPRISE]: enterpriseData,
                    invoiceData,
                });
            }}
            showGoBackButton
            showNextButton={shouldShowNextButton({
                insider: insiderData,
                enterprise: enterpriseData,
            })}
            title="Pricing terms"
        >
            {selectedSubscriptionType === SubscriptionType.INSIDER ? (
                <InsiderPricingTerms
                    numberOfCycles={numberOfCycles}
                    initialData={insiderData}
                    billingCycleLength={billingCycleLength}
                    setInsiderData={setInsiderData}
                    invoiceData={invoiceData}
                    setInvoiceData={setInvoiceData}
                    onInvoiceSetupSubmit={onInvoiceSetupSubmit}
                />
            ) : (
                <EnterprisePricingTerms
                    selectedBillingType={selectedBillingType}
                    initialData={enterpriseData}
                    setEnterpriseData={setEnterpriseData}
                    invoiceData={invoiceData}
                    setInvoiceData={setInvoiceData}
                    onInvoiceSetupSubmit={onInvoiceSetupSubmit}
                />
            )}
        </StepContainer>
    );
};

export default PricingTermsStep;
