import React from 'react';
import {
    ManageSubscriptionNotificationTitle,
    ManageSubscriptionNotificationWrapper,
} from './ManageSubscriptionNotification.styles';

const ManageSubscriptionPollingNotification = () => (
    <ManageSubscriptionNotificationWrapper
        paddingX={{ xs: 2, md: 4.5 }}
        paddingY={{ xs: 2.5, md: 2.5 }}
        data-testid="subscription-polling-notification"
        position="relative"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        data-allowed={true}
        marginBottom={{ sm: 2.5, xs: 2 }}
    >
        <ManageSubscriptionNotificationTitle variant="h4" data-testid="subscription-polling-notification-title">
                Subscription state is expected to change soon. This page will refresh automatically when that happens.
        </ManageSubscriptionNotificationTitle>
    </ManageSubscriptionNotificationWrapper>
);

export default ManageSubscriptionPollingNotification;
