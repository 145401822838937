import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { CreateStripeCustomerForm } from 'src/components/Forms/CreateStripeCustomerForm';
import TabbedContainer from 'src/components/TabbedContainer/TabbedContainer';
import { assignStripeCustomer, createStripeCustomer } from 'src/services/sso-api';
import { AssignStripeCustomerForm } from 'src/components/Forms/AssignStripeCustomerForm';
import { DEFAULT_MODAL_MAX_WIDTH } from 'src/components/Modals/BaseModal';
import { SubscriptionUserModel } from 'src/@types/subscription-service-api';
import { SnackbarMessageVariants, SubscriptionType } from 'src/constants';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { StripeCustomerInfo } from 'src/@types/sso-api';
import APIClientError from 'src/classes/api-client-error';

export type AssignStripeCustomerModalProps = {
    type: SubscriptionType;
    subscriptionManagers: SubscriptionUserModel[];
    subscriptionUUID: string;
};

const AssignStripeCustomerModal: FC<AssignStripeCustomerModalProps> = ({
    type,
    subscriptionManagers,
    subscriptionUUID,
}) => {
    const { addMessage, addErrorMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();
    const { closeModal } = useGeneralModal();

    const owner = subscriptionManagers.find((manager) => manager.isOwner);
    const defaultName = type === 'enterprise' ? owner?.companyName : owner?.fullName;

    const requestErrorHandler = (step: string) => (error: APIClientError) => {
        addErrorMessage(`Failed to ${step} Stripe customer`, error);
    };

    const handleAssignStripeCustomer = async (stripeCustomerID: string) => {
        return assignStripeCustomer(subscriptionUUID, stripeCustomerID).then(() => {
            addMessage('Stripe customer assigned successfully', SnackbarMessageVariants.SUCCESS);
            reloadPage();
            closeModal();
        }).catch(requestErrorHandler('assign'));
    };

    const handleCreateCustomer = async (customerData: Omit<StripeCustomerInfo, 'id'>) => {
        const createdCustomer = await createStripeCustomer(customerData)
            .then((customerInfo) => {
                addMessage('Stripe customer created successfully', SnackbarMessageVariants.SUCCESS);

                return customerInfo;
            }).catch(requestErrorHandler('create'));

        if (createdCustomer) {
            await handleAssignStripeCustomer(createdCustomer.id);
        }
    };

    return (
        <Stack maxWidth={DEFAULT_MODAL_MAX_WIDTH} data-testid="assign-stripe-customer-modal">
            <TabbedContainer
                testId="customer-modal-tabbed-container"
                items={[
                    { tabLabel: 'Create a new one', content: (
                        <CreateStripeCustomerForm
                            email={owner?.email || ''}
                            name={defaultName || ''}
                            onSubmit={handleCreateCustomer}
                        />
                    ) },
                    { tabLabel: 'Assign existing', content: <AssignStripeCustomerForm onSubmit={handleAssignStripeCustomer} /> },
                ]}
            />
        </Stack>
    );
};

export default AssignStripeCustomerModal;
