import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { StyledStack, TabsContainer } from './InvoiceModals.styles';
import { AttachExistingInvoice, CreateNewInvoice, InvoiceModalContent } from './components';
import { TabBarVariants } from 'src/components/TabBar/TabBar';
import {
    assignStripeCustomer,
    attachInvoiceToBillingRecord,
    createSubscriptionBillingInvoice,
    finalizeBillingRecordInvoice,
} from 'src/services/sso-api';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';
import StyledTooltip from 'src/components/Tooltip';
import { CreateSubscriptionBillingInvoiceParams, StripeInvoice } from 'src/@types/sso-api';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { InvoiceStatus, SnackbarMessageVariants, SubscriptionType } from 'src/constants';
import { BillingRecordModel, BillingRecordWithRenewal } from 'src/@types/subscription-service-api';
import { TabBar } from 'src/components/TabBar';

export type AttachAnInvoiceModalProps = {
    billingRecord: BillingRecordWithRenewal;
    stripeCustomerId?: string;
    subscriptionType: SubscriptionType;
};

export enum InvoiceTabType {
    CREATE_A_NEW_ONE = 'CreateANewOne',
    ATTACH_EXISTING = 'AttachExisting',
}

const AttachAnInvoiceModal: FC<AttachAnInvoiceModalProps> = ({
    billingRecord: initialBillingRecord,
    stripeCustomerId,
    subscriptionType,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [invoiceData, setInvoiceData] = useState<StripeInvoice | null>(null);
    const [isFinalizeInvoice, setIsFinalizeInvoice] = useState<boolean>(true);
    const [billingRecord, setBillingRecord] = useState<BillingRecordWithRenewal>(initialBillingRecord);

    const { addMessage, addErrorMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();
    const { closeModal } = useGeneralModal();
    const {
        priceInCents,
        subscriptionUUID,
        id,
        invoiceStatus,
        stripeInvoiceID,
    } = billingRecord;

    const isCreateInvoiceDisabled = invoiceStatus === InvoiceStatus.PAID && !stripeInvoiceID;

    const [selectedTab, setSelectedTab] = useState<InvoiceTabType>(
        isCreateInvoiceDisabled ? InvoiceTabType.ATTACH_EXISTING : InvoiceTabType.CREATE_A_NEW_ONE,
    );

    const handleTabChange = (newValue: string | number) => {
        setSelectedTab(newValue as InvoiceTabType);
    };

    const handleFinalizeInvoiceChange = () => {
        setIsFinalizeInvoice((value) => !value);
    };

    const handleSave = (data: Partial<BillingRecordModel>) => {
        setBillingRecord((prevRecord) => ({
            ...prevRecord,
            ...data,
        }));
    };

    const handleAttachInvoice = () => {
        if (!invoiceData) {
            return;
        }

        setIsLoading(true);
        return attachInvoiceToBillingRecord(subscriptionUUID, id, invoiceData.id)
            .then(() => {
                if (!stripeCustomerId && invoiceData.customerId) {
                    return assignStripeCustomer(subscriptionUUID, invoiceData.customerId)
                        .then(() => {
                            addMessage('Customer successfully assigned to the subscription', SnackbarMessageVariants.SUCCESS);
                        })
                        .catch(() => {
                            addMessage('Failed to assign customer to the subscription', SnackbarMessageVariants.WARNING);
                        });
                }
            })
            .then(() => {
                reloadPage();
                addMessage('Invoice successfully attached', SnackbarMessageVariants.SUCCESS);
                closeModal();
            })
            .catch((error) => {
                addErrorMessage('Failed to attach an invoice', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleFinalizeInvoice = (): Promise<void> => {
        return finalizeBillingRecordInvoice(subscriptionUUID, id)
            .then(() => {
                addMessage('Invoice successfully finalized', SnackbarMessageVariants.SUCCESS);
            })
            .catch((error) => {
                addErrorMessage('Failed to finalize invoice', error);
            });
    };

    const handleCreateInvoice = (values: Partial<CreateSubscriptionBillingInvoiceParams>) => {
        setIsLoading(true);

        return createSubscriptionBillingInvoice(
            subscriptionUUID,
            id,
            {
                ...(typeof priceInCents === 'number' && { priceInCents }),
                customFields: values.customFields,
                dueDate: values.dueDate,
            },
        )
            .then(async () => {
                addMessage('Invoice successfully created', SnackbarMessageVariants.SUCCESS);

                if (isFinalizeInvoice) {
                    await handleFinalizeInvoice();
                }

                reloadPage();
                closeModal();
            })
            .catch((error) => {
                addErrorMessage('Failed to create invoice', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <StyledStack data-testid="attach-an-invoice-modal">
            {isLoading && <LoaderOverlay />}
            <TabsContainer padding={0.5} marginBottom={{ xs: 2, sm: 3 }} data-testid="tabs-container">
                <StyledTooltip
                    arrow
                    title={isCreateInvoiceDisabled ? 'Creating an invoice is not allowed for this billing record' : ''}
                    disableHoverListener={!isCreateInvoiceDisabled}
                    disableFocusListener={!isCreateInvoiceDisabled}
                >
                    <Stack>
                        <TabBar
                            variant={TabBarVariants.SECONDARY_TABS}
                            onChange={handleTabChange}
                            initialValue={selectedTab}
                            items={[
                                {
                                    label: 'Create a new one',
                                    value: InvoiceTabType.CREATE_A_NEW_ONE,
                                    disabled: isCreateInvoiceDisabled,
                                },
                                { label: 'Attach existing', value: InvoiceTabType.ATTACH_EXISTING },
                            ]}
                            data-testid="tab-bar"
                        />
                    </Stack>
                </StyledTooltip>
            </TabsContainer>
            <Stack spacing={{ xs: 1.5, sm: 1.25 }}>
                <InvoiceModalContent subscriptionType={subscriptionType} billingRecord={billingRecord} />
                <Stack>
                    {selectedTab === InvoiceTabType.CREATE_A_NEW_ONE && (
                        <CreateNewInvoice
                            priceInCents={priceInCents}
                            oldPrice={initialBillingRecord.priceInCents}
                            onCreateInvoice={handleCreateInvoice}
                            handleSave={handleSave}
                            handleFinalizeInvoiceChange={handleFinalizeInvoiceChange}
                            isFinalizeInvoice={isFinalizeInvoice}
                            submitButtonText="Create invoice"
                            cycleEnd={billingRecord.cycleEnd}
                        />
                    )}
                    {selectedTab === InvoiceTabType.ATTACH_EXISTING && (
                        <AttachExistingInvoice
                            invoiceData={invoiceData}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            setInvoiceData={setInvoiceData}
                            onSearchAgain={() => setInvoiceData(null)}
                            onAttachInvoice={handleAttachInvoice}
                        />
                    )}
                </Stack>
            </Stack>
        </StyledStack>
    );
};

export default AttachAnInvoiceModal;
