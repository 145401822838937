import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import UserInfoBox from 'src/components/UserInfoBox/UserInfoBox';
import { Link } from 'src/components/Link';
import { buildStripeCustomerHref } from 'src/services/stripe';
import { StripeCustomerInfo } from 'src/@types/sso-api';
import { CustomerAddress } from 'src/components/CustomerInfo/components';
import { ReactComponent as StripeLinkIcon } from 'src/assets/icons/stripe-link-icon.svg';
import type { RootState } from 'src/redux/root-reducer';
import type { ConfigState } from 'src/@types/redux';
import { configSelector } from 'src/redux/slices';

type CustomerInfoProps = {
    withLink?: boolean;
    customer: StripeCustomerInfo;
};

const CustomerInfo: FC<CustomerInfoProps> = ({
    withLink,
    customer,
}) => {
    const { testMode } = useSelector<RootState, ConfigState>(configSelector);

    return (
        <UserInfoBox
            name={customer.name}
            email={customer.email}
            additionalRows={[
                { row: (
                    <Stack display="inline-flex" alignItems="center" direction="row">
                        Customer ID: {customer.id}
                        {withLink && (
                            <Link
                                gap={0.5}
                                fontSize={14}
                                paddingLeft={1}
                                target="_blank"
                                display="inline-flex"
                                alignItems="center"
                                data-testid="stripe-link"
                                href={buildStripeCustomerHref(customer.id, testMode)}
                            >
                                <Typography
                                    display={{ xs: 'none', sm: 'inline' }}
                                    fontFamily="inherit"
                                    lineHeight="inherit"
                                    fontSize="inherit"
                                    color="inherit"
                                >
                                  Open in Stripe
                                </Typography>
                                <StripeLinkIcon width="16px" height="16px" />
                            </Link>
                        )}
                    </Stack>
                ), key: 'stripeId' },
                { row: <CustomerAddress address={customer.address} />, key: 'address', rowProps: { 'data-text-wrap': 'auto' } },
            ]}
        />
    );
};

export default CustomerInfo;
