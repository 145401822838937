import React, { FC, ReactNode } from 'react';
import Box, { type BoxProps } from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { BackdropProps, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    StyledArrowBackIcon,
    StyledButton,
    StyledCloseIcon,
    StyledContentContainer,
    StyledHeaderContainer,
    StyledModal,
    StyledModalContainer,
} from './BaseModal.styles';
import { DEFAULT_MODAL_CONTENT_PADDING_TOP, DEFAULT_MODAL_PADDING_BOTTOM, DEFAULT_MODAL_PADDING_X } from './constants';
import type { RootState } from 'src/redux/root-reducer';
import { INTERCOM_ICON_Z_INDEX } from 'src/constants';
import Line from 'src/components/Line';
import { modalStepSelector, openPreviousStep } from 'src/redux/slices';

export type BaseModalProps = {
    open: boolean;
    handleClose: () => void;
    title?: string | ReactNode;
    dataTestId?: string;
    step?: number;
    handleGoBack?: () => void;
    hideBackDrop?: boolean;
    contentPaddingTop?: BoxProps['paddingTop'];
    showCloseAndGoBack?: boolean;
    overlayScope?: 'relative' | 'static';
} & Pick<BoxProps, 'paddingX' | 'paddingBottom' | 'children'>;

const BaseModal: FC<BaseModalProps> = ({
    open,
    title = '',
    dataTestId = 'modal',
    paddingX = DEFAULT_MODAL_PADDING_X,
    paddingBottom = DEFAULT_MODAL_PADDING_BOTTOM,
    contentPaddingTop = DEFAULT_MODAL_CONTENT_PADDING_TOP,
    hideBackDrop,
    handleClose,
    handleGoBack,
    children,
    step,
    showCloseAndGoBack,
    overlayScope = 'static',
}) => {
    if (!open) {
        return null;
    }

    const backdropProps: Partial<BackdropProps<'div', { 'data-testid': string }>> = {
        'data-testid': `${dataTestId}-backdrop`,
        style: {
            display: hideBackDrop ? 'none' : 'block',
        },
    };

    const storedStep = useSelector<RootState, number>(modalStepSelector);
    const modalStep = step ?? storedStep;

    const dispatch = useDispatch();

    return (
        <StyledModal
            open={open}
            onClose={handleClose}
            slotProps={{
                backdrop: backdropProps,
                root: {
                    style: {
                        zIndex: INTERCOM_ICON_Z_INDEX + 2,
                    },
                },
            }}
        >
            <StyledModalContainer data-testid={dataTestId}>
                <Stack>
                    <StyledHeaderContainer
                        container
                        alignItems="center"
                        paddingX={DEFAULT_MODAL_PADDING_X}
                    >
                        <Grid item xs={1}>
                            {(showCloseAndGoBack || modalStep > 0) && (
                                <StyledButton
                                    data-testid={`${dataTestId}-arrow-back`}
                                    onClick={() => {
                                        handleGoBack?.();
                                        dispatch(openPreviousStep());
                                    }}
                                >
                                    <StyledArrowBackIcon />
                                </StyledButton>
                            )}
                        </Grid>
                        <Grid item xs={10} textAlign="center" data-testid={`${dataTestId}-title`}>
                            {title}
                        </Grid>
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            {(showCloseAndGoBack || modalStep === 0) && (
                                <StyledButton
                                    data-testid={`${dataTestId}-close-btn`}
                                    onClick={handleClose}
                                >
                                    <StyledCloseIcon />
                                </StyledButton>
                            )}
                        </Grid>
                    </StyledHeaderContainer>
                    <Line />
                    <StyledContentContainer position={overlayScope}>
                        <Box
                            paddingX={paddingX}
                            paddingTop={contentPaddingTop}
                            paddingBottom={paddingBottom}
                            data-testid={`${dataTestId}-content`}
                        >
                            {children}
                        </Box>
                    </StyledContentContainer>
                </Stack>
            </StyledModalContainer>
        </StyledModal>
    );
};

export default BaseModal;
