import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { SubscriptionBillingType, SubscriptionType } from 'src/constants';
import Tooltip from 'src/components/Tooltip';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';

type ActivateLaterLabelProps = {
    label: string;
    hideInfoIcon?: boolean;
    billingType?: SubscriptionBillingType;
    subscriptionType?: SubscriptionType;
};

const ActivateLaterLabel: FC<ActivateLaterLabelProps> = ({
    label,
    billingType,
    subscriptionType,
    hideInfoIcon,
}) => {
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Typography color="inherit" fontSize="inherit" fontWeight="inherit" fontFamily="inherit">
                {label}
            </Typography>
            {billingType === SubscriptionBillingType.INVOICED && !hideInfoIcon && (
                <Tooltip
                    arrow
                    title={`Subscription may be activated manually or it will be activated automatically upon payment${subscriptionType === SubscriptionType.ENTERPRISE ? ' or signing of contract.' : '.'}`}
                >
                    <InfoIcon width={16} height={16} />
                </Tooltip>
            )}
        </Stack>
    );
};

export default ActivateLaterLabel;
