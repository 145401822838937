import React, { FC } from 'react';
import { Box } from '@mui/material';
import { getErrorMessage, getModalSubtitle, getModalTitle, getSuccessMessage } from './services/message';
import { SnackbarMessageVariants, Status } from 'src/constants';
import type { Credentials } from 'src/@types/credentials';
import { updateCredentials } from 'src/services/sso-api';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import { BaseModal } from 'src/components/Modals/BaseModal';

export type ChangeAccountStatusModalProps = {
    credentials: Credentials;
};

const ChangeAccountStatusModal: FC<ChangeAccountStatusModalProps> = ({
    credentials,
}) => {
    const { closeModal } = useGeneralModal();
    const { reloadPage } = useReloadPage();
    const { addMessage, addErrorMessage } = useSnackbarMessage();

    const isActive = credentials.status === Status.ACTIVE;
    const newStatus = isActive ? Status.INACTIVE : Status.ACTIVE;

    const handleConfirm = async () => {
        return updateCredentials(credentials.id, { status: newStatus })
            .then(() => {
                addMessage(getSuccessMessage(isActive), SnackbarMessageVariants.SUCCESS);
                closeModal();
                reloadPage();
            })
            .catch((error) => {
                addErrorMessage(getErrorMessage(isActive), error);
            });
    };

    return (
        <BaseModal
            open
            handleClose={closeModal}
            title={getModalTitle(isActive)}
        >
            <ConfirmationModal
                modalId="change-status-modal"
                onConfirm={handleConfirm}
                onCancel={closeModal}
                confirmButtonText={getModalTitle(isActive)}
                subtitle={(
                    <Box>
                        {getModalSubtitle(isActive)}
                    </Box>
                )}
            />
        </BaseModal>
    );
};

export default ChangeAccountStatusModal;
