import React, { FC } from 'react';
import { FormikHelpers } from 'formik';
import { StripeCustomerInfo } from 'src/@types/sso-api';
import { getStripeCustomerInfo } from 'src/services/sso-api';
import { parseStripeCustomerId } from 'src/services/parser';
import { useIsSubmitDisabled, useSnackbarMessage } from 'src/hooks';
import { InputWithButtonWrapper } from 'src/components/Inputs/StyledInput.styles';
import { FormWrapper } from 'src/components/Forms/components';
import { stripeCustomerIdValidator, validateSchemaObject } from 'src/services/validators';
import { LabeledInput } from 'src/components/Inputs';
import { Button } from 'src/components/Buttons';
import { SnackbarMessageVariants } from 'src/constants';

type RetrieveStripeCustomerFormData = {
    customerId: string;
};

type RetrieveStripeCustomerFormProps = {
    hideLabels?: boolean;
    isSubmitting?: boolean;
    setIsSubmitting: (isSubmitting: boolean) => void;
    onCustomerRetrieved: (data: StripeCustomerInfo) => void;
};

const RetrieveStripeCustomerForm: FC<RetrieveStripeCustomerFormProps> = ({
    hideLabels,
    isSubmitting,
    setIsSubmitting,
    onCustomerRetrieved,
}) => {
    const { addMessage } = useSnackbarMessage();

    const {
        onFormChangeHandler,
        isSubmitDisabled,
    } = useIsSubmitDisabled<RetrieveStripeCustomerFormData>({ customerId: '' });

    const handleSubmitCustomerSearch = async (
        { customerId }: RetrieveStripeCustomerFormData,
        { resetForm }: FormikHelpers<RetrieveStripeCustomerFormData>,
    ) => {
        setIsSubmitting(true);

        return getStripeCustomerInfo(parseStripeCustomerId(customerId))
            .then((data) => {
                onCustomerRetrieved(data);
                resetForm();
            })
            .catch((e) => {
                addMessage(
                    e?.responseError?.data?.error ?? 'Failed to find Stripe customer',
                    SnackbarMessageVariants.ERROR,
                );
            })
            .finally(() => setIsSubmitting(false));
    };

    return (
        <FormWrapper
            onFormChange={onFormChangeHandler}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmitCustomerSearch}
            initialValues={{ customerId: '' }}
            validationSchema={validateSchemaObject({
                customerId: stripeCustomerIdValidator.required(),
            })}
        >
            <InputWithButtonWrapper
                spacing={{ xs: 2, sm: 1 }}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
            >
                <LabeledInput
                    disallowSpace
                    disabled={isSubmitting}
                    name="customerId"
                    label={hideLabels ? '' : 'Customer'}
                    placeholder="Customer ID/URL"
                    flexGrow={1}
                />
                <Button
                    data-testid="customer-retrieve-button"
                    disabled={isSubmitDisabled || isSubmitting}
                    type="submit"
                    minSize={{ xs: 'medium', sm: 'default' }}
                >
                  Retrieve
                </Button>
            </InputWithButtonWrapper>
        </FormWrapper>
    );
};

export default RetrieveStripeCustomerForm;
