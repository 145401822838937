import React, { FC, Fragment } from 'react';
import { Grid } from '@mui/material';
import { getJobInformationFields } from './services/getters';
import { SectionItem } from 'src/components/SectionItem';
import { useModalWithAnchor } from 'src/hooks/useModalWithAnchor';
import { Section } from 'src/components/Section';
import Line from 'src/components/Line';
import { JobInfo } from 'src/@types/unified-db-api';
import { TextButton } from 'src/components/Buttons';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit-icon.svg';
import { GeneralModalKey, TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';

const JobInformationSection: FC<{ jobInformation?: JobInfo, isEditable: boolean, uuid: string, isLoaded: boolean }> = ({
    jobInformation,
    isEditable,
    uuid,
    isLoaded,
}) => {
    const jobInformationFields = getJobInformationFields(jobInformation);
    const modalPropsGetter = () => ({
        extraProps: {
            jobInformation,
            uuid,
        },
    });

    const { openModal } = useModalWithAnchor([GeneralModalKey.editJobInformation], isLoaded, modalPropsGetter);

    const openJobInformationModal = () => {
        openModal(GeneralModalKey.editJobInformation);
    };

    return (
        <Section
            data-testid="job-information-section"
            sectionPaddingBottom={3}
            header="Job information"
            headerChildren={
                isEditable ? (
                    <TextButton onClick={openJobInformationModal} data-testid="edit-btn">
                        <EditIcon />
                    </TextButton>
                ) : null
            }
        >
            <Grid container columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                {jobInformationFields.map((field, index) => (
                    <Fragment key={field.title}>
                        {index !== 0 && (
                            <Grid item display={{ xs: 'block', md: 'none' }} paddingY={2} xs={12}>
                                <Line />
                            </Grid>
                        )}
                        <SectionItem
                            canCopy
                            title={field.title}
                            value={field.value}
                            gridProps={field.gridProps}
                        />
                    </Fragment>
                ))}
            </Grid>
        </Section>
    );
};

export default JobInformationSection;
