import styled from 'styled-components';
import { Typography } from '@mui/material';
import { TextButton } from 'src/components/Buttons';

export const AddButton = styled(TextButton)`
    &&&.MuiButtonBase-root {
        justify-content: flex-start;
        border-radius: 4px;
        font-size: 16px;
        font-family: ${({ theme }) => theme.fonts.medium};
        
        svg {
            margin-right: 16px;
            width: 20px;
            height: 20px;
        }

        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 14px;

            svg {
                width: 18px;
                height: 18px;
            }
        }
    }
`;

export const StyledText = styled(Typography)`
    && {
        line-height: normal;
        font-size: 16px;
        font-weight: 600;
        font-family: ${({ theme }) => theme.fonts.demiBold};
        color: ${({ theme }) => theme.palette.lightBlack};
        
        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 14px;
        }
    }
`;
