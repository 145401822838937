import React, { FC } from 'react';
import { Box } from '@mui/material';
import { SnackbarMessageVariants } from 'src/constants';
import type { Credentials } from 'src/@types/credentials';
import { updateCredentials } from 'src/services/sso-api';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { BaseModal } from 'src/components/Modals/BaseModal';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';

export type Disable2FAModalProps = {
    credentials: Credentials;
};

const Disable2FAModal: FC<Disable2FAModalProps> = ({
    credentials,
}) => {
    const { closeModal } = useGeneralModal();
    const { reloadPage } = useReloadPage();
    const { addMessage, addErrorMessage } = useSnackbarMessage();

    const handleConfirm = async () => {
        return updateCredentials(credentials.id, { tfaEnabled: false })
            .then(() => {
                addMessage('2FA has been successfully disabled', SnackbarMessageVariants.SUCCESS);
                closeModal();
                reloadPage();
            })
            .catch((error) => {
                addErrorMessage('Failed to disable 2FA', error);
            });
    };

    return (
        <BaseModal
            open
            handleClose={closeModal}
            title="Disable 2FA"
        >
            <ConfirmationModal
                modalId="disable-2fa-modal"
                onConfirm={handleConfirm}
                onCancel={closeModal}
                confirmButtonText="Disable"
                subtitle={(
                    <Box>
                        Are you sure want to disable 2FA for this account?<br />
                        This account will no longer be required to enter
                        one-time codes and will become less secure as a result.
                    </Box>
                )}
            />
        </BaseModal>
    );
};

export default Disable2FAModal;
