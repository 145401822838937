import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from 'styled-components';
import { getResponseError } from 'src/services/error-formatters';
import { Button } from 'src/components/Buttons';
import { SnackbarMessageVariants } from 'src/constants';
import { createStripeCustomer, getCustomerByUserUUID } from 'src/services/sso-api';
import CustomerInfo from 'src/components/CustomerInfo/CustomerInfo';
import { RetrieveStripeCustomerForm } from 'src/components/Forms';
import { TabbedContainer } from 'src/components/TabbedContainer';
import { StripeCustomerInfo } from 'src/@types/sso-api';
import { User } from 'src/@types/unified-db-api';
import { useSnackbarMessage } from 'src/hooks';
import { StepContainer } from 'src/pages/udb/CreateSubscription/components/StepContainer';
import { Section } from 'src/components/Section';
import { CreateStripeCustomerForm } from 'src/components/Forms/CreateStripeCustomerForm';

type StripeCustomerStepProps = {
    handleGoBack: (data: StripeCustomerInfo | null) => void;
    handleNextButtonClick: (data: StripeCustomerInfo | null) => void;
    initialCustomer: StripeCustomerInfo | null;
    owner: User;
};

const StripeCustomerStep: FC<StripeCustomerStepProps> = ({
    handleGoBack,
    handleNextButtonClick,
    initialCustomer,
    owner,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(!initialCustomer);
    const [fetchedCustomerInfo, setFetchedCustomerInfo] = useState<StripeCustomerInfo | null>(initialCustomer);
    const [customerToAssign, setCustomerToAssign] = useState<StripeCustomerInfo | null>(fetchedCustomerInfo);

    const createNewLabel = 'Create a new one';
    const assignExistingLabel = 'Assign existing';

    const { addMessage } = useSnackbarMessage();
    const { fonts, palette } = useTheme();

    const handleCreateCustomer = async (customerData: Omit<StripeCustomerInfo, 'id'>) => {
        await createStripeCustomer(customerData)
            .then((customer) => {
                addMessage('Stripe customer created successfully', SnackbarMessageVariants.SUCCESS);

                handleNextButtonClick(customer);
            }).catch((error) => {
                addMessage(getResponseError(error, 'Failed to create Stripe customer'), SnackbarMessageVariants.ERROR);
            });
    };

    const handleAssignStripeCustomer = async () => {
        handleNextButtonClick(customerToAssign);
    };

    const goBack = () => {
        handleGoBack(customerToAssign);
    };

    useEffect(() => {
        setCustomerToAssign(fetchedCustomerInfo);
    }, [fetchedCustomerInfo]);

    useEffect(() => {
        if (!fetchedCustomerInfo) {
            getCustomerByUserUUID(owner.uuid)
                .then(async ({ customer }) => {
                    setFetchedCustomerInfo(customer);
                })
                .catch(() => {
                    addMessage('Failed to fetch customer info', SnackbarMessageVariants.WARNING);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, []);

    return (
        <StepContainer
            isLoading={isLoading}
            title="Assign Stripe customer"
            handleGoBack={goBack}
        >
            <Section isMultiSection>
                <Box paddingTop={{ xs: 1, sm: 0 }}>
                    <TabbedContainer
                        initialTabIndex={fetchedCustomerInfo ? 1 : 0}
                        testId="customer-creation-step-tabbed-container"
                        onTabChange={(label) => {
                            if (label === createNewLabel) {
                                setCustomerToAssign(null);
                                setIsLoading(true);
                            } else {
                                setCustomerToAssign(fetchedCustomerInfo);
                            }
                        }}
                        items={[
                            {
                                tabLabel: createNewLabel,
                                content: (
                                    <CreateStripeCustomerForm
                                        email={initialCustomer?.email || owner.email.email}
                                        name={initialCustomer?.name || owner.fullName}
                                        onConfigLoaded={() => setIsLoading(false)}
                                        onSubmit={handleCreateCustomer}
                                    />
                                ),
                            },
                            {
                                tabLabel: assignExistingLabel,
                                content: (
                                    <RetrieveStripeCustomerForm
                                        hideLabels
                                        setIsSubmitting={setIsLoading}
                                        onCustomerRetrieved={setCustomerToAssign}
                                    />
                                ),
                            },
                        ]}
                    />
                </Box>
                {customerToAssign && (
                    <Box paddingTop={{ xs: 1, sm: 0 }}>
                        <Typography
                            fontSize={14}
                            color={palette.grey}
                            fontFamily={fonts.medium}
                            marginBottom={{ xs: 1, sm: 0.5 }}
                        >
                            Customer
                        </Typography>
                        <CustomerInfo
                            withLink
                            customer={customerToAssign}
                        />
                        <Box paddingTop={{ xs: 3, sm: 6 }}>
                            <Button onClick={handleAssignStripeCustomer} fullWidth>
                                Assign customer
                            </Button>
                        </Box>
                    </Box>
                )}
            </Section>
        </StepContainer>
    );
};

export default StripeCustomerStep;
