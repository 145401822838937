import React, { FC } from 'react';
import { BillingRecordWithRenewal } from 'src/@types/subscription-service-api';
import { finalizeBillingRecordInvoice } from 'src/services/sso-api';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import { BaseModal } from 'src/components/Modals/BaseModal';

export type FinalizeInvoiceModalProps = {
    billingRecord: BillingRecordWithRenewal;
};

const FinalizeInvoiceModal: FC<FinalizeInvoiceModalProps> = ({
    billingRecord,
}) => {
    const { closeModal } = useGeneralModal();
    const { reloadPage } = useReloadPage();
    const { addErrorMessage } = useSnackbarMessage();

    const { subscriptionUUID, id } = billingRecord;

    const handleConfirm = async () => {
        return finalizeBillingRecordInvoice(subscriptionUUID, id)
            .then(() => {
                closeModal();
                reloadPage();
            })
            .catch((error) => {
                addErrorMessage('Failed to finalize invoice', error);
            });
    };

    return (
        <BaseModal
            open
            handleClose={closeModal}
            title="Finalize invoice"
        >
            <ConfirmationModal
                modalId="finalize-invoice-modal"
                onConfirm={handleConfirm}
                onCancel={closeModal}
                subtitle="Are you sure you want to finalize this invoice?"
            />
        </BaseModal>
    );
};

export default FinalizeInvoiceModal;
