import styled from 'styled-components';
import { Typography, Stack } from '@mui/material';

export const SignedText = styled(Typography)`
    && {
        position: absolute;
        top: 0;
        right: 12px;
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 14px;
        color: ${({ theme }) => theme.palette.grey};
    }
`;

export const BillingCycleRow = styled(Stack)`
    && {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & p {
            font-size: 16px;
            font-family: ${({ theme }) => theme.fonts.medium};
            line-height: normal;

            @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
                font-size: 14px
            }
        }
    }
`;
