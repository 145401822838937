import React, { FC } from 'react';
import { Box } from '@mui/material';
import { FormWrapper } from 'src/components/Forms/components';
import { emailValidator, validateSchemaObject } from 'src/services/validators';
import { useIsSubmitDisabled } from 'src/hooks';
import { LabeledInput } from 'src/components/Inputs';
import { Button } from 'src/components/Buttons';

type SearchUserFormProps = {
    onSubmit: (values: { email: string }) => void;
    initialValues: { email: string };
    isDisable: boolean;
};

const SearchUserForm: FC<SearchUserFormProps> = ({ onSubmit, initialValues, isDisable }) => {
    const { onFormChangeHandler, isSubmitDisabled } = useIsSubmitDisabled(initialValues);

    return (
        <FormWrapper
            id="email-search-form"
            testId="email-search-form"
            onFormChange={onFormChangeHandler}
            validationSchema={validateSchemaObject({ email: emailValidator })}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: 3, md: 2 }}>
                <LabeledInput
                    disallowSpace
                    flexGrow={1}
                    placeholder="Enter email address"
                    name="email"
                    label=""
                    testId="email-input"
                    errorPosition="relative"
                />
                <Box>
                    <Button
                        fullWidth
                        testId="search-button"
                        type="submit"
                        minSize="small"
                        disabled={isSubmitDisabled && isDisable}
                    >
                        Find
                    </Button>
                </Box>
            </Box>
        </FormWrapper>
    );
};

export default SearchUserForm;
