import styled from 'styled-components';
import { Grid, Typography } from '@mui/material';
import { Button } from 'src/components/Buttons';

export const FooterContainer = styled(Grid)`
    border-top: 1px solid ${({ theme }) => theme.palette.mainBorderColor};
    background-color: ${({ theme }) => theme.palette.white};
    box-shadow: 0 10px 30px 0 rgba(136, 149, 172, 0.10);
    box-sizing: border-box;
    position: fixed;
    height: 82px;
    width: 100%;
    z-index: 1;
    bottom: 0;
    left: 0;
`;

export const StyledFooterTitle = styled(Typography)`
    && {
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.black};
        line-height: normal;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        padding-right: 16px;
        
        svg {
            margin-right: 16px;
            margin-bottom: -4px;
            
            path {
                fill: ${({ theme }) => theme.palette.grey};
            }
        }
    }
`;

export const IconContainer = styled(Typography)`
    &&&& {
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 0;
        margin: 0;
        
        svg {
            fill: none;
            path {
                stroke: ${({ theme }) => theme.palette.blue};
            }
        }
    }
`;

export const StyledButton = styled(Button)`
    &&& {
        height: 50px;
    }
`;
