import React, { FC, Fragment } from 'react';
import { Grid } from '@mui/material';
import { getPaymentFields } from './services/getters';
import { TextButton } from 'src/components/Buttons';
import { useModalWithAnchor } from 'src/hooks';
import { SectionItem } from 'src/components/SectionItem';
import { ManageActionType } from 'src/components/ManageActions/ManageActions';
import { MenuVariant } from 'src/components/Menu/Menu';
import { Menu } from 'src/components/Menu';
import { SubscriptionPaymentMethodDetails } from 'src/@types/sso-api';
import Line from 'src/components/Line';
import { GeneralModalKey, SubscriptionStatus, TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { NoContentMessage } from 'src/components/TableSection/TableSection.styles';
import { Section } from 'src/components/Section';

type PaymentSectionProps = {
    isLoading: boolean;
    status?: SubscriptionStatus;
    paymentMethod?: SubscriptionPaymentMethodDetails;
    actionsList?: Record<string, ManageActionType>;
    showModalLinkOnEmpty?: boolean;
};

const PaymentMethodSection: FC<PaymentSectionProps> = ({
    paymentMethod,
    isLoading,
    actionsList = {},
    status,
    showModalLinkOnEmpty,
}) => {
    const hasActions = actionsList && Object.keys(actionsList).length > 0;
    const cardDetails = paymentMethod?.card;
    const billingDetails = paymentMethod?.billingDetails;
    const isActive = status === SubscriptionStatus.ACTIVE;
    const paymentFields = getPaymentFields(cardDetails, billingDetails);
    const allowedActions = Object.keys(actionsList);
    const showAddPaymentMethodModal = allowedActions.includes(GeneralModalKey.addPaymentMethodModal);

    const modalPropsGetters = (action: string) => {
        return {
            key: action as GeneralModalKey,
            extraProps: actionsList?.[action].actionExtraProps || {},
        };
    };

    const { openModal } = useModalWithAnchor(allowedActions, !isLoading, modalPropsGetters);

    const actionHandler = (action: string) => {
        openModal(action as GeneralModalKey);
    };

    return (
        <Section
            data-testid="payment-method-section"
            sectionPaddingBottom={3}
            header="Payment method"
            headerChildren={
                hasActions && isActive && (
                    <Menu
                        buttonVariant={MenuVariant.THREE_DOTS_TEXT_BUTTON}
                        tooltipMaxWidth="unset"
                        menuItems={Object.entries(actionsList)
                            .map(([value, { label, disabled, tooltipTitle }]) => (
                                { value, label, disabled, tooltipTitle }
                            ))}
                        onClick={actionHandler}
                    />
                )
            }
        >
            {isLoading && <LoaderOverlay />}
            {!!cardDetails ? (
                <Grid container columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                    {paymentFields.map((field, index) => (
                        <Fragment key={field.title}>
                            {index !== 0 && (
                                <Grid item display={{ xs: 'block', md: 'none' }} paddingY={2} xs={12}>
                                    <Line />
                                </Grid>
                            )}
                            <SectionItem
                                title={field.title}
                                value={field.value}
                                gridProps={field.gridProps}
                            />
                        </Fragment>
                    ))}
                </Grid>
            ) : (
                <NoContentMessage marginY={1.5} data-testid="no-payment-method-message">
                    There is no payment method associated with this subscription.&nbsp;
                    {showModalLinkOnEmpty && (
                        <Fragment>
                            <TextButton
                                data-testid="no-payment-method-button"
                                onClick={() => openModal(
                                    showAddPaymentMethodModal
                                        ? GeneralModalKey.addPaymentMethodModal
                                        : GeneralModalKey.changePaymentMethodModal,
                                )}
                            >
                                {showAddPaymentMethodModal ? 'Add' : 'Assign'} payment method
                            </TextButton>
                            &nbsp;now.
                        </Fragment>
                    )}
                </NoContentMessage>
            )}
        </Section>
    );
};

export default PaymentMethodSection;
