import React, { FC } from 'react';
import { Grid, GridProps } from '@mui/material';
import { StyledInfo, StyledStack } from './SectionItem.styles';
import { CopyIcon } from 'src/components/CopyIcon';
import { GRID_DEFAULT_TOOLTIP_PROPS } from 'src/constants';
import StyledTooltip from 'src/components/Tooltip';
import { StyledHeaderName } from 'src/components/TableSection/HeaderSection/HeaderSection.styles';
import { useOverflowTooltip } from 'src/hooks/useOverflowTooltip';

type SectionItemProps = {
    canCopy?: boolean;
    title?: string;
    value: string | React.ReactNode;
    gridProps: GridProps;
};

const SectionItem: FC<SectionItemProps> = ({
    canCopy,
    title,
    value,
    gridProps,
}) => {
    const strValue = typeof value === 'string' ? value : '';
    const { ref, tooltipTitle } = useOverflowTooltip(strValue);

    return (
        <Grid item {...gridProps} paddingRight={{ xs: 0, sm: 2 }}>
            <StyledHeaderName>{title}</StyledHeaderName>
            <StyledStack direction="row" gap={1}>
                <StyledTooltip {...GRID_DEFAULT_TOOLTIP_PROPS} title={tooltipTitle}>
                    <StyledInfo ref={ref}>{value}</StyledInfo>
                </StyledTooltip>
                {canCopy && typeof value === 'string' && (
                    <CopyIcon content={value} hiddenIcon />
                )}
            </StyledStack>
        </Grid>
    );
};

export default SectionItem;
