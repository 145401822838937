import React, { FC, ReactNode, useState } from 'react';
import { FormControl, SelectProps as MuiSelectProps } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AddButton, StyledInputPlaceholder } from './OwnerSelect.styles';
import { Option } from './components';
import LazyLoadSelectMenuItem from 'src/components/Select/LazyLoadSelect/components/LazyLoadSelectMenuItem';
import LazyLoadSelect from 'src/components/Select/LazyLoadSelect/LazyLoadSelect';
import { User } from 'src/@types/unified-db-api';

export type OptionsType = {
    value: string;
    label: string;
    disabled?: boolean;
};

type OwnerSelectProps = MuiSelectProps & {
    handleInputChange?: (value: string) => void;
    testId?: string;
    managers: Omit<User, 'jobInfo'>[] | null;
    title?: string;
    owner: Omit<User, 'jobInfo'> | null;
    addManagerHandler: () => void;
    loadNextPage?: () => void;
    isLoadingNextPage?: boolean;
};

const OwnerSelect: FC<OwnerSelectProps> = ({
    testId,
    managers,
    handleInputChange,
    title,
    owner,
    addManagerHandler,
    loadNextPage,
    isLoadingNextPage,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const options = (visible = false): ReactNode[] => {
        if (!managers) {
            return [];
        }

        return managers.map((manager) => {
            return (
                <LazyLoadSelectMenuItem
                    disabled={manager.uuid === owner?.uuid}
                    testId={visible ? `select-${testId}-${manager.uuid}` : `select-${testId}-${manager.uuid}-hidden`}
                    key={manager.uuid}
                    value={manager.uuid}
                    onClick={() => handleInputChange?.(manager.uuid)}
                    visible={visible}
                >
                    <Option
                        manager={manager}
                        isOpen={isOpen}
                        owner={owner}
                    />
                </LazyLoadSelectMenuItem>
            );
        });
    };

    return (
        <FormControl fullWidth>
            {!props.value && <StyledInputPlaceholder id={`select-${testId}-placeholder`}>{props.placeholder}</StyledInputPlaceholder>}
            <LazyLoadSelect
                {...props}
                footer={(
                    <AddButton
                        data-testid="add-button"
                        onClick={addManagerHandler}
                    >
                        <AddIcon />
                        Add manager
                    </AddButton>
                )}
                testId={testId}
                options={options}
                loadNextPage={loadNextPage}
                isLoadingNextPage={isLoadingNextPage}
                title={title}
                handleInputChange={handleInputChange}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
            />
        </FormControl>
    );
};

export default OwnerSelect;
