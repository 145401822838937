import React, { FC, Fragment } from 'react';
import { Grid } from '@mui/material';
import { getBillingAddressFields, getNoContentMessage } from './services/getters';
import { useModalWithAnchor } from 'src/hooks';
import { Menu } from 'src/components/Menu';
import { MenuVariant } from 'src/components/Menu/Menu';
import { SectionItem } from 'src/components/SectionItem';
import { CustomerInfo } from 'src/@types/sso-api';
import { NoContentMessage } from 'src/components/TableSection/TableSection.styles';
import Line from 'src/components/Line';
import { actionModalKeyMap, GeneralModalKey, SubscriptionStatus, TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { Section } from 'src/components/Section';

type BillingAddressSectionProps = {
    isLoading?: boolean;
    isUdb?: boolean;
    customerInfo: CustomerInfo;
    status: SubscriptionStatus;
    uuid: string;
};

const BillingAddressSection: FC<BillingAddressSectionProps> = ({
    uuid,
    isUdb,
    status,
    isLoading,
    customerInfo,
}) => {
    const actionsList = {
        [GeneralModalKey.billingAddressModal]: {
            label: `${customerInfo.customer?.address ? 'Update' : 'Add'} billing address`,
        },
    };

    const billingAddressFields = getBillingAddressFields(customerInfo);
    const hasActions = Object.keys(actionsList).length > 0
        && !!customerInfo.customer
        && ![SubscriptionStatus.SERVICE_ENDED, SubscriptionStatus.TERMINATED].includes(status);

    const modalPropsGetters = (selectedAction: string) => {
        const modalKey = actionModalKeyMap[selectedAction as keyof typeof actionModalKeyMap];
        return {
            key: modalKey,
            extraProps: {
                isUdb,
                customerInfo: customerInfo.customer,
            },
        };
    };

    const { openModal } = useModalWithAnchor(Object.keys(actionsList), !isLoading, modalPropsGetters);

    const actionHandler = (selectedAction: string) => {
        openModal(selectedAction as GeneralModalKey);
    };

    return (
        <Section
            sectionPaddingBottom={3}
            header="Billing address"
            data-testid="billing-address-section"
            headerChildren={
                hasActions && (
                    <Menu
                        buttonVariant={MenuVariant.THREE_DOTS_TEXT_BUTTON}
                        tooltipMaxWidth="unset"
                        menuItems={Object.entries(actionsList)
                            .map(([value, { label }]) => (
                                { value, label }
                            ))}
                        onClick={actionHandler}
                    />
                )
            }
        >
            {isLoading && <LoaderOverlay />}
            {customerInfo.customer?.address ? (
                <Grid container columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                    {billingAddressFields.map((field, index) => (
                        <Fragment key={field.title}>
                            {index !== 0 && (
                                <Grid item display={{ xs: 'block', md: index === 2 ? 'block' : 'none' }} paddingY={2} xs={12}>
                                    <Line />
                                </Grid>
                            )}
                            <SectionItem
                                title={field.title}
                                value={field.value}
                                gridProps={field.gridProps}
                            />
                        </Fragment>
                    ))}
                </Grid>
            ) : (
                <NoContentMessage marginBottom={{ xs: 0, sm: 1.5 }} marginTop={{ xs: 1, sm: 1.5 }}>
                    {getNoContentMessage(status, uuid, isUdb, customerInfo)}
                </NoContentMessage>
            )}
        </Section>
    );
};

export default BillingAddressSection;
