import styled from 'styled-components';
import { Box } from '@mui/material';
import { SnackbarMessageVariants } from 'src/constants';
import { theme as mainTheme } from 'src/theme';

const backgroundSelector = (props: {
    'data-type'?: SnackbarMessageVariants;
    theme: typeof mainTheme;
}) => {
    switch (props['data-type']) {
        case SnackbarMessageVariants.ERROR:
            return props.theme.palette.snackbar.errorBackground;
        default:
            return props.theme.palette.snackbar.infoBackground;
    }
};

export const InfoBannerContainer = styled(Box)`
    && {
        align-items: center;
        display: flex;
        background-color: ${backgroundSelector};
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 15px;
        border-radius: 4px;
        text-wrap: auto;
    }
`;
