import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import { ReactComponent as TriangleDownIcon } from 'src/assets/icons/triangle-down-icon.svg';

export const StyledMenu = styled(Menu)`
    && {
         color: ${({ theme }) => theme.palette.white};
        
        .MuiPaper-root.MuiPopover-paper.MuiMenu-paper{
            margin-top: 8px;
            padding: 8px 0;
            box-shadow: 0 10px 30px 0 rgba(136, 149, 172, 0.10);
            border-radius: 4px;
            
            .MuiMenu-list{
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
`;

export const StyledMenuItem = styled(MenuItem)`
    && {
        padding: 0;
        box-shadow: unset;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 42px;

        &:hover {
            background-color: unset;
        }
    }
`;

export const MenuItemWrapper = styled(Stack)`
    && {
        padding: 0 16px;
        align-items: center;
        justify-content: space-between;
        
        &:hover {
            background-color: ${({ theme }) => theme.palette.mainBackgroundColor};
        }
    }
`;

export const StyledButton = styled(Button)`
    && {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid ${({ theme }) => theme.palette.mainBorderColor};
        gap: 10px;
        min-height: 30px;
        padding: 8px 16px;
        border-radius: 4px;
        box-shadow: 0 10px 30px 0 rgba(136, 149, 172, 0.10);
        text-transform: unset;
        text-align: center;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.demiBold};
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        background-color: ${({ theme }) => theme.palette.white};

        &:hover {
            box-shadow: 0 10px 30px 0 rgba(136, 149, 172, 0.10);
            background-color: ${({ theme }) => theme.palette.white};
        }

        &:focus-visible {
            outline: 2px solid ${({ theme }) => theme.palette.blue};
        }
        
        .MuiButton-endIcon{
            margin: 0;
        }

        @media(max-width: ${({ theme }) => theme.breakpoints.sm}) {
            gap: 8px;
            padding: 0 8px;
            font-size: 14px;

            .MuiButton-endIcon{
                font-size: 16px;
            }
        }

        @media(max-width: ${({ theme }) => theme.breakpoints.sm}){
            border: unset;
            font-size: 14px;
            padding: 6px 8px;
            gap: 8px;
        }
    }
`;

export const DropdownIcon = styled(TriangleDownIcon)`
    transform: rotate(${({ rotate }) => rotate});
    transition: transform 0.3s;
`;
