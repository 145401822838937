import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SubscriptionsPage } from './Subscriptions';
import { UdbSubscriptionManage } from './UdbSubscriptionManage';
import { ManageUserPage } from './ManageUser';
import { CreateSubscriptionPage } from './CreateSubscription';
import { UserQuickSearch } from './UserQuickSearch';
import { EnterpriseCalculatorPage } from 'src/pages/udb/EnterpriseCalculator';
import { NotFoundPage } from 'src/pages/NotFound';
import { wrapPageWithHandler } from 'src/components/PageHandler';
import { PAGE_TITLES } from 'src/constants';
import { UsersPage } from 'src/pages/udb/Users';

const UdbRouter = () => {
    return (
        <Routes>
            <Route path="/subscriptions" element={wrapPageWithHandler(<SubscriptionsPage />, PAGE_TITLES.SUBSCRIPTIONS)} />
            <Route path="/subscriptions/:uuid" element={wrapPageWithHandler(<UdbSubscriptionManage />, PAGE_TITLES.MANAGE_SUBSCRIPTIONS)} />
            <Route path="/subscriptions/create" element={wrapPageWithHandler(<CreateSubscriptionPage />, PAGE_TITLES.CREATE_SUBSCRIPTION)} />
            <Route path="/subscriptions/calculator/enterprise" element={wrapPageWithHandler(<EnterpriseCalculatorPage />, PAGE_TITLES.ENTERPRISE_CALCULATOR)} />
            <Route path="/users" element={wrapPageWithHandler(<UsersPage />, PAGE_TITLES.USERS)} />
            <Route path="/users/:uuid" element={wrapPageWithHandler(<ManageUserPage />, PAGE_TITLES.MANAGE_USER)} />
            <Route path="/users/quick-search" element={wrapPageWithHandler(<UserQuickSearch />, PAGE_TITLES.USER_QUICK_SEARCH)} />
            <Route path="/*" element={wrapPageWithHandler(<NotFoundPage />, PAGE_TITLES.NOT_FOUND)} />
        </Routes>
    );
};

export default UdbRouter;
