import styled, { css } from 'styled-components';
import { TextField } from '@mui/material';

const readOnlySelector = (props: { 'data-readonly': boolean }) => (
    props['data-readonly'] ? (
        css`
            border: 1px solid ${({ theme }) => theme.palette.dirtyWhite};
        `
    ) : (
        css`
            border: 1px solid ${({ theme }) => theme.palette.blue};
        `
    )
);

export const StyledCodeInput = styled(TextField)`
    && .MuiInputBase-root {
        width: 100%;
        height: ${({ theme }) => theme.sizes.inputHeight.xs};

        input {
            font-family: ${({ theme }) => theme.fonts.bold};
            color: ${({ theme }) => theme.palette.black};
            text-align: center;
            font-size: 18px;
            height: 100%;
            padding: 0;

            &:read-only {
                cursor: default;
            }
        }

        fieldset {
            border-radius: 4px;
            border: solid 1px ${({ theme }) => theme.palette.dirtyWhite};
        }

        &.Mui-focused {
            fieldset {
                outline: none;

                ${readOnlySelector}
            }
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            height: ${({ theme }) => theme.sizes.inputHeight.default};

            input {
                font-size: 24px;
            }
        }
    }
`;
