import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { BaseModal } from './BaseModal';
import { getMappedModalProps } from './ModalMap';
import { useGeneralModal } from 'src/hooks';
import { generalModalSelector } from 'src/redux/slices';
import type { GeneralModalState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';

export const GeneralModal = () => {
    const { closeModal } = useGeneralModal();

    const { key, title, closeIsBlocked, extraProps, step }
        = useSelector<RootState, GeneralModalState>(generalModalSelector);

    const location = useLocation();
    const navigate = useNavigate();

    if (!key) {
        return null;
    }

    const { content, modalProps = {} } = getMappedModalProps(key, extraProps);
    const { paddingX, paddingBottom, title: defaultTitle, contentPaddingTop, showCloseAndGoBack } = modalProps;

    const handleClose = () => {
        if (closeIsBlocked) {
            return;
        }

        closeModal();
        navigate({ ...location, hash: '' });
    };

    return (
        <BaseModal
            open={!!key}
            title={title ?? defaultTitle}
            showCloseAndGoBack={step !== 0 && showCloseAndGoBack}
            paddingX={paddingX}
            paddingBottom={paddingBottom}
            contentPaddingTop={contentPaddingTop}
            handleClose={handleClose}
            dataTestId="general-modal"
        >
            {content}
        </BaseModal>
    );
};
