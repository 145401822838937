import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Grid, GridProps, Stack } from '@mui/material';
import { TableSectionItem } from 'src/components/TableSection';
import { TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { ReactComponent as RedCrossIcon } from 'src/assets/icons/red-cross-icon.svg';
import StyledTooltip from 'src/components/Tooltip';
import { Link } from 'src/components/Link';
import { RoundedBadge } from 'src/components/Badge';
import { ActionButton, ActionButtonVariant } from 'src/components/Buttons/ActionButton';
import { StyledUserIcon } from 'src/components/ManagerSection/ManagerItem.styles';
import { SubscriptionUserModel } from 'src/@types/subscription-service-api';

type UserItemProps = {
    rowProps: Record<string, GridProps>;
    user: SubscriptionUserModel;
    hasUserLink?: boolean;
};

const UserItem: FC<UserItemProps> = ({
    rowProps,
    user,
    hasUserLink,
}) => {
    const [itemWidth, setItemWidth] = useState<number>(0);
    const [parentWidth, setParentWidth] = useState<number>(0);

    const itemRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (itemRef.current) {
            setItemWidth(itemRef.current.offsetWidth);
            setParentWidth(itemRef.current.parentElement!.offsetWidth);
        }
    }, [itemRef]);

    const {
        fullName,
        email,
        companyName,
        jobTitle,
        userUUID,
        isOwner,
        isManager,
    } = user;

    return (
        <Stack ref={itemRef} position="relative" direction="row" alignItems="center" width={`calc(100% + ${parentWidth - itemWidth}px)`}>
            <Grid container alignItems="center" columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                <TableSectionItem
                    value={fullName}
                    canCopy
                    gridProps={rowProps.name}
                    iconElement={
                        hasUserLink && (
                            <Link display="flex" target="_blank" href={`/udb/users/${userUUID}`}>
                                <StyledTooltip arrow title="Open user page">
                                    <StyledUserIcon />
                                </StyledTooltip>
                            </Link>
                        )
                    }
                    badgeElement={(isOwner || isManager) && <RoundedBadge label={isOwner ? 'Owner' : 'Manager'} />}
                />
                <TableSectionItem
                    value={email}
                    canCopy
                    gridProps={rowProps.email}
                    variant="grey"
                />
                <TableSectionItem
                    value={companyName}
                    canCopy={!!companyName}
                    gridProps={rowProps.companyName}
                />
                <TableSectionItem
                    value={jobTitle}
                    canCopy={!!jobTitle}
                    gridProps={rowProps.jobTitle}
                />
                <TableSectionItem
                    value={`${companyName}${companyName && jobTitle ? ', ' : ''}${jobTitle}`}
                    gridProps={rowProps.jobInfo}
                    variant="lightGrey"
                />
            </Grid>
            <Box position="absolute" right={0} display="none">
                <ActionButton
                    data-attribute-to-change="stroke"
                    data-variant={ActionButtonVariant.RED}
                    data-testid="remove-icon"
                    onClick={() => {}}
                >
                    <RedCrossIcon />
                </ActionButton>
            </Box>
        </Stack>
    );
};

export default UserItem;
