import React, { Dispatch, FC, Fragment, SetStateAction, useEffect } from 'react';
import { StyledStack } from './EnterprisePricingTerms.styles';
import { CreateSubscriptionInvoiceData } from 'src/pages/udb/CreateSubscription/CreateSubscription';
import { CreateInvoiceSetup } from 'src/components/CreateInvoiceSetup';
import { PriceInput } from 'src/components/Inputs';
import { formatPrice } from 'src/services/formatters';
import { SubscriptionBillingType } from 'src/constants';
import PriceCalculator from 'src/pages/udb/EnterpriseCalculator/PriceCalculator';
import { CalculatePriceResponse, DomainModel } from 'src/@types/subscription-service-api';
import { AddDomainForm } from 'src/components/Forms';

export type EnterprisePricingTermsData = {
    domains: DomainModel[];
    priceData: CalculatePriceResponse | null;
    finalPriceInCents: number;
};

type EnterprisePricingTermsProps = {
    selectedBillingType: SubscriptionBillingType;
    initialData?: EnterprisePricingTermsData;
    setEnterpriseData: (data: EnterprisePricingTermsData) => void;
    invoiceData: CreateSubscriptionInvoiceData;
    setInvoiceData: Dispatch<SetStateAction<CreateSubscriptionInvoiceData>>;
    onInvoiceSetupSubmit: (data: CreateSubscriptionInvoiceData) => void;
};

const getFieldToRender = (setPrice: (newPrice: number) => void, finalPriceInCents: number) =>
    (price: CalculatePriceResponse | null, userCount: number) => [
        {
            key: 'pricePerUser',
            label: `Suggested price for ${userCount} users:`,
            value: formatPrice(price?.priceInCents ?? 0),
        },
        {
            key: `${finalPriceInCents}`,
            label: 'Final price:',
            value: (
                <StyledStack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="150px"
                    paddingY={1.5}
                >
                    <PriceInput
                        initialPrice={finalPriceInCents}
                        handleChange={(newPrice: number) => {
                            setPrice(newPrice);
                        }}
                        currency="$"
                    />
                </StyledStack>
            ),
        },
    ];

const EnterprisePricingTerms: FC<EnterprisePricingTermsProps> = ({
    selectedBillingType,
    setEnterpriseData,
    initialData = {
        domains: [],
        finalPriceInCents: 0,
        priceData: null,
    },
    invoiceData,
    setInvoiceData,
    onInvoiceSetupSubmit,
}) => {
    const { domains, priceData, finalPriceInCents } = initialData;
    const totalUserCount = domains.reduce((total, { userCount }) => total + userCount, 0);
    const domainsStr = domains.reduce((acc, { domain }) => `${acc}${domain}`, '');

    const setData = (
        key: keyof EnterprisePricingTermsData,
    ) => (
        value: EnterprisePricingTermsData[keyof EnterprisePricingTermsData],
    ) => {
        setEnterpriseData({ ...initialData, [key]: value });
    };

    useEffect(() => {
        if (priceData) {
            setData('finalPriceInCents')(priceData.priceInCents);
        }
    }, [priceData]);

    return (
        <Fragment>
            <AddDomainForm
                testId="enterprise-pricing-terms-add-domains"
                domains={domains}
                setDomains={setData('domains')}
                totalUserCount={totalUserCount}
            />
            {selectedBillingType === SubscriptionBillingType.INVOICED && domains.length > 0 && (
                <PriceCalculator
                    getFieldToRender={getFieldToRender(setData('finalPriceInCents'), finalPriceInCents)}
                    hash={domainsStr}
                    initialUserCount={totalUserCount}
                    initialPrice={priceData}
                    onPriceChange={setData('priceData')}
                >
                    <CreateInvoiceSetup
                        paddingTop={2}
                        invoiceData={invoiceData}
                        setInvoiceData={setInvoiceData}
                        onSubmit={onInvoiceSetupSubmit}
                    />
                </PriceCalculator>
            )}
        </Fragment>
    );
};

export default EnterprisePricingTerms;
