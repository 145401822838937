import { Typography } from '@mui/material';
import styled from 'styled-components';
import { TextButton } from 'src/components/Buttons';

export const StyledEmail = styled(Typography)`
  && {
    display: inline;
    color: ${({ theme }) => theme.palette.blue};
    font-family: inherit;
    font-size: inherit;
    word-break: break-word;
    line-height: normal;
  }
`;

export const StyledTextButton = styled(TextButton)`
  &&& {
    vertical-align: inherit;
      
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
       font-family: ${({ theme }) => theme.fonts.demiBold};
    }  
  }
`;
