import React, { FC, Fragment } from 'react';
import { Box } from '@mui/material';
import {
    StyledCaption,
    StyledMessage,
    StyledTextButton,
    StyledUserEmail,
    StyledUserName,
} from './UpgradeYourAccountForm.styles';
import { useOverflowTooltip } from 'src/hooks/useOverflowTooltip';
import { FramedBox } from 'src/components/Containers';
import { showIntercom } from 'src/services/intercom';
import { Button, TextButton } from 'src/components/Buttons';
import { SubscriberType } from 'src/constants';
import PageTitle from 'src/components/PageTitle';
import { SingleModalForm } from 'src/components/Forms/components';
import StyledTooltip from 'src/components/Tooltip';

export type AccountToUpgrade = {
    email: string;
    uuid: string;
    fullName: string;
    subscriberType: SubscriberType;
};

type UpgradeYourAccountFormProps = {
    handleSubmit: (accountToUpgrade: AccountToUpgrade) => void;
    accountToUpgrade: AccountToUpgrade;
    handleUpgradeAnotherAccount: () => void;
};

const UpgradeAccountForm: FC<UpgradeYourAccountFormProps> = ({
    handleSubmit,
    accountToUpgrade,
    handleUpgradeAnotherAccount,
}) => {
    const isBasicSubscriber = accountToUpgrade?.subscriberType === SubscriberType.basic;
    const isSubscriberEnterprise = accountToUpgrade?.subscriberType === SubscriberType.enterprise;

    const { ref, tooltipTitle } = useOverflowTooltip(accountToUpgrade.fullName);

    return (
        <SingleModalForm
            initialValues={accountToUpgrade}
            onSubmit={handleSubmit}
            testId="upgrade-account-form"
        >
            <PageTitle
                variant="form"
                testId="form-title"
                title={isBasicSubscriber ? 'Let’s get started' : 'Upgrade to Insider'}
                subtitle={
                    `Confirm the email address you’d like to use${isBasicSubscriber
                        ? ', or purchase a subscription for another account.'
                        : ' for your Insider account.'
                    }`
                }
            />
            <Box>
                <FramedBox padding={2}>
                    <StyledTooltip title={tooltipTitle} arrow>
                        <StyledUserName ref={ref}>
                            {accountToUpgrade.fullName}
                        </StyledUserName>
                    </StyledTooltip>
                    <StyledUserEmail paddingTop={0.5}>
                        {accountToUpgrade.email}
                    </StyledUserEmail>
                </FramedBox>
            </Box>
            <Box paddingY={{ xs: 0.5, sm: 0 }}>
                {!isBasicSubscriber ? (
                    <Fragment>
                        <StyledMessage padding={{ xs: 1.5, sm: 2 }}>
                        Great news. You’re already a premium&nbsp;
                        subscriber{
                                isSubscriberEnterprise
                                    ? ' with unlimited access through your company’s license.'
                                    : '. Want to get a subscription for someone else?'
                            }
                        </StyledMessage>
                        <StyledCaption paddingTop={{ xs: 2.5, sm: 3 }}>
                            Continue to purchase a subscription&nbsp;
                            {isSubscriberEnterprise
                                ? 'with a different email address '
                                : 'for someone else'
                            }
                            or <TextButton onClick={showIntercom}>contact us</TextButton>&nbsp;
                            if you need help.
                        </StyledCaption>
                    </Fragment>
                ) : (
                    <StyledTextButton data-testid="upgrade-another-account" onClick={handleUpgradeAnotherAccount}>
                        Purchase a subscription for another account
                    </StyledTextButton>
                )}
            </Box>
            <Button
                type="submit"
                testId="submit-button"
            >
                {isBasicSubscriber ? 'Continue to checkout' : 'Purchase for someone else'}
            </Button>
        </SingleModalForm>
    );
};

export default UpgradeAccountForm;
