import React from 'react';
import { Stack, StackProps } from '@mui/material';
import WalletIcon, { WalletTextMap } from './WalletIcon';
import CardIcon, { CardTextMap } from './CardIcon';
import { StyledCardInfo, StyledSecondaryInfo } from './CardType.styles';

type CreditCardProps = {
    showWalletName?: boolean;
    shouldHideBrandIcon?: boolean;
    brand?: string | null;
    wallet?: string | null;
    additionalCardInfo?: string;
    secondaryInfo?: string;
    iconsGap?: StackProps['gap'];
};

const CardType: React.FC<CreditCardProps> = ({
    brand,
    wallet,
    shouldHideBrandIcon,
    additionalCardInfo,
    secondaryInfo,
    iconsGap = 1,
    showWalletName,
}) => {
    return (
        <Stack direction="row" alignItems="center" gap={iconsGap}>
            <Stack direction="row" alignItems="center" gap={1}>
                {wallet && <WalletIcon wallet={wallet} />}
                {brand && !shouldHideBrandIcon && <CardIcon brand={brand} />}
            </Stack>
            <Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                    {wallet && showWalletName && <StyledCardInfo>{WalletTextMap[wallet]}</StyledCardInfo>}
                    {wallet && brand && showWalletName && <StyledCardInfo>|</StyledCardInfo>}
                    {brand && <StyledCardInfo>{CardTextMap[brand]}</StyledCardInfo>}
                    {additionalCardInfo && <StyledCardInfo>{additionalCardInfo}</StyledCardInfo>}
                </Stack>
                {secondaryInfo && <StyledSecondaryInfo>{secondaryInfo}</StyledSecondaryInfo>}
            </Stack>
        </Stack>
    );
};

export default CardType;
