import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledUpcomingRenewalModalMainTitle = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.bold};
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 18px;
        }
    }
`;

export const StyledUpcomingRenewalModalSubTitle = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 14px;
        }
    }
`;
