import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { PricingTermsData } from 'src/components/PricingTerm/PricingTerms';
import { useInsiderRenewalPrice } from 'src/hooks/useInsiderRenewalPrice';
import { formatDiscount, formatPrice } from 'src/services/formatters';
import { BaseModal } from 'src/components/Modals/BaseModal';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import {
    CustomAttributes,
    CustomPricingTermsModel,
    CustomPricingTermsProps,
} from 'src/@types/subscription-service-api';
import {
    createCustomPricingTerms,
    deleteCustomPricingTerms,
    getCustomPricingTerms,
    updateCustomPricingTerms,
} from 'src/services/subscription-service-api';
import { Button } from 'src/components/Buttons';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { DEFAULT_SECTION_PADDING_X } from 'src/components/Section/Section';
import Line from 'src/components/Line';
import { SnackbarMessageVariants, SubscriptionStatus } from 'src/constants';
import { PricingTerms } from 'src/components/PricingTerm';

export type AdjustPricingTermsProps = {
    subscriptionUUID: string;
    customAttributes?: CustomAttributes;
    status: SubscriptionStatus;
    initialPricingTerms?: CustomPricingTermsModel;
};

export const getDiscountConfirmation = (
    { discountDurationInCycles, discountType, discountValue }: Omit<CustomPricingTermsProps, 'fixedPriceInCents'>,
    status: SubscriptionStatus,
): ReactElement => {
    const discountToRender = <b>{formatDiscount(discountValue, discountType)}</b>;
    const commonMessage = <>will have a discount of {discountToRender} applied to the standard rate.</>;
    const renewalPricingPolicy = 'Subsequent renewals will be priced at the standard rate applicable at the moment of renewal.';
    const commonBeginningMessage = 'Initial price for this subscription';

    if (discountDurationInCycles === 1) {
        return <>{commonBeginningMessage} {commonMessage} {renewalPricingPolicy}</>;
    }

    if (status === SubscriptionStatus.DRAFT) {
        if (discountDurationInCycles) {
            return (
                <>
                    {commonBeginningMessage} along with&nbsp;
                    <b>{discountDurationInCycles - 1}</b> renewals {commonMessage} {renewalPricingPolicy}
                </>
            );
        }

        return <>{commonBeginningMessage} and all subsequent renewals {commonMessage}</>;
    }

    return discountDurationInCycles
        ? (
            <>
                First <b>{discountDurationInCycles - 1}</b> renewals
                of this subscription {commonMessage} {renewalPricingPolicy}
            </>
        ) : <>All subsequent renewals of this subscription {commonMessage}</>;
};

export const getConfirmationMessage = (
    { fixedPriceInCents, ...discountData }: CustomPricingTermsProps,
    status: SubscriptionStatus,
): string | ReactElement => {
    if (fixedPriceInCents) {
        return <>This subscription will always renew at a fixed price of <b>{formatPrice(fixedPriceInCents)}</b></>;
    }

    if (discountData.discountValue === null) {
        return 'This subscription will renew at the standard rate applicable at the moment of renewal.';
    }

    return getDiscountConfirmation(discountData, status);
};

export const getConfirmAction = (
    modifiedPricingTerms: CustomPricingTermsProps,
    initialPricingTerms: CustomPricingTermsProps | undefined,
): (subscriptionUUID: string, data: CustomPricingTermsProps) => Promise<CustomPricingTermsModel> | Promise<void> => {
    if (modifiedPricingTerms.discountValue === null) {
        return initialPricingTerms ? deleteCustomPricingTerms : () => Promise.resolve();
    }

    return initialPricingTerms ? updateCustomPricingTerms : createCustomPricingTerms;
};

export const isPricingTermsModified = (
    modifiedPricingTerms: CustomPricingTermsProps = {},
    initialPricingTerms: CustomPricingTermsProps = {},
): boolean => {
    if (modifiedPricingTerms.fixedPriceInCents) {
        return !initialPricingTerms || modifiedPricingTerms.fixedPriceInCents !== initialPricingTerms.fixedPriceInCents;
    }

    if (modifiedPricingTerms.discountValue !== null) {
        const wasModified = Number(modifiedPricingTerms.discountValue) !== Number(initialPricingTerms.discountValue)
            || modifiedPricingTerms.discountType !== initialPricingTerms.discountType
            || modifiedPricingTerms.discountDurationInCycles !== initialPricingTerms.discountDurationInCycles;

        const hasAllData = !!modifiedPricingTerms.discountType
        && modifiedPricingTerms.discountDurationInCycles !== undefined
        && !!modifiedPricingTerms.discountValue;

        return wasModified && hasAllData;
    }

    return !!initialPricingTerms.discountValue || !!initialPricingTerms.fixedPriceInCents;
};

const AdjustPricingTerms: FC<AdjustPricingTermsProps> = ({
    subscriptionUUID,
    customAttributes = {},
    status,
    initialPricingTerms,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [confirmation, setConfirmation] = useState<boolean>(false);
    const [customPricingTerms, setCustomPricingTerms] = useState<CustomPricingTermsProps>();
    const [initialCustomPricingTerms, setInitialCustomPricingTerms] = useState<CustomPricingTermsProps>();

    const productPriceInCents = useInsiderRenewalPrice();
    const { cycleLengthInMonths = 12 } = customAttributes;

    const { addMessage, addErrorMessage } = useSnackbarMessage();
    const { closeModal } = useGeneralModal();
    const { reloadPage } = useReloadPage();

    const fetchCustomPricingTerms = async () => (
        getCustomPricingTerms(subscriptionUUID)
            .then((pricingTerms) => {
                const { subscriptionUUID: uuid, fixedPriceInCents, ...discountData } = pricingTerms;
                setCustomPricingTerms(fixedPriceInCents ? { fixedPriceInCents } : discountData);
                setInitialCustomPricingTerms(pricingTerms);
            })
            .catch(() => {
                setCustomPricingTerms({ discountValue: null });
            })
    );

    useEffect(() => {
        if (initialPricingTerms) {
            const { subscriptionUUID: uuid, fixedPriceInCents, ...discountData } = initialPricingTerms;
            setCustomPricingTerms(fixedPriceInCents ? { fixedPriceInCents } : discountData);
            setInitialCustomPricingTerms(initialPricingTerms);
            setIsLoading(false);

            return;
        }

        fetchCustomPricingTerms()
            .finally(() => setIsLoading(false));
    }, []);

    const handleConfirm = (
        { finalPriceInCents, ...pricingTerms }: PricingTermsData,
    ) => async () => {
        return getConfirmAction(pricingTerms, initialCustomPricingTerms)(subscriptionUUID, pricingTerms)
            .then(() => {
                closeModal();
                reloadPage();
                addMessage('Pricing terms updated successfully', SnackbarMessageVariants.SUCCESS);
            })
            .catch((error) => {
                setConfirmation(false);
                addErrorMessage('Failed to update pricing terms', error);
            });
    };

    if (confirmation && customPricingTerms) {
        return (
            <BaseModal
                open
                step={1}
                handleClose={closeModal}
                handleGoBack={() => setConfirmation(false)}
                title="Adjust pricing terms"
            >
                <ConfirmationModal
                    modalId="adjust-pricing-terms"
                    onConfirm={handleConfirm(customPricingTerms)}
                    onCancel={() => setConfirmation(false)}
                    subtitle={getConfirmationMessage(customPricingTerms, status)}
                />
            </BaseModal>
        );
    }

    return (
        <Box data-testid="adjust-pricing-terms-modal">
            <PricingTerms
                discountDurationBanner
                isLoading={isLoading}
                customPricingTerms={customPricingTerms}
                setPricingTermsData={setCustomPricingTerms}
                productPriceInCents={productPriceInCents}
                finalPriceRowTitle="Resulting price"
                billingCycleLength={Number(cycleLengthInMonths)}
                fieldsProps={{
                    discountDuration: {
                        firstCycle: {
                            disabled: status === SubscriptionStatus.ACTIVE,
                        },
                        durationInput: {
                            minValue: status === SubscriptionStatus.ACTIVE ? 2 : 1,
                        },
                    },
                }}
                sectionsMarginTop={0}
                sectionsPaddingBottom={0}
                pricingTermsPaddingBottom={{ xs: 2, sm: 4.5 }}
                discountTypePaddingBottom={{ xs: 3, sm: 4.5 }}
                discountDurationPaddingBottom={{ xs: 2, sm: 2.5 }}
                finalPriceDivider={<Line />}
                discountDivider={(
                    <Box paddingX={DEFAULT_SECTION_PADDING_X}>
                        <Line />
                    </Box>
                )}
            />
            {!isLoading && (
                <Stack paddingX={DEFAULT_SECTION_PADDING_X} direction="row" spacing={2.5} paddingTop={3}>
                    <Button fullWidth variant="secondary" onClick={closeModal} minSize="unset">Cancel</Button>
                    <Button
                        fullWidth
                        minSize="unset"
                        disabled={!isPricingTermsModified(customPricingTerms, initialCustomPricingTerms)}
                        onClick={() => setConfirmation(true)}
                    >
                        Confirm
                    </Button>
                </Stack>
            )}
        </Box>
    );
};

export default AdjustPricingTerms;
