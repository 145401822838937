import styled from 'styled-components';
import Line from 'src/components/Line';

export const ProfileHeaderLine = styled(Line)`
    margin-bottom: 16px;
    display: none;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        display: flex;
    }
`;
