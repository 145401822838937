import React, { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js/pure/index';
import type { Stripe } from '@stripe/stripe-js';
import BillingAddressManager from './BillingAddressManager';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { StripeCustomerInfo } from 'src/@types/sso-api';
import { getCheckoutConfig } from 'src/services/sso-api';
import { StripeElements } from 'src/components/Stripe';
import { DEFAULT_MODAL_MAX_WIDTH } from 'src/components/Modals/BaseModal';
import { SnackbarMessageVariants } from 'src/constants';
import { useSnackbarMessage } from 'src/hooks';

export type BillingAddressModalProps = {
    customerInfo: StripeCustomerInfo;
    isUdb: boolean;
};

const BillingAddressModal: FC<BillingAddressModalProps> = ({
    customerInfo,
    isUdb,
}) => {
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();

    const { addMessage } = useSnackbarMessage();

    useEffect(() => {
        getCheckoutConfig()
            .then(async (config) => {
                setStripePromise(loadStripe(config.apiPublicKey));
            })
            .catch(() => {
                addMessage('Failed to get checkout config', SnackbarMessageVariants.ERROR);
            });
    }, []);

    if (!stripePromise) {
        return <LoaderOverlay />;
    }

    return (
        <Stack maxWidth={DEFAULT_MODAL_MAX_WIDTH} data-testid="billing-address-modal">
            <StripeElements stripePromise={stripePromise}>
                <BillingAddressManager customerInfo={customerInfo} isUdb={isUdb} />
            </StripeElements>
        </Stack>
    );
};

export default BillingAddressModal;
