import React from 'react';
import { PaymentMethodRow } from 'src/components/PaymentMethodRow';
import { CardTextMap } from 'src/components/CreditCard/CardIcon';
import { PaymentMethodDetails } from 'src/@types/sso-api';
import Line from 'src/components/Line';
import { RadioGroupItem } from 'src/components/Radio/RadioGroup';

export const createRadioItem = (method: PaymentMethodDetails): RadioGroupItem => {
    return {
        value: method.id,
        label: <PaymentMethodRow paymentMethod={method} />,
        additionalNode: <Line />,
        additionalNodeProps: {
            direction: 'column',
            spacing: { xs: 2, md: 3 },
        },
    };
};

export const getRadioItems = (availablePaymentMethods: Array<PaymentMethodDetails>): Array<RadioGroupItem> => {
    return availablePaymentMethods.map(createRadioItem);
};

export const getPaymentMethodMessage = (method?: PaymentMethodDetails): string => {
    if (!method?.card) {
        return 'Unknown payment method';
    }
    return `${CardTextMap[method.card.brand]} •••• ${method.card.last4}`;
};

export const getSelectedMethod = (paymentMethods: Array<PaymentMethodDetails>, methodId: string | null) => {
    return paymentMethods.find((method) => method.id === methodId);
};
