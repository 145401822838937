import React, { FC } from 'react';
import { BillingItem } from './components';
import { gridColumnParams } from './services/schemas';
import { useModalWithAnchor } from 'src/hooks/useModalWithAnchor';
import { GeneralModalKey } from 'src/constants';
import { MenuVariant } from 'src/components/Menu/Menu';
import { Scope, useUserCan } from 'src/hooks/useUserCan';
import { ManageActionType } from 'src/components/ManageActions/ManageActions';
import { GridColumnProps } from 'src/components/TableSection/TableSection';
import { PaginationData } from 'src/@types/pagination';
import { Menu } from 'src/components/Menu';
import { BillingRecordModel, BillingRecordWithRenewal, SubscriptionModel } from 'src/@types/subscription-service-api';
import { TableSection } from 'src/components/TableSection';

type PaymentSectionProps = {
    actionsList?: Record<string, ManageActionType>;
    isActivationAllowed?: boolean;
    subscription: SubscriptionModel;
    billingRecords: BillingRecordModel[] | BillingRecordWithRenewal[];
    paginationModel: PaginationData;
    setPagination: (newPagination: Partial<PaginationData>) => void;
    variant: 'udb' | 'profile';
    isLoading: boolean;
    pollingId?: string;
};

const BillingSection: FC<PaymentSectionProps> = ({
    subscription,
    billingRecords,
    paginationModel,
    actionsList,
    setPagination,
    variant,
    isLoading,
    pollingId,
    isActivationAllowed = false,
}) => {
    const hasActions = actionsList && Object.keys(actionsList).length > 0;
    const canManage = useUserCan(Scope.SUBSCRIPTIONS_SERVICE_WRITE);
    const modalPropsGetter = (selectedAction: string) => ({
        extraProps: {
            subscription,
            ...actionsList?.[selectedAction].actionExtraProps,
        },
    });

    const { openModal } = useModalWithAnchor(Object.keys(actionsList || {}), !isLoading, modalPropsGetter);

    const actionHandler = (action: string) => {
        openModal(action as GeneralModalKey);
    };

    const itemRenderer = (
        billingRecord: BillingRecordWithRenewal,
        extraProps: { gridColumnParams: GridColumnProps },
    ) => (
        <BillingItem
            billingRecord={billingRecord}
            rowProps={extraProps.gridColumnParams}
            subscription={subscription}
            variant={variant}
            isLoaded={!isLoading}
            isActivationAllowed={isActivationAllowed}
            pollingId={pollingId}
        />
    );

    return (
        <TableSection
            testId="billing-section"
            items={billingRecords}
            headerPaddingBottom={1}
            sectionHeader="Billing"
            isLoading={isLoading}
            noContentMessage="There are no payment records to be shown"
            renderItem={itemRenderer}
            gridColumnParams={gridColumnParams}
            paginationModel={paginationModel}
            setPagination={setPagination}
            headerChildren={
                hasActions && canManage && (
                    <Menu
                        buttonVariant={MenuVariant.THREE_DOTS_TEXT_BUTTON}
                        tooltipMaxWidth="unset"
                        menuItems={Object.entries(actionsList)
                            .map(([value, { label, disabled, tooltipTitle }]) => (
                                { value, label, disabled, tooltipTitle }
                            ))}
                        onClick={actionHandler}
                    />
                )
            }
        >
        </TableSection>
    );
};

export default BillingSection;
