import { InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { inputStyles } from 'src/components/Inputs/StyledInput.styles';

export const StyledSelect = styled(Select)`
    && {
        font-family: ${({ theme }) => theme.fonts.normal};
        cursor: pointer;
        
        & .MuiList-root{
            padding-top: 12px;
            padding-bottom: 12px;
        }
        
        &.MuiInputBase-root {
            gap: 10px;
            height: ${({ theme }) => theme.sizes.inputHeight.default};

            @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
                height: ${({ theme }) => theme.sizes.inputHeight.xs};
                font-size: 14px;
                font-family: ${({ theme }) => theme.fonts.medium};
            }

            & svg[data-testid="ArrowDropDownIcon"] {
                height: 100%;
                right: 0;
                top: unset;
                position: relative;
                padding-right: 16px;
                color: ${({ theme }) => theme.palette.black};
                transform: ${({ open }) => `rotate(${open ? '180deg' : '0deg'}) translateX(${open ? '40%' : '0%'})`};
            }

            & .MuiSelect-select {
                color: ${({ theme }) => theme.palette.black};
                padding: 14px 0 14px 16px;

                @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
                    font-size: 16px;
                    font-family: ${({ theme }) => theme.fonts.normal};
                }
            }
        }

        &.MuiInputBase-root {
            ${inputStyles}
        }
    }
`;

export const StyledInputPlaceholder = styled(InputLabel)`
   &&& {
    color: ${({ theme }) => theme.palette.lightBlack};
    font-family: ${({ theme }) => theme.fonts.normal};
    font-size: 16px;
    transform: translate(0, 0) scale(1);
    left: 16px;
    top: 14px;
       
    @media(max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 16px;
    }
       
}
`;

export const StyledMenuItem = styled(MenuItem)`
    && {
        font-family: ${({ theme }) => theme.fonts.normal};
        color: ${({ theme }) => theme.palette.black};
        font-size: 16px;
        padding: 10px 16px;
        
        &.Mui-selected {
            background-color: ${({ theme }) => theme.palette.snackbar.infoBackground};
            
            &:hover {
                background-color: ${({ theme }) => theme.palette.snackbar.infoBackground};
            }
        }

        &:hover {
            background-color: ${({ theme }) => theme.palette.mainBackgroundColor};
        }
        
        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            padding: 14px 24px;
            font-size: 14px;
            font-family: ${({ theme }) => theme.fonts.medium};
        }
    }
`;

export const StyledListItemText = styled(ListItemText)`
  && {
     span {
        font-size: 16px;
        font-family: ${({ theme }) => theme.fonts.normal};
    }
}
`;

export const StyledArrowBackIcon = styled(ArrowBackIcon)`
  && {
    color: ${({ theme }) => theme.palette.lightBlack};
    cursor: pointer;
    position: relative;
}
`;

export const StyledSelectHeader = styled(Grid)`
    color: ${({ theme }) => theme.palette.black};
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    
  & svg {
    display: inline-block;
    vertical-align: middle;
    max-height: 23px;
  }
`;
