import styled from 'styled-components';
import { Box } from '@mui/material';

export const OneClickSubscribeWrapper = styled(Box)`
    &&& {
        svg {
            height: 175px;
            width: 175px;
            max-width: 100%;
            margin-bottom: 28px;
            
            @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
                margin-bottom: 32px;
                width: 198px;
                height: 198px;
            }
        }

        .MuiButtonBase-root {
            min-width: unset;
        }
    }
`;
