import React, { createRef, FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { StyledCodeInput } from './Splitted2FACodeInput.styles';
import { TFA_CODE_LENGTH } from 'src/constants';

type SplitCodeInputsProps = {
    currentValue: string;
    onChange?: (code: string) => void;
};

const Splitted2FACodeInput: FC<SplitCodeInputsProps> = ({ onChange, currentValue }) => {
    const initialCodeState = new Array(TFA_CODE_LENGTH).fill('');
    const [codeChunks, setCodeChunks] = useState<Array<string>>(initialCodeState);
    const cellRefs = new Array(TFA_CODE_LENGTH).fill(null).map(createRef<HTMLInputElement>);

    useEffect(() => {
        if (currentValue === '') {
            setCodeChunks(initialCodeState);
        }
    }, [currentValue]);

    useEffect(() => {
        const firstUnFilledCell = codeChunks.findIndex((cell) => !cell);
        const codeValue = codeChunks.join('');

        if (firstUnFilledCell !== -1) {
            cellRefs[firstUnFilledCell].current?.focus();
        }

        onChange?.(codeValue);
    }, [codeChunks]);

    const setCodeValue = (index: number, value: string = '') => {
        setCodeChunks((prev) => {
            const copy = [...prev];
            copy[index] = value;
            return copy;
        });
    };

    const pasteValue = (value: string) => {
        const firstUnFilled = codeChunks.findIndex((cell) => !cell);
        const isAllCellsFilled = firstUnFilled === -1;
        const firstUnFilledIndex = !isAllCellsFilled ? firstUnFilled : codeChunks.length - 1;
        const newCode = value.substring(0, TFA_CODE_LENGTH);
        const newValues = newCode.split('');

        if (Number.isNaN(Number(value)) || isAllCellsFilled) {
            return;
        }

        newValues.forEach((newValue, index) => {
            if (index + firstUnFilledIndex < codeChunks.length) {
                setCodeValue(index + firstUnFilledIndex, newValue);
            }
        });
    };

    const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement> & { nativeEvent: { data: string } }) => {
        const pastedValue = event.target.value;
        const keyboardValue = event.nativeEvent.data;
        const index = Number(event.target.name);

        if (!keyboardValue || pastedValue.length > 1) {
            pasteValue(pastedValue.trim());
            return;
        }

        if (Number.isNaN(Number(keyboardValue))) {
            setCodeValue(index);
            return;
        }

        setCodeValue(index, keyboardValue);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement> & { target: { name: string } }) => {
        if (!['Backspace', 'Delete'].includes(event.key)) {
            return;
        }

        const index = Number(event.target.name);
        const hasValue = !!codeChunks[index];
        const isDeleteKey = event.key === 'Delete';
        const isFirstCell = index === 0;
        const shouldClearCurrentCell = hasValue || isDeleteKey || isFirstCell;

        setCodeValue(shouldClearCurrentCell ? index : index - 1);
    };

    const handleWrapperClick = () => {
        const lastCell = codeChunks.length - 1;
        const firstUnFilledCell = codeChunks.findIndex((cell) => !cell);
        const isAllCellsFilled = firstUnFilledCell === -1;

        cellRefs[isAllCellsFilled ? lastCell : firstUnFilledCell].current?.focus();
    };

    const isReadOnly = (index: number) => {
        const isFirstEmpty = index === 0 && !codeChunks[index];
        const isLastFilled = index === codeChunks.length - 1 && codeChunks[index];
        const firstUnFilled = codeChunks.findIndex((cell) => !cell);

        if (isFirstEmpty || isLastFilled) {
            return false;
        }

        return firstUnFilled !== index;
    };

    return (
        <Stack onClick={handleWrapperClick} direction="row" spacing={{ xs: 1, sm: 1.5 }} paddingBottom={{ xs: 0.5, sm: 3 }}>
            {cellRefs.map((ref, index) => (
                <StyledCodeInput
                    key={index}
                    value={codeChunks[index]}
                    name={index.toString()}
                    onKeyDown={handleKeyDown}
                    onChange={handleCodeChange}
                    data-readonly={isReadOnly(index)}
                    data-testid="tfa-code-input"
                    InputProps={{
                        inputProps: {
                            ref,
                            autoComplete: 'off',
                            readOnly: isReadOnly(index),
                            'data-testid': 'tfa-code-input-element',
                        },
                    }}
                />
            ))}
        </Stack>
    );
};

export default Splitted2FACodeInput;
