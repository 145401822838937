import styled from 'styled-components';
import Box from '@mui/material/Box';

export const StyledDescription = styled(Box)`
    && {
        padding: 20px;
        font-size: 16px;
        font-family: ${({ theme }) => theme.fonts.normal};
        color: ${({ theme }) => theme.palette.black};
        background-color: ${({ theme }) => theme.palette.manageSubscriptionNotificationLightBlue};

        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 14px;
            padding: 12px 16px;
        }
    } 
`;
