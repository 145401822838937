import React, { FC, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { formatDate } from 'src/services/formatters';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { StyledModalMessage } from 'src/components/Modals/BaseModal/BaseModal.styles';
import { CustomAttributes } from 'src/@types/subscription-service-api';
import { StyledExpiresIn } from 'src/components/Modals/ActivateSubscriptionModal/ActivateSubscriptionModal.styles';
import { Button } from 'src/components/Buttons';
import { DurationInput } from 'src/components/Inputs';
import { Section } from 'src/components/Section';
import { extendExpirationDate, extendSubscription } from 'src/services/subscription-service-api';
import {
    additionalShortInputWidth,
    BillingCycleLengthInMonth,
    billingCycleLengthRenderMapping,
    SnackbarMessageVariants,
    SubscriptionBillingType,
} from 'src/constants';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import { BaseModal } from 'src/components/Modals/BaseModal';

export type ExtendSubscriptionModalProps = {
    subscriptionUUID: string;
    billingType: SubscriptionBillingType;
    customAttributes: Partial<CustomAttributes>;
};

const ExtendSubscriptionModal: FC<ExtendSubscriptionModalProps> = ({
    subscriptionUUID,
    billingType,
    customAttributes,
}) => {
    const { closeModal } = useGeneralModal();
    const { addMessage, addErrorMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();

    const [numberOfCycles, setNumberOfCycles] = useState<number>(1);
    const [expirationDate, setExpirationDate] = useState<string>('');
    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { cycleLengthInMonths } = customAttributes;
    const isFree = billingType === SubscriptionBillingType.FREE;

    const cycleLength = cycleLengthInMonths
        ? Number(cycleLengthInMonths) as BillingCycleLengthInMonth
        : BillingCycleLengthInMonth.YEAR;

    const fetchExpirationDate = (cycleCount: number) => {
        setIsLoading(true);
        extendExpirationDate(subscriptionUUID, { cycleCount })
            .then((response) => {
                setExpirationDate(response.expirationDate);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (!isFree) {
            fetchExpirationDate(1);
        }
    }, [subscriptionUUID]);

    const handleNextClick = () => {
        fetchExpirationDate(numberOfCycles);
        setIsConfirming(true);
    };

    const handleConfirm = async () => {
        return extendSubscription(subscriptionUUID, {
            cycleCount: numberOfCycles,
        })
            .then(() => {
                addMessage('Subscription extended successfully', SnackbarMessageVariants.SUCCESS);
                reloadPage();
                closeModal();
            })
            .catch((error) => {
                addErrorMessage('Failed to extend subscription', error);
            });
    };

    const confirmationMessage = (
        <StyledModalMessage>
        Are you sure you want to extend this subscription? Next expiration date will be&nbsp;
            <span>{formatDate(expirationDate)}</span>.
        </StyledModalMessage>
    );

    if (!isFree) {
        return (
            <BaseModal
                open
                overlayScope="relative"
                handleClose={closeModal}
                title="Extend subscription"
            >
                {isLoading && <LoaderOverlay />}
                <ConfirmationModal
                    modalId="confirmation-modal"
                    onConfirm={handleConfirm}
                    onCancel={closeModal}
                    subtitle={confirmationMessage}
                />
            </BaseModal>
        );
    }

    if (isConfirming) {
        return (
            <BaseModal
                open
                step={1}
                overlayScope="relative"
                handleClose={closeModal}
                handleGoBack={() => setIsConfirming(false)}
                title="Extend subscription"
            >
                {isLoading && <LoaderOverlay />}
                <ConfirmationModal
                    modalId="confirmation-modal"
                    onConfirm={handleConfirm}
                    onCancel={() => setIsConfirming(false)}
                    cancelButtonText="Go back"
                    subtitle={confirmationMessage}
                />
            </BaseModal>
        );
    }

    return (
        <Stack>
            <Section
                marginTop={0}
                sectionPaddingX={0}
                sectionPaddingTop={0}
                sectionPaddingBottom={{ xs: 3, md: 6 }}
            >
                <Box>
                    <StyledModalMessage>
                        Select how long you wish to extend your subscription for below.
                    </StyledModalMessage>
                    <StyledExpiresIn paddingTop={{ xs: 2.5, md: 3 }}>
                        Extend with
                    </StyledExpiresIn>
                    <Box
                        maxWidth={additionalShortInputWidth}
                        paddingTop={0.5}
                    >
                        <DurationInput
                            data-testid="duration-input"
                            value={numberOfCycles}
                            onChange={(value) => setNumberOfCycles(value)}
                            adornmentText={
                                billingCycleLengthRenderMapping[cycleLength]
                            }
                        />
                    </Box>
                </Box>
            </Section>
            <Stack direction="row" spacing={2}>
                <Button fullWidth minSize="small" onClick={closeModal} variant="secondary">Cancel</Button>
                <Button fullWidth minSize="small" onClick={handleNextClick}>Next</Button>
            </Stack>
        </Stack>
    );
};

export default ExtendSubscriptionModal;
