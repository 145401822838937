import React, { FC } from 'react';
import { PaymentElement, PaymentElementProps } from '@stripe/react-stripe-js';

type StripePaymentElementProps = {
    onChange: PaymentElementProps['onChange'];
    onReady?: PaymentElementProps['onReady'];
};

const StripePaymentElement: FC<StripePaymentElementProps> = ({
    onChange,
    onReady,
}) => {
    return (
        <PaymentElement
            onChange={onChange}
            onReady={onReady}
            options={{
                terms: {
                    card: 'never',
                    applePay: 'never',
                    googlePay: 'never',
                },
            }}
        />
    );
};

export default StripePaymentElement;
