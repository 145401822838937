import React, { FC, useEffect, useState } from 'react';
import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure/index';
import CreateStripeCustomerFormContent, {
    CreateStripeCustomerFormProps,
} from 'src/components/Forms/CreateStripeCustomerForm/CreateStripeCustomerFormContent';
import { getCheckoutConfig } from 'src/services/sso-api';
import { SnackbarMessageVariants } from 'src/constants';
import { useSnackbarMessage } from 'src/hooks';
import { StripeElements } from 'src/components/Stripe';

type CreateStripeCustomerFormWrapperProps = CreateStripeCustomerFormProps & {
    onConfigLoaded?: () => void;
};

const CreateStripeCustomerForm: FC<CreateStripeCustomerFormWrapperProps> = ({ onConfigLoaded, ...props }) => {
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(Promise.resolve(null));
    const { addMessage } = useSnackbarMessage();

    useEffect(() => {
        getCheckoutConfig()
            .then((config) => {
                setStripePromise(loadStripe(config.apiPublicKey));
                onConfigLoaded?.();
            })
            .catch(() => {
                addMessage('Failed to get checkout config', SnackbarMessageVariants.ERROR);
            });
    }, []);

    return (
        <StripeElements
            stripePromise={stripePromise}
        >
            <CreateStripeCustomerFormContent {...props} />
        </StripeElements>
    );
};

export default CreateStripeCustomerForm;
