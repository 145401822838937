import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { StyledText } from './BillingAddressModal.styles';
import { isSameAddresses } from 'src/services/address';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { updateProfileStripeCustomer, updateStripeCustomer } from 'src/services/sso-api';
import { SnackbarMessageVariants } from 'src/constants';
import { StripeAddress } from 'src/components/Stripe';
import { useStripeAppearance } from 'src/hooks/useStripeAppearance';
import { StripeCustomerInfo } from 'src/@types/sso-api';
import { Button } from 'src/components/Buttons';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';

type BillingAddressFormProps = {
    customerInfo: StripeCustomerInfo;
    isUdb: boolean;
};

const BillingAddressManager: FC<BillingAddressFormProps> = ({ customerInfo: stripeCustomerInfo, isUdb }) => {
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [customerInfo, setCustomerInfo] = useState<Partial<StripeCustomerInfo>>(stripeCustomerInfo);

    const { uuid = '' } = useParams<{ uuid: string }>();
    const { addMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();
    const { closeModal } = useGeneralModal();

    useStripeAppearance();

    const { name, address, id } = stripeCustomerInfo;

    const getApiCall = () => {
        return isUdb
            ? updateStripeCustomer(id, customerInfo)
            : updateProfileStripeCustomer(uuid, customerInfo);
    };

    const hasChanges = () => {
        if (customerInfo.name !== stripeCustomerInfo.name) {
            return true;
        }

        return !isSameAddresses(customerInfo.address, stripeCustomerInfo.address);
    };

    const handleSubmit = async () => {
        setLoading(true);
        return getApiCall()
            .then(() => {
                addMessage(`Billing address ${address ? 'updated' : 'added'} successfully`, SnackbarMessageVariants.SUCCESS);
                reloadPage();
                closeModal();
            })
            .catch(() => {
                addMessage(`Failed to ${address ? 'update' : 'add'} billing address`, SnackbarMessageVariants.ERROR);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Stack data-testid="billing-address-manager">
            {isLoading && <LoaderOverlay />}
            <StripeAddress
                name={name}
                address={address || undefined}
                onChange={({ complete, value }) => {
                    setCustomerInfo(value);
                    setIsComplete(complete);
                }}
                onReady={(addressElement) => {
                    if (!address?.line1) {
                        return;
                    }

                    addressElement.getValue()
                        .then(({ complete }) => {
                            if (complete) {
                                setIsComplete(true);
                            }
                        });
                }}
            />
            <Stack direction="row" marginTop={{ xs: 3, md: 6 }} spacing={{ xs: 2, sm: 2.5 }}>
                <Button onClick={closeModal} minSize="small" variant="secondary" fullWidth>
                    Cancel
                </Button>
                <Button
                    data-testid="submit-button"
                    disabled={isLoading || !isComplete || !hasChanges()}
                    minSize="small"
                    fullWidth
                    onClick={handleSubmit}
                >
                    {address ? 'Update' : 'Add'}
                </Button>
            </Stack>
            {!isUdb && (
                <StyledText>
                    We do not store your billing information.
                    It is stored in and processed by Stripe, our payments platform partner.
                </StyledText>
            )}
        </Stack>
    );
};

export default BillingAddressManager;
