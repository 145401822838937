import { useState, useEffect, useRef } from 'react';

export const useOverflowTooltip = (value?: string | null, isOpen?: boolean) => {
    const elementRef = useRef<HTMLElement | null>(null);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    useEffect(() => {
        if (typeof value === 'string' && elementRef.current) {
            const el = elementRef.current;
            setShowTooltip(el.scrollWidth > el.clientWidth);
        } else {
            setShowTooltip(false);
        }
    }, [value, isOpen]);

    const tooltipTitle = showTooltip ? value : '';

    return {
        ref: elementRef,
        tooltipTitle,
    };
};
