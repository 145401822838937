import styled from 'styled-components';
import { Stack, Typography } from '@mui/material';
import { StyledIcon } from 'src/components/CopyIcon/CopyIcon.styles';

export const StyledInfo = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.normal};
    color: ${({ theme }) => theme.palette.black};
    line-height: 40px;  
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
      
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 14px;
      min-height: 24px;
      line-height: 24px;
    }
  }
`;

export const StyledStack = styled(Stack)`
    &:hover ${StyledIcon} {
        opacity: 1;
    }
`;
