import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Title } from 'src/pages/udb/UserQuickSearch/UserQuickSearch.styles';
import StyledTooltip from 'src/components/Tooltip';
import { NoContentMessage } from 'src/components/TableSection/TableSection.styles';
import { UserInfoBox } from 'src/components/UserInfoBox';
import { ActionButton, ActionButtonVariant } from 'src/components/Buttons/ActionButton';
import { ReactComponent as DetailsIcon } from 'src/assets/icons/payment-details-icon.svg';
import { User } from 'src/@types/unified-db-api';

type SearchUserResultsProps = {
    user: User | null;
};

const SearchUserResults: FC<SearchUserResultsProps> = ({ user }) => {
    if (!user) {
        return (
            <NoContentMessage marginTop={{ xs: 3, sm: 4.5 }} marginBottom={{ xs: 0, sm: 0.5 }} textColor="lightBlack">
                No results found. Please try again.
            </NoContentMessage>
        );
    }

    return (
        <Box paddingTop={3}>
            <Title marginBottom={1.5}>Search results</Title>
            <UserInfoBox
                name={user.fullName}
                companyName={user.jobInfo?.companyName}
                email={user.email.email}
                additionalBlock={
                    <StyledTooltip arrow title="Open user page">
                        <ActionButton
                            href={`/udb/users/${user.uuid}`}
                            data-testid="details-button"
                            data-attribute-to-change="stroke"
                            data-variant={ActionButtonVariant.BLUE}
                        >
                            <DetailsIcon />
                        </ActionButton>
                    </StyledTooltip>
                }
            />
        </Box>
    );
};
export default SearchUserResults;
