import React from 'react';
import Link from 'src/components/Link/Link';
import type { BillingRecordModel } from 'src/@types/subscription-service-api';
import { ReactComponent as StripeLinkIcon } from 'src/assets/icons/stripe-link-icon.svg';
import { buildStipeInvoiceHref } from 'src/services/stripe';

const InvoiceLink = ({
    billingRecord,
    variant,
    isModal = false,
    testMode,
}: {
    billingRecord: BillingRecordModel;
    variant: 'udb' | 'profile';
    isModal?: boolean;
    testMode?: boolean;
}) => {
    const isUdb = variant === 'udb';
    const { stripeInvoiceID, invoiceHostedURL } = billingRecord;

    const props = isUdb ? {
        'data-modal': isModal,
        href: stripeInvoiceID ? buildStipeInvoiceHref(stripeInvoiceID, testMode) : '',
    } : {
        href: invoiceHostedURL,
    };

    return (
        <Link
            target="_blank"
            display="flex"
            alignItems="center"
            gap={1}
            {...props}
        >
            View invoice
            <StripeLinkIcon width="16px" />
        </Link>
    );
};

export default InvoiceLink;
