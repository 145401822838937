import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledExpiresIn = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.demiBold};
        font-size: 16px;

        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 14px;
        }
    }
`;
