import React from 'react';
import { Box } from '@mui/material';
import { ActivateLaterLabel } from 'src/components/Label';
import { ActivateSubscriptionDatePicker } from 'src/components/DatePicker';
import {
    ActivationDate,
    additionalWideInputWidth,
    BillingCycleLengthInMonth,
    SubscriptionBillingType,
    SubscriptionType,
} from 'src/constants';
import type { RadioGroupItem } from 'src/components/Radio/RadioGroup';

export const getActivationDateRadioItems = ({
    onChange,
    activationDate,
    selectedType,
    billingCycleLength,
    position,
    centeredDatePicker,
    initialMinActivationDate,
    selectedBillingType,
    selectedSubscriptionType,
    showActivateLater,
}: {
    onChange: (date: string | undefined) => void;
    activationDate?: string | null;
    selectedType?: ActivationDate;
    billingCycleLength?: BillingCycleLengthInMonth;
    position?: 'fixed' | 'absolute';
    centeredDatePicker?: 'center' | 'default';
    initialMinActivationDate?: string;
    showActivateLater?: boolean;
    selectedBillingType?: SubscriptionBillingType;
    selectedSubscriptionType?: SubscriptionType;
}): Array<RadioGroupItem<ActivationDate>> => {
    const items: Array<RadioGroupItem<ActivationDate>> = [
        {
            value: ActivationDate.NOW,
            label: 'Now',
        },
        {
            value: ActivationDate.SPECIFIED,
            label: 'Specific date & time',
            ...(selectedType === ActivationDate.SPECIFIED && {
                additionalNodeProps: {
                    direction: 'column',
                    spacing: 2,
                },
                additionalNode: (
                    <Box maxWidth={additionalWideInputWidth}>
                        <ActivateSubscriptionDatePicker
                            initialMinActivationDate={initialMinActivationDate}
                            billingCycleLength={billingCycleLength}
                            initialValue={activationDate}
                            handleDateChange={onChange}
                            centered={centeredDatePicker}
                            position={position}
                            dateTimeOnly
                            withSeconds
                        />
                    </Box>
                ),
            }),
        },
    ];

    if (showActivateLater) {
        items.push(
            {
                value: ActivationDate.MANUALLY,
                label: (
                    <ActivateLaterLabel
                        label="Activate later"
                        billingType={selectedBillingType}
                        subscriptionType={selectedSubscriptionType}
                    />
                ),
            },
        );
    }

    return items;
};

export const getActivationDate = (
    selectedActivationDateType: ActivationDate,
    cachedValue: string | null | undefined,
) => {
    if ([ActivationDate.MANUALLY, ActivationDate.NOW].includes(selectedActivationDateType)) {
        return null;
    }

    return cachedValue;
};
