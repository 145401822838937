import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import { Box, Stack } from '@mui/material';
import { FormWrapper } from 'src/components/Forms/components';
import { Button } from 'src/components/Buttons';
import { fetchPaidSubscriptionUsage } from 'src/services/sso-api';
import { useIsSubmitDisabled, useSnackbarMessage } from 'src/hooks';
import { GetPaidSubscriptionUsageRequestData, PaidSubscriptionUsageData } from 'src/@types/sso-api';
import { LabeledDateInput } from 'src/components/Inputs/LabeledDateInput/LabeledDateInput';
import { DATE_ISO_8601, SnackbarMessageVariants } from 'src/constants';
import { UsageDataTable } from 'src/components/Modals/SubscriptionUsageDataModal/components/UsageDataTable';
import Line from 'src/components/Line';
import { DEFAULT_MODAL_PADDING_X } from 'src/components/Modals/BaseModal';
import { CustomDatePicker } from 'src/components/Modals/SubscriptionUsageDataModal/UsageDataModal.styles';

export type UsageDataModalProps = {
    subscriptionUUID: string;
    activationDate: string | null;
    gracePeriodEndDate: string | null;
};

const UsageDataModal: FC<UsageDataModalProps> = ({ subscriptionUUID, activationDate, gracePeriodEndDate }) => {
    const { addMessage } = useSnackbarMessage();
    const [usageData, setUsageData] = useState<PaidSubscriptionUsageData>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [fromDateValue, setFromDateValue] = useState<string>();
    const [toDateValue, setToDateValue] = useState<string>();

    const minDate = activationDate && dayjs(activationDate).isAfter(dayjs('2023-03-01'))
        ? dayjs(activationDate)
        : dayjs('2023-03-01');

    const maxDate = gracePeriodEndDate && dayjs(gracePeriodEndDate).isBefore(dayjs())
        ? dayjs(gracePeriodEndDate)
        : dayjs();

    const initialValues = {
        fromDate: fromDateValue ?? '',
        toDate: toDateValue ?? '',
    };

    const { onFormChangeHandler } = useIsSubmitDisabled<GetPaidSubscriptionUsageRequestData>(initialValues);

    const handleSubmit = (
        values: GetPaidSubscriptionUsageRequestData | object,
    ) => {
        setIsSubmitting(true);
        const { fromDate, toDate } = values as GetPaidSubscriptionUsageRequestData;
        fetchPaidSubscriptionUsage(subscriptionUUID, {
            fromDate: dayjs(fromDate).format(DATE_ISO_8601),
            toDate: dayjs(toDate).format(DATE_ISO_8601),
        })
            .then(setUsageData)
            .catch(() => {
                addMessage('Failed to get subscription usage data', SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <FormWrapper
            testId="usage-data-modal"
            onFormChange={onFormChangeHandler}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            enableReinitialize
        >
            <CustomDatePicker>
                <Stack
                    paddingX={DEFAULT_MODAL_PADDING_X}
                    spacing={2.5}
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'unset', sm: 'flex-end' }}
                    paddingBottom={usageData && { xs: 3, sm: 4.5 }}
                >
                    <LabeledDateInput
                        testId="start-date-picker"
                        onChange={(date) => setFromDateValue(date)}
                        name="fromDate"
                        label="Start date"
                        placeholder="Select start date"
                        minDate={minDate}
                        maxDate={toDateValue ? dayjs(toDateValue) : maxDate}
                        position="fixed"
                        dateOnly
                    />
                    <LabeledDateInput
                        testId="end-date-picker"
                        onChange={(date) => setToDateValue(date)}
                        name="toDate"
                        label="End date"
                        placeholder="Select end date"
                        minDate={fromDateValue ? dayjs(fromDateValue) : minDate}
                        maxDate={maxDate}
                        position="fixed"
                        dateOnly
                    />
                    <Box paddingTop={{ xs: 0.5, sm: 'unset' }}>
                        <Button
                            data-testid="usage-data-modal-submit-button"
                            variant="primary"
                            type="submit"
                            fullWidth
                            disabled={isSubmitting || !fromDateValue || !toDateValue}
                        >
                      Get data
                        </Button>
                    </Box>
                </Stack>
            </CustomDatePicker>
            {usageData && (
                <>
                    <Line />
                    <UsageDataTable
                        paidSubscriptionUsage={usageData}
                    />
                </>
            )}
        </FormWrapper>
    );
};

export default UsageDataModal;
