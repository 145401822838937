import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledText = styled(Typography)`
    && {
        margin-top: 16px;
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.lightGrey};
        font-size: 14px;
        line-height: normal;
        text-align: center;
        letter-spacing: 0.14px;
        
        @media(max-width: ${({ theme }) => theme.breakpoints.sm}) {
            margin-top: 12px;
            font-size: 12px;
            letter-spacing: 0.12px;
        }
    } 
`;
