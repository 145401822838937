import { formatPrice } from './formatters';
import { StripeDiscountInfo } from 'src/@types/sso-api';

export const composeDiscountText = (discountData: StripeDiscountInfo | null): { amount: string, duration: string } => {
    const formattedDiscountData = {
        amount: '',
        duration: '',
    };

    if (!discountData) {
        return formattedDiscountData;
    }

    if (discountData.percentOff) {
        formattedDiscountData.amount = `${discountData.percentOff}%`;
    }

    if (discountData.amountOff) {
        formattedDiscountData.amount = formatPrice(discountData.amountOff, { showCurrency: true });
    }

    switch (discountData.duration) {
        case 'forever':
            formattedDiscountData.duration = 'Annual';
            break;
        case 'repeating':
            if (discountData.durationInMonths && discountData.durationInMonths > 12) {
                formattedDiscountData.duration = `${Math.ceil(discountData.durationInMonths / 12)} years`;
            } else {
                formattedDiscountData.duration = 'One-time';
            }
            break;
        case 'once':
        default:
            formattedDiscountData.duration = 'One-time';
            break;
    }

    return formattedDiscountData;
};
