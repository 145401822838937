import React, { ReactElement, useEffect, useState } from 'react';
import { cssTransition, Id, toast, ToastItem } from 'react-toastify';
import { SnackbarBody } from 'src/components/Snackbar/SnackbarBody';
import { SNACKBAR_MAX_COUNT, SnackbarMessageVariants } from 'src/constants';
import APIClientError from 'src/classes/api-client-error';
import { appendResponseError } from 'src/services/error-formatters';

type AddMessageFunc = (message: string | ReactElement, type?: SnackbarMessageVariants) => Id;
type AddErrorMessageFunc = (baseMessage: string, error: APIClientError | Error) => Id;
type DismissMessageFunc = (id: Id) => void;
type UseSnackbarMessageReturnType = {
    addMessage: AddMessageFunc;
    addErrorMessage: AddErrorMessageFunc;
    dismissMessage: DismissMessageFunc;
    activeMessages: Id[];
};

const ToastAnimations = cssTransition({
    enter: 'slide-from-bottom',
    exit: 'slide-left',
});

let messageCounter = 0;

export const useSnackbarMessage = (): UseSnackbarMessageReturnType => {
    const [activeMessages, setActiveMessages] = useState<Array<Id>>([]);

    const dismissMessage: DismissMessageFunc = (id: Id) => {
        toast.dismiss(id);
    };

    const addMessage: AddMessageFunc = (message, type = SnackbarMessageVariants.DEFAULT) => {
        const messageId = `snackbar-message-${type}-${++messageCounter}`;
        const messageBody = <SnackbarBody type={type}>{message}</SnackbarBody>;
        const messageProps = {
            transition: ToastAnimations,
            delay: 50,
            toastId: messageId,
            type,
        };
        toast(messageBody, messageProps);
        return messageId;
    };

    const addErrorMessage: AddErrorMessageFunc = (baseMessage, error) => {
        return addMessage(appendResponseError(baseMessage, error), SnackbarMessageVariants.ERROR);
    };

    useEffect(() => {
        toast.onChange((payload: ToastItem) => {
            switch (payload.status) {
                case 'added':
                    setActiveMessages((prev) => [...prev, payload.id]);
                    break;
                case 'removed':
                    setActiveMessages((prev) => [...prev.filter((id) => (id !== payload.id))]);
                    break;
            }
        });
    }, []);

    useEffect(() => {
        if (activeMessages.length > SNACKBAR_MAX_COUNT) {
            const amountToRemove = activeMessages.length - SNACKBAR_MAX_COUNT;

            for (let i = 0; i < amountToRemove; i++) {
                dismissMessage(activeMessages[i]);
            }
        }
    }, [activeMessages]);

    return { addMessage, addErrorMessage, dismissMessage, activeMessages };
};
