import { Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledTimer = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.bold};
        font-size: 24px;
        
        @media(max-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 18px;
        }
    }
`;
