import styled from 'styled-components';
import { InputLabel, Stack, Typography } from '@mui/material';

import { TextButton } from 'src/components/Buttons';

export const StyledInputPlaceholder = styled(InputLabel)`
    &&& {
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 16px;
        transform: translate(0, 0) scale(1);
        left: 16px;
        top: 14px;

        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 14px;
        }
    }
`;

export const OptionWrapper = styled(Stack)`
    border-bottom: 1px solid ${({ theme }) => theme.palette.borderGrey};
`;

export const Title = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const Description = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 14px;
    }
`;

export const AddButton = styled(TextButton)`
    &&&.MuiButtonBase-root {
        display: flex;
        align-items: center;
        padding-right: 4px;
        border-radius: 4px;
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 16px;

        svg {
            margin-right: 8px;
        }

        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 14px;
        }
    }
`;
