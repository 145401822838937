import React, { FC, Fragment, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { getActivationDate, getActivationDateRadioItems } from 'src/services/activation-date-getters';
import { Feature, useIsFeatureEnabled } from 'src/hooks/useIsFeatureEnabled';
import { Section } from 'src/components/Section';
import { DurationInput } from 'src/components/Inputs';
import {
    billingCycleRadioItems,
    getBillingRadioItems,
    getNumberOfCyclesWhenBillingTypeChange,
} from 'src/pages/udb/CreateSubscription/services/type-and-billing-step-getters';
import { StepContainer } from 'src/pages/udb/CreateSubscription/components/StepContainer';
import { SubscriptionTypeRadioGroup } from 'src/pages/udb/CreateSubscription/components/SubscriptionTypeRadioGroup';
import { RadioGroup } from 'src/components/Radio';
import {
    ActivationDate,
    additionalShortInputWidth,
    BillingCycleLengthInMonth,
    billingCycleLengthRenderMapping,
    SubscriptionBillingType,
    SubscriptionType,
} from 'src/constants';

export type TypeAndBillingData = {
    selectedSubscriptionType?: SubscriptionType;
    selectedBillingType?: SubscriptionBillingType;
    selectedBillingCycleLength?: BillingCycleLengthInMonth;
    selectedActivationDateType?: ActivationDate;
    numberOfCycles?: number;
    activationDate?: string | null;
};

type TypeAndBillingStepProps = {
    initialData?: TypeAndBillingData;
    handleNextButtonClick: (data: Required<TypeAndBillingData>) => void;
};

const TypeAndBillingStep: FC<TypeAndBillingStepProps> = ({ handleNextButtonClick, initialData = {} }) => {
    const featureAccountUpgrade = useIsFeatureEnabled(Feature.accountUpgrade);
    const [typeAndBillingData, setTypeAndBillingData] = useState<TypeAndBillingData>(initialData);
    const [cachedActivationDate, setCachedActivationDate]
        = useState<string | null | undefined>(initialData.activationDate || undefined);

    const { activationDate,
        selectedActivationDateType,
        selectedSubscriptionType,
        selectedBillingType,
        selectedBillingCycleLength,
        numberOfCycles } = typeAndBillingData;

    const hasAllData = [
        selectedSubscriptionType,
        selectedBillingType,
        selectedBillingCycleLength,
        selectedActivationDateType,
        numberOfCycles,
        activationDate,
    ].every((item) => item !== undefined);

    useEffect(() => {
        if (!numberOfCycles && selectedSubscriptionType) {
            setTypeAndBillingData({
                ...typeAndBillingData,
                numberOfCycles: getNumberOfCyclesWhenBillingTypeChange(selectedBillingType, numberOfCycles),
            });
        }
    }, [selectedSubscriptionType]);

    const dataSetter = (
        key: keyof TypeAndBillingData,
        preSetProcessor?: (value: unknown) => TypeAndBillingData[keyof TypeAndBillingData],
    ) => (value: TypeAndBillingData[keyof TypeAndBillingData]) => {
        setTypeAndBillingData({
            ...typeAndBillingData,
            [key]: preSetProcessor ? preSetProcessor(value) : value,
        });
    };

    const handleSubscriptionTypeChange = (selectedType: SubscriptionType) => {
        setTypeAndBillingData({
            ...typeAndBillingData,
            selectedSubscriptionType: selectedType,
        });
    };

    const setSelectedBillingType = (billingType: SubscriptionBillingType) => {
        setTypeAndBillingData({
            ...typeAndBillingData,
            selectedBillingType: billingType,
            numberOfCycles: getNumberOfCyclesWhenBillingTypeChange(billingType, numberOfCycles),
        });
    };

    const setSelectedActivationDateType = (activationDateType: ActivationDate) => {
        const isNewTypeSpecified = activationDateType === ActivationDate.SPECIFIED;
        const isOldTypeSpecified = selectedActivationDateType === ActivationDate.SPECIFIED;

        if (!isNewTypeSpecified && isOldTypeSpecified) {
            setCachedActivationDate(activationDate);
        }

        setTypeAndBillingData({
            ...typeAndBillingData,
            selectedActivationDateType: activationDateType,
            activationDate: getActivationDate(activationDateType, cachedActivationDate),
        });
    };

    return (
        <StepContainer
            title={`Select subscription type ${selectedSubscriptionType ? ' and billing' : ''}`}
            showNextButton={hasAllData}
            handleNextButtonClick={() => handleNextButtonClick(typeAndBillingData as Required<TypeAndBillingData>)}
        >
            <Section variant="headless" header="Subscription type">
                <SubscriptionTypeRadioGroup
                    selectedSubscriptionType={selectedSubscriptionType}
                    onChange={handleSubscriptionTypeChange}
                />
            </Section>
            {selectedSubscriptionType && (
                <Fragment>
                    <Section variant="headless" header="Billing">
                        <RadioGroup<SubscriptionBillingType>
                            selectedValue={selectedBillingType}
                            items={getBillingRadioItems(selectedSubscriptionType, featureAccountUpgrade)}
                            onChange={setSelectedBillingType}
                        />
                    </Section>
                    <Section variant="headless" header="Billing cycle length">
                        <RadioGroup<BillingCycleLengthInMonth>
                            selectedValue={selectedBillingCycleLength}
                            items={billingCycleRadioItems}
                            onChange={dataSetter('selectedBillingCycleLength', Number)}
                        />
                    </Section>
                    <Section variant="headless" header="Activation date">
                        <RadioGroup<ActivationDate>
                            groupDisabled={!typeAndBillingData.selectedBillingCycleLength}
                            selectedValue={selectedActivationDateType}
                            items={getActivationDateRadioItems({
                                onChange: dataSetter('activationDate'),
                                billingCycleLength: selectedBillingCycleLength,
                                selectedType: selectedActivationDateType,
                                showActivateLater: true,
                                selectedSubscriptionType,
                                selectedBillingType,
                                activationDate,
                            })}
                            onChange={setSelectedActivationDateType}
                        />
                    </Section>
                    <Section variant="headless" header="Expires in">
                        <Box maxWidth={additionalShortInputWidth}>
                            <DurationInput
                                disabled={selectedBillingType === SubscriptionBillingType.INVOICED}
                                value={numberOfCycles}
                                onChange={dataSetter('numberOfCycles')}
                                adornmentText={selectedBillingCycleLength && (
                                    billingCycleLengthRenderMapping[selectedBillingCycleLength]
                                )}
                            />
                        </Box>
                    </Section>
                </Fragment>
            )}
        </StepContainer>
    );
};

export default TypeAndBillingStep;
