import React, { FC } from 'react';
import { getExpirationText } from './services/getters';
import CardType from 'src/components/CreditCard/CardType';
import { PaymentMethodDetails } from 'src/@types/sso-api';

type PaymentMethodRowProps = {
    paymentMethod: PaymentMethodDetails;
};

const PaymentMethodRow: FC<PaymentMethodRowProps> = ({ paymentMethod }) => {
    const walletType = paymentMethod.card?.walletType || null;
    const cardBrand = paymentMethod.card?.brand;
    const lastFourDigits = paymentMethod.card?.last4 ? ` •••• ${paymentMethod.card.last4}` : '';

    return (
        <CardType
            iconsGap={{ xs: 1.5, sm: 2 }}
            showWalletName
            brand={cardBrand}
            wallet={walletType}
            additionalCardInfo={lastFourDigits}
            shouldHideBrandIcon={!!walletType}
            secondaryInfo={getExpirationText(paymentMethod.card.expMonth, paymentMethod.card.expYear)}
        />
    );
};

export default PaymentMethodRow;
