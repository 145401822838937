import React, { FC, useState } from 'react';
import { BoxProps, Grid, StackProps } from '@mui/material';
import { RenewalDataRow } from 'src/components/Modals/UpcomingRenewalModal/components';
import type { BillingRecordModel } from 'src/@types/subscription-service-api';
import { PriceInput } from 'src/components/Inputs';
import { StyledTextButton } from 'src/components/Modals/UpcomingRenewalModal/UpcomingRenewalModal.styles';
import { formatPrice } from 'src/services/formatters';
import { Button } from 'src/components/Buttons';

type PriceRowProps = {
    handleSave: (renewal: Partial<BillingRecordModel>) => Promise<void> | void;
    finalPrice: number | null;
    oldPrice?: number | null;
    skipLine?: boolean;
    title: string;
    paddingTop?: BoxProps['paddingTop'];
    paddingBottom?: BoxProps['paddingBottom'];
    height?: StackProps['height'];
};

const PriceRow: FC<PriceRowProps> = ({
    finalPrice,
    handleSave,
    skipLine,
    oldPrice,
    title,
    height,
    paddingTop,
    paddingBottom = { xs: 0.5, sm: 0 },
}) => {
    const [showOverridePriceInput, setShowOverridePriceInput] = useState<boolean>(false);
    const [price, setPrice] = useState<number | null>(finalPrice);

    const isSaveOverridePriceDisabled = () => price === finalPrice || !Number(price);

    return (
        <RenewalDataRow
            title={title}
            height={height}
            skipLine={skipLine}
            formatter={formatPrice}
            oldPrice={oldPrice}
            paddingTop={paddingTop ?? { xs: showOverridePriceInput ? 0 : 0.5, sm: 0 }}
            paddingBottom={paddingBottom}
            currentRenewalData={finalPrice}
            overrideButton={(
                <>
                    {!showOverridePriceInput && (
                        <StyledTextButton onClick={() => setShowOverridePriceInput(true)}>
                            Override price
                        </StyledTextButton>
                    )}
                </>
            )}
        >
            {showOverridePriceInput && (
                <Grid
                    container
                    spacing={1}
                    marginTop={{ xs: 0, sm: 0.5 }}
                    marginBottom={{ xs: skipLine ? 0 : 1, sm: skipLine ? 0.5 : 2 }}
                >
                    <Grid item xs={9} sm={8}>
                        <PriceInput
                            initialPrice={price}
                            placeholder="Override price"
                            handleChange={setPrice}
                        />
                    </Grid>
                    <Grid item xs={3} sm={4}>
                        <Button
                            fullWidth
                            disabled={isSaveOverridePriceDisabled()}
                            minSize="unset"
                            onClick={() => {
                                Promise.resolve(handleSave({ priceInCents: price }))
                                    .then(() => setShowOverridePriceInput(false));
                            }}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )}
        </RenewalDataRow>
    );
};

export default PriceRow;
