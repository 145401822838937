import dayjs, { Dayjs, isDayjs } from 'dayjs';
import { TimeZoneType } from './profile-settings-helpers';

let timeZone = 'UTC';

export const setTimeZone = (userTimeZone: string) => {
    timeZone = userTimeZone;
};

export const convertDateTimeZone = (date: number | string | Date | Dayjs): Dayjs => {
    return timeZone === TimeZoneType.UTC ? dayjs.utc(date) : dayjs.utc(date).tz(timeZone);
};

export const convertToUTCDate = (date: string | Dayjs | null | undefined): Dayjs => {
    return isDayjs(date) ? date.utc() : dayjs.utc(date);
};

export const getTimeZone = (): string => {
    return timeZone;
};
