import React, { FC, useMemo, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { useReloadPage } from 'src/hooks/useReloadPage';
import { Button } from 'src/components/Buttons';
import { changePhoneNumber, changePhoneNumberSend, clearPhoneNumber } from 'src/services/sso-api';
import PhoneNumberInput from 'src/components/Inputs/PhoneNumberInput';
import { FormWrapper } from 'src/components/Forms/components';
import { useIsSubmitDisabled, useSnackbarMessage } from 'src/hooks';
import { phoneNumberConfirmCodeValidator, validateSchemaObject } from 'src/services/validators';
import { GeneralModalKey, SnackbarMessageVariants } from 'src/constants';
import ConfirmPhoneNumberInput from 'src/components/Inputs/ConfirmPhoneNumberInput';
import { FormikValuesType } from 'src/@types/forms';
import { useConfirmationModal } from 'src/hooks/useConfirmationModal';
import { getResponseError } from 'src/services/error-formatters';

type ChangePhoneNumberFormProps = {
    initialPhoneNumber: string;
};

const ChangePhoneNumberForm: FC<ChangePhoneNumberFormProps> = ({ initialPhoneNumber }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [showSendButton, setShowSendButton] = useState<boolean>(false);
    const [showConfirmButton, setShowConfirmButton] = useState<boolean>(false);
    const [showConfirmForm, setShowConfirmForm] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState<string>(initialPhoneNumber);

    const { reloadPage } = useReloadPage();
    const { addMessage } = useSnackbarMessage();
    const { openModal } = useConfirmationModal({
        onConfirm: () => {
            return clearPhoneNumber()
                .then(() => {
                    addMessage('Phone number removed', SnackbarMessageVariants.SUCCESS);
                    reloadPage();
                    setPhoneNumber('');
                }).catch(() => {
                    addMessage('Failed to remove phone number', SnackbarMessageVariants.ERROR);
                });
        },
    });

    const {
        isSubmitDisabled: isConfirmSubmitDisabled,
        onFormChangeHandler: onConfirmFormChangeHandler,
        setIsSubmitDisabled: setIsConfirmSubmitDisabled,
    } = useIsSubmitDisabled({ code: '' });

    const shouldShowRemoveButton = useMemo(() => {
        const hasInitialPhoneNumber = !!initialPhoneNumber.length;

        if (!hasInitialPhoneNumber) {
            return false;
        }

        const wasPhoneNumberRemoved = !phoneNumber;
        const wasPhoneNumberChanged = phoneNumber !== initialPhoneNumber;

        return wasPhoneNumberRemoved || !wasPhoneNumberChanged;
    }, [phoneNumber, initialPhoneNumber]);

    const handleSubmitPhoneNumberSend = () => {
        const prevConfirmSubmitDisabled = isConfirmSubmitDisabled;
        setIsSubmitDisabled(true);
        setIsConfirmSubmitDisabled(true);
        setIsSubmitting(true);

        changePhoneNumberSend({
            phoneNumber,
        })
            .then(() => {
                setShowConfirmForm(true);
                setShowSendButton(false);
            })
            .catch((error) => {
                addMessage(
                    getResponseError(error, 'Failed to add phone number'),
                    SnackbarMessageVariants.ERROR,
                );
            })
            .finally(() => {
                setIsSubmitDisabled(false);
                setIsConfirmSubmitDisabled(prevConfirmSubmitDisabled);
                setIsSubmitting(false);
            });
    };

    const handleSubmitPhoneNumberConfirm = (values: FormikValuesType) => {
        const prevSubmitDisabled = isSubmitDisabled;
        setIsSubmitDisabled(true);
        setIsConfirmSubmitDisabled(true);
        setIsSubmitting(true);

        changePhoneNumber({
            code: values.code,
            phoneNumber,
        })
            .then(() => {
                addMessage('Phone number added successfully', SnackbarMessageVariants.SUCCESS);
                reloadPage();
                setShowConfirmForm(false);
            })
            .catch((error) => {
                addMessage(
                    getResponseError(error, 'Failed to confirm phone number change'),
                    SnackbarMessageVariants.ERROR,
                );
            })
            .finally(() => {
                setIsSubmitDisabled(prevSubmitDisabled);
                setIsConfirmSubmitDisabled(false);
                setIsSubmitting(false);
            });
    };

    const handleRemoveButtonClick = () => {
        openModal({
            key: GeneralModalKey.confirmationModal,
            title: 'Remove phone number',
            extraProps: {
                confirmButtonText: 'Proceed',
                header: 'Are you sure you want to proceed?',
                text: 'Your phone number will be deleted from our records and you will no longer be able to receive newsletters via SMS.',
            },
        });
    };

    const handleValidation = (newValue: string, isValid: boolean) => {
        if (!newValue) {
            setIsSubmitDisabled(!initialPhoneNumber);
            return;
        }

        setIsSubmitDisabled(!isValid);
    };

    const handleClickAway = () => {
        if (shouldShowRemoveButton && phoneNumber === initialPhoneNumber) {
            setShowSendButton(false);
            return;
        }

        setShowSendButton(!isSubmitDisabled && !showConfirmForm);
    };

    const inputButton = shouldShowRemoveButton ? (
        <Button
            fullWidth
            testId="change-phone-number-remove-button"
            disabled={isSubmitDisabled}
            type="submit"
            variant="primary"
            minSize="small"
            onClick={handleRemoveButtonClick}
        >
            Remove
        </Button>
    ) : (
        <Button
            fullWidth
            testId="change-phone-number-send-button"
            disabled={isSubmitDisabled}
            type="submit"
            variant="primary"
            minSize="small"
            onClick={handleSubmitPhoneNumberSend}
        >
            Verify
        </Button>
    );

    return (
        <Grid item md={6} xs={12}>
            <PhoneNumberInput
                testId="phone-number-input"
                name="phoneNumber"
                label="Phone number"
                initialValue={initialPhoneNumber}
                placeholder=""
                onFocus={() => setShowSendButton(true)}
                onValidation={handleValidation}
                onValueChange={(value) => setPhoneNumber(value)}
                handleClickAway={handleClickAway}
                showButton={showSendButton}
                button={inputButton}
            />
            {showConfirmForm && (
                <FormWrapper
                    onFormChange={onConfirmFormChangeHandler}
                    isSubmitting={isSubmitting}
                    validationSchema={validateSchemaObject({ code: phoneNumberConfirmCodeValidator })}
                    initialValues={{ code: '' }}
                    onSubmit={handleSubmitPhoneNumberConfirm}
                >
                    <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                        marginTop={{ md: 4, xs: 2.5 }}
                        marginBottom={{ md: 0, xs: 1.5 }}
                    >
                        <ConfirmPhoneNumberInput
                            testId="confirm-phone-input"
                            name="code"
                            placeholder="Enter the 6-digit SMS code"
                            flex={1}
                            onFocus={() => setShowConfirmButton(true)}
                            onBlur={() => setShowConfirmButton(!isConfirmSubmitDisabled)}
                            handleButtonClick={handleSubmitPhoneNumberSend}
                        />
                        {showConfirmButton && (
                            <Button
                                testId="change-phone-number-verify-button"
                                disabled={isConfirmSubmitDisabled}
                                type="submit"
                                variant="primary"
                                minSize="small"
                            >
                                Confirm
                            </Button>
                        )}
                    </Stack>
                </FormWrapper>
            )}
        </Grid>
    );
};

export default ChangePhoneNumberForm;
