import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { StyledRow } from 'src/components/Modals/InvoiceModals/InvoiceModals.styles';
import StyledTooltip, { StyledMultilineTooltipBody } from 'src/components/Tooltip';
import { DateFormats, SubscriptionType } from 'src/constants';
import { formatDate } from 'src/services/formatters';
import { BillingRecordWithRenewal } from 'src/@types/subscription-service-api';
import Line from 'src/components/Line';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';

export type InvoiceModalContentProps = {
    billingRecord: BillingRecordWithRenewal;
    subscriptionType: SubscriptionType;
};

const InvoiceModalContent: FC<InvoiceModalContentProps> = ({ billingRecord, subscriptionType }) => {
    const {
        cycleStart,
        cycleEnd,
        requestedAmountOfUsers,
        domains,
    } = billingRecord;

    return (
        <>
            <Stack paddingY={{ xs: 0.5, sm: 1.25 }} flexDirection="row" justifyContent="space-between">
                <StyledRow>Billing cycle:</StyledRow>
                <StyledRow>{`${formatDate(cycleStart, DateFormats.DATE)} - ${formatDate(cycleEnd, DateFormats.DATE)}`}</StyledRow>
            </Stack>
            <Line />
            <Stack paddingY={{ xs: 0.5, sm: 1.25 }} flexDirection="row" justifyContent="space-between">
                <StyledRow>Amount of users:</StyledRow>
                <StyledRow>{requestedAmountOfUsers || 0}</StyledRow>
            </Stack>
            <Line />
            {subscriptionType === SubscriptionType.ENTERPRISE && (
                <>
                    <Stack paddingY={{ xs: 0.5, sm: 1.25 }} flexDirection="row" justifyContent="space-between">
                        <StyledRow>Covered domains:</StyledRow>
                        <Stack flexDirection="row" gap={0.5}>
                            <StyledRow>{domains?.length ?? 0}</StyledRow>
                            {domains?.length ? (
                                <StyledTooltip
                                    arrow
                                    title={
                                        <StyledMultilineTooltipBody>
                                            {domains.join('\n')}
                                        </StyledMultilineTooltipBody>
                                    }
                                >
                                    <InfoIcon data-testid="info-icon" />
                                </StyledTooltip>
                            ) : null}
                        </Stack>
                    </Stack>
                    <Line />
                </>
            )}
        </>
    );
};

export default InvoiceModalContent;
