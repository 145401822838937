import { Box, Stack } from '@mui/material';
import styled from 'styled-components';

export const NewslettersSetHeader = styled(Stack)`
    svg {
        height: 11px;
        width: auto;
        max-width: 100%;

        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
            height: 16px;
        }
    }

    && .MuiTypography-root {
        font-family: ${({ theme }) => theme.fonts.normal};
        color: ${({ theme }) => theme.palette.lightBlack};
        font-size: 14px;
        line-height: normal;

        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
            font-size: 16px;
        }
    }
`;

export const StyledSelectAllContainer = styled(Stack)`

    && .MuiTypography-root {
        display: none;
        
        @media(min-width: ${({ theme }) => theme.breakpoints.md}){
            display: block;
        }
    }
`;

export const TitleContainer = styled(Box)`
    background-color: ${({ theme }) => theme.palette.white};
`;
