import styled from 'styled-components';
import { List, Typography } from '@mui/material';

export const StyledTitle = styled(Typography)`
  && {
    max-width: 460px;
    padding-bottom: 4px;
    font-family: ${({ theme }) => theme.fonts.bold};
    color: ${({ theme }) => theme.palette.black};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
      
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 14px;
      padding-bottom: 0;
    }
  }
`;

export const StyledList = styled(List)`
  && {
    padding:0;
        
    .MuiListItem-root{
      padding: 16px 0 0 0;
            
      .MuiTypography-root{
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.lightBlack};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
          
        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
          font-size: 14px;
        }
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        padding: 20px 0 0 0;
      }
    }
  }
`;
