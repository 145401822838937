import React, { FC, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { StyledBox } from './EditUserNameModal.styles';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import { BaseModal } from 'src/components/Modals/BaseModal';
import { updateUser } from 'src/services/unified-db-api';
import { LabeledInput } from 'src/components/Inputs';
import { Button } from 'src/components/Buttons';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { MAX_FULL_NAME_LENGTH, SnackbarMessageVariants } from 'src/constants';

export type EditUserNameModalProps = {
    name: string;
    uuid: string;
    setIsConfirming: (isConfirming: boolean) => void;
    isConfirming: boolean;
    isSubmitDisabled: boolean;
};

const EditUserNameModalContent: FC<EditUserNameModalProps> = ({
    name,
    uuid,
    setIsConfirming,
    isConfirming,
    isSubmitDisabled,
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [newName, setNewName] = useState<string>(name);

    const { addMessage, addErrorMessage } = useSnackbarMessage();
    const { closeModal, updateModal } = useGeneralModal();
    const { reloadPage } = useReloadPage();

    const handleSubmit = async () => {
        setIsConfirming(false);
        setIsSubmitting(true);
        return updateUser(uuid, {
            fullName: newName,
        })
            .then(() => {
                addMessage('User’s name updated successfully', SnackbarMessageVariants.SUCCESS);
                reloadPage();
                closeModal();
            })
            .catch((error) => {
                setIsSubmitting(false);
                updateModal({ closeIsBlocked: false });
                addErrorMessage('Failed to update user’s name', error);
            });
    };

    const handleConfirmClose = () => {
        setIsConfirming(false);
    };

    if (isConfirming) {
        return (
            <BaseModal
                step={1}
                handleGoBack={handleConfirmClose}
                open={true}
                handleClose={handleConfirmClose}
                title="Confirm changes"
            >
                <ConfirmationModal
                    modalId="edit-user-modal"
                    subtitle={
                        <StyledBox>
                            Name changed from <b>{name}</b> to <b>{newName}</b>
                        </StyledBox>
                    }
                    confirmButtonText="Confirm"
                    cancelButtonText="Go back"
                    onConfirm={handleSubmit}
                    onCancel={handleConfirmClose}
                />
            </BaseModal>
        );
    }

    return (
        <Stack>
            {isSubmitting && <LoaderOverlay />}
            <Grid container spacing={{ xs: 2.5, sm: 4 }}>
                <Grid item xs={12}>
                    <LabeledInput
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewName(event.target.value.trim())}
                        testId="name-input"
                        name="name"
                        label="Name"
                        placeholder=""
                        inputMaxLength={MAX_FULL_NAME_LENGTH}
                    />
                </Grid>
            </Grid>
            <Stack direction="row" spacing={{ xs: 2, sm: 2.5 }} marginTop={{ xs: 3, sm: 6 }}>
                <Button
                    minSize="small"
                    data-testid="edit-user-cancel-button"
                    onClick={closeModal}
                    fullWidth
                    variant="secondary"
                >
                        Cancel
                </Button>
                <Button
                    minSize="small"
                    fullWidth
                    data-testid="edit-user-submit-button"
                    disabled={isSubmitDisabled || isSubmitting || !newName}
                    type="submit"
                >
                    Save
                </Button>
            </Stack>
        </Stack>
    );
};

export default EditUserNameModalContent;
