import styled, { css } from 'styled-components';
import { DataGrid, GridColumnMenu, GridPaginationModel, GridPreferencesPanel } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { StyledIcon } from 'src/components/CopyIcon/CopyIcon.styles';

export const ROWS_HEIGHT = 55;
export const STICKY_COLUMN_CLASS_NAME = 'DataGridStickyColumn';
export const cellPadding = css`
    padding: 0 16px 0 24px;
`;

export const cellStyles = css`
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      white-space: initial;
      display: -webkit-box;
      max-height: 46px;
      line-height: 23px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      user-select: none;
`;

export const cellWithIconStyles = css`
    position: absolute;
    top: 1px;
    right: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${ROWS_HEIGHT}px;
    padding: 0 16px 0 12px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 18%, #FFF 100%)
`;

export const StyledDataGrid = styled(DataGrid)<{ minDataRows: number, paginationModel?: GridPaginationModel, smallHeight: boolean }>`
    &&& {
        --gridScrollWidth: 0;

        margin-top: 16px;
        border: none;
        background-color: ${({ theme }) => theme.palette.white};
        max-height: ${({ theme, smallHeight }) => `calc(100vh - ${smallHeight ? '238px' : '180px'} - ${theme.sizes.footerHeight.xs})`};
        min-height: ${({ minDataRows, paginationModel, smallHeight }) => ROWS_HEIGHT * (smallHeight ? minDataRows : minDataRows + 1) + (paginationModel ? 85 : 0)}px;
        
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            max-height: ${({ theme, smallHeight }) => `calc(100vh - ${smallHeight ? '260px' : '199px'} - ${theme.sizes.footerHeight.xs})`};
        }
        
        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
            margin-top: 20px;
            max-height: ${({ theme, smallHeight }) => `calc(100vh - ${smallHeight ? '292px' : '235px'} - ${theme.sizes.footerHeight.md})`};
        }

        .MuiDataGrid-row {
            &:hover {
                background-color: ${({ theme }) => theme.palette.dataGridHoverBackground};
                & .${STICKY_COLUMN_CLASS_NAME} {
                    background-color: ${({ theme }) => theme.palette.dataGridHoverBackground};
                }

                & ${StyledIcon} {
                    background: linear-gradient(90deg, rgba(251, 252, 255, 0.00) 0%, ${({ theme }) => theme.palette.dataGridHoverBackground} 18%, ${({ theme }) => theme.palette.dataGridHoverBackground} 100%)
                }
            }

            &:nth-child(n + ${({ minDataRows }) => minDataRows + 1}) {
                &.MuiDataGrid-row--lastVisible {
                    .MuiDataGrid-cell {
                        border-bottom: none;
                    }
                }
            }

            &.Mui-selected {
                background-color: ${({ theme }) => theme.palette.dataGridSelectedBackground};
                & .${STICKY_COLUMN_CLASS_NAME} {
                    background-color: ${({ theme }) => theme.palette.dataGridSelectedBackground};
                }

                & ${StyledIcon} {
                    background: linear-gradient(90deg, rgba(244, 246, 253, 0.00) 0%, ${({ theme }) => theme.palette.dataGridSelectedBackground} 18%, ${({ theme }) => theme.palette.dataGridSelectedBackground} 100%)
                }
            }
        }

        .MuiDataGrid-columnHeaders {
            &.MuiDataGrid-withBorderColor {
                border-color: ${({ theme }) => theme.palette.mainBorderColor};

                .MuiDataGrid-columnHeaderDraggableContainer {
                    .MuiDataGrid-columnHeaderTitleContainer {
                        justify-content: start;
                    }

                    .MuiDataGrid-menuIcon {
                        ${cellWithIconStyles};
                        right: 12px;

                        & button {
                            padding: 0;

                            &:hover {
                                background: none;
                            }
                        }
                    }
                }
            }

            &:after {
                display: block;
                width: var(--gridScrollWidth);
                background-color: ${({ theme }) => theme.palette.white};
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
            }

            .MuiDataGrid-columnHeader {
                border-left: 1px solid ${({ theme }) => theme.palette.mainBorderColor};
                border-right: none;
                font-size: 16px;
                max-height: 55px;
                font-family: ${({ theme }) => theme.fonts.bold};
                color: ${({ theme }) => theme.palette.lightBlack};
                position: relative;
                & + .${STICKY_COLUMN_CLASS_NAME} {
                    position: sticky;
                    border-right: 1px solid ${({ theme }) => theme.palette.mainBorderColor};
                    border-left: 1px solid ${({ theme }) => theme.palette.mainBorderColor};
                }

                ${cellPadding}

                & .MuiDataGrid-columnHeaderTitle {
                    line-height: 21px;

                    ${cellStyles}
                }

                &.MuiDataGrid-withBorderColor {
                    border-color: ${({ theme }) => theme.palette.mainBorderColor};
                }

                &[aria-colindex="1"] {
                    border-left: none;
                }

                .MuiDataGrid-columnSeparator {
                    display: none;
                }

                &.MuiDataGrid-columnHeaderCheckbox {
                    padding: 0;

                    .MuiDataGrid-columnHeaderTitleContainer {
                        justify-content: center;
                    }
                }
            }
        }

        .MuiDataGrid-cell {
            color: ${({ theme }) => theme.palette.black};
            border-left: 1px solid ${({ theme }) => theme.palette.mainBorderColor};
            border-right: none;
            font-size: 16px;
            padding: 0;
            font-family: ${({ theme }) => theme.fonts.normal};
            width: 100%;
            position: relative;

            &:focus {
                outline: 1px solid #85AEE6;
            }

            & .MuiDataGrid-cellContent {
                ${cellPadding}
                ${cellStyles}
            }

            &.MuiDataGrid-withBorderColor {
                border-color: ${({ theme }) => theme.palette.mainBorderColor};
            }

            &[data-colindex='0'],
            &:not([data-colindex]) {
                border-left: none;
            }
        }

        .MuiDataGrid-virtualScroller {
            min-height: ${({ smallHeight }) => `${ROWS_HEIGHT * (smallHeight ? 3 : 4) - 3}px`};
        }

        & .${STICKY_COLUMN_CLASS_NAME} {
            position: sticky;
            right: 0;
            left: 0;
            z-index: 1;
            border-right: 1px solid ${({ theme }) => theme.palette.mainBorderColor};
            border-left: 1px solid ${({ theme }) => theme.palette.mainBorderColor};
            background-color: ${({ theme }) => theme.palette.white};
        }
    }
`;

export const StyledGridColumnMenu = styled(GridColumnMenu)`
    .MuiDivider-root {
        display: none;
    }


    & .MuiTypography-root {
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
            font-size: 16px;
        }
    }
}
`;

export const StyledNoRowsOverlay = styled(Typography)`
    text-align: center;
    line-height: ${ROWS_HEIGHT}px !important;
    font-family: ${({ theme }) => theme.fonts.normal} !important;
`;

export const StyledGridColumnsPanel = styled(GridPreferencesPanel)`
    .MuiPaper-root {
        max-height: unset;
    }
`;
