import styled, { css } from 'styled-components';
import { TextButton } from 'src/components/Buttons/TextButton';

export enum ActionButtonVariant {
    GREY = 'grey',
    RED = 'red',
    BLUE = 'blue',
}

type ActionButtonProps = {
    'data-variant': ActionButtonVariant;
    'data-attribute-to-change': 'stroke' | 'fill';
    target?: '_blank';
};

const variantSelector = ({ 'data-variant': variant, 'data-attribute-to-change': attributeToChange }: ActionButtonProps) => {
    switch (variant) {
        case ActionButtonVariant.RED:
            return css`
                background-color: #FFF8F8;

                &:hover {
                    background-color: #FAEDEC;

                    & svg {
                        & path {
                            ${attributeToChange}:${({ theme }) => theme.palette.darkRed};
                        }
                    }
                }

                &:active {
                    background-color: #FCE0DD;

                    & svg {
                        & path {
                            ${attributeToChange}:${({ theme }) => theme.palette.darkRed};
                        }
                    }
                }

                &:focus-visible {
                    outline: none;
                    border: 1px solid ${({ theme }) => theme.palette.red};
                }
            `;
        case ActionButtonVariant.GREY:
            return css`
                background-color: #F7F8FA;

                &:hover {
                    background-color: #ECEFF4;

                    & svg {
                        & path {
                            ${attributeToChange}:${({ theme }) => theme.palette.lightBlack};
                        }
                    }
                }

                &:active {
                    background-color: #DDE0E5;

                    & svg {
                        & path {
                            ${attributeToChange}:${({ theme }) => theme.palette.lightBlack};
                        }
                    }
                }
            `;
        case ActionButtonVariant.BLUE:
            return css`
                background-color: #F3F8FF;

                &:hover {
                    background-color: #E8EFF8;
                }

                &:active {
                    background-color: #D3E1F3;
                }
            `;
    }
};

export const ActionButton = styled(TextButton)<ActionButtonProps>`
    &&& {
        border-radius: 4px;
        border: 1px solid transparent;
        
        &:disabled {
            opacity: 0.5;
        }
        
        ${variantSelector}
    }
`;

export const InputActionButton = styled(ActionButton)`
    &&& {
        padding: 3px;
        height: 26px;
        width: 26px;
        
        svg {
            height: 20px;
            width: 20px;
        }
    }
`;
