import React, { FC, useState } from 'react';
import { type BoxProps } from '@mui/material';
import { ErrorMessage, Field, FieldProps } from 'formik';
import { toUpperCaseWords } from 'src/services/formatters';
import PriceInput from 'src/components/Inputs/PriceInput';
import { StyledErrorMessage, StyledFieldContainer } from 'src/components/Inputs/StyledInput.styles';
import { Label } from 'src/components/Label';

type PriceLabeledInputProps = {
    name: string;
    placeholder?: string;
    label?: string;
    testId?: string;
    labelIcon?: JSX.Element;
    required?: boolean;
    disabled?: boolean;
    errorPosition?: 'relative' | 'absolute';
} & BoxProps;

const PriceFormInput: FC<PriceLabeledInputProps> = ({
    name,
    placeholder,
    label,
    testId,
    labelIcon,
    required,
    disabled,
    errorPosition = 'absolute',
    ...BoxProps
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const preparedTestId = testId ?? `${name}-input`;
    const preparedLabel = label ?? toUpperCaseWords(name);

    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => {
                const hasError = !!form.errors[name] && !!form.touched[name];
                return (
                    <StyledFieldContainer data-testid={preparedTestId} {...BoxProps}>
                        {preparedLabel && (
                            <Label
                                isFocused={isFocused}
                                icon={labelIcon}
                                htmlFor={name}
                                required={required}
                            >
                                {preparedLabel}
                            </Label>
                        )}
                        <PriceInput
                            name={name}
                            placeholder={placeholder}
                            initialPrice={field.value}
                            handleChange={(price) => {
                                form.setFieldValue(name, price);
                                form.setFieldError(name, undefined);
                            }}
                            className={hasError && !isFocused ? 'invalid' : ''}
                            onBlur={() => setIsFocused(false)}
                            onFocus={() => setIsFocused(true)}
                            disabled={disabled}
                            inputProps={{
                                'data-testid': `${preparedTestId}-element`,
                                'data-required': required || undefined,
                            }}
                        />
                        <ErrorMessage
                            name={name}
                            render={(message) => (
                                message.trim() ? (
                                    <StyledErrorMessage
                                        dangerouslySetInnerHTML={{ __html: message }}
                                        position={errorPosition}
                                    />
                                ) : null
                            )}
                        />
                    </StyledFieldContainer>
                );
            }}
        </Field>
    );
};

export default PriceFormInput;
