import React, { FC, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { BillingCycleRow, SignedText } from './ContractDetailsModal.styles';
import { getContractConfirmationMessage } from './services/message-builders';
import type {
    BillingRecordModel,
    BillingRecordWithRenewal,
    SubscriptionModel,
} from 'src/@types/subscription-service-api';
import { startPolling } from 'src/redux/slices';
import Line from 'src/components/Line';
import { formatDate } from 'src/services/formatters';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import { BaseModal } from 'src/components/Modals/BaseModal';
import { SwitchAdornment } from 'src/components/Inputs/Adornments/Switch';
import { DateFormats, INVALID_SALESFORCE_CONTRACT_LINK } from 'src/constants';
import { contractLinkValidator, validateSchemaObject } from 'src/services/validators';
import { LabeledInput } from 'src/components/Inputs';
import { useGeneralModal, useIsSubmitDisabled, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { Button } from 'src/components/Buttons';
import { FormWrapper } from 'src/components/Forms/components';
import { updateBillingRecord } from 'src/services/subscription-service-api';

export type ContractDetailsModalProps = {
    billingRecord: BillingRecordWithRenewal;
    subscription: SubscriptionModel;
    isActivationAllowed: boolean;
    pollingId?: string;
};

const ContractDetailsModal: FC<ContractDetailsModalProps> = ({
    billingRecord,
    subscription,
    isActivationAllowed,
    pollingId,
}) => {
    const { cycleStart, cycleEnd, contractLink, contractSigned, id } = billingRecord;

    const initialValues: Partial<BillingRecordModel> = {
        contractLink: contractLink || '',
        contractSigned: contractSigned,
    };

    const dispatch = useDispatch();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const [isContractJustSigned, setIsContractJustSigned] = useState<boolean>(false);
    const [updateData, setUpdateData] = useState<Partial<BillingRecordModel>>(initialValues);

    const { onFormChangeHandler, isSubmitDisabled, setIsSubmitDisabled } = useIsSubmitDisabled(initialValues);
    const { reloadPage } = useReloadPage();
    const { addErrorMessage } = useSnackbarMessage();
    const { closeModal } = useGeneralModal();

    const isAdd = !contractLink && !contractSigned;
    const confirmationMessage = getContractConfirmationMessage({
        billingRecord,
        subscription,
        isActivationAllowed,
        isContractJustSigned,
    });

    const handleSaveContractDetails = async (values: Partial<BillingRecordModel>) => {
        setIsSubmitting(true);
        return updateBillingRecord(subscription.uuid, id, values)
            .then(() => {
                if (!!confirmationMessage && pollingId) {
                    dispatch(startPolling(pollingId));
                }

                reloadPage();
                closeModal();
            })
            .catch((error) => {
                addErrorMessage(`Failed to ${isAdd ? 'add' : 'update'} contract details`, error);
                setIsSubmitDisabled(false);
            })
            .finally(() => setIsSubmitting(false));
    };

    const handleSubmit = (values: Partial<BillingRecordModel>, helpers: FormikHelpers<Partial<BillingRecordModel>>) => {
        if (contractLink && !values.contractLink) {
            helpers.setErrors({
                contractLink: INVALID_SALESFORCE_CONTRACT_LINK,
            });
            return;
        }

        setUpdateData(values);

        if (confirmationMessage) {
            setIsConfirming(true);
        } else {
            handleSaveContractDetails(values);
        }
    };

    const handleCloseConfirmModal = () => {
        setIsConfirming(false);
    };

    const handleContractSignChange = (newValue: boolean) => {
        setIsContractJustSigned(!billingRecord.contractSigned && newValue);
    };

    if (isConfirming && confirmationMessage) {
        return (
            <BaseModal
                step={1}
                open={true}
                title="Confirm changes"
                handleClose={handleCloseConfirmModal}
                handleGoBack={handleCloseConfirmModal}
            >
                <ConfirmationModal
                    modalId="confirm-contract-details-modal"
                    subtitle={(<Box>{confirmationMessage}</Box>)}
                    onConfirm={() => handleSaveContractDetails(updateData)}
                    onCancel={handleCloseConfirmModal}
                />
            </BaseModal>
        );
    }

    return (
        <FormWrapper
            onFormChange={onFormChangeHandler}
            testId="contract-link-form"
            isSubmitting={isSubmitting}
            initialValues={updateData}
            onSubmit={handleSubmit}
            validationSchema={validateSchemaObject({ contractLink: contractLinkValidator })}
        >
            {cycleStart && cycleEnd && (
                <Stack spacing={{ xs: 2, sm: 2.5 }} marginBottom={{ xs: 3, sm: 4 }}>
                    <BillingCycleRow>
                        <Typography>Billing cycle:</Typography>
                        <Typography>{`${formatDate(cycleStart, DateFormats.DATE)} - ${formatDate(cycleEnd, DateFormats.DATE)}`}</Typography>
                    </BillingCycleRow>
                    <Line />
                </Stack>
            )}
            <Stack position="relative">
                <SignedText>Signed</SignedText>
                <LabeledInput
                    disallowSpace
                    flexGrow={1}
                    placeholder="Add contract link"
                    name="contractLink"
                    label="Contract link"
                    testId="contract-link-input"
                    endAdornment={(
                        <SwitchAdornment
                            name="contractSigned"
                            ariaLabel="contract signed switch"
                            onClick={handleContractSignChange}
                        />
                    )}
                />
                <Stack direction="row" marginTop={{ xs: 3, md: 6 }} spacing={{ xs: 2, sm: 2.5 }}>
                    <Button onClick={closeModal} minSize="small" variant="secondary" fullWidth>Cancel</Button>
                    <Button disabled={isSubmitting || isSubmitDisabled} minSize="small" fullWidth type="submit">
                        {isAdd ? 'Add' : 'Update'}
                    </Button>
                </Stack>
            </Stack>
        </FormWrapper>
    );
};

export default ContractDetailsModal;
