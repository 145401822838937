import React, { FC, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure/index';
import type { Stripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { getCheckoutConfig } from 'src/services/sso-api';
import { StripeElements } from 'src/components/Stripe';
import type {
    AccountToUpgrade,
} from 'src/components/Forms/UpgradeToInsiderForms/UpgradeAccountForm/UpgradeAccountForm';
import { SnackbarMessageVariants } from 'src/constants';
import { Spinner } from 'src/components/Spinner';
import RequestError from 'src/components/Forms/components/RequestError/RequestError';
import { CheckoutConfig } from 'src/@types/sso-api';
import { useSnackbarMessage } from 'src/hooks';

type CheckoutProps = {
    accountToUpgrade: AccountToUpgrade;
    isNewUser: boolean;
};

const CheckoutFlow: FC<CheckoutProps> = ({ accountToUpgrade, isNewUser }) => {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [checkoutConfig, setCheckoutConfig] = useState<CheckoutConfig>();
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
    const { addMessage } = useSnackbarMessage();

    useEffect(() => {
        getCheckoutConfig()
            .then(async (config) => {
                setCheckoutConfig(config);
                setStripePromise(loadStripe(config.apiPublicKey));
            })
            .catch(() => {
                addMessage('Failed to get checkout config', SnackbarMessageVariants.ERROR);
            })
            .finally(() => setLoading(false));
    }, []);

    if (isLoading) {
        return <Spinner />;
    }

    if (!checkoutConfig?.product || !stripePromise) {
        return <RequestError>Configuration issue occurred. Please reload the page or contact us</RequestError>;
    }

    return (
        <StripeElements
            stripePromise={stripePromise}
            options={{
                mode: 'subscription',
                amount: checkoutConfig.product.priceInCents,
                currency: 'usd',
            }}
        >
            <CheckoutForm
                priceInCents={checkoutConfig.product.priceInCents}
                priceId={checkoutConfig.product.priceId}
                productName={checkoutConfig.product.productName}
                isNewUser={isNewUser}
                userUUID={accountToUpgrade.uuid}
                userFullName={accountToUpgrade.fullName}
            />
        </StripeElements>
    );
};

export default CheckoutFlow;
