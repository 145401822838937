import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, ClickAwayListener, Stack } from '@mui/material';
import {
    AddButton,
    StyledConditionsIndicator,
    StyledToolBarError,
    StyledToolBarItem,
    StyledToolbarMobileHeader,
    TabsContainer,
    ToolBarButton,
    ToolBarItemTitle,
} from './ToolBarItem.styles';
import { hasParamWithoutCondition } from 'src/components/ToolBar/services/tool-bar-condition-helpers';
import { Overlay } from 'src/components/ToolBar/ToolBar';
import { QuickActions } from 'src/components/ToolBar/components/QuickActions';
import {
    getConditionsModel,
    getErrorText,
    getToolBarModelValue,
    isConditionsModelValid,
} from 'src/components/ToolBar/services/tool-bar-item-helpers';
import { ConditionModelType, SchemaType, ToolbarItemContext, ToolBarModelType } from 'src/@types/tool-bar';
import { ToolBarModelTypes } from 'src/constants';
import { TabBar } from 'src/components/TabBar';
import { AddCondition } from 'src/components/ToolBar/components/AddCondition';
import Line from 'src/components/Line';
import { ReactComponent as AddIcon } from 'src/assets/icons/add-condition-icon.svg';
import { ReactComponent as WarnIcon } from 'src/assets/icons/small-warn-icon.svg';
import { Button } from 'src/components/Buttons';
import { ToolBarContextProvider } from 'src/components/ToolBar/components/ToolBarContext';
import { useKeyDown } from 'src/hooks/useKeyDown';

type CachedConditionsModelType = Record<ToolBarActions, ConditionModelType[]>;
type ToolBarItemProps = {
    setModel: (model: ToolBarModelType) => void;
    model: ToolBarModelType;
    schema: SchemaType;
    toolBarTitle: string;
    selectPlaceholder: string;
    submitLabel: string;
    clearLabel: string;
    toolBarButton: {
        icon: React.ReactNode;
        label: string;
    };
};

export enum ToolBarActions {
    QUICK_ACTIONS = 'quickActions',
    ADD_CONDITIONS = 'addConditions',
}

const ToolBarItem: FC<ToolBarItemProps> = ({
    toolBarButton,
    toolBarTitle,
    schema,
    selectPlaceholder,
    model,
    submitLabel,
    clearLabel,
    setModel,
}) => {
    const isModelAddConditionsType = Object.values(model).length && !model.quickAction;
    const defaultActionType = schema.quickAction && !isModelAddConditionsType ?
        ToolBarActions.QUICK_ACTIONS : ToolBarActions.ADD_CONDITIONS;
    const [hasError, setHasError] = useState<boolean>(false);
    const [actionType, setActionType] = useState<ToolBarActions>(defaultActionType);
    const [isMouseEvent, setIsMouseEvent] = useState<boolean>(false);
    const [conditionsModel, setConditionsModel] = useState<ConditionModelType[]>(getConditionsModel(model));
    const [shouldShowNewCondition, setShouldShowNewCondition] = useState<boolean>(!conditionsModel.length);
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [cachedConditionsModel, setCachedConditionsModel] =
        useState<Record<ToolBarActions, ConditionModelType[]>>({} as CachedConditionsModelType);
    const [isChanged, setChanged] = useState<boolean>(false);

    const modelLength = getConditionsModel(model).length;
    const dataHasCondition = actionType === ToolBarActions.ADD_CONDITIONS && (
        schema.modelType === ToolBarModelTypes.FILTERING && conditionsModel.filter(([param]) => param !== 'quickAction').length);
    const hasConditionsModelQuickAction = conditionsModel.some(([param]) => param === 'quickAction');
    const shouldShowItemButtons = conditionsModel.length && (
        actionType === ToolBarActions.ADD_CONDITIONS && !hasConditionsModelQuickAction
    ) || (
        actionType === ToolBarActions.QUICK_ACTIONS && hasConditionsModelQuickAction
    );

    useEffect(() => {
        setConditionsModel(getConditionsModel(model));
    }, [JSON.stringify(model)]);

    useEffect(() => {
        setChanged(JSON.stringify(getConditionsModel(model)) !== JSON.stringify(conditionsModel));
    }, [conditionsModel]);

    const handleOpen = (isMouse: boolean) => {
        setIsOpened(true);
        setIsMouseEvent(isMouse);
        setActionType(defaultActionType);
    };

    const handleClose = () => {
        setIsOpened(false);
        setConditionsModel(getConditionsModel(model));
        setShouldShowNewCondition(!modelLength);
        setActionType(defaultActionType);
        setCachedConditionsModel({} as CachedConditionsModelType);
    };

    useKeyDown(handleClose, ['Escape']);

    const handleClickOutside = () => {
        if (!shouldShowItemButtons || !isChanged) {
            handleClose();
        }
    };

    const handleDelete = (index: number) => {
        setConditionsModel((prevToolBarModel) => {
            const copy = [...prevToolBarModel];
            copy.splice(index, 1);
            return copy;
        });
    };

    const handleSubmit = () => {
        if (!isConditionsModelValid(conditionsModel)) {
            setHasError(true);
            return;
        }

        setModel(
            conditionsModel.reduce((preparedModel, [key, conditionObject], index) => ({
                ...preparedModel,
                ...getToolBarModelValue({
                    key,
                    conditionObject,
                    index,
                    preparedModel,
                }, handleDelete),
            }), {}),
        );
        setCachedConditionsModel({} as CachedConditionsModelType);
        setIsOpened(false);
        setShouldShowNewCondition(false);
    };

    const handleClear = () => {
        setModel({});
        setCachedConditionsModel({} as CachedConditionsModelType);
        setConditionsModel([]);
        setIsOpened(false);
    };

    const handleAddButtonClick = () => {
        setShouldShowNewCondition(true);
    };

    const handleTabChange = (value: string | number) => {
        setCachedConditionsModel((prevState) => ({
            ...prevState,
            [actionType]: conditionsModel,
        }));
        setActionType(value as ToolBarActions);
        setConditionsModel(cachedConditionsModel[value as ToolBarActions] || getConditionsModel(model));
        setShouldShowNewCondition(false);
    };

    const handleToolBarClick = () => {
        if (hasError) {
            setHasError(false);
        }
    };

    const toolBarContext = useMemo<ToolbarItemContext>(() => ({
        schema,
        actionType,
        setModel,
        model,
        conditionsModel,
        selectPlaceholder,
        isMouseEvent,
        setIsMouseEvent,
        handleDelete,
        setConditionsModel,
    }), [model, conditionsModel, isMouseEvent, actionType]);

    return (
        <Box data-testid="tool-bar-container">
            {isOpened && <Overlay
                data-testid="tool-bar-overlay"
                display={{ xs: 'inherit', sm: shouldShowItemButtons && isChanged ? 'inherit' : 'none' }}
                color-variant="darker"
                onClick={handleClickOutside}
            />}
            <ToolBarButton
                className={isOpened || modelLength ? 'active' : ''}
                onClick={() => handleOpen(true)}
                data-testid="tool-bar-open-button"
            >
                {toolBarButton.icon}
                <Box component="span" display={{ xs: 'none', sm: 'inherit' }}>
                    {toolBarButton.label}
                </Box>
                {!!modelLength && (
                    <StyledConditionsIndicator>
                        {modelLength}
                    </StyledConditionsIndicator>
                )}
            </ToolBarButton>
            {isOpened && (
                <ClickAwayListener onClickAway={handleClickOutside}>
                    <StyledToolBarItem
                        data-has-condition={!!dataHasCondition}
                        data-testid="tool-bar-item-container"
                        onClick={handleToolBarClick}
                    >
                        <Stack display={{ xs: 'inherit', sm: 'none' }}>
                            <StyledToolbarMobileHeader
                                container
                                data-testid="tool-bar-mobile-title"
                                justifyContent="center"
                                paddingX={3}
                                paddingTop={2.5}
                            >
                                {toolBarButton.label}
                            </StyledToolbarMobileHeader>
                        </Stack>
                        {schema.quickAction && (
                            <TabsContainer padding={0.5} margin={{ xs: 2.5, sm: 3 }}>
                                <TabBar
                                    onChange={handleTabChange}
                                    initialValue={actionType}
                                    items={[
                                        {
                                            label: schema.quickAction.label,
                                            value: ToolBarActions.QUICK_ACTIONS,
                                        },
                                        {
                                            label: 'Add conditions',
                                            value: ToolBarActions.ADD_CONDITIONS,
                                        },
                                    ]}
                                />
                            </TabsContainer>
                        )}
                        <Box component="span" display={{ xs: 'none', sm: 'inherit' }}>
                            <ToolBarItemTitle>
                                {actionType === ToolBarActions.QUICK_ACTIONS && schema.quickAction ? (
                                    schema.quickAction.title
                                ) : (
                                    toolBarTitle
                                )}
                            </ToolBarItemTitle>
                        </Box>
                        {hasError && (
                            <StyledToolBarError data-testid="tool-bar-error" marginX={3} marginBottom={3}>
                                <WarnIcon />
                                {getErrorText(schema.modelType)}
                            </StyledToolBarError>
                        )}
                        <ToolBarContextProvider context={toolBarContext}>
                            {actionType === ToolBarActions.QUICK_ACTIONS && (
                                <QuickActions />
                            )}
                            {actionType === ToolBarActions.ADD_CONDITIONS && (
                                <AddCondition
                                    toolBarTitle={toolBarTitle}
                                    setShouldShowNewCondition={setShouldShowNewCondition}
                                    shouldShowNewCondition={shouldShowNewCondition}
                                />
                            )}
                        </ToolBarContextProvider>
                        {shouldShowItemButtons && (
                            <>
                                {actionType === ToolBarActions.ADD_CONDITIONS && (
                                    <Box marginTop={{ xs: 0, sm: 3 }} marginBottom={3} marginLeft={3}>
                                        <AddButton
                                            onClick={handleAddButtonClick}
                                            data-testid="add-button"
                                            autoFocus={!isMouseEvent}
                                            disabled={
                                                hasParamWithoutCondition(conditionsModel) || shouldShowNewCondition
                                            }
                                        >
                                            <AddIcon />
                                            Add condition
                                        </AddButton>
                                    </Box>
                                )}
                                <Line />
                                <Stack
                                    direction="row"
                                    gap={{ xs: 1.125, sm: 2 }}
                                    paddingX={3}
                                    paddingTop={{ xs: 2.5, sm: 3 }}
                                    justifyContent={{ xs: 'center', sm: 'unset' }}
                                    marginBottom={{ xs: 3.375, sm: 0 }}
                                >
                                    <Box flex={{ xs: 1, sm: 0 }}>
                                        <Button fullWidth onClick={handleSubmit} data-testid="submit-button">
                                            {submitLabel}
                                        </Button>
                                    </Box>
                                    <Box flex={{ xs: 1, sm: 0 }}>
                                        <Button fullWidth onClick={handleClear} variant="secondary" data-testid="clear-button">
                                            {clearLabel}
                                        </Button>
                                    </Box>
                                </Stack>
                            </>
                        )}
                    </StyledToolBarItem>
                </ClickAwayListener>
            )}
        </Box>
    );
};

export default ToolBarItem;
