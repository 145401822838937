import React, { FC } from 'react';
import { Grid, GridProps } from '@mui/material';
import { StyledInfo, StyledStack } from './ContactInfoItem.styles';
import { CopyIcon } from 'src/components/CopyIcon';
import { GRID_DEFAULT_TOOLTIP_PROPS } from 'src/constants';
import StyledTooltip from 'src/components/Tooltip';
import { StyledHeaderName } from 'src/components/TableSection/HeaderSection/HeaderSection.styles';
import { useOverflowTooltip } from 'src/hooks/useOverflowTooltip';

type ContactInfoItemProps = {
    label: string;
    value?: string | null;
    gridProps: GridProps;
    canCopy?: boolean;
    children?: React.ReactNode;
};

const ContactInfoItem: FC<ContactInfoItemProps> = ({
    label,
    value,
    gridProps,
    canCopy,
    children,
}) => {
    const { ref, tooltipTitle } = useOverflowTooltip(value);

    return (
        <Grid item {...gridProps}>
            <StyledHeaderName>{label}</StyledHeaderName>
            <StyledStack flexDirection="row" alignItems="center" gap={1}>
                <StyledTooltip {...GRID_DEFAULT_TOOLTIP_PROPS} title={tooltipTitle}>
                    <StyledInfo ref={ref}>{value}</StyledInfo>
                </StyledTooltip>
                {children}
                {canCopy && value && (<CopyIcon content={value} hiddenIcon />)}
            </StyledStack>
        </Grid>
    );
};

export default ContactInfoItem;
