import dayjs from 'dayjs';
import { BillingRecordModel, SubscriptionModel, SubscriptionUserModel } from 'src/@types/subscription-service-api';
import type { ManageActionType } from 'src/components/ManageActions/ManageActions';
import {
    DISALLOWED_ACTION_DUE_TO_SUBSCRIPTION_STATUS_MESSAGE,
    editableSubscriptionStatuses,
    InvoiceStatus,
    MONTHLY_RENEWAL_DAYS,
    SUBSCRIPTION_RENEWAL_DAYS,
    SubscriptionAction,
    SubscriptionBillingType,
    SubscriptionStatus,
    SubscriptionType,
    UdbBillingSectionModal,
    UdbManagerSectionModal,
    UdbManageSubscriptionActions,
} from 'src/constants';

export const isBillingAllowedToRenew = ({ invoiceStatus, contractSigned }: BillingRecordModel) => {
    return invoiceStatus === InvoiceStatus.PAID || contractSigned;
};

const getBillingRecordDisableMessage = (billingRecord?: BillingRecordModel): string | undefined => {
    if (!billingRecord) {
        return;
    }

    if (billingRecord.invoiceStatus || billingRecord.contractSigned) {
        return 'Adjusting information for this renewal is prohibited';
    }
};

export const isRenewalDisabled = (
    {
        expirationDate,
        billingRecord,
        hasMonthlyBillingCycle,
    }: {
        expirationDate: string;
        billingRecord?: BillingRecordModel;
        hasMonthlyBillingCycle: boolean;
    },
) => {
    const today = dayjs();
    const renewalPeriodStartDate = dayjs(expirationDate)
        .subtract(hasMonthlyBillingCycle ? MONTHLY_RENEWAL_DAYS : SUBSCRIPTION_RENEWAL_DAYS, 'days');

    const isRenewalPeriodStartedToday = today.isSame(renewalPeriodStartDate, 'days');
    const isRenewalPeriodStartedAfterToday = today.isAfter(renewalPeriodStartDate, 'days');
    const isInRenewalPeriod = isRenewalPeriodStartedAfterToday || isRenewalPeriodStartedToday;

    const isRenewalForbidden = isInRenewalPeriod && (
        billingRecord && isBillingAllowedToRenew(billingRecord)
    );

    return !isInRenewalPeriod || isRenewalForbidden;
};

export const getUpcomingRenewalAction = (
    { expirationDate, status, type, customAttributes }: SubscriptionModel,
    billingRecord?: BillingRecordModel,
) => {
    const excludedStatusesForUpcomingRenewal = [
        SubscriptionStatus.SERVICE_ENDED,
        SubscriptionStatus.TERMINATED,
        SubscriptionStatus.PAUSED,
    ];

    const hideUpcomingRenewalAction = excludedStatusesForUpcomingRenewal.includes(status)
      || type !== SubscriptionType.ENTERPRISE
      || !expirationDate;

    if (hideUpcomingRenewalAction) {
        return;
    }

    const hasMonthlyBillingCycle = Number(customAttributes?.cycleLengthInMonths) === 1;
    const daysAmount = hasMonthlyBillingCycle ? MONTHLY_RENEWAL_DAYS : SUBSCRIPTION_RENEWAL_DAYS;
    const billingRecordDisableMessage = getBillingRecordDisableMessage(billingRecord);
    const isDisabled = isRenewalDisabled({
        expirationDate,
        billingRecord,
        hasMonthlyBillingCycle,
    });
    const disableMessage = isDisabled
        ? `This functionality will become available ${daysAmount} days before the renewal date`
        : '';

    return {
        [UdbManageSubscriptionActions.upcomingRenewal]: {
            label: 'Upcoming renewal',
            disabled: !!billingRecordDisableMessage || isDisabled,
            tooltipTitle: billingRecordDisableMessage || disableMessage,
        },
    };
};

export const getUdbManageSubscriptionActionsList = ({
    getActionExtra,
    isActionAllowed,
    billingRecord,
    canManage,
    subscription,
}: {
    isActionAllowed: (actionKey: SubscriptionAction) => boolean;
    getActionExtra: (actionKey: SubscriptionAction) => Record<string, unknown> | null;
    subscription?: SubscriptionModel;
    billingRecord?: BillingRecordModel;
    canManage?: boolean;
}): Partial<Record<UdbManageSubscriptionActions, ManageActionType>> => {
    const {
        status,
        type,
        billingType,
        customAttributes,
        activationDate,
    } = subscription || {};

    if (!canManage) {
        return {
            ...(status !== SubscriptionStatus.DRAFT && {
                [UdbManageSubscriptionActions.getUsageData]: { label: 'Get usage data' },
            }),
        };
    }

    const isActivateActionDisabled = !isActionAllowed(SubscriptionAction.ACTIVATE);
    const isActive = status === SubscriptionStatus.ACTIVE;
    const isDraft = status === SubscriptionStatus.DRAFT;
    const hideUpdateGracePeriod =
        type === SubscriptionType.INSIDER && billingType === SubscriptionBillingType.AUTO_RENEWAL;
    const allowAssignStripeCustomer =
        !customAttributes?.stripeCustomerId &&
        billingType !== SubscriptionBillingType.FREE &&
        status !== SubscriptionStatus.TERMINATED;

    const canAdjustPricingTerms = isActionAllowed(SubscriptionAction.CUSTOM_PRICING_TERMS_UPDATE)
    || isActionAllowed(SubscriptionAction.CUSTOM_PRICING_TERMS_CREATE);

    return {
        ...(isDraft && billingType !== SubscriptionBillingType.AUTO_RENEWAL && {
            [UdbManageSubscriptionActions.activateSubscription]: {
                label: 'Activate subscription',
                disabled: isActivateActionDisabled,
                tooltipTitle: isActivateActionDisabled ? 'Activation conditions have not been met' : '',
                actionExtraProps: {
                    ...getActionExtra(SubscriptionAction.ACTIVATE),
                    initialActivationDate: activationDate,
                },
            },
        }),
        ...(isActionAllowed(SubscriptionAction.CANCEL) && { [UdbManageSubscriptionActions.cancelSubscription]: { label: 'Cancel subscription' } }),
        ...(isActionAllowed(SubscriptionAction.RESTORE) && { [UdbManageSubscriptionActions.restoreSubscription]: { label: 'Restore subscription' } }),
        ...(isActionAllowed(SubscriptionAction.PAUSE) && { [UdbManageSubscriptionActions.pauseSubscription]: { label: 'Pause subscription' } }),
        ...(isActionAllowed(SubscriptionAction.RESUME) && { [UdbManageSubscriptionActions.resumeSubscription]: { label: 'Resume subscription' } }),
        ...(isActionAllowed(SubscriptionAction.TERMINATE) && { [UdbManageSubscriptionActions.terminateSubscription]: { label: 'Terminate subscription' } }),
        ...(isActionAllowed(SubscriptionAction.REACTIVATE) && { [UdbManageSubscriptionActions.reactivateSubscription]: { label: 'Reactivate subscription' } }),
        ...(isActionAllowed(SubscriptionAction.EXTEND) && { [UdbManageSubscriptionActions.extendSubscription]: { label: 'Extend subscription' } }),
        ...(!isDraft && {
            [UdbManageSubscriptionActions.getUsageData]: { label: 'Get usage data' },
        }),
        ...(isActive && !hideUpdateGracePeriod && { [UdbManageSubscriptionActions.updateGracePeriod]: { label: 'Update grace period' } }),
        ...(subscription && getUpcomingRenewalAction(subscription, billingRecord)),
        ...(allowAssignStripeCustomer && { [UdbManageSubscriptionActions.assignStripeCustomer]: { label: 'Assign Stripe customer' } }),
        ...(canAdjustPricingTerms && { [UdbManageSubscriptionActions.adjustPricingTerms]: { label: 'Adjust pricing terms' } }),
        ...(isDraft && activationDate !== null && { [UdbManageSubscriptionActions.cancelScheduledActivationModal]: { label: 'Cancel scheduled activation' } }),
    };
};

export const getManagerSectionActionsList = (
    managersData: SubscriptionUserModel[],
    { status }: SubscriptionModel,
): Record<UdbManagerSectionModal, ManageActionType> => {
    const hasManagers = !!managersData.length;
    const disabledDueToSubscriptionStatus = !editableSubscriptionStatuses.includes(status);
    const tooltipTitle = disabledDueToSubscriptionStatus ? DISALLOWED_ACTION_DUE_TO_SUBSCRIPTION_STATUS_MESSAGE : '';

    return {
        [UdbManagerSectionModal.addManager]: {
            label: 'Add manager',
            disabled: disabledDueToSubscriptionStatus,
            tooltipTitle,
        },
        [UdbManagerSectionModal.changeOwner]: {
            label: 'Change owner',
            disabled: !hasManagers || disabledDueToSubscriptionStatus,
            tooltipTitle: !hasManagers ? 'Please add at least one manger first' : tooltipTitle,
        },
    };
};

export const getBillingSectionActionsList = ({ isActionAllowed, billingType, isActivationAllowed, pollingId }: {
    isActionAllowed: (actionKey: SubscriptionAction) => boolean;
    billingType: SubscriptionBillingType;
    isActivationAllowed: boolean;
    pollingId: string;
}): Partial<Record<UdbBillingSectionModal, ManageActionType>> => {
    const isAutoRenewal = billingType === SubscriptionBillingType.AUTO_RENEWAL;

    return {
        ...(!isAutoRenewal && isActionAllowed(SubscriptionAction.BILLING_RECORD_CREATE_INITIAL) && {
            [UdbBillingSectionModal.createInitialRecordModal]: { label: 'Create initial record', actionExtraProps: { isActivationAllowed, pollingId } },
        }),
    };
};
