import styled, { keyframes } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { Box } from '@mui/material';
import { INTERCOM_ICON_Z_INDEX } from 'src/constants';
import { theme as mainThemeType } from 'src/theme';

const slideLeft = keyframes`
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-200px, 0);
    opacity: 0;
  }
`;

const slideFromBottom = keyframes`
  0% {
    transform: translate(0, 200px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
`;

export const StyledToastContainer = styled(ToastContainer)<{ mainTheme: typeof mainThemeType }>`
  .slide-from-bottom {
    animation: ${slideFromBottom} 0.3s ease-out both;
  }

  .slide-left {
    animation: ${slideLeft} 0.3s ease-in both;
  }

  &&&.Toastify__toast-container {
    bottom: 48px;
    left: 48px;
    width: 348px;
    z-index: ${INTERCOM_ICON_Z_INDEX + 3};

    @media(max-width: ${({ mainTheme }) => mainTheme.breakpoints.sm}){
      bottom: 20px;
      left: 16px;
    }
  }

  .Toastify__toast {
    width: 348px;
    margin-bottom: 0;
    margin-top: 16px;
    box-shadow: none;
    font-family: ${({ mainTheme }) => mainTheme.fonts.normal};
    font-weight: 600;
    padding: 15px 12px;
    font-size: 15px;
    min-height: 50px;

    @media(max-width: ${({ mainTheme }) => mainTheme.breakpoints.sm}){
      width: 343px;
      margin-top: 8px;
      min-height: 44px;
    }
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__toast-icon {
    display: none;
  }

  .Toastify__toast-theme--light {
    color: ${({ mainTheme }) => mainTheme.palette.black};
  }

  .Toastify__toast-theme--light.Toastify__toast--default,
  .Toastify__toast-theme--light.Toastify__toast--info {
    background-color: ${({ mainTheme }) => mainTheme.palette.snackbar.infoBackground};

    .Toastify__close-button svg * {
      fill: ${({ mainTheme }) => mainTheme.palette.snackbar.infoFont};
    }
  }
  .Toastify__toast-theme--light.Toastify__toast--success {
    background-color: ${({ mainTheme }) => mainTheme.palette.snackbar.successBackground};

    .Toastify__close-button svg * {
      fill: ${({ mainTheme }) => mainTheme.palette.snackbar.successFont};
    }
  }
  .Toastify__toast-theme--light.Toastify__toast--warning {
    background-color: ${({ mainTheme }) => mainTheme.palette.snackbar.warningBackground};

    .Toastify__close-button svg * {
      fill: ${({ mainTheme }) => mainTheme.palette.snackbar.warningFont};
    }
  }
  .Toastify__toast-theme--light.Toastify__toast--error {
    background-color: ${({ mainTheme }) => mainTheme.palette.snackbar.errorBackground};

    .Toastify__close-button svg * {
      fill: ${({ mainTheme }) => mainTheme.palette.snackbar.errorFont};
    }
  }

  .Toastify__close-button {
    opacity: 1;
    align-self: center;
    height: 20px;

    & svg {
      height: 20px;
      width: 20px;
    }
  }
`;

export const StyledSnackbarBody = styled(Box)`
  display: flex;
  align-items: center;

  .snackbar-icon {
    margin-right: 8px;
    min-width: 20px;
  }
`;
