import React, { FC, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure/index';
import type { Stripe } from '@stripe/stripe-js';
import { AddPaymentMethodContentProps } from './AddPaymentMethodContent';
import { AddPaymentMethodContent } from './index';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { getCheckoutConfig } from 'src/services/sso-api';
import { SnackbarMessageVariants } from 'src/constants';
import { CheckoutConfig } from 'src/@types/sso-api';
import { useSnackbarMessage } from 'src/hooks';
import { StripeElements } from 'src/components/Stripe';
import RequestError from 'src/components/Forms/components/RequestError/RequestError';

export type AddPaymentMethodModalProps = AddPaymentMethodContentProps;

const AddPaymentMethodModal: FC<AddPaymentMethodModalProps> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [checkoutConfig, setCheckoutConfig] = useState<CheckoutConfig>();
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
    const { addMessage } = useSnackbarMessage();

    useEffect(() => {
        getCheckoutConfig()
            .then(async (config) => {
                setCheckoutConfig(config);
                setStripePromise(loadStripe(config.apiPublicKey));
            })
            .catch(() => {
                addMessage('Failed to get checkout config', SnackbarMessageVariants.ERROR);
            })
            .finally(() => setIsLoading(false));
    }, []);

    if (isLoading) {
        return <LoaderOverlay />;
    }

    if (!checkoutConfig || !stripePromise) {
        return <RequestError>Configuration issue occurred. Please reload the page or contact us</RequestError>;
    }

    return (
        <StripeElements
            stripePromise={stripePromise}
            options={{
                mode: 'setup',
                currency: 'usd',
            }}
        >
            <AddPaymentMethodContent {...props} />
        </StripeElements>
    );
};

export default AddPaymentMethodModal;
