import React, { FC } from 'react';
import { ListItem, ListItemText, Stack } from '@mui/material';
import dayjs from 'dayjs';
import {
    ManageSubscriptionNotificationList,
    ManageSubscriptionNotificationTitle,
    ManageSubscriptionNotificationWrapper,
} from './ManageSubscriptionNotification.styles';
import { convertToUTCDate } from 'src/services/date-time-zone-converter';
import { SubscriptionActionError } from 'src/@types/subscription-service-api';
import { ReactComponent as ListCircle } from 'src/assets/icons/list-circle.svg';
import { formatDate } from 'src/services/formatters';

type ManageSubscriptionNotificationProps = {
    activationDate: string | null;
    allowed: boolean;
    errors: Array<SubscriptionActionError> | null;
};

const ManageSubscriptionNotification: FC<ManageSubscriptionNotificationProps> = ({
    allowed,
    activationDate,
    errors,
}) => {
    const titleText = () => {
        if (allowed) {
            return activationDate
                ? `This subscription is scheduled to be activated on ${formatDate(activationDate)}`
                : 'This subscription is ready to be activated';
        }

        if (activationDate) {
            const now = dayjs();
            const activation = convertToUTCDate(activationDate);
            const conditionMessage = activation.isBefore(now)
                ? 'was supposed to be activated on'
                : 'is scheduled to be activated on';

            return `This subscription ${conditionMessage} ${formatDate(activationDate)} but activation conditions are no longer fulfilled`;
        }

        return 'This subscription is not ready to be activated just yet';
    };

    return (
        <ManageSubscriptionNotificationWrapper
            paddingX={{ xs: 2, md: 4.5 }}
            paddingY={{ xs: 2.5, md: 2.5 }}
            data-testid="manage-subscription-notification"
            position="relative"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            data-allowed={allowed}
            marginBottom={{ sm: 2.5, xs: 2 }}
        >
            <ManageSubscriptionNotificationTitle variant="h4" data-testid="manage-subscription-notification-title">
                {titleText()}
            </ManageSubscriptionNotificationTitle>
            {!allowed && errors && errors.length > 0 && (
                <ManageSubscriptionNotificationList data-testid="manage-subscription-notification-list">
                    {errors?.map((error) => (
                        <ListItem key={error.message}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <ListCircle height={5} width={5} />
                                <ListItemText primary={error.message} />
                            </Stack>
                        </ListItem>
                    ))}
                </ManageSubscriptionNotificationList>
            )}
        </ManageSubscriptionNotificationWrapper>
    );
};

export default ManageSubscriptionNotification;
