import React, { FC, useEffect } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { GeneralModalKey, SnackbarMessageVariants } from 'src/constants';
import { useSnackbarMessage } from 'src/hooks';
import { LoaderOverlay } from 'src/components/LoaderOverlay';

const AddPaymentMethodResultPage: FC = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();

    const { addMessage } = useSnackbarMessage();
    const { uuid = '' } = useParams<{ uuid: string }>();

    const setupIntent = searchParams.get('setup_intent');
    const redirectStatus = searchParams.get('redirect_status');

    useEffect(() => {
        const isSuccess = redirectStatus === 'succeeded';

        if (setupIntent) {
            addMessage(
                isSuccess ? 'Payment method added successfully' : 'Failed to add payment method',
                isSuccess ? SnackbarMessageVariants.SUCCESS : SnackbarMessageVariants.ERROR,
            );
        }

        navigate({
            pathname: `/subscriptions/${uuid}`,
            hash: setupIntent && isSuccess ? GeneralModalKey.changePaymentMethodModal : location.hash,
        });
    }, [redirectStatus, setupIntent]);

    return (
        <LoaderOverlay />
    );
};

export default AddPaymentMethodResultPage;
