import styled from 'styled-components';
import { Stack, Typography } from '@mui/material';

export const FinalPriceContainer = styled(Stack)`
    border-radius: 4px;
    border: 1px ${({ theme }) => theme.palette.borderGrey} solid;
`;

export const StyledFinalPrice = styled(Typography)<{ 'data-is-normal'?: boolean }>`
    && {
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme, 'data-is-normal': isNormal }) =>
        isNormal ? theme.fonts.normal : theme.fonts.demiBold};
        text-align: right;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 16px;
        }
    }
`;

export const StyledPrice = styled(Typography)<{ 'data-font-size'?: string }>`
    && {
        font-family: ${({ theme }) => theme.fonts.normal};
        color: ${({ theme }) => theme.palette.lightGrey};
        text-align: right;
        font-size: ${({ 'data-font-size': fontSize }) => fontSize || '12px'};
        font-style: normal;
        font-weight: 450;
        line-height: normal;
        text-decoration: line-through;
        
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 16px;
        }
    }
`;

export const StyledPriceLabel = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
        
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 16px;
        }
    }
`;

export const StyledDiscountInfo = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 12px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
    }
`;
