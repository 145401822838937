import React, { FC } from 'react';
import { Box, Stack } from '@mui/material';
import PaymentMethodRow from 'src/components/PaymentMethodRow/PaymentMethodRow';
import StyledTooltip from 'src/components/Tooltip';
import { TextButton } from 'src/components/Buttons';
import { ReactComponent as RedCrossIcon } from 'src/assets/icons/red-cross-icon.svg';
import { PaymentMethodDetails } from 'src/@types/sso-api';

type PaymentMethodProps = {
    method: PaymentMethodDetails;
    isDefault: boolean;
    onRemove: (id: string) => void;
};

const PaymentMethod: FC<PaymentMethodProps> = ({ method, isDefault, onRemove }) => (
    <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
        <PaymentMethodRow paymentMethod={method} />
        <StyledTooltip
            title={isDefault ? 'Removing default payment method is not allowed' : ''}
            data-max-width="unset"
            arrow
        >
            <Box>
                <TextButton
                    data-testid="remove-icon"
                    disabled={isDefault}
                    onClick={() => onRemove(method.id)}
                >
                    <RedCrossIcon />
                </TextButton>
            </Box>
        </StyledTooltip>
    </Stack>
);

export default PaymentMethod;
