import styled from 'styled-components';
import { Box, Stack } from '@mui/material';
import { StyledIcon } from 'src/components/CopyIcon/CopyIcon.styles';

export const StyledInfo = styled(Box)`
    && {
        font-family: ${({ theme }) => theme.fonts.normal};
        color: ${({ theme }) => theme.palette.black};
        display: inline-block;
        line-height: 40px;
        font-size: 16px;
        font-weight: 450;
        text-transform: capitalize;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: fit-content;
        
        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 14px;
            line-height: 24px;
            min-height: 24px;
        }
    }
`;

export const StyledStack = styled(Stack)`
    && {
        overflow: hidden;
        
        &:hover ${StyledIcon} {
            opacity: 1;
        }
    }
`;
