import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { PageState } from 'src/@types/redux';
import { pageSelector, startPolling as startPollingAction, stopPolling as stopPollingAction } from 'src/redux/slices';
import { RootState } from 'src/redux/root-reducer';

export const usePolling = (
    pollingFn: () => Promise<void>,
) => {
    const { activePollingIds } = useSelector<RootState, PageState>(pageSelector);
    const [pollingId] = useState<string>(crypto.randomUUID());

    const dispatch = useDispatch();

    let polingInterval: NodeJS.Timeout;

    const stopPolling = () => {
        dispatch(stopPollingAction(pollingId));
        clearInterval(polingInterval);
    };

    useEffect(() => {
        if (!activePollingIds.includes(pollingId)) {
            return;
        }

        polingInterval = setInterval(() => {
            pollingFn();
        }, 5000);

        const timeout = setTimeout(() => {
            stopPolling();
        }, 60000);

        return () => {
            clearInterval(polingInterval);
            clearTimeout(timeout);
        };
    }, [activePollingIds]);

    const startPolling = () => {
        dispatch(startPollingAction(pollingId));
    };

    return { startPolling, stopPolling, pollingId };
};
