import React from 'react';
import { Stack, Typography } from '@mui/material';
import { gridColumnParams } from './schemas';
import Tooltip from 'src/components/Tooltip';
import { SettingsData } from 'src/@types/sso-api';
import { RoundedBadge } from 'src/components/Badge';
import { Status } from 'src/constants';
import { RoundedBadgeProps } from 'src/components/Badge/RoundedBadge/RoundedBadge';
import { formatDate } from 'src/services/formatters';
import type { Credentials } from 'src/@types/credentials';
import { RoundedBadgeVariant } from 'src/components/Badge/RoundedBadge/RoundedBadge.styles';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';

const roundedBadgePropsMapping: Record<Status, RoundedBadgeProps> = {
    [Status.ACTIVE]: {
        variant: RoundedBadgeVariant.GREEN,
        label: 'Active',
    },
    [Status.INACTIVE]: {
        label: 'Inactive',
    },
};

export const getCredentialsDetailsFields = (
    credentials: Credentials,
    settings: SettingsData | undefined,
) => {
    return [
        {
            title: gridColumnParams.id.column_title,
            value: credentials.id,
            gridProps: gridColumnParams.id,
        },
        {
            title: gridColumnParams.status.column_title,
            value: <RoundedBadge {...roundedBadgePropsMapping[credentials.status]} />,
            gridProps: gridColumnParams.status,
        },
        {
            title: gridColumnParams.tfaEnabled.column_title,
            value: (
                <Stack display="inline-flex" alignItems="center" direction="row" spacing={0.5}>
                    <Typography color="inherit" fontSize="inherit" fontFamily="inherit">
                        {credentials.tfaEnabled ? 'Yes' : 'No'}
                    </Typography>
                    {settings?.disableTFAForcing && (
                        <Tooltip title="Enforcement disabled">
                            <InfoIcon />
                        </Tooltip>
                    )}
                </Stack>
            ),
            gridProps: gridColumnParams.tfaEnabled,
        },
        {
            title: gridColumnParams.createdAt.column_title,
            value: formatDate(credentials.createdAt),
            gridProps: gridColumnParams.createdAt,
        },
        {
            title: gridColumnParams.updatedAt.column_title,
            value: formatDate(credentials.updatedAt),
            gridProps: gridColumnParams.updatedAt,
        },
    ];
};
