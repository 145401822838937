import React, { FC, ReactNode } from 'react';
import { Box, Stack, StackProps, TypographyProps } from '@mui/material';
import { StyledStack, UserInfoName, UserInfoText } from './UserInfoBox.styles';
import { useOverflowTooltip } from 'src/hooks/useOverflowTooltip';
import StyledTooltip from 'src/components/Tooltip';
import { RoundedBadge } from 'src/components/Badge';

type UserInfoBoxProps = {
    name: string;
    email: string;
    jobTitle?: string;
    companyName?: string | null;
    isOwner?: boolean;
    additionalRows?: Array<{ row: ReactNode, key: string, rowProps?: TypographyProps & { 'data-text-wrap'?: 'auto' } }>;
    additionalBlock?: ReactNode;
    infoBoxDisplay?: StackProps['display'];
};

const UserInfoBox: FC<UserInfoBoxProps> = ({
    jobTitle,
    companyName,
    name,
    email,
    isOwner,
    additionalRows,
    additionalBlock,
    infoBoxDisplay = 'flex',
}) => {
    const jobInfoSeparator = jobTitle && companyName && ' | ';
    const {
        ref: nameRef,
        tooltipTitle: nameTooltipTitle,
    } = useOverflowTooltip(name);

    const {
        ref: emailRef,
        tooltipTitle: emailTooltipTitle,
    } = useOverflowTooltip(email);

    return (
        <StyledStack
            data-testid="search-item-wrapper"
            gap={additionalBlock ? 1 : 0}
            display={infoBoxDisplay}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
        >
            <Box overflow="hidden" width="100%">
                <Stack direction="row" alignItems="center" marginBottom={0.5}>
                    <StyledTooltip title={nameTooltipTitle} arrow>
                        <UserInfoName ref={nameRef}>{name}</UserInfoName>
                    </StyledTooltip>
                    {isOwner && <Box marginLeft={1}><RoundedBadge label={'Owner'} /></Box>}
                </Stack>
                {(jobTitle || companyName) && (
                    <UserInfoText marginBottom={0.5}>
                        {jobTitle}{jobInfoSeparator}{companyName}
                    </UserInfoText>)}
                <StyledTooltip title={emailTooltipTitle} arrow>
                    <UserInfoText ref={emailRef} marginBottom={{ xs: infoBoxDisplay !== 'flex' ? 1 : 0, sm: 0 }}>
                        {email}
                    </UserInfoText>
                </StyledTooltip>
                {additionalRows?.map(({ row, key, rowProps }) => (
                    <UserInfoText variant="caption" key={key} {...rowProps}>{row}</UserInfoText>
                ))}
            </Box>
            {additionalBlock && (
                <Stack display="block">
                    {additionalBlock}
                </Stack>
            )}
        </StyledStack>
    );
};

export default UserInfoBox;
