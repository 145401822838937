import { Box } from '@mui/material';
import React, { FC } from 'react';
import { User } from 'src/@types/unified-db-api';
import { useOverflowTooltip } from 'src/hooks/useOverflowTooltip';
import { Description, OptionWrapper, Title } from 'src/components/Select/OwnerSelect/OwnerSelect.styles';
import StyledTooltip from 'src/components/Tooltip';
import { RoundedBadge } from 'src/components/Badge';

type OptionProps = {
    isOpen: boolean;
    manager: Omit<User, 'jobInfo'>;
    owner: Omit<User, 'jobInfo'> | null;
};

const Option: FC<OptionProps> = ({
    manager,
    owner,
    isOpen,
}) => {
    const { ref, tooltipTitle } = useOverflowTooltip(manager.fullName, isOpen);

    return (
        <OptionWrapper
            direction="row"
            flexGrow={1}
            justifyContent="space-between"
            alignItems="center"
            paddingY={1.5}
        >
            <Box>
                <StyledTooltip arrow title={tooltipTitle}>
                    <Title ref={ref}>{manager.fullName}</Title>
                </StyledTooltip>
                <Description>{manager.email.email}</Description>
            </Box>
            {manager.uuid === owner?.uuid && <RoundedBadge label="Owner" />}
        </OptionWrapper>
    );
};

export default Option;
