import styled from 'styled-components';
import { Link, Typography } from '@mui/material';
import { Section } from 'src/components/Section';

export const StyledText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.black};
    font-family: ${({ theme }) => theme.fonts.normal};
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
      
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 14px;
        line-height: 20px;
    }
  }
`;

export const StyledLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.palette.blue};
    font-family: ${({ theme }) => theme.fonts.medium};
  }
`;

export const StyledFooterText = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.demiBold};
    color: ${({ theme }) => theme.palette.black};
    text-align: center;
    font-size: 16px;
    line-height: 24px;
      
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        line-height: normal;
        font-size: 14px;
    }
  }
`;

export const CustomStyledPageSection = styled(Section)`
  && {
    margin-top: 8px;
    padding-top: 12px;

    &.MuiBox-root > span {
        display: none;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        padding-top: 8px;
        margin-top: 0;
    }
  }
`;
